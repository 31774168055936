import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVerificationCodecopy_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVerificationCodecopy_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  NAVIGATE: any;
  AydinlatmaVeUyelikKontrol: number;
  IsVerificationCodeValid: boolean;
  goToNextScreenAfterVerifyCode: any;
}

export class VerificationCodecopy_ScreenBase extends React.PureComponent<IVerificationCodecopy_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 695885, PropertyName: "value", Value: "Verify your email adress", TagName: "lblTitle_value" },
        {
          Id: 722774,
          PropertyName: "value",
          Value: "Please enter your verification code",
          TagName: "lblSubTitle_value"
        },
        {
          Id: 846142,
          PropertyName: "value",
          Value:
            "Enter the verification code sent to the email address associated with your account in the field below.",
          TagName: "lblDescription_value"
        },
        {
          Id: 617467,
          PropertyName: "placeholder",
          Value: "Verification code",
          TagName: "txtVerificationCode_placeholder"
        },
        {
          Id: 908603,
          PropertyName: "value",
          Value: "Regarding the protection of personal data",
          TagName: "Label5_1_value"
        },
        { Id: 465670, PropertyName: "value", Value: "Privacy Policy", TagName: "Label5_2_value" },
        { Id: 425332, PropertyName: "value", Value: "I have read and I agree", TagName: "Label5_3_value" },
        { Id: 740832, PropertyName: "value", Value: "MeMap", TagName: "Label8_1_value" },
        { Id: 340407, PropertyName: "value", Value: "Membership Agreement", TagName: "Label8_2_value" },
        { Id: 331003, PropertyName: "value", Value: "I have read and", TagName: "Label12_value" },
        { Id: 4724, PropertyName: "value", Value: "I agree", TagName: "Label8_3_value" },
        { Id: 854781, PropertyName: "label", Value: "Send", TagName: "btnSend_label" },
        { Id: 28660, PropertyName: "value", Value: "Log in", TagName: "lnkSignIn_value" },
        { Id: 384658, PropertyName: "value", Value: "Go back to", TagName: "lblQ_value" }
      ]
    },
    {
      Id: "43e43883-fbb7-4a75-9626-61e3f636dc13",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 695885, PropertyName: "value", Value: "Eposta adresinizi doğrulayın" },
        { Id: 722774, PropertyName: "value", Value: "Lütfen doğrulama kodunu giriniz" },
        {
          Id: 846142,
          PropertyName: "value",
          Value: "Hesabınızda kullandığınız e-posta adresine gönderilen doğrulama kodunu aşağıdaki alana girin."
        },
        { Id: 617467, PropertyName: "placeholder", Value: "Doğrulama kodu" },
        { Id: 908603, PropertyName: "value", Value: "Kişisel verilerimin korunmasına yönelik" },
        { Id: 465670, PropertyName: "value", Value: "Aydınlatma Metnini" },
        { Id: 425332, PropertyName: "value", Value: "okudum ve onaylıyorum." },
        { Id: 740832, PropertyName: "value", Value: "MeMap" },
        { Id: 340407, PropertyName: "value", Value: "Üyelik Sözleşmesini" },
        { Id: 331003, PropertyName: "value", Value: "okudum ve" },
        { Id: 4724, PropertyName: "value", Value: "onaylıyorum." },
        { Id: 854781, PropertyName: "label", Value: "Gönder" },
        { Id: 28660, PropertyName: "value", Value: "Giriş yap" },
        { Id: 384658, PropertyName: "value", Value: "'a geri dön" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      NAVIGATE: "",
      AydinlatmaVeUyelikKontrol: 0,
      IsVerificationCodeValid: false,
      goToNextScreenAfterVerifyCode: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("verificationcodecopy", "verificationcodecopy", this.props.context);
      return;
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("verificationcodecopy", "verificationcodecopy", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("854781");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("verificationcodecopy", "verificationcodecopy", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      verificationcodecopy_617467_value: undefined,
      verificationcodecopy_647170_value: false,
      verificationcodecopy_551338_value: false
    });
  }

  VerificationCodecopyComponent_465670_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VerificationCodecopy",
      "KVKK",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "702303",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VerificationCodecopyComponent_340407_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VerificationCodecopy",
      "UyelikSozlesme",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "87895",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VerificationCodecopyComponent_854781_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ck01_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "verificationcodecopy_647170_value", "value", "", "", "")
        ),
        "number"
      ),
      ck02_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "verificationcodecopy_551338_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VerificationCodecopy/VerificationCodecopyComponent_854781_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AydinlatmaVeUyelikKontrol = result?.data.aydinlatmaVeUyelikKontrol;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VerificationCodecopyComponent_854781_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VerificationCodecopyComponent_854781_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "verificationcodecopy_617467_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "verificationcodecopy_617467_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.IsVerificationCodeValid = await ReactSystemFunctions.isVerificationCodeValid(
      this,
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "verificationcodecopy_617467_value", "value", "", "", "")
      )
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterVerifyCode(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VerificationCodecopyComponent_28660_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VerificationCodecopy",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [702303, 87895] as number[];
  }
}
