import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISinifStatistics_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISinifStatistics_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  SinifSelectById: any[];
  SinifIstatistik: any[];
  DashboarAktifCheckupDonemi: any[];
  SinifCheckUpData2: any[];
  SinifCheckUpData3: any[];
  SinifCheckUpData4: any[];
  SinifCheckUpData5: any[];
  SinifCheckUpData6: any[];
  DashboardMevcutDurum: any[];
  SinifCheckIstatistik: any[];
  SinifSezonIstatistikleri: any[];
  SinifSezonIstatistikleriHeader: any[];
  isComp158943Visible: "visible" | "hidden";
  isComp673782Visible: "visible" | "hidden";
  isComp241041Visible: "visible" | "hidden";
  isComp33922Visible: "visible" | "hidden";
  isComp503676Visible: "visible" | "hidden";
  isComp929642Visible: "visible" | "hidden";
  isComp599288Visible: "visible" | "hidden";
  isComp978882Visible: "visible" | "hidden";
  isComp466823Visible: "visible" | "hidden";
  isComp898685Visible: "visible" | "hidden";
  isComp308326Visible: "visible" | "hidden";
}

export class SinifStatistics_ScreenBase extends React.PureComponent<ISinifStatistics_ScreenProps, any> {
  sinifstatistics_292008_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 436400, PropertyName: "value", Value: "FitSkor® Class Statistics", TagName: "label0_0_value" },
        { Id: 759328, PropertyName: "value", Value: "Check-Up Student Number", TagName: "Label3_value" },
        { Id: 644866, PropertyName: "value", Value: "Average FitSkor®", TagName: "Label0_0_value" },
        {
          Id: 292008,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 407697, PropertyName: "title", Value: "School Number", TagName: "TableColumn1_0_title" },
        { Id: 895341, PropertyName: "value", Value: "[datafield:schoolnumber]", TagName: "Label13_value" },
        { Id: 836138, PropertyName: "title", Value: "Student Name", TagName: "TableColumn7_title" },
        { Id: 233094, PropertyName: "value", Value: "[datafield:studentname]", TagName: "Label0_0_value" },
        { Id: 688239, PropertyName: "title", Value: "FitSkor 1", TagName: "TableColumn2_title" },
        { Id: 833105, PropertyName: "value", Value: "[datafield:fitskor1]", TagName: "Label14_value" },
        { Id: 929642, PropertyName: "title", Value: "FitSkor 2", TagName: "TableColumn3_title" },
        { Id: 916321, PropertyName: "value", Value: "[datafield:fitskor2]", TagName: "Label15_value" },
        { Id: 599288, PropertyName: "title", Value: "FitSkor 3", TagName: "TableColumn4_title" },
        { Id: 403215, PropertyName: "value", Value: "[datafield:fitskor3]", TagName: "Label16_value" },
        { Id: 978882, PropertyName: "title", Value: "FitSkor 4", TagName: "TableColumn5_title" },
        { Id: 661945, PropertyName: "value", Value: "[datafield:fitskor4]", TagName: "Label17_value" },
        { Id: 466823, PropertyName: "title", Value: "FitSkor 5", TagName: "TableColumn6_title" },
        { Id: 807996, PropertyName: "value", Value: "[datafield:fitskor5]", TagName: "Label0_0_value" },
        { Id: 898685, PropertyName: "title", Value: "FitSkor 6", TagName: "TableColumn1_title" },
        { Id: 183846, PropertyName: "value", Value: "[datafield:fitskor6]", TagName: "Label0_0_value" }
      ]
    },
    {
      Id: "43974c68-6591-4c53-abbd-3456835c6253",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 436400, PropertyName: "value", Value: "Fit Skor Sınıf İstatistik" },
        { Id: 759328, PropertyName: "value", Value: "Check-Up Öğrenci Sayısı" },
        { Id: 644866, PropertyName: "value", Value: "Ortalama FitSkor®" },
        { Id: 292008, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 407697, PropertyName: "title", Value: "Okul No" },
        { Id: 895341, PropertyName: "value", Value: "[datafield:okulno]" },
        { Id: 836138, PropertyName: "title", Value: "Öğrenci Adı" },
        { Id: 233094, PropertyName: "value", Value: "[datafield:ogrenciadi]" },
        { Id: 688239, PropertyName: "title", Value: "Fit Skor 1" },
        { Id: 833105, PropertyName: "value", Value: "[datafield:fitskor1]" },
        { Id: 929642, PropertyName: "title", Value: "Fit Skor 2" },
        { Id: 916321, PropertyName: "value", Value: "[datafield:fitskor2]" },
        { Id: 599288, PropertyName: "title", Value: "Fit Skor 3" },
        { Id: 403215, PropertyName: "value", Value: "[datafield:fitskor3]" },
        { Id: 978882, PropertyName: "title", Value: "Fit Skor 4" },
        { Id: 661945, PropertyName: "value", Value: "[datafield:fitskor4]" },
        { Id: 466823, PropertyName: "title", Value: "Fit Skor 5" },
        { Id: 807996, PropertyName: "value", Value: "[datafield:fitskor5]" },
        { Id: 898685, PropertyName: "title", Value: "Fit Skor 6" },
        { Id: 183846, PropertyName: "value", Value: "[datafield:fitskor6]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.sinifstatistics_292008_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      SinifSelectById: [],
      SinifIstatistik: [],
      DashboarAktifCheckupDonemi: [],
      SinifCheckUpData2: [],
      SinifCheckUpData3: [],
      SinifCheckUpData4: [],
      SinifCheckUpData5: [],
      SinifCheckUpData6: [],
      DashboardMevcutDurum: [],
      SinifCheckIstatistik: [],
      SinifSezonIstatistikleri: [],
      SinifSezonIstatistikleriHeader: [],
      isComp158943Visible: "hidden",
      isComp673782Visible: "hidden",
      isComp241041Visible: "hidden",
      isComp33922Visible: "hidden",
      isComp503676Visible: "hidden",
      isComp929642Visible: "hidden",
      isComp599288Visible: "hidden",
      isComp978882Visible: "hidden",
      isComp466823Visible: "hidden",
      isComp898685Visible: "hidden",
      isComp308326Visible: "hidden"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sinifstatistics", "sinifstatistics", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.SinifStatisticsPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("sinifstatistics", "sinifstatistics", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("sinifstatistics", "sinifstatistics", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.SinifStatisticsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  SinifStatisticsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sezonId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sinifId_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_5: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_6: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_7: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_8: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_10: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_11: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifStatistics/SinifStatisticsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    stateVars.SinifIstatistik = result?.data.sinifIstatistik;
    formVars.sinifstatistics_605636_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu1Tanim : null
    );
    formVars.sinifstatistics_613469_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu2Tanim : null
    );
    formVars.sinifstatistics_906265_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu3Tanim : null
    );
    formVars.sinifstatistics_60059_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu4Tanim : null
    );
    formVars.sinifstatistics_189528_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu5Tanim : null
    );
    formVars.sinifstatistics_812743_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu6Tanim : null
    );
    stateVars.isComp158943Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu2Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp673782Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu3Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp241041Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu4Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp33922Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu5Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp503676Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929642Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu2Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu3Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp978882Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu4Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466823Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu5Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898685Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp308326Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DashboarAktifCheckupDonemi = result?.data.dashboarAktifCheckupDonemi;
    stateVars.SinifCheckUpData2 = result?.data.sinifCheckUpData2;

    stateVars.SinifCheckUpData3 = result?.data.sinifCheckUpData3;

    stateVars.SinifCheckUpData4 = result?.data.sinifCheckUpData4;

    stateVars.SinifCheckUpData5 = result?.data.sinifCheckUpData5;

    stateVars.SinifCheckUpData6 = result?.data.sinifCheckUpData6;

    stateVars.DashboardMevcutDurum = result?.data.dashboardMevcutDurum;

    stateVars.SinifCheckIstatistik = result?.data.sinifCheckIstatistik;
    formVars.sinifstatistics_279590_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifCheckIstatistik?.length > 0 ? stateVars.SinifCheckIstatistik[0]?.adet : null
    );
    formVars.sinifstatistics_652506_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifCheckIstatistik?.length > 0 ? stateVars.SinifCheckIstatistik[0]?.ortalama : null
    );
    stateVars.SinifSezonIstatistikleri = result?.data.sinifSezonIstatistikleri;

    stateVars.SinifSezonIstatistikleriHeader = result?.data.sinifSezonIstatistikleriHeader;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifStatisticsPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifStatisticsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.sinifstatistics_279590_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifCheckIstatistik?.length > 0 ? this.state.SinifCheckIstatistik[0]?.adet : null
    );

    formVars.sinifstatistics_652506_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifCheckIstatistik?.length > 0 ? this.state.SinifCheckIstatistik[0]?.ortalama : null
    );

    stateVars.dataSource_459861 = this.state.DashboardMevcutDurum;
    formVars.sinifstatistics_605636_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu1Tanim : null
    );

    stateVars.isComp158943Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu2Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.sinifstatistics_613469_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu2Tanim : null
    );

    stateVars.dataSource_32899 = this.state.SinifCheckUpData2;
    stateVars.isComp673782Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu3Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.sinifstatistics_906265_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu3Tanim : null
    );

    stateVars.dataSource_179213 = this.state.SinifCheckUpData3;
    stateVars.isComp241041Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu4Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.sinifstatistics_60059_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu4Tanim : null
    );

    stateVars.dataSource_691349 = this.state.SinifCheckUpData4;
    stateVars.isComp33922Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu5Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.sinifstatistics_189528_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu5Tanim : null
    );

    stateVars.dataSource_287766 = this.state.SinifCheckUpData5;
    stateVars.isComp503676Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.sinifstatistics_812743_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu6Tanim : null
    );

    stateVars.dataSource_450594 = this.state.SinifCheckUpData6;

    stateVars.dataSource_292008 = this.state.SinifSezonIstatistikleri;
    formVars.sinifstatistics_372430_columnTitles = ReactSystemFunctions.toString(
      this,
      this.state.SinifSezonIstatistikleriHeader?.length > 0 ? this.state.SinifSezonIstatistikleriHeader[0]?.ad : null
    );

    stateVars.isComp929642Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu2Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu3Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp978882Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu4Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466823Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu5Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898685Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp308326Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifStatisticsComponent_603965_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
