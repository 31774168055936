import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupDonemiList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupDonemiList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  CheckupDonemiCount: any[];
  CheckupDonemiSearch: any[];
  SetValueOf: any;
  NAVIGATE: any;
  OkulunAktifCheckupDoneminiGuncelle: number;
  CheckupDonemiDeleteById: number;
  isCompcheckupdonemilist_3122895AuthorizationVisible: "visible" | "hidden";
}

export class CheckupDonemiList_ScreenBase extends React.PureComponent<ICheckupDonemiList_ScreenProps, any> {
  checkupdonemilist_399769_value_kuikaTableRef: React.RefObject<any>;
  checkupdonemilist_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 945050, PropertyName: "value", Value: "Check-Up Intervals", TagName: "Label3_0_value" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search...", TagName: "txtSearch_placeholder" },
        { Id: 814413, PropertyName: "label", Value: "NEW CHECK-UP INTERVAL", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblCheckupdonemi_nodatafoundmessage"
        },
        { Id: 3122895, PropertyName: "title", Value: "School", TagName: "clmSinifId_title" },
        { Id: 5833478, PropertyName: "value", Value: "[datafield:school]", TagName: "lblSinifId_value" },
        { Id: 5551194, PropertyName: "title", Value: "Check-Up Interval", TagName: "clmTanimi_title" },
        { Id: 4262295, PropertyName: "value", Value: "[datafield:description]", TagName: "lblTanimi_value" },
        { Id: 993404, PropertyName: "title", Value: "Is it active?", TagName: "TableColumn5_title" },
        { Id: 72203, PropertyName: "label", Value: "Activate it", TagName: "Button2_label" },
        {
          Id: 65010222,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this record?",
          TagName: "CheckupDonemiDeleteById_confirmation",
          IsConfirmation: true
        }
      ]
    },
    {
      Id: "3def96cb-56e6-4438-9fe7-dba3415bd183",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 945050, PropertyName: "value", Value: "Check-Up Dönemleri" },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 814413, PropertyName: "label", Value: "YENİ CHECKUP DÖNEMİ" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 3122895, PropertyName: "title", Value: "Okul" },
        { Id: 5833478, PropertyName: "value", Value: "[datafield:okulu]" },
        { Id: 5551194, PropertyName: "title", Value: "Check-Up Dönemi" },
        { Id: 4262295, PropertyName: "value", Value: "[datafield:tanimi]" },
        { Id: 993404, PropertyName: "title", Value: "Aktif Mi" },
        { Id: 72203, PropertyName: "label", Value: "Aktif Et" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.checkupdonemilist_399769_value_kuikaTableRef = React.createRef();
    this.checkupdonemilist_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      CheckupDonemiCount: [],
      CheckupDonemiSearch: [],
      SetValueOf: "",
      NAVIGATE: "",
      OkulunAktifCheckupDoneminiGuncelle: 0,
      CheckupDonemiDeleteById: 0,
      isCompcheckupdonemilist_3122895AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkupdonemilist", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.CheckupDonemiListPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcheckupdonemilist_3122895AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("checkupdonemilist", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkupdonemilist", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.CheckupDonemiListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  CheckupDonemiListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "CheckupDonemiList/CheckupDonemiListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.CheckupDonemiCount = result?.data.checkupDonemiCount;
    stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupDonemiListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupDonemiListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;
    formVars.checkupdonemilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CheckupDonemiCount?.length > 0 ? this.state.CheckupDonemiCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupDonemiListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "checkupdonemilist_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupDonemiListComponent_416063_onPressEnter1_();
    });

    return isErrorOccurred;
  };
  CheckupDonemiListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CheckupDonemiList/CheckupDonemiListComponent_416063_onPressEnter1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupDonemiCount = result?.data.checkupDonemiCount;
    stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupDonemiListComponent_416063_onPressEnter2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupDonemiListComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;
    formVars.checkupdonemilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CheckupDonemiCount?.length > 0 ? this.state.CheckupDonemiCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupDonemiListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CheckupDonemiForm", "Id", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CheckupDonemiList",
      "CheckupDonemiForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "450px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupDonemiListComponent_72203_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      checkupDonemiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "checkupdonemilist_399769_value", "id"),
        "Guid"
      ),
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "checkupdonemilist_399769_value", "okulId"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CheckupDonemiList/CheckupDonemiListComponent_72203_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OkulunAktifCheckupDoneminiGuncelle = result?.data.okulunAktifCheckupDoneminiGuncelle;
    stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupDonemiListComponent_72203_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupDonemiListComponent_72203_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupDonemiListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CheckupDonemiForm",
      "Id",
      ReactSystemFunctions.value(this, "checkupdonemilist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CheckupDonemiList",
      "CheckupDonemiForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "450px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupDonemiListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "checkupdonemilist_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "CheckupDonemiList/CheckupDonemiListComponent_505853_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.CheckupDonemiDeleteById = result?.data.checkupDonemiDeleteById;
        stateVars.CheckupDonemiCount = result?.data.checkupDonemiCount;
        stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.CheckupDonemiListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupDonemiListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;
    formVars.checkupdonemilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CheckupDonemiCount?.length > 0 ? this.state.CheckupDonemiCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupDonemiListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CheckupDonemiList/CheckupDonemiListComponent_172599_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupDonemiListComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupDonemiListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CheckupDonemiListPageInit();
    }
  }
}
