import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupDonemiForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupDonemiForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  OkulSelectAll: any[];
  CheckupDonemiSelectById: any[];
  SinifbySezon: any[];
  CheckupDonemiSave: number;
  isCompcheckupdonemiform_756197AuthorizationVisible: "visible" | "hidden";
}

export class CheckupDonemiForm_ScreenBase extends React.PureComponent<ICheckupDonemiForm_ScreenProps, any> {
  checkupdonemiform_33000_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Check-Up Interval", TagName: "lblHead_value" },
        { Id: 6235126, PropertyName: "value", Value: "School", TagName: "lblSinifId_value" },
        { Id: 33000, PropertyName: "placeholder", Value: "Choose", TagName: "selOkul_placeholder" },
        { Id: 7365591, PropertyName: "value", Value: "Inerval name", TagName: "lblTanimi_value" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "53091c71-4f99-465a-8319-64fec5f97147",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Checkup Dönemi" },
        { Id: 6235126, PropertyName: "value", Value: "Okul" },
        { Id: 33000, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 7365591, PropertyName: "value", Value: "Dönemin Adı" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.checkupdonemiform_33000_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      OkulSelectAll: [],
      CheckupDonemiSelectById: [],
      SinifbySezon: [],
      CheckupDonemiSave: 0,
      isCompcheckupdonemiform_756197AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkupdonemiform", "checkupdonemiform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.CheckupDonemiFormPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcheckupdonemiform_756197AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("checkupdonemiform", "checkupdonemiform", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkupdonemiform", "checkupdonemiform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.CheckupDonemiFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      checkupdonemiform_33000_value:
        this.state.CheckupDonemiSelectById?.length > 0 ? this.state.CheckupDonemiSelectById[0].okulId : undefined,
      checkupdonemiform_48435880_value:
        this.state.CheckupDonemiSelectById?.length > 0 ? this.state.CheckupDonemiSelectById[0].tanimi : undefined
    });
  }

  CheckupDonemiFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "CheckupDonemiForm/CheckupDonemiFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OkulSelectAll = result?.data.okulSelectAll;

    formVars.checkupdonemiform_33000_value =
      stateVars.CheckupDonemiSelectById?.length > 0 ? stateVars.CheckupDonemiSelectById[0]?.okulId : null;
    formVars.checkupdonemiform_33000_options = stateVars.OkulSelectAll;
    stateVars.CheckupDonemiSelectById = result?.data.checkupDonemiSelectById;
    formVars.checkupdonemiform_33000_value =
      stateVars.CheckupDonemiSelectById?.length > 0 ? stateVars.CheckupDonemiSelectById[0]?.okulId : null;
    formVars.checkupdonemiform_33000_options = stateVars.OkulSelectAll;
    formVars.checkupdonemiform_48435880_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupDonemiSelectById?.length > 0 ? stateVars.CheckupDonemiSelectById[0]?.tanimi : null
    );
    stateVars.SinifbySezon = result?.data.sinifbySezon;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupDonemiFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupDonemiFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.checkupdonemiform_33000_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupDonemiSelectById?.length > 0 ? this.state.CheckupDonemiSelectById[0]?.okulId : null
    );

    stateVars.dataSource_33000 = this.state.OkulSelectAll;
    formVars.checkupdonemiform_48435880_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupDonemiSelectById?.length > 0 ? this.state.CheckupDonemiSelectById[0]?.tanimi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupDonemiFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupDonemiFormComponent_33000_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupdonemiform_33000_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupdonemiform_33000_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sezonId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "checkupdonemiform_33000_value",
            "value",
            "OkulSelectAll",
            "id",
            "aktifSezonId"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CheckupDonemiForm/CheckupDonemiFormComponent_33000_onBlur",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifbySezon = result?.data.sinifbySezon;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  CheckupDonemiFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupdonemiform_33000_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupdonemiform_33000_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupdonemiform_48435880_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupdonemiform_48435880_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SezonId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "checkupdonemiform_33000_value",
            "value",
            "OkulSelectAll",
            "id",
            "aktifSezonId"
          )
        ),
        "Guid"
      ),
      Tanimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupdonemiform_48435880_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid"),
      OkulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupdonemiform_33000_value", "value", "OkulSelectAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CheckupDonemiForm/CheckupDonemiFormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupDonemiSave = result?.data.checkupDonemiSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupDonemiFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupDonemiFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
