import Axios, { AxiosResponse } from "axios";
import {
  IPendingTasksRequestDto,
  IProcessHistoryRequestDto,
  ITaskDataParameters,
  ITaskNavigationParameters,
  IWorkflowHistoryDto,
  IWorkflowInstanceDto,
  IWorkflowTaskDto
} from "../models/dto/workflow-task.dto";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();

export class WorkflowService {
  public static async ListMyTasks(request: IPendingTasksRequestDto): Promise<AxiosResponse<IWorkflowTaskDto[]>> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/Workflows/usertasks`, request);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetTaskDetail(stepInstance: string): Promise<AxiosResponse<IWorkflowTaskDto>> {
    try {
      return await Axios.get<null, AxiosResponse>(`${backendUrl}/Workflows/usertasks/${stepInstance}`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ListMyRequests(
    request: IProcessHistoryRequestDto
  ): Promise<AxiosResponse<IWorkflowInstanceDto[]>> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/Workflows/userprocesses`, request);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ListUserRequests(
    request: IWorkflowInstanceDto,
    user?: string
  ): Promise<AxiosResponse<IWorkflowInstanceDto[]>> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/Workflows/userprocesses/${user}`, request);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ShowWorkflowHistory(ProcessInstance: string): Promise<AxiosResponse<IWorkflowHistoryDto[]>> {
    try {
      return await Axios.get<null, AxiosResponse>(`${backendUrl}/Workflows/processhistory/${ProcessInstance}`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  // public static async GetWorkflowEngine(): Promise<AxiosResponse<IApplicationRegistery>> {
  //   try {
  //     return await Axios.get<null, AxiosResponse>(`${backendUrl}/Application/workflowengine`);
  //   } catch (error: any) {
  //     KMainFunctions.exceptionHandler(error);
  //     return error;
  //   }
  // }

  public static async GetNavigationParameters(key: string): Promise<AxiosResponse<ITaskNavigationParameters>> {
    try {
      return await Axios.get<null, AxiosResponse>(`${backendUrl}/Workflows/navigationparameters/${key}`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetStepScreen(processId: string, stepId: string, appId: string): Promise<AxiosResponse<any>> {
    try {
      if (appId && appId.length > 0)
        return await Axios.get<null, AxiosResponse>(
          `${backendUrl}/Workflows/stepscreen/${processId}/${stepId}/${appId}`
        );
      return await Axios.get<null, AxiosResponse>(`${backendUrl}/Workflows/stepscreen/${processId}/${stepId}`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetTaskInstanceParameters(key: string): Promise<AxiosResponse<ITaskDataParameters>> {
    try {
      return await Axios.get<null, AxiosResponse>(`${backendUrl}/Workflows/stepdata/${key}`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async AssignTask(stepKey: string, userKey: string): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/Workflows/assigntask/${stepKey}/${userKey}`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ReleaseTask(stepKey: string): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/Workflows/releasetask/${stepKey}`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}
