import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboard_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboard_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  ReadFromLocalStorage: string;
  SetLanguage: string;
  GetLanguage: string;
  MyContext: any[];
  WriteToLocalStorage: any;
  NAVIGATE: any;
  DashboardMevcutDurum: any[];
  DashboarAktifCheckupDonemi: any[];
  SinifIstatistik: any[];
  OkulKatilimIstatistikleri: any[];
  OkulKatilimIstatistikleriHeader: any[];
  OkulFitnessIstatistigi: any[];
  isComp442818Visible: "visible" | "hidden";
  isComp55404Visible: "visible" | "hidden";
  isComp545930Visible: "visible" | "hidden";
  isComp981727Visible: "visible" | "hidden";
  isComp272542Visible: "visible" | "hidden";
  isComp448896Visible: "visible" | "hidden";
  isCompdashboard_725592AuthorizationVisible: "visible" | "hidden";
}

export class Dashboard_ScreenBase extends React.PureComponent<IDashboard_ScreenProps, any> {
  dashboard_698692_value_kuikaTableRef: React.RefObject<any>;
  dashboard_2234863_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 384910, PropertyName: "value", Value: "Current State", TagName: "Label1_value" },
        {
          Id: 605636,
          PropertyName: "value",
          Value: "Total number of participants with data:",
          TagName: "Label1_value"
        },
        { Id: 381757, PropertyName: "value", Value: "128", TagName: "Label3_value" },
        { Id: 293357, PropertyName: "value", Value: "Participants with a FitSkor < 25", TagName: "Label21_value" },
        { Id: 389892, PropertyName: "value", Value: "student", TagName: "Label5_value" },
        { Id: 288269, PropertyName: "value", Value: "Participants with a FitSkor 25 - 50", TagName: "Label0_0_value" },
        { Id: 658377, PropertyName: "value", Value: "student", TagName: "Label0_2_value" },
        { Id: 795328, PropertyName: "value", Value: "Participants with a FitSkor 50 - 75", TagName: "Label0_0_value" },
        { Id: 127758, PropertyName: "value", Value: "student", TagName: "Label0_2_value" },
        { Id: 762550, PropertyName: "value", Value: "Participants with a FitSkor 75 - 100", TagName: "Label0_0_value" },
        { Id: 127965, PropertyName: "value", Value: "student", TagName: "Label0_2_value" },
        { Id: 820175, PropertyName: "value", Value: "CLASS STATISTICS", TagName: "Label33_value" },
        { Id: 78089, PropertyName: "tab", Value: "Participation", TagName: "TabItem1_0_tab" },
        {
          Id: 698692,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table0_0_nodatafoundmessage"
        },
        { Id: 217354, PropertyName: "title", Value: "Existing", TagName: "TableColumn0_0_title" },
        { Id: 433439, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label0_0_value" },
        { Id: 227493, PropertyName: "title", Value: "1.st Check-Up Period", TagName: "TableColumn0_2_title" },
        { Id: 860599, PropertyName: "value", Value: "[datafield:count1]", TagName: "Label0_0_value" },
        { Id: 55404, PropertyName: "title", Value: "2.nd Check-Up Period", TagName: "TableColumn0_3_title" },
        { Id: 540976, PropertyName: "value", Value: "[datafield:count2]", TagName: "Label0_0_value" },
        { Id: 545930, PropertyName: "title", Value: "3.rd Check-Up Period", TagName: "TableColumn0_4_title" },
        { Id: 955655, PropertyName: "value", Value: "[datafield:count3]", TagName: "Label0_0_value" },
        { Id: 981727, PropertyName: "title", Value: "4.th Check-Up Period", TagName: "TableColumn0_5_title" },
        { Id: 508121, PropertyName: "value", Value: "[datafield:count4]", TagName: "Label0_0_value" },
        { Id: 272542, PropertyName: "title", Value: "5.th Check-Up Period", TagName: "TableColumn0_6_title" },
        { Id: 130177, PropertyName: "value", Value: "[datafield:count5]", TagName: "Label0_0_value" },
        { Id: 448896, PropertyName: "title", Value: "6.th Check-Up Period", TagName: "TableColumn0_7_title" },
        { Id: 451275, PropertyName: "value", Value: "[datafield:count6]", TagName: "Label0_0_value" },
        { Id: 505503, PropertyName: "tab", Value: "FitSkor® Components", TagName: "TabItem1_2_tab" },
        {
          Id: 2234863,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblOkulfitnessistatistigi_2234863_nodatafoundmessage"
        },
        { Id: 3576646, PropertyName: "title", Value: "Class", TagName: "clmSinifAdi_3576646_title" },
        { Id: 9846594, PropertyName: "value", Value: "[datafield:classname]", TagName: "lblSinifAdi_9846594_value" },
        { Id: 2472377, PropertyName: "title", Value: "FitSkor®", TagName: "clmFitSkor_2472377_title" },
        { Id: 4422350, PropertyName: "value", Value: "[datafield:fitskor]", TagName: "lblFitSkor_4422350_value" },
        { Id: 9190147, PropertyName: "title", Value: "Heart Strength", TagName: "clmKalpGucu_9190147_title" },
        {
          Id: 6241852,
          PropertyName: "value",
          Value: "[datafield:heartstrenght]",
          TagName: "lblKalpGucu_6241852_value"
        },
        { Id: 7851066, PropertyName: "title", Value: "Flexibility", TagName: "clmEsneklik_7851066_title" },
        { Id: 4864984, PropertyName: "value", Value: "[datafield:flexibility]", TagName: "lblEsneklik_4864984_value" },
        { Id: 9612618, PropertyName: "title", Value: "Upper Extremity", TagName: "clmUstEkstremite_9612618_title" },
        {
          Id: 9619719,
          PropertyName: "value",
          Value: "[datafield:upperextremity]",
          TagName: "lblUstEkstremite_9619719_value"
        },
        { Id: 4497742, PropertyName: "title", Value: "Core", TagName: "clmGovde_4497742_title" },
        { Id: 4448855, PropertyName: "value", Value: "[datafield:core]", TagName: "lblGovde_4448855_value" },
        { Id: 5157581, PropertyName: "title", Value: "Lower Extremity", TagName: "clmAltEkstremite_5157581_title" },
        {
          Id: 6399777,
          PropertyName: "value",
          Value: "[datafield:lowerextremity]",
          TagName: "lblAltEkstremite_6399777_value"
        },
        { Id: 7746690, PropertyName: "title", Value: "BMI", TagName: "clmVucutKompozisyonu_7746690_title" },
        {
          Id: 2431820,
          PropertyName: "value",
          Value: "[datafield:bodycomposition]",
          TagName: "lblVucutKompozisyonu_2431820_value"
        },
        { Id: 705168, PropertyName: "value", Value: "Evaluated", TagName: "Label34_value" },
        { Id: 56870, PropertyName: "value", Value: "student", TagName: "Label37_value" },
        { Id: 646782, PropertyName: "value", Value: "Not evaluated", TagName: "Label34_0_value" },
        { Id: 783338, PropertyName: "value", Value: "student", TagName: "Label36_1_value" },
        { Id: 126358, PropertyName: "value", Value: "Active Period:", TagName: "Label2_value" },
        { Id: 442818, PropertyName: "title", Value: "1.st Check-Up Period", TagName: "TableColumn18_title" },
        { Id: 835194, PropertyName: "value", Value: "[datafield:count1]", TagName: "Label39_0_value" }
      ]
    },
    {
      Id: "e96e5f6e-b7bf-4a5f-958a-257355d3dcd1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 384910, PropertyName: "value", Value: "Mevcut Durum" },
        { Id: 605636, PropertyName: "value", Value: "Veriye sahip toplam öğrenci sayısı:" },
        { Id: 293357, PropertyName: "value", Value: "FitSkor’u < 25 olan" },
        { Id: 389892, PropertyName: "value", Value: "öğrenci" },
        { Id: 288269, PropertyName: "value", Value: "FitSkor’u 25-50 arası olan" },
        { Id: 658377, PropertyName: "value", Value: "öğrenci" },
        { Id: 795328, PropertyName: "value", Value: "FitSkor’u 50-75 arası olan" },
        { Id: 127758, PropertyName: "value", Value: "öğrenci" },
        { Id: 762550, PropertyName: "value", Value: "FitSkor’u 75-100 arası olan" },
        { Id: 127965, PropertyName: "value", Value: "öğrenci" },
        { Id: 126358, PropertyName: "value", Value: "Aktif Dönem:" },
        { Id: 705168, PropertyName: "value", Value: "Değerlendirilen" },
        { Id: 56870, PropertyName: "value", Value: "öğrenci" },
        { Id: 646782, PropertyName: "value", Value: "Değerlendirilmeyen" },
        { Id: 783338, PropertyName: "value", Value: "öğrenci" },
        { Id: 820175, PropertyName: "value", Value: "Katılım ve Mevcut Durum İstatistikleri" },
        { Id: 78089, PropertyName: "tab", Value: "Katılım" },
        { Id: 698692, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 217354, PropertyName: "title", Value: "Sınıf" },
        { Id: 433439, PropertyName: "value", Value: "[datafield:sinifiadi]" },
        { Id: 227493, PropertyName: "title", Value: "Mevcut" },
        { Id: 860599, PropertyName: "value", Value: "[datafield:mevcut]" },
        { Id: 442818, PropertyName: "title", Value: "1. Check-up Dönemi" },
        { Id: 835194, PropertyName: "value", Value: "[datafield:adet1]" },
        { Id: 55404, PropertyName: "title", Value: "2. Check-up Dönemi" },
        { Id: 540976, PropertyName: "value", Value: "[datafield:adet2]" },
        { Id: 545930, PropertyName: "title", Value: "3. Check-up Dönemi" },
        { Id: 955655, PropertyName: "value", Value: "[datafield:adet3]" },
        { Id: 981727, PropertyName: "title", Value: "4. Check-up Dönemi" },
        { Id: 508121, PropertyName: "value", Value: "[datafield:adet4]" },
        { Id: 272542, PropertyName: "title", Value: "5. Check-up Dönemi" },
        { Id: 130177, PropertyName: "value", Value: "[datafield:adet5]" },
        { Id: 448896, PropertyName: "title", Value: "6. Check-up Dönemi" },
        { Id: 451275, PropertyName: "value", Value: "[datafield:adet6]" },
        { Id: 505503, PropertyName: "tab", Value: "FitSkor Bileşenleri" },
        { Id: 2234863, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 3576646, PropertyName: "title", Value: "Sınıf" },
        { Id: 9846594, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 7746690, PropertyName: "title", Value: "Beden Kitle Endeksi" },
        { Id: 2431820, PropertyName: "value", Value: "[datafield:vucutkompozisyonu]" },
        { Id: 9190147, PropertyName: "title", Value: "Kalp Gücü" },
        { Id: 6241852, PropertyName: "value", Value: "[datafield:kalpgucu]" },
        { Id: 7851066, PropertyName: "title", Value: "Esneklik" },
        { Id: 4864984, PropertyName: "value", Value: "[datafield:esneklik]" },
        { Id: 9612618, PropertyName: "title", Value: "Üst Ekstremite" },
        { Id: 9619719, PropertyName: "value", Value: "[datafield:ustekstremite]" },
        { Id: 4497742, PropertyName: "title", Value: "Gövde" },
        { Id: 4448855, PropertyName: "value", Value: "[datafield:govde]" },
        { Id: 5157581, PropertyName: "title", Value: "Alt Ekstremite" },
        { Id: 6399777, PropertyName: "value", Value: "[datafield:altekstremite]" },
        { Id: 2472377, PropertyName: "title", Value: "FitSkor" },
        { Id: 4422350, PropertyName: "value", Value: "[datafield:fitskor]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboard_698692_value_kuikaTableRef = React.createRef();
    this.dashboard_2234863_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      ReadFromLocalStorage: "",
      SetLanguage: "",
      GetLanguage: "",
      MyContext: [],
      WriteToLocalStorage: "",
      NAVIGATE: "",
      DashboardMevcutDurum: [],
      DashboarAktifCheckupDonemi: [],
      SinifIstatistik: [],
      OkulKatilimIstatistikleri: [],
      OkulKatilimIstatistikleriHeader: [],
      OkulFitnessIstatistigi: [],
      isComp442818Visible: "hidden",
      isComp55404Visible: "hidden",
      isComp545930Visible: "hidden",
      isComp981727Visible: "hidden",
      isComp272542Visible: "hidden",
      isComp448896Visible: "hidden",
      isCompdashboard_725592AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboard", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.DashboardPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompdashboard_725592AuthorizationVisible", [
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "515ef1b5-9177-01ad-2582-29e5f319c421",
      "Okul",
      "Ogretmen"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("dashboard", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboard", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.DashboardPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  DashboardPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("lan");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardPageInit1_();
    });

    return isErrorOccurred;
  };
  DashboardPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage), null)) {
      stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(
        this,
        ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage)
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.DashboardPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.DashboardPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  DashboardPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardPageInit3_();
    });

    return isErrorOccurred;
  };
  DashboardPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Dashboard/DashboardPageInit3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardPageInit4_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage("lastVisitedMenuId", "400097");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardPageInit5_();
    });

    return isErrorOccurred;
  };
  DashboardPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kvvkOkudum : null),
        true
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.DashboardPageInit6_,
        "Dashboard",
        "KVKK",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "858440",
        null,
        null,
        "False",
        "90vw",
        "80vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.DashboardPageInit6_();
      });
    }

    return isErrorOccurred;
  };
  DashboardPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      checkupDonemiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      sezonId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifSezonId : null
        ),
        "Guid"
      ),
      checkupDonemiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      sezonId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifSezonId : null
        ),
        "Guid"
      ),
      sezonId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifSezonId : null
        ),
        "Guid"
      ),
      kuserId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      sezonId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifSezonId : null
        ),
        "Guid"
      ),
      checkupDonemiId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Dashboard/DashboardPageInit6_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DashboardMevcutDurum = result?.data.dashboardMevcutDurum;

    formVars.dashboard_756777_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0 ? stateVars.DashboardMevcutDurum[0]?.l1 : null
    );
    formVars.dashboard_110243_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0 ? stateVars.DashboardMevcutDurum[0]?.l2 : null
    );
    formVars.dashboard_274066_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0 ? stateVars.DashboardMevcutDurum[0]?.l3 : null
    );
    formVars.dashboard_223048_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0 ? stateVars.DashboardMevcutDurum[0]?.l4 : null
    );
    stateVars.DashboarAktifCheckupDonemi = result?.data.dashboarAktifCheckupDonemi;

    formVars.dashboard_381757_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0 ? stateVars.DashboarAktifCheckupDonemi[0]?.yapildi : null
    );
    formVars.dashboard_799399_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0 ? stateVars.DashboarAktifCheckupDonemi[0]?.yapildi : null
    );
    formVars.dashboard_25035_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0 ? stateVars.DashboarAktifCheckupDonemi[0]?.yapilmadi : null
    );
    stateVars.SinifIstatistik = result?.data.sinifIstatistik;
    formVars.dashboard_17524_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu1Tanim : null
    );
    stateVars.isComp442818Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu1Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55404Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu2Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp545930Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu3Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981727Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu4Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp272542Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu5Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp448896Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.OkulKatilimIstatistikleri = result?.data.okulKatilimIstatistikleri;

    stateVars.OkulKatilimIstatistikleriHeader = result?.data.okulKatilimIstatistikleriHeader;

    stateVars.OkulFitnessIstatistigi = result?.data.okulFitnessIstatistigi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardPageInit7_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_309677 = this.state.DashboardMevcutDurum;
    formVars.dashboard_696826_text = ReactSystemFunctions.toString(
      this,
      this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0]?.fitSkorOrtalama
        : null
    );

    formVars.dashboard_381757_value = ReactSystemFunctions.toString(
      this,
      this.state.DashboarAktifCheckupDonemi?.length > 0 ? this.state.DashboarAktifCheckupDonemi[0]?.yapildi : null
    );

    formVars.dashboard_756777_value = ReactSystemFunctions.toString(
      this,
      this.state.DashboardMevcutDurum?.length > 0 ? this.state.DashboardMevcutDurum[0]?.l1 : null
    );

    formVars.dashboard_110243_value = ReactSystemFunctions.toString(
      this,
      this.state.DashboardMevcutDurum?.length > 0 ? this.state.DashboardMevcutDurum[0]?.l2 : null
    );

    formVars.dashboard_274066_value = ReactSystemFunctions.toString(
      this,
      this.state.DashboardMevcutDurum?.length > 0 ? this.state.DashboardMevcutDurum[0]?.l3 : null
    );

    formVars.dashboard_223048_value = ReactSystemFunctions.toString(
      this,
      this.state.DashboardMevcutDurum?.length > 0 ? this.state.DashboardMevcutDurum[0]?.l4 : null
    );

    formVars.dashboard_17524_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu1Tanim : null
    );

    stateVars.dataSource_716662 = this.state.DashboarAktifCheckupDonemi;
    formVars.dashboard_133586_text = ReactSystemFunctions.toString(
      this,
      this.state.DashboarAktifCheckupDonemi?.length > 0 ? this.state.DashboarAktifCheckupDonemi[0]?.yapilmaOrani : null
    );

    formVars.dashboard_799399_value = ReactSystemFunctions.toString(
      this,
      this.state.DashboarAktifCheckupDonemi?.length > 0 ? this.state.DashboarAktifCheckupDonemi[0]?.yapildi : null
    );

    formVars.dashboard_25035_value = ReactSystemFunctions.toString(
      this,
      this.state.DashboarAktifCheckupDonemi?.length > 0 ? this.state.DashboarAktifCheckupDonemi[0]?.yapilmadi : null
    );

    stateVars.dataSource_698692 = this.state.OkulKatilimIstatistikleri;
    formVars.dashboard_399821_columnTitles = ReactSystemFunctions.toString(
      this,
      this.state.OkulKatilimIstatistikleriHeader?.length > 0 ? this.state.OkulKatilimIstatistikleriHeader[0]?.ad : null
    );

    stateVars.isComp442818Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu1Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55404Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu2Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp545930Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu3Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981727Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu4Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp272542Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu5Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp448896Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_2234863 = this.state.OkulFitnessIstatistigi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardComponent_686392_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DashboardKatilimDetay",
      "SinifId",
      ReactSystemFunctions.value(this, "dashboard_698692_value", "sinifId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Dashboard",
      "DashboardKatilimDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "561935",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardComponent_745032_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DashboardFitBilesenDetay",
      "SinifId",
      ReactSystemFunctions.value(this, "dashboard_2234863_value", "sinifId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Dashboard",
      "DashboardFitBilesenDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "515754",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [858440, 561935, 515754] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashboardPageInit();
    }
    if (diId == 858440) {
      isErrorOccurred = await this.DashboardPageInit6_();
      if (isErrorOccurred) return true;
    }
  }
}
