import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupRaporu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupRaporu_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  ComponentToPdf: string;
  OpenPdf: any;
  GoBack: any;
  GetLanguage: string;
  MyContext: any[];
  MeMapAll: any[];
  GetChartLevels: any[];
  CheckupSelectById: any[];
  OgrenciSelectById: any[];
  OkulLogo: any[];
  GetCheckupChart: any[];
  KalpGucuLine: any[];
  AltEkstremiteLine: any[];
  UstEkstremiteLine: any[];
  GovdeLine: any[];
  EsneklikLine: any[];
  VucutKompozisyonuLine: any[];
  OpenInBrowser: any;
  isComp921799Visible: "visible" | "hidden";
  isComp984311Visible: "visible" | "hidden";
  isComp980645Visible: "visible" | "hidden";
  isComp620411Visible: "visible" | "hidden";
  isComp385281Visible: "visible" | "hidden";
  isComp205531Visible: "visible" | "hidden";
  isComp212435Visible: "visible" | "hidden";
  isComp236809Visible: "visible" | "hidden";
  isComp881730Visible: "visible" | "hidden";
  isComp835064Visible: "visible" | "hidden";
  isComp799063Visible: "visible" | "hidden";
  isComp821975Visible: "visible" | "hidden";
  isComp470912Visible: "visible" | "hidden";
  isComp748069Visible: "visible" | "hidden";
  isComp71036Visible: "visible" | "hidden";
  isComp905999Visible: "visible" | "hidden";
  isComp996542Visible: "visible" | "hidden";
  isComp624519Visible: "visible" | "hidden";
  isComp109509Visible: "visible" | "hidden";
  isComp241632Visible: "visible" | "hidden";
  isComp315003Visible: "visible" | "hidden";
  isComp422144Visible: "visible" | "hidden";
  isComp525421Visible: "visible" | "hidden";
  isComp139495Visible: "visible" | "hidden";
  isComp978176Visible: "visible" | "hidden";
  isComp502207Visible: "visible" | "hidden";
  isComp805962Visible: "visible" | "hidden";
  isComp623297Visible: "visible" | "hidden";
  isComp884836Visible: "visible" | "hidden";
  isComp254208Visible: "visible" | "hidden";
  isComp386363Visible: "visible" | "hidden";
  isComp830218Visible: "visible" | "hidden";
  isComp281092Visible: "visible" | "hidden";
  isComp748471Visible: "visible" | "hidden";
  isComp189839Visible: "visible" | "hidden";
  isComp907915Visible: "visible" | "hidden";
  isComp823314Visible: "visible" | "hidden";
  isComp78528Visible: "visible" | "hidden";
  isComp353767Visible: "visible" | "hidden";
  isComp344455Visible: "visible" | "hidden";
  isComp438142Visible: "visible" | "hidden";
  isComp438785Visible: "visible" | "hidden";
  isComp803641Visible: "visible" | "hidden";
  isComp82551Visible: "visible" | "hidden";
  isComp613902Visible: "visible" | "hidden";
  isComp56136Visible: "visible" | "hidden";
  isComp400374Visible: "visible" | "hidden";
}

export class CheckupRaporu_ScreenBase extends React.PureComponent<ICheckupRaporu_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 347690, PropertyName: "value", Value: "Fitness Check-Up Report", TagName: "Label110_value" },
        { Id: 903221, PropertyName: "value", Value: "Health-Related Junior FitScore®", TagName: "Label39_value" },
        { Id: 406271, PropertyName: "value", Value: "Full Name:", TagName: "Label2_value" },
        { Id: 455626, PropertyName: "value", Value: "Age:", TagName: "Label1_0_value" },
        { Id: 141488, PropertyName: "value", Value: "Class:", TagName: "Label1_0_value" },
        { Id: 847908, PropertyName: "value", Value: "School:", TagName: "Label1_0_value" },
        { Id: 351959, PropertyName: "value", Value: "Report Date:", TagName: "Label1_0_value" },
        { Id: 401881, PropertyName: "value", Value: "What is FitScore®?", TagName: "Label14_value" },
        {
          Id: 277319,
          PropertyName: "value",
          Value:
            "FitScore® is a purpose-specific measure of physical fitness. All fitness components relevant to the goal come together to create the FitSkor® based on their contribution ratios. The FitSkor® pie chart displays your level of fitness on a scale of 1 to 100. The fill levels of individual segments represent your current status and your potential for improvement. In addition to Health-related Fitness Check-Up protocols for children, adults, and individuals over the age of 65, protocols for sports performance and occupational fitness have also been developed. FitSkor®, the world's first and only fitness assessment system, is a patented innovation in the United States, Europe, and Turkey.",
          TagName: "Label15_value"
        },
        { Id: 704330, PropertyName: "value", Value: "FitScore® Pie Chart", TagName: "Label2_value" },
        {
          Id: 251957,
          PropertyName: "value",
          Value: "What does your child's FitScore® indicate?",
          TagName: "Label2_value"
        },
        {
          Id: 917221,
          PropertyName: "value",
          Value: "Your child has undergone a physical examination with a health-related Fitness Check-Up protocol",
          TagName: "Label2_value"
        },
        { Id: 270679, PropertyName: "value", Value: "physical fitness score", TagName: "Label45_value" },
        { Id: 32000, PropertyName: "value", Value: "low", TagName: "Label34_value" },
        { Id: 33405, PropertyName: "value", Value: "it has been calculated as.", TagName: "Label33_value" },
        {
          Id: 651099,
          PropertyName: "value",
          Value:
            "Being nearly physically inactive is associated with an increased risk of various significant health problems for your child in later years, such as cardiovascular diseases. Simply increasing daily physical activities may suffice to raise your child's level of physical fitness. To rapidly and safely improve your child's physical fitness, you can seek assistance from your school's physical education teachers or MeMap instructors.",
          TagName: "Label46_value"
        },
        {
          Id: 334316,
          PropertyName: "value",
          Value: "Your child has undergone a Fitness Check-Up protocol related to his/her health, ",
          TagName: "Label0_0_value"
        },
        { Id: 43964, PropertyName: "value", Value: "and his/her physical fitness score", TagName: "Label47_value" },
        { Id: 541155, PropertyName: "value", Value: "has been calculated ", TagName: "Label0_1_value" },
        { Id: 403679, PropertyName: "value", Value: "below the average.", TagName: "Label0_3_value" },
        {
          Id: 425065,
          PropertyName: "value",
          Value:
            "As the level of physical fitness increases, the risk of experiencing health issues in later years, such as cardiovascular diseases, decreases. Daily physical activities alone are not sufficient to improve your child's physical fitness. To surpass the average and enhance your child's physical fitness, they need a structured exercise strategy tailored to their physical needs. To rapidly and safely improve your child's physical fitness, you can seek assistance from your school's physical education teachers or MeMap instructors.",
          TagName: "Label50_value"
        },
        {
          Id: 899865,
          PropertyName: "value",
          Value: "Your child has undergone a Fitness Check-Up protocol related to his/her health, ",
          TagName: "Label0_0_value"
        },
        { Id: 886948, PropertyName: "value", Value: "and his/her physical fitness score", TagName: "Label49_value" },
        { Id: 618784, PropertyName: "value", Value: "has been calculated ", TagName: "Label0_1_value" },
        { Id: 681017, PropertyName: "value", Value: "as average.", TagName: "Label0_3_value" },
        {
          Id: 667082,
          PropertyName: "value",
          Value:
            "To ensure healthy growth and reduce the risk of potential diseases in the future, it is essential to take your child's physical fitness to a more advanced level. Your child needs a structured exercise strategy tailored to their physical needs to elevate their physical fitness to the next level. To rapidly and safely improve your child's physical fitness, you can seek assistance from your school's physical education teachers or MeMap instructors.",
          TagName: "Label51_value"
        },
        {
          Id: 808251,
          PropertyName: "value",
          Value: "Your child has undergone a Fitness Check-Up protocol related to his/her health, ",
          TagName: "Label52_value"
        },
        { Id: 747850, PropertyName: "value", Value: "and his/her physical fitness score", TagName: "Label0_0_value" },
        { Id: 59900, PropertyName: "value", Value: "has been calculated ", TagName: "Label0_1_value" },
        { Id: 140071, PropertyName: "value", Value: "as average.", TagName: "Label0_3_value" },
        {
          Id: 703881,
          PropertyName: "value",
          Value:
            "Having your child's physical fitness at this level shows that you are investing in their physical health. However, you have not yet reached their full potential. To reach 100% of their potential, your child needs to engage in exercises with more technical content. Now that your child has reached this level, you can have them undergo a Skill-Related Fitness Check-Up. This way, you can assess their level of fitness related to athletic skills and make the right guidance based on their performance in fitness components associated with sports skills.",
          TagName: "Label53_value"
        },
        {
          Id: 698279,
          PropertyName: "value",
          Value: "Your child has undergone a Fitness Check-Up protocol related to his/her health, ",
          TagName: "Label0_0_value"
        },
        { Id: 122862, PropertyName: "value", Value: "and his/her physical fitness score", TagName: "Label55_value" },
        { Id: 499807, PropertyName: "value", Value: "has been calculated ", TagName: "Label0_1_value" },
        { Id: 813474, PropertyName: "value", Value: "as average.", TagName: "Label0_3_value" },
        {
          Id: 279582,
          PropertyName: "value",
          Value:
            "Regular exercise is evident, and your child likely possesses a good level of health. Your investment in their physical fitness is extending their healthy lifespan. There is no higher level for your child's age group. Now that your child has reached this stage, you can have them undergo a Skill-Related Fitness Check-Up. This way, you can assess their performance in fitness components related to athletic skills and provide appropriate guidance.",
          TagName: "Label56_value"
        },
        { Id: 158973, PropertyName: "value", Value: "FITNESS COMPONENT DETAILS", TagName: "Label95_value" },
        { Id: 212396, PropertyName: "value", Value: "Heart Strength Score:", TagName: "Label18_value" },
        { Id: 360537, PropertyName: "value", Value: "Age of Aging:", TagName: "Label25_value" },
        {
          Id: 877323,
          PropertyName: "value",
          Value:
            "Heart strength refers to cardiorespiratory endurance and is the most crucial fitness component affecting physical fitness related to health. As cardiorespiratory capacity increases, the risk of various diseases in later life, especially cardiovascular diseases, decreases. Due to its significant contribution to overall health, heart strength plays a crucial role in exercise strategies.",
          TagName: "Label21_value"
        },
        { Id: 636487, PropertyName: "value", Value: "İmportant:", TagName: "Label22_value" },
        {
          Id: 129125,
          PropertyName: "value",
          Value: "The test protocols we apply to evaluate heart strength do not indicate how healthy the heart is; ",
          TagName: "Label43_value"
        },
        {
          Id: 522516,
          PropertyName: "value",
          Value:
            "instead, they measure the endurance levels of the respiratory, circulatory, and muscular systems. The evaluation of heart health can only be conducted by doctors who specialize in cardiology.",
          TagName: "Label44_value"
        },
        { Id: 206588, PropertyName: "value", Value: "To determine heart strength,", TagName: "Label23_value" },
        { Id: 827799, PropertyName: "value", Value: "the Shuttle Run Test was used, and ", TagName: "Label4_value" },
        { Id: 706648, PropertyName: "value", Value: "the test was completed in", TagName: "Label5_value" },
        { Id: 488030, PropertyName: "value", Value: "level", TagName: "Label24_value" },
        { Id: 723817, PropertyName: "value", Value: "laps", TagName: "Label26_value" },
        { Id: 903856, PropertyName: "value", Value: "The test result has been calculated as", TagName: "Label7_value" },
        { Id: 637008, PropertyName: "value", Value: "ml/kg/min. ", TagName: "Label27_value" },
        { Id: 863519, PropertyName: "value", Value: "This result is in the ", TagName: "Label32_value" },
        { Id: 105601, PropertyName: "value", Value: "class for the age group.", TagName: "Label11_value" },
        { Id: 447540, PropertyName: "value", Value: "Lower Extremity Score:", TagName: "Label1_0_value" },
        { Id: 653515, PropertyName: "value", Value: "Age of Aging:", TagName: "Label42_value" },
        {
          Id: 462460,
          PropertyName: "value",
          Value:
            "Lower extremity strength represents the strength of your leg and hip muscles. The lower extremities are the largest and most crucial muscle group in the body. They play a critical role in strengthening the heart and are essential for healthy bone development in children. Additionally, they contribute to increased energy expenditure and improved balance.",
          TagName: "Label1_1_value"
        },
        {
          Id: 318774,
          PropertyName: "value",
          Value: "To determine lower extremity strength,",
          TagName: "Label1_0_value"
        },
        {
          Id: 996216,
          PropertyName: "value",
          Value: "the Standing Long Jump Test was conducted.",
          TagName: "Label1_1_value"
        },
        {
          Id: 138733,
          PropertyName: "value",
          Value: "The best distance was determined to be",
          TagName: "Label1_2_value"
        },
        { Id: 360654, PropertyName: "value", Value: "cm", TagName: "Label28_value" },
        { Id: 285832, PropertyName: "value", Value: "This result has been calculated as", TagName: "Label37_value" },
        { Id: 827390, PropertyName: "value", Value: "for the age group.", TagName: "Label1_8_value" },
        { Id: 647884, PropertyName: "value", Value: "Upper Extremity Score:", TagName: "Label1_0_value" },
        { Id: 796567, PropertyName: "value", Value: "Age of Aging:", TagName: "Label57_value" },
        {
          Id: 40595,
          PropertyName: "value",
          Value: "Upper extremity strength refers to the strength of your arm, chest, and shoulder muscles.",
          TagName: "Label1_2_value"
        },
        {
          Id: 563781,
          PropertyName: "value",
          Value: "To determine upper extremity strength,",
          TagName: "Label1_0_value"
        },
        {
          Id: 424738,
          PropertyName: "value",
          Value: "the Maximal Push Up Test was conducted.",
          TagName: "Label1_1_value"
        },
        { Id: 79492, PropertyName: "value", Value: "This result has been calculated as", TagName: "Label1_6_value" },
        { Id: 957414, PropertyName: "value", Value: "for the age group.", TagName: "Label1_8_value" },
        { Id: 453756, PropertyName: "value", Value: "Trunk Score:", TagName: "Label1_0_value" },
        { Id: 110392, PropertyName: "value", Value: "Age of Aging:", TagName: "Label59_value" },
        {
          Id: 5464,
          PropertyName: "value",
          Value:
            "Core strength represents the strength of the coordinated muscles in the hip, lower back, and abdominal area. Having strong muscles in these areas is necessary for maintaining good posture, improving spinal health, and preventing lower back and neck pain.",
          TagName: "Label1_1_value"
        },
        { Id: 610527, PropertyName: "value", Value: "This result has been calculated as", TagName: "Label1_6_value" },
        { Id: 975519, PropertyName: "value", Value: "for the age group.", TagName: "Label1_8_value" },
        { Id: 534166, PropertyName: "value", Value: "Flexibility Score:", TagName: "Label1_0_value" },
        { Id: 446137, PropertyName: "value", Value: "Age of Aging:", TagName: "Label61_value" },
        {
          Id: 256429,
          PropertyName: "value",
          Value:
            "Flexibility refers to the ability of muscles and joints to stretch to their normal range of motion. While flexibility may not contribute to your health as significantly as other fitness components, it enhances comfort in daily life activities by enabling more comfortable movement.",
          TagName: "Label1_1_value"
        },
        { Id: 669743, PropertyName: "value", Value: "To determine flexibility, the", TagName: "Label1_0_value" },
        {
          Id: 567048,
          PropertyName: "value",
          Value:
            "Sit & Reach Test, which evaluates the flexibility of the hamstrings and lower back muscles, was used. ",
          TagName: "Label1_1_value"
        },
        {
          Id: 675263,
          PropertyName: "value",
          Value: "The maximum stretching distance was determined to be",
          TagName: "Label1_2_value"
        },
        { Id: 653315, PropertyName: "value", Value: "cm", TagName: "Label31_value" },
        { Id: 428059, PropertyName: "value", Value: "This result has been calculated as", TagName: "Label1_6_value" },
        { Id: 180142, PropertyName: "value", Value: "for the age group.", TagName: "Label1_8_value" },
        { Id: 307542, PropertyName: "value", Value: "Body Mass Index Score:", TagName: "Label1_0_value" },
        {
          Id: 17674,
          PropertyName: "value",
          Value:
            "Body Mass Index (BMI) indicates whether body weight is at an ideal level relative to height. Being overweight can lead to health issues such as diabetes, insulin resistance, musculoskeletal disorders, and cardiovascular diseases. Conversely, having a very low BMI can negatively affect health due to hormonal imbalances.",
          TagName: "Label1_1_value"
        },
        { Id: 444833, PropertyName: "value", Value: "cm", TagName: "Label29_value" },
        { Id: 521187, PropertyName: "value", Value: "height and", TagName: "Label1_2_value" },
        { Id: 381442, PropertyName: "value", Value: "kg", TagName: "Label30_value" },
        { Id: 886650, PropertyName: "value", Value: "weight BMI is ", TagName: "Label1_4_value" },
        { Id: 893861, PropertyName: "value", Value: "calculated.", TagName: "Label1_5_value" },
        {
          Id: 82551,
          PropertyName: "value",
          Value:
            "Your child's body weight is significantly high relative to their height. This body weight poses a health risk. We recommend increasing your child's physical activity and seeking assistance from nutrition experts.",
          TagName: "Label16_value"
        },
        {
          Id: 613902,
          PropertyName: "value",
          Value:
            "Your child's body weight is excessive relative to their height. This body weight carries health risks. We recommend increasing your child's physical activity and seeking assistance from nutrition experts.",
          TagName: "Label41_value"
        },
        {
          Id: 56136,
          PropertyName: "value",
          Value:
            "Your child's body weight is slightly above the healthy level relative to their height. We recommend increasing your child's physical activity and paying attention to their nutrition",
          TagName: "Label40_value"
        },
        {
          Id: 400374,
          PropertyName: "value",
          Value:
            "Your child's body weight is within the healthy range relative to their height. We recommend continuing to pay attention to your child's physical activity and nutrition to maintain this level.",
          TagName: "Label13_value"
        },
        { Id: 829080, PropertyName: "value", Value: "Teacher's Mark", TagName: "Label2_value" },
        {
          Id: 593244,
          PropertyName: "value",
          Value:
            "To learn how exercise improves your child's health and to get more information about fitness components related to health, click here or scan the QR code below.",
          TagName: "Label0_0_value"
        },
        {
          Id: 478049,
          PropertyName: "value",
          Value: "http://memap.com.tr/en/page/cocuk-saglik",
          TagName: "Label0_2_value"
        }
      ]
    },
    {
      Id: "2a2b3792-cdb6-43cd-86fa-2d4172eb1253",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 347690, PropertyName: "value", Value: "Fitness Check-Up Raporu" },
        { Id: 903221, PropertyName: "value", Value: "Sağlığa ilişkin Junior FitSkor®" },
        { Id: 406271, PropertyName: "value", Value: "Adı soyadı :" },
        { Id: 455626, PropertyName: "value", Value: "Yaş:" },
        { Id: 141488, PropertyName: "value", Value: "Sınıfı :" },
        { Id: 847908, PropertyName: "value", Value: "Okul :" },
        { Id: 351959, PropertyName: "value", Value: "Rapor Tarihi :" },
        { Id: 401881, PropertyName: "value", Value: "FitSkor® nedir?" },
        {
          Id: 277319,
          PropertyName: "value",
          Value:
            "FitSkor® amaca yönelik fiziksel uygunluk notudur. Amaçla ilgili tüm fitness bileşenleri amaca katkı oranlarına göre bir araya gelerek FitSkor®’u oluştururlar. FitSkor® pasta grafiği 100 üzerinden ne kadar fit olduğunuzu gösterir. Bileşen dilimlerinin doluluk seviyesi mevcut durumunuzu ve ne kadar ilerleyeceğinizi gösterir. Sağlık amaçlı olarak çocuklar, yetişkinler ve 65 üstü bireyler için Fitness Check-Up protokollerinin yanı sıra sportif performans ve mesleki uygunluk protokolleri de geliştirilmiştir. Dünyanın ilk ve tek fitness değerlendirme sistemi olan FitSkor® ABD, Avrupa ve Türkiye’de patent almış bir inovasyondur."
        },
        { Id: 704330, PropertyName: "value", Value: "FitSkor® Pasta Grafiği Özeti" },
        { Id: 251957, PropertyName: "value", Value: "Çocuğunuzun FitSkor®'u ne söylüyor?" },
        {
          Id: 917221,
          PropertyName: "value",
          Value: "Çocuğunuza sağlığa ilişkin Fitness Check-Up protokolü uygulanmış ve fiziksel uygunluk"
        },
        { Id: 455132, PropertyName: "value", Value: "notu" },
        { Id: 816314, PropertyName: "value", Value: "hesaplanmıştır.  Bu not" },
        { Id: 989953, PropertyName: "value", Value: "çok düşük" },
        { Id: 616860, PropertyName: "value", Value: "olarak sınıflandırılır." },
        { Id: 651099, PropertyName: "value", Value: "Bu değerlendirmeye göre çocuğunuz, fiziksel olarak" },
        { Id: 426429, PropertyName: "value", Value: "çok hareketsiz" },
        { Id: 623354, PropertyName: "value", Value: "kabul edilir. Gelecek yıllarda oluşabilecek kalp-damar" },
        {
          Id: 718359,
          PropertyName: "value",
          Value:
            "ve metabolizma hastalıklarından korunmak için fiziksel uygunluk seviyesinin artırılması önemle tavsiye edilir. Çocuğunuzun uygunluk puanını bir üst seviyeye çıkarmak için sadece günlük fiziksel aktivitelerini artırmak bile yeterli olabilir."
        },
        {
          Id: 512695,
          PropertyName: "value",
          Value: "Çocuğunuza sağlığa ilişkin Fitness Check-Up protokolü uygulanmış ve  fiziksel uygunluk notu"
        },
        { Id: 894822, PropertyName: "value", Value: "hesaplanmıştır." },
        { Id: 290564, PropertyName: "value", Value: "Bu not" },
        { Id: 461633, PropertyName: "value", Value: "düşük" },
        { Id: 898787, PropertyName: "value", Value: "olarak sınıflandırılır." },
        { Id: 425065, PropertyName: "value", Value: "Bu değerlendirmeye göre çocuğunuz, fiziksel olarak" },
        { Id: 815602, PropertyName: "value", Value: "hareketsiz" },
        { Id: 967953, PropertyName: "value", Value: "kabul edilir. Gelecek yıllarda oluşabilecek kalp-damar" },
        {
          Id: 122901,
          PropertyName: "value",
          Value:
            "ve metabolizma hastalıklarından korunmak için fiziksel uygunluk seviyesinin artırılması önemle tavsiye edilir. Çocuğunuzun fiziksel uygunluk puanını ortalama seviyeye çıkarmak için günlük fiziksel aktiviteleri artırmaya ve haftada 2-3 gün planlanmış bir egzersiz stratejisine ihtiyaç vardır."
        },
        {
          Id: 108141,
          PropertyName: "value",
          Value: "Çocuğunuza sağlığa ilişkin Fitness Check-Up protokolü uygulanmış ve  fiziksel uygunluk notu"
        },
        { Id: 823140, PropertyName: "value", Value: "hesaplanmıştır." },
        { Id: 488657, PropertyName: "value", Value: "Bu not" },
        { Id: 851864, PropertyName: "value", Value: "ortalama" },
        { Id: 290950, PropertyName: "value", Value: "olarak sınıflandırılır." },
        { Id: 667082, PropertyName: "value", Value: "Bu değerlendirmeye göre çocuğunuz, fiziksel olarak" },
        { Id: 623544, PropertyName: "value", Value: "az hareketli" },
        { Id: 391340, PropertyName: "value", Value: "kabul edilir. Gelecek yıllarda önlenebilir hastalıkların" },
        {
          Id: 732783,
          PropertyName: "value",
          Value:
            "risklerini daha da azaltmak için fiziksel uygunluk seviyesinin artırılması tavsiye edilir. Çocuğunuzun fiziksel uygunluk puanını tatmin edici seviyeye çıkarmak için haftada 3-4 gün fiziksel ihtiyaçlarına yönelik planlanmış bir egzersiz stratejisine ihtiyaç vardır."
        },
        {
          Id: 112262,
          PropertyName: "value",
          Value: "Çocuğunuza sağlığa ilişkin Fitness Check-Up protokolü uygulanmış ve  fiziksel uygunluk notu"
        },
        { Id: 371900, PropertyName: "value", Value: "hesaplanmıştır." },
        { Id: 141494, PropertyName: "value", Value: "Bu not" },
        { Id: 789718, PropertyName: "value", Value: "tatmin edici" },
        { Id: 696843, PropertyName: "value", Value: "olarak sınıflandırılır." },
        { Id: 703881, PropertyName: "value", Value: "Bu değerlendirmeye göre çocuğunuz, fiziksel olarak" },
        { Id: 295672, PropertyName: "value", Value: "normal düzeyde hareketli" },
        { Id: 546154, PropertyName: "value", Value: "kabul edilir. Gelecek yıllarda önlenebilir" },
        {
          Id: 195627,
          PropertyName: "value",
          Value:
            "hastalıkların risklerini daha da azaltmak için fiziksel uygunluk seviyesinin artırılması tavsiye edilir. Çocuğunuzun fiziksel uygunluk puanını iyi seviyeye çıkarmak için haftada 4-5 gün fiziksel ihtiyaçlarına yönelik planlanmış bir egzersiz stratejisine ihtiyaç vardır."
        },
        {
          Id: 225496,
          PropertyName: "value",
          Value: "Çocuğunuza sağlığa ilişkin Fitness Check-Up protokolü uygulanmış ve  fiziksel uygunluk notu"
        },
        { Id: 749582, PropertyName: "value", Value: "hesaplanmıştır." },
        { Id: 701099, PropertyName: "value", Value: "Bu not" },
        { Id: 907075, PropertyName: "value", Value: "iyi" },
        { Id: 75752, PropertyName: "value", Value: "olarak sınıflandırılır." },
        { Id: 279582, PropertyName: "value", Value: "Bu değerlendirmeye göre çocuğunuz, fiziksel olarak" },
        { Id: 419846, PropertyName: "value", Value: "yeteri kadar hareketli" },
        { Id: 551919, PropertyName: "value", Value: "kabul edilir. Çocuğunuzun egzersiz/spor" },
        {
          Id: 69640,
          PropertyName: "value",
          Value:
            "yaptığı anlaşılıyor. Sağlığına yaptığınız bu yatırım için sizi kutlarız! Çocuğunuzun fiziksel uygunluk puanını çok iyi seviyeye çıkarmak için artık daha verimli ve dikkatle seçilmiş egzersizler gereklidir. Bunun için egzersiz uzmanlarından yardım almanız gerekebilir."
        },
        {
          Id: 938231,
          PropertyName: "value",
          Value: "Çocuğunuza sağlığa ilişkin Fitness Check-Up protokolü uygulanmış ve fiziksel uygunluk notu"
        },
        { Id: 289466, PropertyName: "value", Value: "hesaplanmıştır." },
        { Id: 732203, PropertyName: "value", Value: "Bu not" },
        { Id: 840409, PropertyName: "value", Value: "çok iyi" },
        { Id: 524202, PropertyName: "value", Value: "olarak sınıflandırılır." },
        { Id: 323857, PropertyName: "value", Value: "Bu değerlendirmeye göre çocuğunuz, fiziksel olarak" },
        { Id: 493962, PropertyName: "value", Value: "çok hareketli" },
        { Id: 182977, PropertyName: "value", Value: "kabul edilir. Çocuğunuzun egzersiz/spor yaptığı" },
        {
          Id: 70373,
          PropertyName: "value",
          Value:
            "anlaşılıyor. Sağlığına yaptığınız bu yatırım için sizi kutlarız! Çocuğunuz sporcu değilse daha fazla gelişim için şartları zorlamanız gerekmez. Sağlık için bu seviyesini muhafaza edecek kadar egzersiz yapması yeterlidir.  Çocuğunuzun tam potansiyeline ulaşmasını isterseniz egzersiz uzmanlarından yardım almanız gerekir."
        },
        {
          Id: 864858,
          PropertyName: "value",
          Value: "Çocuğunuza sağlığa ilişkin Fitness Check-Up protokolü uygulanmış ve fiziksel uygunluk notu"
        },
        { Id: 798523, PropertyName: "value", Value: "hesaplanmıştır." },
        { Id: 720479, PropertyName: "value", Value: "Bu not" },
        { Id: 963998, PropertyName: "value", Value: "mükemmel" },
        { Id: 541152, PropertyName: "value", Value: "olarak sınıflandırılır." },
        {
          Id: 730364,
          PropertyName: "value",
          Value:
            "Bu değerlendirmeye göre çocuğunuz, fiziksel olarak mükemmel seviyeye ulaştığı için sizi kutlarız! Çocuğunuz artık ilgilendiği spor branşına özgü Fitness Check-Up ile değerlendirilmeli. Daha fazla gelişim hedefiniz varsa eklemleri korumak ve aşırı yüklenme yaralanmalarını önlemek için egzersiz uzmanlarından yardım almanız gerekir."
        },
        { Id: 158973, PropertyName: "value", Value: "FITNESS BİLEŞEN DETAYLARI" },
        { Id: 212396, PropertyName: "value", Value: "Kalp Gücü Puanı:" },
        { Id: 278086, PropertyName: "value", Value: "(" },
        { Id: 543705, PropertyName: "value", Value: "yaş )" },
        {
          Id: 877323,
          PropertyName: "value",
          Value:
            "Kalp gücü kardiyorespiratuar dayanıklılığı ifade eder ve sağlığa ilişkin fiziksel uygunluğu etkileyen en önemli fitness bileşenidir. Kardiyorespiratuar kapasite arttıkça, kalp-damar hastalıkları başta olmak üzere, ileri yaşlarda görülebilecek birçok hastalığın riski düşer. Kalp gücünün sağlığa katkısı büyük olduğu için egzersiz stratejisinde önemli yeri vardır."
        },
        { Id: 636487, PropertyName: "value", Value: "Önemli bilgi :" },
        {
          Id: 129125,
          PropertyName: "value",
          Value:
            "Kalp gücünü ölçmek için uygulanan test protokolü çocuğunuzun solunum, dolaşım ve kas sistemlerinin dayanıklılık"
        },
        {
          Id: 522516,
          PropertyName: "value",
          Value:
            "seviyesini göstermektedir. Son derece sağlıklı bir kalbin de hareketsizlik sonucu kapasitesi düşük olabilir."
        },
        { Id: 533778, PropertyName: "value", Value: "Kalp sağlığı ile ilgili" },
        {
          Id: 707799,
          PropertyName: "value",
          Value: "değerlendirmeyi sadece kardiyoloji uzmanı olan doktorlar yapabilir."
        },
        { Id: 206588, PropertyName: "value", Value: "Kardiyorespiratuar dayanıklılığı belirlemek için" },
        { Id: 827799, PropertyName: "value", Value: "Mekik Koşusu Testi" },
        { Id: 706648, PropertyName: "value", Value: "uygulanmıştır ve test" },
        { Id: 488030, PropertyName: "value", Value: ". seviyenin" },
        { Id: 723817, PropertyName: "value", Value: ". turunda" },
        { Id: 903856, PropertyName: "value", Value: "bitirilmiştir. Testin" },
        { Id: 266751, PropertyName: "value", Value: "sonucu" },
        { Id: 637008, PropertyName: "value", Value: "ml/kg/dk" },
        { Id: 700920, PropertyName: "value", Value: "olarak hesaplanmıştır." },
        { Id: 863519, PropertyName: "value", Value: "Bu sonuç yaş grubunda" },
        { Id: 105601, PropertyName: "value", Value: "sınıfına girer." },
        { Id: 447540, PropertyName: "value", Value: "Alt Ekstremite Puanı:" },
        { Id: 660912, PropertyName: "value", Value: "(" },
        { Id: 556787, PropertyName: "value", Value: "yaş )" },
        {
          Id: 462460,
          PropertyName: "value",
          Value:
            "Alt ekstremite kuvveti, bacak ve kalça kaslarınızın kuvvetini ifade eder. Alt ekstremite vücuttaki en büyük ve en önemli kas grubudur. Kalbin güçlenmesinde kritik öneme sahiptir. Çocuklarda sağlıklı kemik gelişimi için önemlidir. Ayrıca enerji sarfiyatının artmasına ve dengenin gelişmesine katkı sağlar."
        },
        { Id: 318774, PropertyName: "value", Value: "Alt ekstremite kuvvetini belirlemek için" },
        { Id: 996216, PropertyName: "value", Value: "Durarak Uzun Atlama Testi" },
        { Id: 138733, PropertyName: "value", Value: "uygulanmıştır. En iyi mesafe" },
        { Id: 360654, PropertyName: "value", Value: "cm" },
        { Id: 748338, PropertyName: "value", Value: "olarak tespit" },
        { Id: 440710, PropertyName: "value", Value: "edilmiştir." },
        { Id: 285832, PropertyName: "value", Value: "Bu sonuç yaş grubunda" },
        { Id: 827390, PropertyName: "value", Value: "sınıfına girer." },
        { Id: 647884, PropertyName: "value", Value: "Üst Ekstremite Puanı:" },
        { Id: 266616, PropertyName: "value", Value: "(" },
        { Id: 909158, PropertyName: "value", Value: "yaş )" },
        {
          Id: 40595,
          PropertyName: "value",
          Value: "Üst ekstremite kuvveti kol, göğüs ve omuz kaslarınızın kuvvetini ifade eder."
        },
        { Id: 563781, PropertyName: "value", Value: "Üst ekstremite kuvvetini belirlemek için" },
        { Id: 424738, PropertyName: "value", Value: "Maksimal Şınav testi" },
        { Id: 161873, PropertyName: "value", Value: "uygulanmıştır. Test sonucunda maksimal tekrar" },
        { Id: 650353, PropertyName: "value", Value: "olarak tespit" },
        { Id: 261671, PropertyName: "value", Value: "edilmiştir." },
        { Id: 79492, PropertyName: "value", Value: "Bu sonuç yaş grubunda" },
        { Id: 957414, PropertyName: "value", Value: "sınıfına girer." },
        { Id: 453756, PropertyName: "value", Value: "Gövde Puanı:" },
        { Id: 590581, PropertyName: "value", Value: "(" },
        { Id: 994038, PropertyName: "value", Value: "yaş )" },
        {
          Id: 5464,
          PropertyName: "value",
          Value:
            "Gövde dayanıklılığı, uyum içinde çalışan kalça, bel ve karın kaslarının kuvvetini ifade eder. Bu kas gruplarının güçlü olması iyi postürü sağlamak, omurga sağlığını iyileştirmek, bel ve boyun ağrılarını önlemek için gereklidir."
        },
        { Id: 809410, PropertyName: "value", Value: "Gövde dayanıklılığını belirlemek için" },
        { Id: 268733, PropertyName: "value", Value: "30 Saniye Mekik Testi" },
        { Id: 384608, PropertyName: "value", Value: "uygulanmıştır. Test sonucunda maksimum tekrar sayısı" },
        { Id: 784225, PropertyName: "value", Value: "olarak tespit" },
        { Id: 499149, PropertyName: "value", Value: "edilmiştir." },
        { Id: 610527, PropertyName: "value", Value: "Bu sonuç yaş grubunda" },
        { Id: 975519, PropertyName: "value", Value: "sınıfına girer." },
        { Id: 534166, PropertyName: "value", Value: "Esneklik Puanı:" },
        { Id: 954841, PropertyName: "value", Value: "(" },
        { Id: 665589, PropertyName: "value", Value: "yaş )" },
        {
          Id: 256429,
          PropertyName: "value",
          Value:
            "Esneklik, kas ve eklemlerin normal hareket genişlikleri kadar esneyebilmesini ifade eder. Esneklik, diğer fiziksel uygunluk bileşenleri kadar sağlığınıza katkı yapmasa da daha rahat hareket edebilmeyi sağladığı için günlük yaşam aktivitelerinin daha konforlu olmasını sağlar."
        },
        {
          Id: 669743,
          PropertyName: "value",
          Value: "Esnekliği belirlemek için bacak arkası ve bel kaslarının esnekliğini değerlendiren"
        },
        { Id: 567048, PropertyName: "value", Value: "Otur-Uzan Testi" },
        { Id: 675263, PropertyName: "value", Value: "uygulanmıştır. Test sonucunda" },
        { Id: 10566, PropertyName: "value", Value: "maksimum uzanma mesafesi" },
        { Id: 653315, PropertyName: "value", Value: "cm" },
        { Id: 122042, PropertyName: "value", Value: "olarak tespit edilmiştir. Bu sonuç yaş grubunda" },
        { Id: 180142, PropertyName: "value", Value: "sınıfına girer." },
        { Id: 307542, PropertyName: "value", Value: "Beden Kitle İndeksi Puanı:" },
        {
          Id: 17674,
          PropertyName: "value",
          Value:
            "Beden kitle indeksi, boy uzunluğuna göre vücut ağırlığının ideal seviyede olup olmadığını gösterir. Fazla kilolu olmak diyabet, insülin direnci, kas-iskeket sistemi hastalıkları, kalp-damar hastalıkları gibi sağlık sorunlarına neden olur. Çok düşük olması da hormon dengesinin bozulmasına neden olduğu için sağlığı olumsuz etkiler."
        },
        { Id: 444833, PropertyName: "value", Value: "cm" },
        { Id: 521187, PropertyName: "value", Value: "boy uzunluğu ve" },
        { Id: 381442, PropertyName: "value", Value: "kg" },
        { Id: 886650, PropertyName: "value", Value: "vücut ağırlığı ile Beden Kitle İndeksi (BMI)" },
        { Id: 893861, PropertyName: "value", Value: "olarak hesaplanmıştır." },
        {
          Id: 82551,
          PropertyName: "value",
          Value:
            "Çocuğunuzun vücut ağırlığı boyuna göre çok fazla. Bu vücut ağırlığı sağlık riski taşımaktadır. Çocuğunuzun fiziksel aktivitesini arttırmanızı ve beslenme uzmanlarından yardım almanızı tavsiye ederiz."
        },
        {
          Id: 613902,
          PropertyName: "value",
          Value:
            "Çocuğunuzun vücut ağırlığı boyuna göre fazla. Bu vücut ağırlığı sağlık riski taşımaktadır. Çocuğunuzun fiziksel aktivitesini arttırmanızı ve beslenme uzmanlarından yardım almanızı tavsiye ederiz."
        },
        {
          Id: 56136,
          PropertyName: "value",
          Value:
            "Çocuğunuzun vücut ağırlığı boyuna göre sağlıklı seviyenin biraz üstünde. Çocuğunuzun fiziksel aktivitesini arttırmanızı ve beslenmesine dikkat etmenizi tavsiye ederiz."
        },
        {
          Id: 400374,
          PropertyName: "value",
          Value:
            "Çocuğunuzun vücut ağırlığı boyuna göre sağlıklı aralıkta. Bu seviyeyi korumak için çocuğunuzun fiziksel aktivitesine ve beslenmesine gösterdiğiniz özeni devam ettirmenizi tavsiye ederiz."
        },
        { Id: 829080, PropertyName: "value", Value: "Eğitmen Notu:" },
        {
          Id: 593244,
          PropertyName: "value",
          Value:
            "Çocuğunuzun fiziksel uygunluğunu artırmak için okulunuzun beden eğitimi öğretmenlerinden veya MeMap eğitmenlerinden yardım alabilirsiniz."
        },
        {
          Id: 718442,
          PropertyName: "value",
          Value:
            "Egzersizin çocuğunuzun sağlığını nasıl iyileştirdiği öğrenmek ve sağlığa ilişkin fitness bileşenleri hakkında daha fazla bilgi almak için tıklayın ya da yandaki kare kodu okutun."
        },
        { Id: 478049, PropertyName: "value", Value: "http://memap.com.tr/tr/page/cocuk-saglik" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      ComponentToPdf: "",
      OpenPdf: "",
      GoBack: "",
      GetLanguage: "",
      MyContext: [],
      MeMapAll: [],
      GetChartLevels: [],
      CheckupSelectById: [],
      OgrenciSelectById: [],
      OkulLogo: [],
      GetCheckupChart: [],
      KalpGucuLine: [],
      AltEkstremiteLine: [],
      UstEkstremiteLine: [],
      GovdeLine: [],
      EsneklikLine: [],
      VucutKompozisyonuLine: [],
      OpenInBrowser: "",
      isComp921799Visible: "hidden",
      isComp984311Visible: "hidden",
      isComp980645Visible: "hidden",
      isComp620411Visible: "hidden",
      isComp385281Visible: "hidden",
      isComp205531Visible: "hidden",
      isComp212435Visible: "hidden",
      isComp236809Visible: "hidden",
      isComp881730Visible: "hidden",
      isComp835064Visible: "hidden",
      isComp799063Visible: "hidden",
      isComp821975Visible: "hidden",
      isComp470912Visible: "hidden",
      isComp748069Visible: "hidden",
      isComp71036Visible: "hidden",
      isComp905999Visible: "hidden",
      isComp996542Visible: "hidden",
      isComp624519Visible: "hidden",
      isComp109509Visible: "hidden",
      isComp241632Visible: "hidden",
      isComp315003Visible: "hidden",
      isComp422144Visible: "hidden",
      isComp525421Visible: "hidden",
      isComp139495Visible: "hidden",
      isComp978176Visible: "hidden",
      isComp502207Visible: "hidden",
      isComp805962Visible: "hidden",
      isComp623297Visible: "hidden",
      isComp884836Visible: "hidden",
      isComp254208Visible: "hidden",
      isComp386363Visible: "hidden",
      isComp830218Visible: "hidden",
      isComp281092Visible: "hidden",
      isComp748471Visible: "hidden",
      isComp189839Visible: "hidden",
      isComp907915Visible: "hidden",
      isComp823314Visible: "hidden",
      isComp78528Visible: "hidden",
      isComp353767Visible: "hidden",
      isComp344455Visible: "hidden",
      isComp438142Visible: "hidden",
      isComp438785Visible: "hidden",
      isComp803641Visible: "hidden",
      isComp82551Visible: "hidden",
      isComp613902Visible: "hidden",
      isComp56136Visible: "hidden",
      isComp400374Visible: "hidden"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkupraporu", "checkupraporu", this.props.context);
      return;
    }

    await this.CheckupRaporuPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("checkupraporu", "checkupraporu", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkupraporu", "checkupraporu", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.CheckupRaporuPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      checkupraporu_106109_value: this.state.MyContext?.length > 0 ? this.state.MyContext[0].meMapLogo : undefined,
      checkupraporu_63879_value: this.state.OkulLogo?.length > 0 ? this.state.OkulLogo[0].dinamikLogo : undefined
    });
  }

  CheckupRaporuPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupRaporuPageInit1_();
    });

    return isErrorOccurred;
  };
  CheckupRaporuPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      olcumId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId, "Guid"),
      checkupId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId, "Guid"),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      sinifId_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sezonId_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      checkupId_6: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId, "Guid"),
      lang_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "CheckupRaporu/CheckupRaporuPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.checkupraporu_106109_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.meMapLogo : null
    );
    stateVars.MeMapAll = result?.data.meMapAll;
    stateVars.GetChartLevels = result?.data.getChartLevels;
    stateVars.CheckupSelectById = result?.data.checkupSelectById;
    formVars.checkupraporu_117694_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yas : null
    );
    formVars.checkupraporu_855199_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.olcumTarihiStr : null
    );
    formVars.checkupraporu_482277_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporu_504747_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporu_366812_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporu_716786_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporu_271706_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporu_67812_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporu_715007_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporu_110991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucu : null
    );
    formVars.checkupraporu_70133_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiKalpGucu : null
    );
    formVars.checkupraporu_34991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifKalpGucu : null
    );
    formVars.checkupraporu_835064_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporu_799063_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporu_821975_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporu_470912_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporu_748069_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporu_310175_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.kalpGucuSeviye : null
    );
    formVars.checkupraporu_525257_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.kalpGucuTur : null
    );
    formVars.checkupraporu_689188_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.kalpGucuTespit : null
    );
    formVars.checkupraporu_736688_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifKalpGucu : null
    );
    formVars.checkupraporu_238454_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremite : null
    );
    formVars.checkupraporu_355902_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiAltEkstremite : null
    );
    formVars.checkupraporu_325532_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifAltEkstremite : null
    );
    formVars.checkupraporu_905999_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporu_996542_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporu_624519_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporu_109509_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporu_241632_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporu_523110_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.altEkstremite : null
    );
    formVars.checkupraporu_575800_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifAltEkstremite : null
    );
    formVars.checkupraporu_268380_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremite : null
    );
    formVars.checkupraporu_413310_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiUstEkstremite : null
    );
    formVars.checkupraporu_853972_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifUstEkstremite : null
    );
    formVars.checkupraporu_422144_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporu_525421_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporu_139495_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporu_978176_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporu_502207_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporu_650765_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.ustEkstremite : null
    );
    formVars.checkupraporu_10824_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifUstEkstremite : null
    );
    formVars.checkupraporu_117523_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovde : null
    );
    formVars.checkupraporu_647782_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiGovde : null
    );
    formVars.checkupraporu_2012_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifGovde : null
    );
    formVars.checkupraporu_623297_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporu_884836_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporu_254208_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporu_386363_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporu_830218_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporu_862551_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.govde : null
    );
    formVars.checkupraporu_562817_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifGovde : null
    );
    formVars.checkupraporu_508262_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklik : null
    );
    formVars.checkupraporu_665352_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiEsneklik : null
    );
    formVars.checkupraporu_130023_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifEsneklik : null
    );
    formVars.checkupraporu_748471_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporu_189839_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporu_907915_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporu_823314_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporu_78528_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporu_722765_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.esneklik : null
    );
    formVars.checkupraporu_751474_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifEsneklik : null
    );
    formVars.checkupraporu_371218_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonu : null
    );
    formVars.checkupraporu_175454_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonu : null
    );
    formVars.checkupraporu_353767_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporu_344455_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporu_438142_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporu_438785_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporu_803641_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporu_829840_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.boy : null
    );
    formVars.checkupraporu_810514_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.agirlik : null
    );
    formVars.checkupraporu_139108_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.bmi : null
    );
    formVars.checkupraporu_723989_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.egitmenNotu : null
    );

    stateVars.isComp984311Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ÇOK DÜŞÜK"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp980645Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "DÜŞÜK"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp620411Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ORTALAMA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385281Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "TATMİN EDİCİ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp205531Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "İYİ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212435Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ÇOK İYİ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp236809Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "MÜKEMMEL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp881730Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiKalpGucu : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp71036Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiAltEkstremite : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315003Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiUstEkstremite : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805962Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiGovde : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp281092Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiEsneklik : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp82551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp613902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp56136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp400374Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.OgrenciSelectById = result?.data.ogrenciSelectById;
    formVars.checkupraporu_792525_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.adi : null
    );
    formVars.checkupraporu_250564_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.sinifi : null
    );
    formVars.checkupraporu_828779_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulu : null
    );
    stateVars.OkulLogo = result?.data.okulLogo;
    formVars.checkupraporu_63879_value = ReactSystemFunctions.toString(
      this,
      stateVars.OkulLogo?.length > 0 ? stateVars.OkulLogo[0]?.dinamikLogo : null
    );
    stateVars.GetCheckupChart = result?.data.getCheckupChart;
    stateVars.KalpGucuLine = result?.data.kalpGucuLine;
    stateVars.isComp835064Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821975Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp470912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp748069Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.AltEkstremiteLine = result?.data.altEkstremiteLine;
    stateVars.isComp905999Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624519Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp109509Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp241632Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.UstEkstremiteLine = result?.data.ustEkstremiteLine;
    stateVars.isComp422144Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp525421Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp978176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp502207Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GovdeLine = result?.data.govdeLine;
    stateVars.isComp623297Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.zeroLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp884836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.firstLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp254208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.secondLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386363Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.thirdLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830218Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.fourthLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.EsneklikLine = result?.data.esneklikLine;
    stateVars.isComp748471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp189839Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp907915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp823314Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78528Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.VucutKompozisyonuLine = result?.data.vucutKompozisyonuLine;
    stateVars.isComp353767Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344455Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438142Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438785Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp803641Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupRaporuPageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupRaporuPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp921799Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.ShowDownload, true) === true ? "visible" : "hidden";

    formVars.checkupraporu_106109_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.meMapLogo : null
    );

    formVars.checkupraporu_63879_value = ReactSystemFunctions.toString(
      this,
      this.state.OkulLogo?.length > 0 ? this.state.OkulLogo[0]?.dinamikLogo : null
    );

    formVars.checkupraporu_792525_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.adi : null
    );

    formVars.checkupraporu_117694_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yas : null
    );

    formVars.checkupraporu_250564_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.sinifi : null
    );

    formVars.checkupraporu_828779_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.okulu : null
    );

    formVars.checkupraporu_855199_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.olcumTarihiStr : null
    );

    formVars.checkupraporu_31410_chartText = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitSkorLbl : null
    );

    stateVars.dataSource_31410 = this.state.GetCheckupChart;
    stateVars.isComp984311Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ÇOK DÜŞÜK"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_482277_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp980645Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "DÜŞÜK"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_504747_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp620411Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ORTALAMA"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_366812_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp385281Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "TATMİN EDİCİ"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_716786_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp205531Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "İYİ"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_271706_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp212435Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ÇOK İYİ"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_67812_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp236809Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "MÜKEMMEL"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_715007_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    formVars.checkupraporu_110991_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucu : null
    );

    stateVars.isComp881730Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiKalpGucu : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_70133_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiKalpGucu : null
    );

    formVars.checkupraporu_34991_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifKalpGucu : null
    );

    formVars.checkupraporu_835064_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp835064Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_799063_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp799063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_821975_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp821975Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_470912_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp470912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_748069_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp748069Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_310175_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.kalpGucuSeviye : null
    );

    formVars.checkupraporu_525257_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.kalpGucuTur : null
    );

    formVars.checkupraporu_689188_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.kalpGucuTespit : null
    );

    formVars.checkupraporu_736688_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifKalpGucu : null
    );

    formVars.checkupraporu_238454_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremite : null
    );

    stateVars.isComp71036Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.yaslandirmaYasiAltEkstremite
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_355902_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiAltEkstremite : null
    );

    formVars.checkupraporu_325532_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifAltEkstremite : null
    );

    formVars.checkupraporu_905999_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp905999Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_996542_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp996542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_624519_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp624519Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_109509_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp109509Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_241632_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp241632Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_523110_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.altEkstremite : null
    );

    formVars.checkupraporu_575800_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifAltEkstremite : null
    );

    formVars.checkupraporu_268380_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremite : null
    );

    stateVars.isComp315003Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.yaslandirmaYasiUstEkstremite
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_413310_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiUstEkstremite : null
    );

    formVars.checkupraporu_853972_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifUstEkstremite : null
    );

    formVars.checkupraporu_422144_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp422144Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_525421_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp525421Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_139495_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp139495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_978176_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp978176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_502207_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp502207Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_650765_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.ustEkstremite : null
    );

    formVars.checkupraporu_10824_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifUstEkstremite : null
    );

    formVars.checkupraporu_117523_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovde : null
    );

    stateVars.isComp805962Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiGovde : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_647782_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiGovde : null
    );

    formVars.checkupraporu_2012_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifGovde : null
    );

    formVars.checkupraporu_623297_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp623297Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.zeroLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_884836_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp884836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.firstLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_254208_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp254208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.secondLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_386363_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp386363Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.thirdLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_830218_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp830218Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.fourthLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_862551_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.govde : null
    );

    formVars.checkupraporu_562817_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifGovde : null
    );

    formVars.checkupraporu_508262_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklik : null
    );

    stateVars.isComp281092Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiEsneklik : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_665352_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiEsneklik : null
    );

    formVars.checkupraporu_130023_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifEsneklik : null
    );

    formVars.checkupraporu_748471_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp748471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_189839_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp189839Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_907915_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp907915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_823314_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp823314Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_78528_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp78528Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_722765_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.esneklik : null
    );

    formVars.checkupraporu_751474_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifEsneklik : null
    );

    formVars.checkupraporu_371218_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonu : null
    );

    formVars.checkupraporu_175454_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonu : null
    );

    formVars.checkupraporu_353767_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp353767Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_344455_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp344455Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_438142_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp438142Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_438785_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp438785Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_803641_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp803641Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_829840_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.boy : null
    );

    formVars.checkupraporu_810514_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.agirlik : null
    );

    formVars.checkupraporu_139108_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.bmi : null
    );

    stateVars.isComp82551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp613902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp56136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp400374Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporu_723989_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.egitmenNotu : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupRaporuComponent_921799_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ComponentToPdf = await ReactSystemFunctions.componentToPDF("462381", "HIGH", "portrait");
    stateVars.OpenPdf = await ReactSystemFunctions.openPdf(
      ReactSystemFunctions.toString(this, stateVars.ComponentToPdf),
      false,
      "newTab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupRaporuComponent_447149_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupRaporuComponent_478049_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("http://memap.com.tr/tr/page/cocuk-saglik");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
