import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOkulForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOkulForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  OkulSelectById: any[];
  BolgeAll: any[];
  SehirByBolge: any[];
  SemtBySehir: any[];
  SetFocusTo: any;
  PhotoFromGallery: string;
  SetValueOf: any;
  OkulSave: number;
}

export class OkulForm_ScreenBase extends React.PureComponent<IOkulForm_ScreenProps, any> {
  okulform_502372_value_kuikaDateRef: React.RefObject<any>;
  okulform_805346_value_kuikaSelectBoxRef: React.RefObject<any>;
  okulform_91863_value_kuikaSelectBoxRef: React.RefObject<any>;
  okulform_757607_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "School Description", TagName: "lblHead_value" },
        { Id: 824846, PropertyName: "value", Value: "School Logo", TagName: "Label1_0_value" },
        { Id: 7029084, PropertyName: "value", Value: "School Name", TagName: "lblAdi_value" },
        { Id: 348257, PropertyName: "value", Value: "License", TagName: "Label1_0_value" },
        { Id: 297476, PropertyName: "value", Value: "Teacher Number", TagName: "Label1_0_value" },
        { Id: 988366, PropertyName: "placeholder", Value: "15", TagName: "numLisansOgretmen_placeholder" },
        { Id: 889113, PropertyName: "value", Value: "Period Number", TagName: "Label1_0_value" },
        { Id: 559016, PropertyName: "placeholder", Value: "15", TagName: "numLisansDonem_placeholder" },
        { Id: 917353, PropertyName: "value", Value: "Student Number", TagName: "Label1_0_value" },
        { Id: 921093, PropertyName: "placeholder", Value: "15", TagName: "numLisansOgrenci_placeholder" },
        { Id: 638961, PropertyName: "value", Value: "Check-Up Number", TagName: "Label1_0_value" },
        { Id: 698625, PropertyName: "placeholder", Value: "15", TagName: "numOlcumAdedi_placeholder" },
        { Id: 999400, PropertyName: "value", Value: "License Due Date", TagName: "Label1_0_value" },
        { Id: 502372, PropertyName: "placeholder", Value: "Choose", TagName: "dtSonKullanma_placeholder" },
        { Id: 502602, PropertyName: "value", Value: "Location", TagName: "Label6_value" },
        { Id: 157048, PropertyName: "value", Value: "Region", TagName: "Label1_0_value" },
        { Id: 805346, PropertyName: "placeholder", Value: "Choose", TagName: "selBolge_placeholder" },
        { Id: 559946, PropertyName: "value", Value: "City", TagName: "Label1_0_value" },
        { Id: 91863, PropertyName: "placeholder", Value: "Choose", TagName: "selSehir_placeholder" },
        { Id: 235497, PropertyName: "value", Value: "District", TagName: "Label1_0_value" },
        { Id: 757607, PropertyName: "placeholder", Value: "Choose", TagName: "selSemt_placeholder" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "5fff40aa-2a29-4ed5-897c-9f96c9d9739a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Okul Tanım" },
        { Id: 824846, PropertyName: "value", Value: "Okulun Logosu" },
        { Id: 7029084, PropertyName: "value", Value: "Okul Adı" },
        { Id: 348257, PropertyName: "value", Value: "LİSANS" },
        { Id: 297476, PropertyName: "value", Value: "Öğretmen Adedi" },
        { Id: 988366, PropertyName: "placeholder", Value: "15" },
        { Id: 889113, PropertyName: "value", Value: "Dönem Adedi" },
        { Id: 559016, PropertyName: "placeholder", Value: "15" },
        { Id: 917353, PropertyName: "value", Value: "Öğrenci Adedi" },
        { Id: 921093, PropertyName: "placeholder", Value: "15" },
        { Id: 638961, PropertyName: "value", Value: "Ölçüm Adedi" },
        { Id: 698625, PropertyName: "placeholder", Value: "15" },
        { Id: 999400, PropertyName: "value", Value: "Son Kullanma Tarihi" },
        { Id: 502372, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 502602, PropertyName: "value", Value: "KONUM" },
        { Id: 157048, PropertyName: "value", Value: "Bölge" },
        { Id: 805346, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 559946, PropertyName: "value", Value: "Şehir" },
        { Id: 91863, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 235497, PropertyName: "value", Value: "Semt" },
        { Id: 757607, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.okulform_502372_value_kuikaDateRef = React.createRef();
    this.okulform_805346_value_kuikaSelectBoxRef = React.createRef();
    this.okulform_91863_value_kuikaSelectBoxRef = React.createRef();
    this.okulform_757607_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      OkulSelectById: [],
      BolgeAll: [],
      SehirByBolge: [],
      SemtBySehir: [],
      SetFocusTo: "",
      PhotoFromGallery: "",
      SetValueOf: "",
      OkulSave: 0
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("okulform", "okulform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OkulFormPageInit();

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("okulform", "okulform", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("okulform", "okulform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OkulFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      okulform_992115_value: this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0].logo : undefined,
      okulform_96735240_value: this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0].adi : undefined,
      okulform_988366_value:
        this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0].lisansOgretmenAdedi : undefined,
      okulform_559016_value:
        this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0].lisansDonemAdedi : undefined,
      okulform_921093_value:
        this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0].lisansOgrenciAdedi : undefined,
      okulform_698625_value:
        this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0].lisansOlcumAdedi : undefined,
      okulform_502372_value:
        this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0].lisansExpirationDate : undefined
    });
  }

  OkulFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OkulForm/OkulFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OkulSelectById = result?.data.okulSelectById;
    formVars.okulform_992115_value = ReactSystemFunctions.toString(
      this,
      stateVars.OkulSelectById?.length > 0 ? stateVars.OkulSelectById[0]?.logo : null
    );
    formVars.okulform_96735240_value = ReactSystemFunctions.toString(
      this,
      stateVars.OkulSelectById?.length > 0 ? stateVars.OkulSelectById[0]?.adi : null
    );
    formVars.okulform_988366_value = ReactSystemFunctions.value(
      this,
      stateVars.OkulSelectById?.length > 0 ? stateVars.OkulSelectById[0]?.lisansOgretmenAdedi : null
    );
    formVars.okulform_559016_value = ReactSystemFunctions.value(
      this,
      stateVars.OkulSelectById?.length > 0 ? stateVars.OkulSelectById[0]?.lisansDonemAdedi : null
    );
    formVars.okulform_921093_value = ReactSystemFunctions.value(
      this,
      stateVars.OkulSelectById?.length > 0 ? stateVars.OkulSelectById[0]?.lisansOgrenciAdedi : null
    );
    formVars.okulform_698625_value = ReactSystemFunctions.value(
      this,
      stateVars.OkulSelectById?.length > 0 ? stateVars.OkulSelectById[0]?.lisansOlcumAdedi : null
    );
    formVars.okulform_502372_value = ReactSystemFunctions.value(
      this,
      stateVars.OkulSelectById?.length > 0 ? stateVars.OkulSelectById[0]?.lisansExpirationDate : null
    );
    stateVars.BolgeAll = result?.data.bolgeAll;

    formVars.okulform_805346_options = stateVars.BolgeAll;
    stateVars.SehirByBolge = result?.data.sehirByBolge;

    formVars.okulform_91863_options = stateVars.SehirByBolge;
    stateVars.SemtBySehir = result?.data.semtBySehir;

    formVars.okulform_757607_options = stateVars.SemtBySehir;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("96735240");
    formVars.okulform_992115_value = ReactSystemFunctions.toString(
      this,
      this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.logo : null
    );

    formVars.okulform_96735240_value = ReactSystemFunctions.toString(
      this,
      this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.adi : null
    );

    formVars.okulform_988366_value = ReactSystemFunctions.value(
      this,
      this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.lisansOgretmenAdedi : null
    );

    formVars.okulform_559016_value = ReactSystemFunctions.value(
      this,
      this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.lisansDonemAdedi : null
    );

    formVars.okulform_921093_value = ReactSystemFunctions.value(
      this,
      this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.lisansOgrenciAdedi : null
    );

    formVars.okulform_698625_value = ReactSystemFunctions.value(
      this,
      this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.lisansOlcumAdedi : null
    );

    formVars.okulform_502372_value = ReactSystemFunctions.value(
      this,
      this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.lisansExpirationDate : null
    );

    stateVars.dataSource_805346 = this.state.BolgeAll;

    stateVars.dataSource_91863 = this.state.SehirByBolge;

    stateVars.dataSource_757607 = this.state.SemtBySehir;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulFormComponent_895050_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "okulform_992115_value",
      ReactSystemFunctions.value(this, stateVars.PhotoFromGallery),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulFormComponent_805346_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      bolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_805346_value", "value", "BolgeAll", "id", "id")
        ),
        "Guid"
      ),
      sehirId_1: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OkulForm/OkulFormComponent_805346_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SehirByBolge = result?.data.sehirByBolge;

    formVars.okulform_91863_options = stateVars.SehirByBolge;
    stateVars.SemtBySehir = result?.data.semtBySehir;

    formVars.okulform_757607_options = stateVars.SemtBySehir;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulFormComponent_805346_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulFormComponent_805346_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "okulform_91863_value", null, null, "Id");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "okulform_757607_value", null, null, "Id");

    stateVars.dataSource_91863 = this.state.SehirByBolge;

    stateVars.dataSource_757607 = this.state.SemtBySehir;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulFormComponent_91863_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_91863_value", "value", "SehirByBolge", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OkulForm/OkulFormComponent_91863_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemtBySehir = result?.data.semtBySehir;

    formVars.okulform_757607_options = stateVars.SemtBySehir;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulFormComponent_91863_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulFormComponent_91863_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "okulform_757607_value", null, null, "Id");

    stateVars.dataSource_757607 = this.state.SemtBySehir;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "okulform_96735240_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "okulform_96735240_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_559016_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "okulform_559016_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_988366_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "okulform_988366_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_698625_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "okulform_698625_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_921093_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "okulform_921093_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_502372_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "okulform_502372_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "okulform_96735240_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansDonemAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_559016_value", "value", "", "", "")
        ),
        "number"
      ),
      LisansOgretmenAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_988366_value", "value", "", "", "")
        ),
        "number"
      ),
      LisansOlcumAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_698625_value", "value", "", "", "")
        ),
        "number"
      ),
      LisansOgrenciAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_921093_value", "value", "", "", "")
        ),
        "number"
      ),
      LisansExpirationDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_502372_value", "value", "", "", "")
        ),
        "Date"
      ),
      BolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_805346_value", "value", "BolgeAll", "id", "id")
        ),
        "Guid"
      ),
      SehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_91863_value", "value", "SehirByBolge", "id", "id")
        ),
        "Guid"
      ),
      SemtId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_757607_value", "value", "SemtBySehir", "id", "id")
        ),
        "Guid"
      ),
      Logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okulform_992115_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OkulForm/OkulFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OkulSave = result?.data.okulSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
