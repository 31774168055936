import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVeliOgrenciEkleme_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVeliOgrenciEkleme_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  VeliSelectById: any[];
  OgrenciSelectById: any[];
  OkulSelectAll: any[];
  SinifbySezon: any[];
  OgrenciBySinif: any[];
  SetValueOf: any;
  VeliOgrenciSave: number;
}

export class VeliOgrenciEkleme_ScreenBase extends React.PureComponent<IVeliOgrenciEkleme_ScreenProps, any> {
  veliogrenciekleme_9792_value_kuikaSelectBoxRef: React.RefObject<any>;
  veliogrenciekleme_495885_value_kuikaSelectBoxRef: React.RefObject<any>;
  veliogrenciekleme_336723_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Student Matching", TagName: "lblHead_value" },
        { Id: 6124627, PropertyName: "value", Value: "Parent Name Surname", TagName: "lblAdi_value" },
        { Id: 8221716, PropertyName: "value", Value: "School", TagName: "lblEposta_value" },
        { Id: 9792, PropertyName: "placeholder", Value: "Choose", TagName: "selOkul_placeholder" },
        { Id: 757109, PropertyName: "value", Value: "Class", TagName: "Label1_0_value" },
        { Id: 495885, PropertyName: "placeholder", Value: "Choose", TagName: "selSinif_placeholder" },
        { Id: 726602, PropertyName: "value", Value: "Student", TagName: "Label1_0_value" },
        { Id: 336723, PropertyName: "placeholder", Value: "Choose", TagName: "selOgrenci_placeholder" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "136b997c-4022-44ca-98b5-8af5fef639dc",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Öğrenci Eşleştirme" },
        { Id: 6124627, PropertyName: "value", Value: "Veli Adı Soyadı" },
        { Id: 8221716, PropertyName: "value", Value: "Okul" },
        { Id: 9792, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 757109, PropertyName: "value", Value: "Sınıf" },
        { Id: 495885, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 726602, PropertyName: "value", Value: "Öğrenci" },
        { Id: 336723, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.veliogrenciekleme_9792_value_kuikaSelectBoxRef = React.createRef();
    this.veliogrenciekleme_495885_value_kuikaSelectBoxRef = React.createRef();
    this.veliogrenciekleme_336723_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      VeliSelectById: [],
      OgrenciSelectById: [],
      OkulSelectAll: [],
      SinifbySezon: [],
      OgrenciBySinif: [],
      SetValueOf: "",
      VeliOgrenciSave: 0
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("veliogrenciekleme", "veliogrenciekleme", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.VeliOgrenciEklemePageInit();

    KuikaAppManager.calculateAndSetBodyHeight("veliogrenciekleme", "veliogrenciekleme", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("veliogrenciekleme", "veliogrenciekleme", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.VeliOgrenciEklemePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      veliogrenciekleme_9792_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].okulId : undefined,
      veliogrenciekleme_495885_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].mevcutSinifId : undefined,
      veliogrenciekleme_336723_value: this.props.screenInputs.OgrenciId
    });
  }

  VeliOgrenciEklemePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.VeliId, "Guid"),
      Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sezonId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliOgrenciEkleme/VeliOgrenciEklemePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.VeliSelectById = result?.data.veliSelectById;
    formVars.veliogrenciekleme_948017_value = ReactSystemFunctions.toString(
      this,
      stateVars.VeliSelectById?.length > 0 ? stateVars.VeliSelectById[0]?.adi : null
    );
    stateVars.OgrenciSelectById = result?.data.ogrenciSelectById;
    formVars.veliogrenciekleme_9792_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulId : null;
    formVars.veliogrenciekleme_9792_options = stateVars.OkulSelectAll;
    formVars.veliogrenciekleme_495885_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.mevcutSinifId : null;
    formVars.veliogrenciekleme_495885_options = stateVars.SinifbySezon;
    stateVars.OkulSelectAll = result?.data.okulSelectAll;

    formVars.veliogrenciekleme_9792_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulId : null;
    formVars.veliogrenciekleme_9792_options = stateVars.OkulSelectAll;
    stateVars.SinifbySezon = result?.data.sinifbySezon;

    formVars.veliogrenciekleme_495885_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.mevcutSinifId : null;
    formVars.veliogrenciekleme_495885_options = stateVars.SinifbySezon;
    stateVars.OgrenciBySinif = result?.data.ogrenciBySinif;

    formVars.veliogrenciekleme_336723_options = stateVars.OgrenciBySinif;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliOgrenciEklemePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliOgrenciEklemePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.veliogrenciekleme_948017_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0]?.adi : null
    );

    formVars.veliogrenciekleme_9792_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.okulId : null
    );

    stateVars.dataSource_9792 = this.state.OkulSelectAll;
    formVars.veliogrenciekleme_495885_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.mevcutSinifId : null
    );

    stateVars.dataSource_495885 = this.state.SinifbySezon;

    stateVars.dataSource_336723 = this.state.OgrenciBySinif;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliOgrenciEklemeComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliOgrenciEklemeComponent_9792_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sezonId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "veliogrenciekleme_9792_value",
            "value",
            "OkulSelectAll",
            "id",
            "aktifSezonId"
          )
        ),
        "Guid"
      ),
      sinifId_1: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VeliOgrenciEkleme/VeliOgrenciEklemeComponent_9792_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifbySezon = result?.data.sinifbySezon;

    formVars.veliogrenciekleme_495885_options = stateVars.SinifbySezon;
    stateVars.OgrenciBySinif = result?.data.ogrenciBySinif;

    formVars.veliogrenciekleme_336723_options = stateVars.OgrenciBySinif;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliOgrenciEklemeComponent_9792_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliOgrenciEklemeComponent_9792_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "veliogrenciekleme_495885_value",
      null,
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "veliogrenciekleme_336723_value",
      null,
      null,
      "Id"
    );

    stateVars.dataSource_495885 = this.state.SinifbySezon;

    stateVars.dataSource_336723 = this.state.OgrenciBySinif;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliOgrenciEklemeComponent_495885_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sinifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "veliogrenciekleme_495885_value", "value", "SinifbySezon", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VeliOgrenciEkleme/VeliOgrenciEklemeComponent_495885_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OgrenciBySinif = result?.data.ogrenciBySinif;

    formVars.veliogrenciekleme_336723_options = stateVars.OgrenciBySinif;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliOgrenciEklemeComponent_495885_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliOgrenciEklemeComponent_495885_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "veliogrenciekleme_336723_value",
      null,
      null,
      "Id"
    );

    stateVars.dataSource_336723 = this.state.OgrenciBySinif;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliOgrenciEklemeComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "veliogrenciekleme_336723_value", "value", "OgrenciBySinif", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "veliogrenciekleme_336723_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OgrenciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "veliogrenciekleme_336723_value", "value", "OgrenciBySinif", "id", "id")
        ),
        "Guid"
      ),
      VeliId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.VeliId, "Guid"),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VeliOgrenciEkleme/VeliOgrenciEklemeComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VeliOgrenciSave = result?.data.veliOgrenciSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliOgrenciEklemeComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliOgrenciEklemeComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
