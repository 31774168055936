import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKullaniciForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKullaniciForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  Roller: any[];
  KUserById: any[];
  OkulSelectAll: any[];
  ChangeEnabledOf: any;
  SetValueOf: any;
  ChangeVisibilityOf: string;
  GetLanguage: string;
  UserSaveLisansKontrol: any[];
  SaveUser: any;
  MemapUserSave: number;
  FindUsersToken: any[];
  SendMail: boolean;
  isComp185986Visible: "visible" | "hidden";
  isComp48486400Enabled: "enabled" | "disabled";
  isCompkullaniciform_185986AuthorizationVisible: "visible" | "hidden";
}

export class KullaniciForm_ScreenBase extends React.PureComponent<IKullaniciForm_ScreenProps, any> {
  kullaniciform_666773_value_kuikaSelectBoxRef: React.RefObject<any>;
  kullaniciform_375540_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "User form", TagName: "lblHead_value" },
        { Id: 6124627, PropertyName: "value", Value: "User email", TagName: "lblAdi_value" },
        { Id: 48486400, PropertyName: "placeholder", Value: "Type here", TagName: "txtEposta_placeholder" },
        { Id: 975430, PropertyName: "value", Value: "Name", TagName: "Label1_0_value" },
        { Id: 919027, PropertyName: "value", Value: "Surname", TagName: "Label1_0_value" },
        { Id: 7930811, PropertyName: "value", Value: "Phone", TagName: "lblTelefon_value" },
        { Id: 785362, PropertyName: "value", Value: "Role", TagName: "label1_0_value" },
        { Id: 666773, PropertyName: "placeholder", Value: "Choose", TagName: "selRole_placeholder" },
        { Id: 320372, PropertyName: "value", Value: "School", TagName: "label1_0_value" },
        { Id: 375540, PropertyName: "placeholder", Value: "Choose", TagName: "selOkul_placeholder" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "7e868c7f-e18c-4fdf-b60d-c3ef1e3d33b2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Kullanıcı Formu" },
        { Id: 6124627, PropertyName: "value", Value: "Kullanıcı Eposta" },
        { Id: 48486400, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 975430, PropertyName: "value", Value: "Adı" },
        { Id: 919027, PropertyName: "value", Value: "Soyadı" },
        { Id: 7930811, PropertyName: "value", Value: "Telefon" },
        { Id: 785362, PropertyName: "value", Value: "Rol" },
        { Id: 666773, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 320372, PropertyName: "value", Value: "Okul" },
        { Id: 375540, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.kullaniciform_666773_value_kuikaSelectBoxRef = React.createRef();
    this.kullaniciform_375540_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      Roller: [],
      KUserById: [],
      OkulSelectAll: [],
      ChangeEnabledOf: "",
      SetValueOf: "",
      ChangeVisibilityOf: "",
      GetLanguage: "",
      UserSaveLisansKontrol: [],
      SaveUser: "",
      MemapUserSave: 0,
      FindUsersToken: [],
      SendMail: false,
      isComp185986Visible: "hidden",
      isComp48486400Enabled: "enabled",
      isCompkullaniciform_185986AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.KullaniciFormPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullaniciform_185986AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.KullaniciFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kullaniciform_48486400_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].userName : undefined,
      kullaniciform_602812_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].firstName : undefined,
      kullaniciform_41490_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].lastName : undefined,
      kullaniciform_26438440_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].phoneNumber : undefined,
      kullaniciform_666773_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].rolename : undefined,
      kullaniciform_375540_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].okulId : undefined
    });
  }

  KullaniciFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.KUserId, "Guid"),
      lang_2: ReactSystemFunctions.convertToTypeByName("tr_Tr", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "KullaniciForm/KullaniciFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.Roller = result?.data.roller;

    formVars.kullaniciform_666773_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.rolename : null;
    formVars.kullaniciform_666773_options = stateVars.Roller;
    stateVars.KUserById = result?.data.kUserById;
    formVars.kullaniciform_48486400_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.userName : null
    );
    formVars.kullaniciform_602812_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.firstName : null
    );
    formVars.kullaniciform_41490_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.lastName : null
    );
    formVars.kullaniciform_26438440_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.phoneNumber : null
    );
    formVars.kullaniciform_666773_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.rolename : null;
    formVars.kullaniciform_666773_options = stateVars.Roller;
    formVars.kullaniciform_375540_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.okulId : null;
    formVars.kullaniciform_375540_options = stateVars.OkulSelectAll;
    stateVars.isComp185986Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.rolename : null),
        "MeMap"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.OkulSelectAll = result?.data.okulSelectAll;

    formVars.kullaniciform_375540_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.okulId : null;
    formVars.kullaniciform_375540_options = stateVars.OkulSelectAll;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.isNew : null),
        "0"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp48486400Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.kullaniciform_48486400_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.userName : null
    );

    formVars.kullaniciform_602812_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.firstName : null
    );

    formVars.kullaniciform_41490_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.lastName : null
    );

    formVars.kullaniciform_26438440_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.phoneNumber : null
    );

    formVars.kullaniciform_666773_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.rolename : null
    );

    stateVars.dataSource_666773 = this.state.Roller;
    stateVars.isComp185986Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.rolename : null
        ),
        "MeMap"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kullaniciform_375540_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulId : null
    );

    stateVars.dataSource_375540 = this.state.OkulSelectAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciFormComponent_666773_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "kullaniciform_375540_value", null, null, "Id");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp185986Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormComponent_666773_onChange1_();
    });

    return isErrorOccurred;
  };
  KullaniciFormComponent_666773_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_666773_value", "value", "Roller", "val", "")
        ),
        "MeMap"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp185986Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  KullaniciFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormComponent_9544_onClick1_();
    });

    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_375540_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_375540_value",
      condition: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_666773_value", "value", "Roller", "val", "")
        ),
        "MeMap"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OkulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_375540_value", "value", "OkulSelectAll", "id", "id")
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.id : null),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KullaniciForm/KullaniciFormComponent_9544_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UserSaveLisansKontrol = result?.data.userSaveLisansKontrol;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_48486400_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_48486400_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_602812_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_602812_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_41490_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_41490_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_666773_value", "value", "Roller", "val", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_666773_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_26438440_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_26438440_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_375540_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_375540_value",
      condition: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_666773_value", "value", "Roller", "val", "")
        ),
        "MeMap"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_48486400_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_602812_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_41490_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_666773_value", "value", "Roller", "val", "val")
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("72d79ea2-ea5e-4033-9b22-28d0de8ac7e6", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_26438440_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      OkulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_375540_value", "value", "OkulSelectAll", "id", "id")
        ),
        "Guid"
      ),
      Rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_666773_value", "value", "Roller", "val", "")
        ),
        "string"
      ),
      MeMapUserId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MeMapUserId, "Guid"),
      okulId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciForm/KullaniciFormComponent_9544_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveUser = result?.data.saveUser;
    stateVars.MemapUserSave = result?.data.memapUserSave;
    stateVars.FindUsersToken = result?.data.findUsersToken;
    stateVars.KUserById = result?.data.kUserById;
    formVars.kullaniciform_48486400_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.userName : null
    );
    formVars.kullaniciform_602812_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.firstName : null
    );
    formVars.kullaniciform_41490_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.lastName : null
    );
    formVars.kullaniciform_26438440_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.phoneNumber : null
    );
    formVars.kullaniciform_666773_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.rolename : null;
    formVars.kullaniciform_375540_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.okulId : null;
    stateVars.isComp185986Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.rolename : null),
        "MeMap"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormComponent_9544_onClick3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_48486400_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_48486400_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("1fad66c5-a467-4833-a111-36ef72ab7023", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_48486400_value", "value", "", "", "")
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.emailBaslik : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_48486400_value", "value", "", "", "")
        ),
        "string"
      ),
      pass_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.FindUsersToken?.length > 0 ? this.state.FindUsersToken[0]?.token : null
        ),
        "string"
      ),
      okulLogoUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulLogoUrl : null
        ),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.baseUrl : null),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR")) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KullaniciForm/KullaniciFormComponent_9544_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick4_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_48486400_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_48486400_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("96ee22b3-e3fc-4880-9534-b68becf068a6", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_48486400_value", "value", "", "", "")
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.emailBaslik : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_48486400_value", "value", "", "", "")
        ),
        "string"
      ),
      pass_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.FindUsersToken?.length > 0 ? this.state.FindUsersToken[0]?.token : null
        ),
        "string"
      ),
      okulLogoUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulLogoUrl : null
        ),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.baseUrl : null),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US")) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KullaniciForm/KullaniciFormComponent_9544_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick5_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);
    formVars.kullaniciform_48486400_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.userName : null
    );

    formVars.kullaniciform_602812_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.firstName : null
    );

    formVars.kullaniciform_41490_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.lastName : null
    );

    formVars.kullaniciform_26438440_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.phoneNumber : null
    );

    formVars.kullaniciform_666773_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.rolename : null
    );

    formVars.kullaniciform_375540_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulId : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
