import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVerificationCode_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVerificationCode_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  IsVerificationCodeValid: boolean;
  goToNextScreenAfterVerifyCode: any;
  NAVIGATE: any;
}

export class VerificationCode_ScreenBase extends React.PureComponent<IVerificationCode_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 695885, PropertyName: "value", Value: "Verify your email address", TagName: "lblTitle_value" },
        {
          Id: 722774,
          PropertyName: "value",
          Value: "Please enter the verification code",
          TagName: "lblSubTitle_value"
        },
        {
          Id: 846142,
          PropertyName: "value",
          Value:
            "Enter the verification code sent to the email address associated with your account in the field below.",
          TagName: "lblDescription_value"
        },
        {
          Id: 617467,
          PropertyName: "placeholder",
          Value: "Verification code",
          TagName: "txtVerificationCode_placeholder"
        },
        { Id: 854781, PropertyName: "label", Value: "Send", TagName: "btnSend_label" },
        { Id: 28660, PropertyName: "value", Value: "Log in", TagName: "lnkSignIn_value" },
        { Id: 384658, PropertyName: "value", Value: "Go back to", TagName: "lblQ_value" }
      ]
    },
    {
      Id: "f7eb46ba-a13a-4390-85ce-fd8f184b922e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 695885, PropertyName: "value", Value: "Eposta adresinizi doğrulayın" },
        { Id: 722774, PropertyName: "value", Value: "Lütfen doğrulama kodunu giriniz" },
        {
          Id: 846142,
          PropertyName: "value",
          Value: "Hesabınızda kullandığınız e-posta adresine gönderilen doğrulama kodunu aşağıdaki alana girin."
        },
        { Id: 617467, PropertyName: "placeholder", Value: "Doğrulama kodu" },
        { Id: 854781, PropertyName: "label", Value: "Gönder" },
        { Id: 28660, PropertyName: "value", Value: "Giriş yap" },
        { Id: 384658, PropertyName: "value", Value: "'a geri dön" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      IsVerificationCodeValid: false,
      goToNextScreenAfterVerifyCode: "",
      NAVIGATE: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("verificationcode", "verificationcode", this.props.context);
      return;
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("verificationcode", "verificationcode", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("854781");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("verificationcode", "verificationcode", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      verificationcode_617467_value: undefined
    });
  }

  VerificationCodeComponent_854781_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "verificationcode_617467_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "verificationcode_617467_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.IsVerificationCodeValid = await ReactSystemFunctions.isVerificationCodeValid(
      this,
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "verificationcode_617467_value", "value", "", "", "")
      )
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterVerifyCode(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VerificationCodeComponent_28660_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VerificationCode",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
  }
}
