import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SideBarMenu_Screen } from "../sidebarmenu/sidebarmenu";
import { Ayarlar_ScreenBase } from "./ayarlar-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class Ayarlar_Screen extends Ayarlar_ScreenBase {
  render() {
    return (
      <>
        <SideBarMenu_Screen
          form={this.props.form}
          initialValues={{
            ayarlar_461915_value: undefined,
            ayarlar_296695_value: null,
            ayarlar_255763_value: null,
            ayarlar_272564_value: undefined,
            ayarlar_290483_value: undefined,
            ayarlar_708831_value: this.state.GetLanguage,
            ayarlar_905678_value: this.state.ReadFromLocalStorage
          }}
          screenInputs={this.props.screenInputs}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="ayarlar_body"
              style={
                {
                  backgroundColor: "rgba(245, 247, 250, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 0px)",
                  maxHeight: "calc(100vh - 0px)",
                  height: "calc(100vh - 0px)"
                } as any
              }
            >
              <KRow
                id="213093"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="758260"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <HorizontalStack
                    id="223507"
                    direction="horizontal"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="18116"
                      value={ReactSystemFunctions.translate(this.ml, 18116, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          width: "100px",
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="ayarlar_461915_value">
                      <TextInput
                        id="461915"
                        placeholder={ReactSystemFunctions.translate(this.ml, 461915, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            width: "400px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>

                    <Button
                      id="228661"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.AyarlarComponent_228661_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 228661, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(0, 190, 214, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </HorizontalStack>
                </KCol>
              </KRow>

              <KRow
                id="800624"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="598184"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <Label
                    id="841528"
                    value={ReactSystemFunctions.translate(this.ml, 841528, "value", this.defaultML)}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  ></Label>

                  <Form.Item className="kFormItem" name="ayarlar_296695_value">
                    <Image
                      id="296695"
                      zoomOnClick={false}
                      imageFit="fit"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          height: "100px",
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    ></Image>
                  </Form.Item>

                  <Icon
                    id="919676"
                    onClick={(e?: any) => {
                      if (e && e.stopPropagation) e.stopPropagation();
                      this.AyarlarComponent_919676_onClick();
                    }}
                    showCursorPointer
                    iconName="add_circle"
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "rgba(155, 155, 155, 1)",
                        letterSpacing: "1px"
                      } as any
                    }
                  ></Icon>
                </KCol>

                <KCol
                  id="143705"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <Label
                    id="534343"
                    value={ReactSystemFunctions.translate(this.ml, 534343, "value", this.defaultML)}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  ></Label>

                  <Form.Item className="kFormItem" name="ayarlar_255763_value">
                    <Image
                      id="255763"
                      zoomOnClick={false}
                      imageFit="fit"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          height: "100px",
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    ></Image>
                  </Form.Item>

                  <Icon
                    id="636111"
                    onClick={(e?: any) => {
                      if (e && e.stopPropagation) e.stopPropagation();
                      this.AyarlarComponent_636111_onClick();
                    }}
                    showCursorPointer
                    iconName="add_circle"
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "rgba(155, 155, 155, 1)",
                        letterSpacing: "1px"
                      } as any
                    }
                  ></Icon>
                </KCol>

                <KCol
                  id="138820"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <Button
                    id="313622"
                    onClick={(e?: any) => {
                      if (e && e.stopPropagation) e.stopPropagation();
                      this.AyarlarComponent_313622_onClick();
                    }}
                    showCursorPointer
                    label={ReactSystemFunctions.translate(this.ml, 313622, "label", this.defaultML)}
                    size="middle"
                    loading={false}
                    ghost={false}
                    block={false}
                    htmlType="button"
                    iconPosition="left"
                    danger={false}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(0, 190, 214, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 8,
                        paddingRight: 16,
                        paddingBottom: 8,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "rgba(255, 255, 255, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  ></Button>
                </KCol>
              </KRow>

              <KRow
                id="740569"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="631352"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <Form.Item className="kFormItem" name="ayarlar_272564_value" noStyle>
                    <KTab
                      id="272564"
                      type="line"
                      size="default"
                      tabPosition="top"
                      selectedTabColor="#0078FF"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KTabItem
                        id="997499"
                        tab={ReactSystemFunctions.translate(this.ml, 997499, "tab", this.defaultML)}
                        key={997499}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KTable
                          id="8676401"
                          kuikaRef={this.ayarlar_8676401_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.SinifSelectAll}
                          size="middle"
                          bordered={true}
                          showHeader={true}
                          loading={false}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            8676401,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          sorter={false}
                          pagination={false}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="2900748"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "12px",
                                color: "rgba(10, 34, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="7687983"
                            hoverFontColor="red"
                            hoverBgColor="#F5F5F5"
                            style={
                              {
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "30px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="2812573"
                              title={ReactSystemFunctions.translate(this.ml, 2812573, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="8010890"
                                value="[datafield:sezonId]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="2553616"
                              title={ReactSystemFunctions.translate(this.ml, 2553616, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="6282357"
                                value="[datafield:adi]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>
                      </KTabItem>

                      <KTabItem
                        id="106991"
                        tab={ReactSystemFunctions.translate(this.ml, 106991, "tab", this.defaultML)}
                        key={106991}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KTable
                          id="614386"
                          kuikaRef={this.ayarlar_614386_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.SinifSelectAll}
                          size="middle"
                          bordered={true}
                          showHeader={true}
                          loading={false}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            614386,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          sorter={false}
                          pagination={false}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="873109"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "12px",
                                color: "rgba(10, 34, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="130580"
                            hoverFontColor="red"
                            hoverBgColor="#F5F5F5"
                            style={
                              {
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "30px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="539445"
                              title={ReactSystemFunctions.translate(this.ml, 539445, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="812347"
                                value="[datafield:sezonId]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="793691"
                              title={ReactSystemFunctions.translate(this.ml, 793691, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="192751"
                                value="[datafield:adi]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>
                      </KTabItem>

                      <KTabItem
                        id="359624"
                        tab={ReactSystemFunctions.translate(this.ml, 359624, "tab", this.defaultML)}
                        key={359624}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      ></KTabItem>
                    </KTab>
                  </Form.Item>
                </KCol>
              </KRow>

              <KRow
                id="368917"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="704739"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <HorizontalStack
                    id="535326"
                    direction="horizontal"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="ayarlar_290483_value">
                      <TextInput
                        id="290483"
                        placeholder={ReactSystemFunctions.translate(this.ml, 290483, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>

                    <Button
                      id="542168"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.AyarlarComponent_542168_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 542168, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(0, 190, 214, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </HorizontalStack>
                </KCol>

                <KCol
                  id="212613"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="899785"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="304192"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <Label
                        id="217190"
                        value={ReactSystemFunctions.translate(this.ml, 217190, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      ></Label>

                      <Form.Item className="kFormItem" name="ayarlar_708831_value">
                        <TextInput
                          id="708831"
                          placeholder={ReactSystemFunctions.translate(this.ml, 708831, "placeholder", this.defaultML)}
                          allowClear={false}
                          bordered={true}
                          disabled={false}
                          type="text"
                          iconColor="rgba(0, 0, 0, 1)"
                          formatter="None"
                          isCharOnly={false}
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              width: "200px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></TextInput>
                      </Form.Item>
                    </KCol>

                    <KCol
                      id="21423"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <Label
                        id="496528"
                        value={ReactSystemFunctions.translate(this.ml, 496528, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      ></Label>

                      <Form.Item className="kFormItem" name="ayarlar_905678_value">
                        <TextInput
                          id="905678"
                          placeholder={ReactSystemFunctions.translate(this.ml, 905678, "placeholder", this.defaultML)}
                          allowClear={false}
                          bordered={true}
                          disabled={false}
                          type="text"
                          iconColor="rgba(0, 0, 0, 1)"
                          formatter="None"
                          isCharOnly={false}
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              width: "200px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></TextInput>
                      </Form.Item>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KRow
                id="78089"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="878911"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <Button
                    id="424003"
                    onClick={(e?: any) => {
                      if (e && e.stopPropagation) e.stopPropagation();
                      this.AyarlarComponent_424003_onClick();
                    }}
                    showCursorPointer
                    label={ReactSystemFunctions.translate(this.ml, 424003, "label", this.defaultML)}
                    size="middle"
                    loading={false}
                    ghost={false}
                    block={false}
                    htmlType="button"
                    iconPosition="left"
                    danger={false}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(0, 190, 214, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 8,
                        paddingRight: 16,
                        paddingBottom: 8,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "rgba(255, 255, 255, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  ></Button>
                </KCol>
              </KRow>
            </div>
          </Spin>
        </SideBarMenu_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Ayarlar_Screen)))))
);
export { tmp as Ayarlar_Screen };
//export default tmp;
//export { tmp as Ayarlar_Screen };
