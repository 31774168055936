import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  GetLanguage: string;
  CheckupSelectById: any[];
  CheckupSave: number;
}

export class CheckupForm_ScreenBase extends React.PureComponent<ICheckupForm_ScreenProps, any> {
  checkupform_67346270_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Check-Up", TagName: "lblHead_value" },
        { Id: 9783984, PropertyName: "value", Value: "Evaluation Date", TagName: "lblOlcumTarihi_value" },
        { Id: 67346270, PropertyName: "placeholder", Value: "Choose", TagName: "dtOlcumTarihi_placeholder" },
        { Id: 6182193, PropertyName: "value", Value: "Weight", TagName: "lblAgirlik_value" },
        { Id: 9042173, PropertyName: "value", Value: "Height", TagName: "lblBoy_value" },
        { Id: 4806021, PropertyName: "value", Value: "Running stage", TagName: "lblKalpGucuSeviye_value" },
        { Id: 4365862, PropertyName: "value", Value: "Running level", TagName: "lblKalpGucuTur_value" },
        { Id: 1973972, PropertyName: "value", Value: "Flexibility", TagName: "lblEsneklik_value" },
        { Id: 4847312, PropertyName: "value", Value: "Sit Up", TagName: "lblGovde_value" },
        { Id: 5825459, PropertyName: "value", Value: "Push Up", TagName: "lblUstEkstremite_value" },
        { Id: 8585215, PropertyName: "value", Value: "Standing Long Jump", TagName: "lblAltEkstremite_value" },
        { Id: 686370, PropertyName: "value", Value: "Teacher's Mark", TagName: "Label1_0_value" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "7d5b2f8a-d089-4c3d-a400-6694b954c720",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Check-Up" },
        { Id: 9783984, PropertyName: "value", Value: "Ölçüm Tarihi" },
        { Id: 67346270, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 6182193, PropertyName: "value", Value: "Ağırlık" },
        { Id: 9042173, PropertyName: "value", Value: "Boy" },
        { Id: 4806021, PropertyName: "value", Value: "Koşu Seviyesi" },
        { Id: 4365862, PropertyName: "value", Value: "Koşu Turu" },
        { Id: 1973972, PropertyName: "value", Value: "Esneklik" },
        { Id: 4847312, PropertyName: "value", Value: "Mekik" },
        { Id: 5825459, PropertyName: "value", Value: "Şınav" },
        { Id: 8585215, PropertyName: "value", Value: "Uzun Atlama" },
        { Id: 686370, PropertyName: "value", Value: "Eğitmen Notu" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.checkupform_67346270_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      GetLanguage: "",
      CheckupSelectById: [],
      CheckupSave: 0
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkupform", "checkupform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.CheckupFormPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("checkupform", "checkupform", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkupform", "checkupform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.CheckupFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      checkupform_67346270_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].olcumTarihi : undefined,
      checkupform_85525500_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].agirlik : undefined,
      checkupform_51438300_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].boy : undefined,
      checkupform_84478270_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].kalpGucuSeviye : undefined,
      checkupform_35136480_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].kalpGucuTur : undefined,
      checkupform_29701080_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].esneklik : undefined,
      checkupform_18247190_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].govde : undefined,
      checkupform_22377740_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].ustEkstremite : undefined,
      checkupform_74196790_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].altEkstremite : undefined,
      checkupform_885354_value:
        this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0].egitmenNotu : undefined
    });
  }

  CheckupFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupFormPageInit1_();
    });

    return isErrorOccurred;
  };
  CheckupFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      checkupId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId, "Guid"),
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "CheckupForm/CheckupFormPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupSelectById = result?.data.checkupSelectById;
    formVars.checkupform_67346270_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.olcumTarihi : null
    );
    formVars.checkupform_85525500_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.agirlik : null
    );
    formVars.checkupform_51438300_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.boy : null
    );
    formVars.checkupform_84478270_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.kalpGucuSeviye : null
    );
    formVars.checkupform_35136480_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.kalpGucuTur : null
    );
    formVars.checkupform_29701080_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.esneklik : null
    );
    formVars.checkupform_18247190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.govde : null
    );
    formVars.checkupform_22377740_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.ustEkstremite : null
    );
    formVars.checkupform_74196790_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.altEkstremite : null
    );
    formVars.checkupform_885354_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.egitmenNotu : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupFormPageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.checkupform_67346270_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.olcumTarihi : null
    );

    formVars.checkupform_85525500_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.agirlik : null
    );

    formVars.checkupform_51438300_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.boy : null
    );

    formVars.checkupform_84478270_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.kalpGucuSeviye : null
    );

    formVars.checkupform_35136480_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.kalpGucuTur : null
    );

    formVars.checkupform_29701080_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.esneklik : null
    );

    formVars.checkupform_18247190_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.govde : null
    );

    formVars.checkupform_22377740_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.ustEkstremite : null
    );

    formVars.checkupform_74196790_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.altEkstremite : null
    );

    formVars.checkupform_885354_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.egitmenNotu : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_67346270_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupform_67346270_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Agirlik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_85525500_value", "value", "", "", "")
        ),
        "number"
      ),
      AltEkstremite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_74196790_value", "value", "", "", "")
        ),
        "number"
      ),
      Boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_51438300_value", "value", "", "", "")
        ),
        "number"
      ),
      Esneklik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_29701080_value", "value", "", "", "")
        ),
        "number"
      ),
      Govde_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_18247190_value", "value", "", "", "")
        ),
        "number"
      ),
      checkupId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId, "Guid"),
      KalpGucuSeviye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_84478270_value", "value", "", "", "")
        ),
        "number"
      ),
      KalpGucuTur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_35136480_value", "value", "", "", "")
        ),
        "number"
      ),
      OgrenciId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      OlcumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_67346270_value", "value", "", "", "")
        ),
        "Date"
      ),
      SinifId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      UstEkstremite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_22377740_value", "value", "", "", "")
        ),
        "number"
      ),
      egitmenNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupform_885354_value", "value", "", "", "")
        ),
        "string"
      ),
      checkupDonemiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "CheckupForm/CheckupFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupSave = result?.data.checkupSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
