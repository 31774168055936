import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISinifList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISinifList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  SinifCount: any[];
  SinifSearch: any[];
  SetValueOf: any;
  NAVIGATE: any;
  SinifDeleteById: number;
  isCompsiniflist_814413AuthorizationVisible: "visible" | "hidden";
  isCompsiniflist_1298546AuthorizationVisible: "visible" | "hidden";
}

export class SinifList_ScreenBase extends React.PureComponent<ISinifList_ScreenProps, any> {
  siniflist_399769_value_kuikaTableRef: React.RefObject<any>;
  siniflist_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 111749, PropertyName: "value", Value: "Class List", TagName: "Label2_0_value" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search", TagName: "txtSearch_placeholder" },
        { Id: 814413, PropertyName: "label", Value: "NEW CLASS", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblSinif_nodatafoundmessage"
        },
        { Id: 1298546, PropertyName: "title", Value: "School", TagName: "clmDonemId_title" },
        { Id: 7107513, PropertyName: "value", Value: "[datafield:schoolname]", TagName: "lblDonemId_value" },
        { Id: 3887240, PropertyName: "title", Value: "Class Name/Branch", TagName: "clmAdi_title" },
        { Id: 9358721, PropertyName: "value", Value: "[datafield:name]", TagName: "lblAdi_value" },
        { Id: 225189, PropertyName: "title", Value: "Student", TagName: "TableColumn5_title" },
        { Id: 198944, PropertyName: "value", Value: "[datafield:present]", TagName: "Label4_0_value" },
        { Id: 864078, PropertyName: "title", Value: "Class Students", TagName: "Tooltip1_title" },
        { Id: 401562, PropertyName: "label", Value: "Statistics", TagName: "Button2_label" },
        {
          Id: 9872116,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this class?",
          TagName: "SinifDeleteById_confirmation",
          IsConfirmation: true
        }
      ]
    },
    {
      Id: "544f9ab8-f663-40ba-af55-f8ae7beb3e07",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 111749, PropertyName: "value", Value: "Sınıf Listesi" },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 814413, PropertyName: "label", Value: "YENİ SINIF" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 1298546, PropertyName: "title", Value: "Okul" },
        { Id: 7107513, PropertyName: "value", Value: "[datafield:okuladi]" },
        { Id: 3887240, PropertyName: "title", Value: "Sınıf Adı / Şube" },
        { Id: 9358721, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 225189, PropertyName: "title", Value: "Öğrenci" },
        { Id: 198944, PropertyName: "value", Value: "[datafield:mevcut]" },
        { Id: 864078, PropertyName: "title", Value: "Sınıfın Öğrencileri" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.siniflist_399769_value_kuikaTableRef = React.createRef();
    this.siniflist_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      SinifCount: [],
      SinifSearch: [],
      SetValueOf: "",
      NAVIGATE: "",
      SinifDeleteById: 0,
      isCompsiniflist_814413AuthorizationVisible: "visible",
      isCompsiniflist_1298546AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siniflist", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.SinifListPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsiniflist_814413AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "MeMap",
      "Okul"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsiniflist_1298546AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("siniflist", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("siniflist", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.SinifListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  SinifListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      isActifSezon_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      isActifSezon_2: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifList/SinifListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifCount = result?.data.sinifCount;
    stateVars.SinifSearch = result?.data.sinifSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.SinifSearch;
    formVars.siniflist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.SinifCount?.length > 0 ? this.state.SinifCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "siniflist_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifListComponent_416063_onPressEnter1_();
    });

    return isErrorOccurred;
  };
  SinifListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      isActifSezon_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      isActifSezon_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifList/SinifListComponent_416063_onPressEnter1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifCount = result?.data.sinifCount;
    stateVars.SinifSearch = result?.data.sinifSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifListComponent_416063_onPressEnter2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifListComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.SinifSearch;
    formVars.siniflist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.SinifCount?.length > 0 ? this.state.SinifCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SinifForm", "Id", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifList",
      "SinifForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "375px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifListComponent_929693_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SinifOgrenciList",
      "SinifId",
      ReactSystemFunctions.value(this, "siniflist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifList",
      "SinifOgrenciList",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "337513",
      null,
      "right",
      null,
      "760px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifListComponent_157199_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SinifTopluOlcumList",
      "SinifId",
      ReactSystemFunctions.value(this, "siniflist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifList",
      "SinifTopluOlcumList",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "956798",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SinifForm",
      "Id",
      ReactSystemFunctions.value(this, "siniflist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifList",
      "SinifForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "375px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "siniflist_399769_value", "id"),
        "Guid"
      ),
      isActifSezon_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      isActifSezon_2: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Sınıf kaydını silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(this, "SinifList/SinifListComponent_505853_onClick", localVar);
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.SinifDeleteById = result?.data.sinifDeleteById;
        stateVars.SinifCount = result?.data.sinifCount;
        stateVars.SinifSearch = result?.data.sinifSearch;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.SinifListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.SinifSearch;
    formVars.siniflist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.SinifCount?.length > 0 ? this.state.SinifCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      isActifSezon_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siniflist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifList/SinifListComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifSearch = result?.data.sinifSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifListComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.SinifSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 337513, 956798, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SinifListPageInit();
    }
  }
}
