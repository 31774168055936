import { setTimeout } from "worker-timers";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";

interface IRedirectToOptions {
  openInNewTab: boolean;
}

class HelperService {
  static ensurePathStartsWithSlash(path: string): string {
    if (path[0] === "/") return path;

    return `/${path}`;
  }

  static redirectToPath(path: string, options?: IRedirectToOptions): void {
    const frontEndUrl = KuikaAppManager.GetFrontEndUrl();
    const isWebComponent = KuikaAppManager.IsWebComponent();
    HelperService.redirectToFullUrl(`${frontEndUrl}${isWebComponent === true ? "#" : ""}${path}`, options);
  }

  static redirectToFullUrl(url: string, options?: IRedirectToOptions): void {
    if (options?.openInNewTab) {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      window.location.href = url;
    }
  }

  static setCorrectTimer(
    timeout: number,
    iterationPerSecond: number,
    onIteration: (steps: number, count: number) => void,
    onCompleted: (steps: number, count: number) => void
  ): void {
    const steps: number = (timeout / 100) * (iterationPerSecond / 10);
    const speed: number = timeout / steps;
    const start: number = new Date().getTime();
    let count: number = 0;

    const instance = () => {
      count += 1;

      if (count === steps) {
        onCompleted(steps, count);
      } else {
        onIteration(steps, count);

        const diff: number = new Date().getTime() - start - count * speed;
        setTimeout(instance, speed - diff);
      }
    };

    setTimeout(instance, speed);
  }
}

export default HelperService;
