import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISinifOgrenciList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISinifOgrenciList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  SinifSelectById: any[];
  OkulSelectById: any[];
  SinifOgrenciListesi: any[];
  EmptySheet1: number;
  UploadFile: any;
  ImportExcelBase64: any[];
  ImportOgrenciFromSheet1: number;
  OgrenciListesi: any[];
  ExportExcel: any;
  NAVIGATE: any;
  OgrenciDeleteById: number;
}

export class SinifOgrenciList_ScreenBase extends React.PureComponent<ISinifOgrenciList_ScreenProps, any> {
  sinifogrencilist_399769_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Class Student List", TagName: "Label5_value" },
        { Id: 814644, PropertyName: "label", Value: "Upload", TagName: "Button2_label" },
        { Id: 273906, PropertyName: "label", Value: "Sample", TagName: "Button3_label" },
        { Id: 814413, PropertyName: "label", Value: "NEW STUDENT", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblOgrenci_nodatafoundmessage"
        },
        { Id: 809041, PropertyName: "title", Value: "School Number", TagName: "TableColumn6_title" },
        { Id: 305733, PropertyName: "value", Value: "[datafield:schoolnumber]", TagName: "Label5_0_value" },
        { Id: 893668, PropertyName: "title", Value: "Student Name", TagName: "TableColumn5_title" },
        { Id: 862047, PropertyName: "value", Value: "[datafield:name]", TagName: "Label4_0_value" },
        { Id: 1197933, PropertyName: "title", Value: "Gender", TagName: "clmCinsiyet_title" },
        { Id: 8464216, PropertyName: "value", Value: "[datafield:gender]", TagName: "lblCinsiyet_value" },
        { Id: 4259169, PropertyName: "title", Value: "Birth Date", TagName: "clmDogumTarihi_title" },
        { Id: 6999863, PropertyName: "value", Value: "[datafield:birthdate]", TagName: "lblDogumTarihi_value" },
        {
          Id: 11945040,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this student?",
          TagName: "OgrenciDeleteById_confirmation",
          IsConfirmation: true
        }
      ]
    },
    {
      Id: "81c765f3-f9f8-41e7-aad7-53ba3e62e604",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Sınıf Öğrenci Listesi" },
        { Id: 814644, PropertyName: "label", Value: "Yükle" },
        { Id: 273906, PropertyName: "label", Value: "Örnek" },
        { Id: 814413, PropertyName: "label", Value: "YENİ ÖĞRENCİ" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 809041, PropertyName: "title", Value: "Okul No" },
        { Id: 305733, PropertyName: "value", Value: "[datafield:okulno]" },
        { Id: 893668, PropertyName: "title", Value: "Öğrencinin Adı" },
        { Id: 862047, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 1197933, PropertyName: "title", Value: "Cinsiyeti" },
        { Id: 8464216, PropertyName: "value", Value: "[datafield:cinsiyet]" },
        { Id: 4259169, PropertyName: "title", Value: "Doğum Tarihi" },
        { Id: 6999863, PropertyName: "value", Value: "[datafield:dogumtarihi]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.sinifogrencilist_399769_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      SinifSelectById: [],
      OkulSelectById: [],
      SinifOgrenciListesi: [],
      EmptySheet1: "",
      UploadFile: "",
      ImportExcelBase64: [],
      ImportOgrenciFromSheet1: "",
      OgrenciListesi: [],
      ExportExcel: "",
      NAVIGATE: "",
      OgrenciDeleteById: 0
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sinifogrencilist", "sinifogrencilist", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.SinifOgrenciListPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("sinifogrencilist", "sinifogrencilist", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("sinifogrencilist", "sinifogrencilist", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.SinifOgrenciListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  SinifOgrenciListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(null, "string"),
      sinifId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifOgrenciList/SinifOgrenciListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    stateVars.OkulSelectById = result?.data.okulSelectById;
    stateVars.SinifOgrenciListesi = result?.data.sinifOgrenciListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifOgrenciListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifOgrenciListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.SinifOgrenciListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifOgrenciListComponent_814644_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifOgrenciList/SinifOgrenciListComponent_814644_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EmptySheet1 = result?.data.emptySheet1;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifOgrenciListComponent_814644_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifOgrenciListComponent_814644_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifOgrenciListComponent_814644_onClick2_();
    });

    return isErrorOccurred;
  };
  SinifOgrenciListComponent_814644_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      excelBase64_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifOgrenciList/SinifOgrenciListComponent_814644_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
    stateVars.ImportOgrenciFromSheet1 = result?.data.importOgrenciFromSheet1;
    stateVars.SinifOgrenciListesi = result?.data.sinifOgrenciListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifOgrenciListComponent_814644_onClick3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifOgrenciListComponent_814644_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.SinifOgrenciListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifOgrenciListComponent_273906_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifOgrenciList/SinifOgrenciListComponent_273906_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OgrenciListesi = result?.data.ogrenciListesi;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SinifOgrenciListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OgrenciForm", "Id", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("OgrenciForm", "OkulId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("OgrenciForm", "SinifId", this.props.screenInputs.SinifId);
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "SezonId",
      ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sezonId : null)
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifOgrenciList",
      "OgrenciForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "380px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifOgrenciListComponent_948694_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CheckupList",
      "OgrenciId",
      ReactSystemFunctions.value(this, "sinifogrencilist_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CheckupList",
      "SinifId",
      ReactSystemFunctions.value(this, "sinifogrencilist_399769_value", "mevcutSinifId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifOgrenciList",
      "CheckupList",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "220797",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifOgrenciListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "Id",
      ReactSystemFunctions.value(this, "sinifogrencilist_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "OkulId",
      ReactSystemFunctions.value(this, "sinifogrencilist_399769_value", "okulId")
    );
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "SinifId",
      ReactSystemFunctions.value(this, "sinifogrencilist_399769_value", "mevcutSinifId")
    );
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "SezonId",
      ReactSystemFunctions.value(this, "sinifogrencilist_399769_value", "mevcutSezonId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifOgrenciList",
      "OgrenciForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "380px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifOgrenciListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifogrencilist_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Bu öğrenciyi silmek istediğine emin misin?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "SinifOgrenciList/SinifOgrenciListComponent_505853_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.OgrenciDeleteById = result?.data.ogrenciDeleteById;
        stateVars.SinifOgrenciListesi = result?.data.sinifOgrenciListesi;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.SinifOgrenciListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifOgrenciListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.SinifOgrenciListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 220797, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SinifOgrenciListPageInit();
    }
  }
}
