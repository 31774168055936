import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVelininOgrencileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVelininOgrencileri_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  VeliSelectById: any[];
  VelininOgrencileri: any[];
  NAVIGATE: any;
  VeliOgrenciDelete: number;
}

export class VelininOgrencileri_ScreenBase extends React.PureComponent<IVelininOgrencileri_ScreenProps, any> {
  velininogrencileri_399769_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 832576, PropertyName: "value", Value: "Parent's Children", TagName: "Label10_0_value" },
        { Id: 490878, PropertyName: "value", Value: "Parent", TagName: "Label11_value" },
        { Id: 608692, PropertyName: "label", Value: "Add Children", TagName: "Button1_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblOlcum_nodatafoundmessage"
        },
        { Id: 1142521, PropertyName: "title", Value: "Student's Name", TagName: "clmOlcumTarihi_title" },
        { Id: 2058824, PropertyName: "title", Value: "Birth Date", TagName: "clmBoy_title" },
        { Id: 97568, PropertyName: "title", Value: "School", TagName: "TableColumn2_title" },
        { Id: 359225, PropertyName: "title", Value: "Class/Branch", TagName: "TableColumn1_title" },
        {
          Id: 79269921,
          PropertyName: "3e3fba76-e0ed-0834-d94d-85527eda8ab3_confirmation",
          Value: "Are you sure you want to delete this relationship between student and parent?",
          TagName: "VeliOgrenciDelete_confirmation",
          IsConfirmation: true
        },
        { Id: 6206830, PropertyName: "value", Value: "[datafield:name]", TagName: "lblOlcumTarihi_value" },
        { Id: 3231944, PropertyName: "value", Value: "[datafield:birthdate]", TagName: "lblBoy_value" },
        { Id: 161205, PropertyName: "value", Value: "[datafield:school]", TagName: "Label1_0_value" },
        { Id: 274761, PropertyName: "value", Value: "[datafield:class]", TagName: "Label1_0_value" }
      ]
    },
    {
      Id: "3b48c56e-eea8-4067-9f52-a5384bc57dd3",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 832576, PropertyName: "value", Value: "Velinin Öğrencileri" },
        { Id: 490878, PropertyName: "value", Value: "Veli" },
        { Id: 608692, PropertyName: "label", Value: "Öğrenci Ekle" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 1142521, PropertyName: "title", Value: "Öğrencinin Adı" },
        { Id: 6206830, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 2058824, PropertyName: "title", Value: "Doğum Tarihi" },
        { Id: 3231944, PropertyName: "value", Value: "[datafield:dogumtarihi]" },
        { Id: 97568, PropertyName: "title", Value: "Okulu" },
        { Id: 161205, PropertyName: "value", Value: "[datafield:okulu]" },
        { Id: 359225, PropertyName: "title", Value: "Sınıfı / Şubesi" },
        { Id: 274761, PropertyName: "value", Value: "[datafield:sinifi]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.velininogrencileri_399769_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      VeliSelectById: [],
      VelininOgrencileri: [],
      NAVIGATE: "",
      VeliOgrenciDelete: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("velininogrencileri", "velininogrencileri", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.VelininOgrencileriPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("velininogrencileri", "velininogrencileri", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("velininogrencileri", "velininogrencileri", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.VelininOgrencileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  VelininOgrencileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.VeliId, "Guid"),
      veliId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.VeliId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VelininOgrencileri/VelininOgrencileriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.VeliSelectById = result?.data.veliSelectById;
    formVars.velininogrencileri_828205_value = ReactSystemFunctions.toString(
      this,
      stateVars.VeliSelectById?.length > 0 ? stateVars.VeliSelectById[0]?.adi : null
    );
    stateVars.VelininOgrencileri = result?.data.velininOgrencileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VelininOgrencileriPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VelininOgrencileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.velininogrencileri_828205_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0]?.adi : null
    );

    stateVars.dataSource_399769 = this.state.VelininOgrencileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VelininOgrencileriComponent_928392_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VelininOgrencileriComponent_608692_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("VeliOgrenciEkleme", "VeliId", this.props.screenInputs.VeliId);
    KuikaAppManager.addToPageInputVariables("VeliOgrenciEkleme", "OgrenciId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VelininOgrencileri",
      "VeliOgrenciEkleme",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "576173",
      null,
      "right",
      null,
      "375px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VelininOgrencileriComponent_599977_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velininogrencileri_399769_value", "veliOgrenciId"),
        "Guid"
      ),
      veliId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.VeliId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3e3fba76_e0ed_0834_d94d_85527eda8ab3_confirmation",
        this.defaultML,
        "Bu öğrenci ile velinin ilişkisini silmekistediğine emin misin?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "VelininOgrencileri/VelininOgrencileriComponent_599977_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.VeliOgrenciDelete = result?.data.veliOgrenciDelete;
        stateVars.VelininOgrencileri = result?.data.velininOgrencileri;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.VelininOgrencileriComponent_599977_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VelininOgrencileriComponent_599977_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.VelininOgrencileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [576173] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.VelininOgrencileriPageInit();
    }
  }
}
