import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IStatisticsDetails_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IStatisticsDetails_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  SinifSelectById: any[];
  SinifBilesenDetay: any[];
}

export class StatisticsDetails_ScreenBase extends React.PureComponent<IStatisticsDetails_ScreenProps, any> {
  statisticsdetails_4310514_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Class Statistics", TagName: "lblHead_value" },
        { Id: 267472, PropertyName: "value", Value: "Class:", TagName: "Label4_value" },
        { Id: 835196, PropertyName: "value", Value: "Component:", TagName: "Label2_value" },
        {
          Id: 4310514,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblSinifbilesendetay_4310514_nodatafoundmessage"
        },
        { Id: 1292913, PropertyName: "title", Value: "<25", TagName: "clmu25OgrenciAdi_1292913_title" },
        { Id: 6465275, PropertyName: "title", Value: "25-50", TagName: "clmu51OgrenciAdi_6465275_title" },
        { Id: 9729010, PropertyName: "title", Value: "50-75", TagName: "clmu76OgrenciAdi_9729010_title" },
        { Id: 5224240, PropertyName: "title", Value: "75-100", TagName: "clmu101OgrenciAdi_5224240_title" },
        {
          Id: 6278120,
          PropertyName: "value",
          Value: "[datafield:u25studentname]",
          TagName: "lblu25OgrenciAdi_6278120_value"
        },
        {
          Id: 3816301,
          PropertyName: "value",
          Value: "[datafield:u51studentname]",
          TagName: "lblu51OgrenciAdi_3816301_value"
        },
        {
          Id: 8702893,
          PropertyName: "value",
          Value: "[datafield:u76studentname]",
          TagName: "lblu76OgrenciAdi_8702893_value"
        },
        {
          Id: 8901685,
          PropertyName: "value",
          Value: "[datafield:u101studentname]",
          TagName: "lblu101OgrenciAdi_8901685_value"
        }
      ]
    },
    {
      Id: "7700c588-8c29-4329-8317-870bea59d08b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Sınıf İstatistikleri" },
        { Id: 267472, PropertyName: "value", Value: "Sınıf:" },
        { Id: 835196, PropertyName: "value", Value: "Bileşen:" },
        { Id: 4310514, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 1292913, PropertyName: "title", Value: "< 25" },
        { Id: 6278120, PropertyName: "value", Value: "[datafield:u25ogrenciadi]" },
        { Id: 6465275, PropertyName: "title", Value: "25 - 50" },
        { Id: 3816301, PropertyName: "value", Value: "[datafield:u51ogrenciadi]" },
        { Id: 9729010, PropertyName: "title", Value: "50 - 75" },
        { Id: 8702893, PropertyName: "value", Value: "[datafield:u76ogrenciadi]" },
        { Id: 5224240, PropertyName: "title", Value: "75 - 100" },
        { Id: 8901685, PropertyName: "value", Value: "[datafield:u101ogrenciadi]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.statisticsdetails_4310514_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      SinifSelectById: [],
      SinifBilesenDetay: []
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("statisticsdetails", "statisticsdetails", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.StatisticsDetailsPageInit();

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("statisticsdetails", "statisticsdetails", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("statisticsdetails", "statisticsdetails", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.StatisticsDetailsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  StatisticsDetailsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      bilesenTipi_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.BilesenTipi, "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "StatisticsDetails/StatisticsDetailsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    formVars.statisticsdetails_319436_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifSelectById?.length > 0 ? stateVars.SinifSelectById[0]?.adi : null
    );
    stateVars.SinifBilesenDetay = result?.data.sinifBilesenDetay;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.StatisticsDetailsPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  StatisticsDetailsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.statisticsdetails_319436_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0]?.adi : null
    );

    stateVars.dataSource_4310514 = this.state.SinifBilesenDetay;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsDetailsComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
