import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISideBarMenu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISideBarMenu_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  Logout: boolean;
  goToSignInActivity: any;
  NAVIGATE: any;
  OpenInBrowser: any;
  isComp548615Visible: "visible" | "hidden";
  isComp448238Visible: "visible" | "hidden";
  isCompsidebarmenu_400097AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_772189AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_408596AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_950837AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_314940AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_756009AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_355172AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_685183AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_438913AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_544581AuthorizationVisible: "visible" | "hidden";
  isCompsidebarmenu_598686AuthorizationVisible: "visible" | "hidden";
}

export class SideBarMenu_ScreenBase extends React.PureComponent<ISideBarMenu_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 989465, PropertyName: "label", Value: "My children ", TagName: "MenuItem1_0_label" },
        { Id: 408596, PropertyName: "label", Value: "Schools", TagName: "MenuItem1_1_label" },
        { Id: 756009, PropertyName: "label", Value: "Seasons", TagName: "MenuItem3_label" },
        { Id: 355172, PropertyName: "label", Value: "Check-Up Intervals", TagName: "MenuItem7_label" },
        { Id: 685183, PropertyName: "label", Value: "Classes", TagName: "MenuItem4_label" },
        { Id: 438913, PropertyName: "label", Value: "Students", TagName: "MenuItem5_label" },
        { Id: 544581, PropertyName: "label", Value: "Parents", TagName: "MenuItem6_label" },
        { Id: 950837, PropertyName: "label", Value: "Users", TagName: "MenuItem1_label" },
        { Id: 314940, PropertyName: "label", Value: "Teachers", TagName: "MenuItem2_label" },
        { Id: 400097, PropertyName: "label", Value: "Dashboard", TagName: "MenuItem8_label" },
        { Id: 772189, PropertyName: "label", Value: "Statistics", TagName: "MenuItem9_label" },
        { Id: 598686, PropertyName: "label", Value: "Resources", TagName: "MenuItem12_label" }
      ]
    },
    {
      Id: "8bda4e5f-180c-4f91-9901-fa8b66d6c943",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 400097, PropertyName: "label", Value: "Gösterge Paneli" },
        { Id: 772189, PropertyName: "label", Value: "İstatistikler" },
        { Id: 408596, PropertyName: "label", Value: "Okullar" },
        { Id: 950837, PropertyName: "label", Value: "Kullanıcılar" },
        { Id: 314940, PropertyName: "label", Value: "Öğretmenler" },
        { Id: 756009, PropertyName: "label", Value: "Sezonlar" },
        { Id: 355172, PropertyName: "label", Value: "Check-Up Dönemler" },
        { Id: 685183, PropertyName: "label", Value: "Sınıflar" },
        { Id: 438913, PropertyName: "label", Value: "Öğrenciler" },
        { Id: 544581, PropertyName: "label", Value: "Veliler" },
        { Id: 989465, PropertyName: "label", Value: "Çocuklarım" },
        { Id: 598686, PropertyName: "label", Value: "Kaynaklar" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      Logout: false,
      goToSignInActivity: "",
      NAVIGATE: "",
      OpenInBrowser: "",
      isComp548615Visible: "hidden",
      isComp448238Visible: "hidden",
      isCompsidebarmenu_400097AuthorizationVisible: "visible",
      isCompsidebarmenu_772189AuthorizationVisible: "visible",
      isCompsidebarmenu_408596AuthorizationVisible: "visible",
      isCompsidebarmenu_950837AuthorizationVisible: "visible",
      isCompsidebarmenu_314940AuthorizationVisible: "visible",
      isCompsidebarmenu_756009AuthorizationVisible: "visible",
      isCompsidebarmenu_355172AuthorizationVisible: "visible",
      isCompsidebarmenu_685183AuthorizationVisible: "visible",
      isCompsidebarmenu_438913AuthorizationVisible: "visible",
      isCompsidebarmenu_544581AuthorizationVisible: "visible",
      isCompsidebarmenu_598686AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sidebarmenu", "sidebarmenu", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.SideBarMenuPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_400097AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "515ef1b5-9177-01ad-2582-29e5f319c421",
      "MeMap",
      "Okul",
      "Ogretmen"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_772189AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "515ef1b5-9177-01ad-2582-29e5f319c421",
      "MeMap",
      "Okul",
      "Ogretmen"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_408596AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_950837AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_314940AuthorizationVisible", [
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "Okul"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_756009AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "MeMap",
      "Okul"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_355172AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "MeMap",
      "Okul"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_685183AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "515ef1b5-9177-01ad-2582-29e5f319c421",
      "MeMap",
      "Okul",
      "Ogretmen"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_438913AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "515ef1b5-9177-01ad-2582-29e5f319c421",
      "MeMap",
      "Okul",
      "Ogretmen"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_544581AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "515ef1b5-9177-01ad-2582-29e5f319c421",
      "MeMap",
      "Okul",
      "Ogretmen"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsidebarmenu_598686AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "515ef1b5-9177-01ad-2582-29e5f319c421",
      "MeMap",
      "Okul",
      "Ogretmen"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("sidebarmenu", "sidebarmenu", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("sidebarmenu", "sidebarmenu", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.SideBarMenuPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      sidebarmenu_448238_value: this.state.MyContext?.length > 0 ? this.state.MyContext[0].logo2 : undefined,
      sidebarmenu_618574_value: { collapsed: false }
    });
  }

  SideBarMenuPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SideBarMenu/SideBarMenuPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.sidebarmenu_169016_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolename : null
    );
    formVars.sidebarmenu_448238_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.logo2 : null
    );
    stateVars.isComp548615Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolename : null),
        "MeMap"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp448238Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolename : null),
        "MeMap"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SideBarMenuPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SideBarMenuPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.sidebarmenu_169016_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
    );

    stateVars.isComp548615Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "MeMap"
      ) === true
        ? "visible"
        : "hidden";
    formVars.sidebarmenu_448238_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.logo2 : null
    );

    stateVars.isComp448238Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "MeMap"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_556000_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_400097_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "Dashboard",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_772189_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "Statistics",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_408596_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "OkulList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_950837_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "KullaniciList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_314940_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "OgretmenList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_756009_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "SezonList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_355172_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "CheckupDonemiList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_685183_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "SinifList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_438913_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "OgrenciList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_544581_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "VeliList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_989465_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SideBarMenu",
      "Ailem",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SideBarMenuComponent_598686_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "http://yeninesilfitnessegitimi.com/class/school_fitness_check-up"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, , , , , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SideBarMenuPageInit();
    }
  }
}
