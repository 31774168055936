import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  ChartLabel,
  DonutChart,
  HorizontalStack,
  Icon,
  KCol,
  KDrawer,
  KModal,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SideBarMenu_Screen } from "../sidebarmenu/sidebarmenu";
import { Dashboard_ScreenBase } from "./dashboard-base";

import { DashboardFitBilesenDetay_Screen } from "../dashboardfitbilesendetay/dashboardfitbilesendetay";
import { DashboardKatilimDetay_Screen } from "../dashboardkatilimdetay/dashboardkatilimdetay";
import { KVKK_Screen } from "../kvkk/kvkk";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class Dashboard_Screen extends Dashboard_ScreenBase {
  render() {
    return (
      <>
        <SideBarMenu_Screen
          form={this.props.form}
          initialValues={{ dashboard_880623_value: undefined }}
          screenInputs={this.props.screenInputs}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="dashboard_body"
              style={
                {
                  backgroundColor: "rgba(245, 247, 250, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 0px)",
                  maxHeight: "calc(100vh - 0px)",
                  height: "calc(100vh - 0px)"
                } as any
              }
            >
              <KRow
                id="689240"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="926161"
                  xs={14}
                  sm={14}
                  md={14}
                  lg={14}
                  xl={14}
                  xxl={14}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingRight: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="101358"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        paddingTop: 0,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="173520"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="866992"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="686738"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="384910"
                            value={ReactSystemFunctions.translate(this.ml, 384910, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>
                      </KRow>

                      <KPanel
                        id="688817"
                        scrolling="hidden"
                        scrollRadius={false}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <DonutChart
                          id="309677"
                          dataSource={this.state.DashboardMevcutDurum}
                          dataLabelKeyName="FitSkorKategori"
                          dataDisplayLabelKeyName="Oran"
                          firstDataFieldKeyName="Adet"
                          firstDataBgColorKeyName="Renk"
                          leftAxis={false}
                          rightAxis={false}
                          beginAtZero={true}
                          showDataLabels={true}
                          legend=""
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              width: "300px",
                              height: "300px",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "12px",
                              color: "rgba(10, 34, 64, 1)",
                              lineHeight: "20px"
                            } as any
                          }
                        >
                          <ChartLabel
                            id="306863"
                            text="Ortlama FitSkor®"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          ></ChartLabel>

                          <ChartLabel
                            id="696826"
                            text={
                              this.state.DashboarAktifCheckupDonemi?.length > 0
                                ? this.state.DashboarAktifCheckupDonemi[0].fitSkorOrtalama
                                : undefined
                            }
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "48px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          ></ChartLabel>
                        </DonutChart>
                      </KPanel>
                    </KCol>

                    <KCol
                      id="126898"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 100,
                          paddingRight: 0,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <HorizontalStack
                        id="923461"
                        direction="horizontal"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="605636"
                          value={ReactSystemFunctions.translate(this.ml, 605636, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="381757"
                          value={
                            this.state.DashboarAktifCheckupDonemi?.length > 0
                              ? this.state.DashboarAktifCheckupDonemi[0].yapildi
                              : undefined
                          }
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></Label>
                      </HorizontalStack>

                      <KRow
                        id="359294"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 36,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="566765"
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          xxl={2}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="845756"
                            iconName="circle"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(192, 0, 1, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>

                        <KCol
                          id="675938"
                          xs={22}
                          sm={22}
                          md={22}
                          lg={22}
                          xl={22}
                          xxl={22}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="861443"
                            direction="horizontal"
                            size={4}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="293357"
                              value={ReactSystemFunctions.translate(this.ml, 293357, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="756777"
                              value={
                                this.state.DashboardMevcutDurum?.length > 0
                                  ? this.state.DashboardMevcutDurum[0].l1
                                  : undefined
                              }
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="389892"
                              value={ReactSystemFunctions.translate(this.ml, 389892, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KCol>
                      </KRow>

                      <KRow
                        id="232286"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="841713"
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          xxl={2}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="338872"
                            iconName="circle"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(236, 125, 49, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>

                        <KCol
                          id="274347"
                          xs={22}
                          sm={22}
                          md={22}
                          lg={22}
                          xl={22}
                          xxl={22}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="35178"
                            direction="horizontal"
                            size={4}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="288269"
                              value={ReactSystemFunctions.translate(this.ml, 288269, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="110243"
                              value={
                                this.state.DashboardMevcutDurum?.length > 0
                                  ? this.state.DashboardMevcutDurum[0].l2
                                  : undefined
                              }
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="658377"
                              value={ReactSystemFunctions.translate(this.ml, 658377, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KCol>
                      </KRow>

                      <KRow
                        id="869143"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="486178"
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          xxl={2}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="171516"
                            iconName="circle"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(254, 192, 8, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>

                        <KCol
                          id="825494"
                          xs={22}
                          sm={22}
                          md={22}
                          lg={22}
                          xl={22}
                          xxl={22}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="696698"
                            direction="horizontal"
                            size={4}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="795328"
                              value={ReactSystemFunctions.translate(this.ml, 795328, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="274066"
                              value={
                                this.state.DashboardMevcutDurum?.length > 0
                                  ? this.state.DashboardMevcutDurum[0].l3
                                  : undefined
                              }
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="127758"
                              value={ReactSystemFunctions.translate(this.ml, 127758, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KCol>
                      </KRow>

                      <KRow
                        id="846035"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="150145"
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          xxl={2}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="257385"
                            iconName="circle"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(0, 175, 80, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>

                        <KCol
                          id="613831"
                          xs={22}
                          sm={22}
                          md={22}
                          lg={22}
                          xl={22}
                          xxl={22}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="292861"
                            direction="horizontal"
                            size={4}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="762550"
                              value={ReactSystemFunctions.translate(this.ml, 762550, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="223048"
                              value={
                                this.state.DashboardMevcutDurum?.length > 0
                                  ? this.state.DashboardMevcutDurum[0].l4
                                  : undefined
                              }
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="127965"
                              value={ReactSystemFunctions.translate(this.ml, 127965, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>

                <KCol
                  id="725592"
                  visibility={this.state.isCompdashboard_725592AuthorizationVisible}
                  visibilityByAuthorization={this.state.isCompdashboard_725592AuthorizationVisible}
                  xs={10}
                  sm={10}
                  md={10}
                  lg={10}
                  xl={10}
                  xxl={10}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingRight: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      alignItems: "flex-start",
                      textAlign: "-webkit-center",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <KPanel
                    id="295767"
                    scrolling="hidden"
                    scrollRadius={false}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 20,
                        paddingLeft: 0,
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="207744"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="575967"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="790914"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="126358"
                            value={ReactSystemFunctions.translate(this.ml, 126358, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="17524"
                            value={
                              this.state.SinifIstatistik?.length > 0
                                ? this.state.SinifIstatistik[0].cu1Tanim
                                : undefined
                            }
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>
                      </KCol>
                    </KRow>

                    <DonutChart
                      id="716662"
                      dataSource={this.state.DashboarAktifCheckupDonemi}
                      dataLabelKeyName="Txt"
                      dataDisplayLabelKeyName="Val"
                      firstDataFieldKeyName="Val"
                      firstDataBgColorKeyName="Renk"
                      leftAxis={false}
                      rightAxis={false}
                      beginAtZero={true}
                      showDataLabels={false}
                      legend=""
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          width: "260px",
                          height: "260px",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "12px",
                          color: "rgba(10, 34, 64, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    >
                      <ChartLabel
                        id="133586"
                        text={
                          this.state.DashboarAktifCheckupDonemi?.length > 0
                            ? this.state.DashboarAktifCheckupDonemi[0].yapilmaOrani
                            : undefined
                        }
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "36px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      ></ChartLabel>
                    </DonutChart>

                    <KRow
                      id="476787"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 16,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="938397"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 12,
                            paddingLeft: 12,
                            textAlign: "-webkit-right",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="602434"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="826083"
                            direction="horizontal"
                            size={2}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="705168"
                              value={ReactSystemFunctions.translate(this.ml, 705168, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="799399"
                              value={
                                this.state.DashboarAktifCheckupDonemi?.length > 0
                                  ? this.state.DashboarAktifCheckupDonemi[0].yapildi
                                  : undefined
                              }
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="56870"
                              value={ReactSystemFunctions.translate(this.ml, 56870, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>

                          <Icon
                            id="577439"
                            iconName="circle"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(0, 190, 214, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </HorizontalStack>
                      </KCol>

                      <KCol
                        id="551438"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 12,
                            paddingLeft: 12,
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="637745"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="332322"
                            iconName="circle"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(155, 155, 155, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>

                          <HorizontalStack
                            id="432612"
                            direction="horizontal"
                            size={2}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="646782"
                              value={ReactSystemFunctions.translate(this.ml, 646782, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="25035"
                              value={
                                this.state.DashboarAktifCheckupDonemi?.length > 0
                                  ? this.state.DashboarAktifCheckupDonemi[0].yapilmadi
                                  : undefined
                              }
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="783338"
                              value={ReactSystemFunctions.translate(this.ml, 783338, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </HorizontalStack>
                      </KCol>
                    </KRow>
                  </KPanel>
                </KCol>
              </KRow>

              <KRow
                id="261317"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 16,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="56399"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="80692"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="484621"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <Label
                        id="820175"
                        value={ReactSystemFunctions.translate(this.ml, 820175, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(140, 140, 140, 1)",
                            textTransform: "uppercase"
                          } as any
                        }
                      ></Label>
                    </KCol>
                  </KRow>

                  <KRow
                    id="37338"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="291119"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KPanel
                        id="17134"
                        scrolling="hidden"
                        scrollRadius={false}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            maxWidth: "1000px",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="dashboard_880623_value" noStyle>
                          <KTab
                            id="880623"
                            type="line"
                            size="default"
                            tabPosition="top"
                            selectedTabColor="rgba(0, 190, 214, 1)"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTabItem
                              id="78089"
                              tab={ReactSystemFunctions.translate(this.ml, 78089, "tab", this.defaultML)}
                              forceRender={true}
                              key={78089}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTable
                                id="698692"
                                kuikaRef={this.dashboard_698692_value_kuikaTableRef}
                                form={this.props.form}
                                dataSource={this.state.OkulKatilimIstatistikleri}
                                size="middle"
                                bordered={true}
                                showHeader={true}
                                loading={false}
                                nodatafoundmessage={ReactSystemFunctions.translate(
                                  this.ml,
                                  698692,
                                  "nodatafoundmessage",
                                  this.defaultML
                                )}
                                sorter={false}
                                pagination={false}
                                striped={true}
                                stripedColor="rgba(255, 255, 255, 1)"
                                rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                                insertRowActive={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <KTableHeader
                                  id="399821"
                                  columnTitles={
                                    this.state.OkulKatilimIstatistikleriHeader?.length > 0
                                      ? this.state.OkulKatilimIstatistikleriHeader[0].ad
                                      : undefined
                                  }
                                  hideOnMobileResolution={false}
                                  textDirection="Default"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      color: "rgba(10, 34, 64, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></KTableHeader>

                                <KTableRow
                                  id="410462"
                                  hoverFontColor="red"
                                  hoverBgColor="#F5F5F5"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KTableColumn
                                    id="217354"
                                    title={ReactSystemFunctions.translate(this.ml, 217354, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "90px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="433439"
                                      value="[datafield:sinifiAdi]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="227493"
                                    title={ReactSystemFunctions.translate(this.ml, 227493, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="860599"
                                      value="[datafield:mevcut]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="442818"
                                    visibility={this.state.isComp442818Visible}
                                    title={ReactSystemFunctions.translate(this.ml, 442818, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="835194"
                                      visExField1="[datafield:adet1]"
                                      visExField2="0"
                                      visExRule="isNotEqualTo"
                                      value="[datafield:adet1]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(192, 0, 1, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Icon
                                      id="270840"
                                      visExField1="[datafield:adet1]"
                                      visExField2="0"
                                      visExRule="isEqualTo"
                                      iconName="check"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 190, 214, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="55404"
                                    visibility={this.state.isComp55404Visible}
                                    title={ReactSystemFunctions.translate(this.ml, 55404, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="540976"
                                      visExField1="[datafield:adet2]"
                                      visExField2="0"
                                      visExRule="isNotEqualTo"
                                      value="[datafield:adet2]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(192, 0, 1, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Icon
                                      id="728024"
                                      visExField1="[datafield:adet2]"
                                      visExField2="0"
                                      visExRule="isEqualTo"
                                      iconName="check"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 190, 214, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="545930"
                                    visibility={this.state.isComp545930Visible}
                                    title={ReactSystemFunctions.translate(this.ml, 545930, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="955655"
                                      visExField1="[datafield:adet3]"
                                      visExField2="0"
                                      visExRule="isNotEqualTo"
                                      value="[datafield:adet3]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(192, 0, 1, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Icon
                                      id="23929"
                                      visExField1="[datafield:adet3]"
                                      visExField2="0"
                                      visExRule="isEqualTo"
                                      iconName="check"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 190, 214, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="981727"
                                    visibility={this.state.isComp981727Visible}
                                    title={ReactSystemFunctions.translate(this.ml, 981727, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="508121"
                                      visExField1="[datafield:adet4]"
                                      visExField2="0"
                                      visExRule="isNotEqualTo"
                                      value="[datafield:adet4]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(192, 0, 1, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Icon
                                      id="25666"
                                      visExField1="[datafield:adet4]"
                                      visExField2="0"
                                      visExRule="isEqualTo"
                                      iconName="check"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 190, 214, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="272542"
                                    visibility={this.state.isComp272542Visible}
                                    title={ReactSystemFunctions.translate(this.ml, 272542, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="130177"
                                      visExField1="[datafield:adet5]"
                                      visExField2="0"
                                      visExRule="isNotEqualTo"
                                      value="[datafield:adet5]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(192, 0, 1, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Icon
                                      id="360737"
                                      visExField1="[datafield:adet5]"
                                      visExField2="0"
                                      visExRule="isEqualTo"
                                      iconName="check"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 190, 214, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="448896"
                                    visibility={this.state.isComp448896Visible}
                                    title={ReactSystemFunctions.translate(this.ml, 448896, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="451275"
                                      visExField1="[datafield:adet6]"
                                      visExField2="0"
                                      visExRule="isNotEqualTo"
                                      value="[datafield:adet6]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(192, 0, 1, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Icon
                                      id="506765"
                                      visExField1="[datafield:adet6]"
                                      visExField2="0"
                                      visExRule="isEqualTo"
                                      iconName="check"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 190, 214, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="817542"
                                    title={ReactSystemFunctions.translate(this.ml, 817542, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "60px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="686392"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DashboardComponent_686392_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="remove_red_eye"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 190, 214, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>
                                </KTableRow>
                              </KTable>
                            </KTabItem>

                            <KTabItem
                              id="505503"
                              tab={ReactSystemFunctions.translate(this.ml, 505503, "tab", this.defaultML)}
                              forceRender={true}
                              key={505503}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTable
                                id="2234863"
                                kuikaRef={this.dashboard_2234863_value_kuikaTableRef}
                                form={this.props.form}
                                dataSource={this.state.OkulFitnessIstatistigi}
                                size="middle"
                                bordered={true}
                                showHeader={true}
                                loading={false}
                                nodatafoundmessage={ReactSystemFunctions.translate(
                                  this.ml,
                                  2234863,
                                  "nodatafoundmessage",
                                  this.defaultML
                                )}
                                sorter={false}
                                pagination={false}
                                striped={false}
                                stripedColor="#F5F7FA"
                                insertRowActive={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <KTableHeader
                                  id="4315241"
                                  hideOnMobileResolution={false}
                                  textDirection="Default"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      color: "rgba(10, 34, 64, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></KTableHeader>

                                <KTableRow
                                  id="7826774"
                                  hoverFontColor="red"
                                  hoverBgColor="#F5F5F5"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      height: "30px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KTableColumn
                                    id="3576646"
                                    title={ReactSystemFunctions.translate(this.ml, 3576646, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="9846594"
                                      value="[datafield:sinifAdi]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="7746690"
                                    title={ReactSystemFunctions.translate(this.ml, 7746690, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="2431820"
                                      value="[datafield:vucutKompozisyonu]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="9190147"
                                    title={ReactSystemFunctions.translate(this.ml, 9190147, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="6241852"
                                      value="[datafield:kalpGucu]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="7851066"
                                    title={ReactSystemFunctions.translate(this.ml, 7851066, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="4864984"
                                      value="[datafield:esneklik]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="9612618"
                                    title={ReactSystemFunctions.translate(this.ml, 9612618, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="9619719"
                                      value="[datafield:ustEkstremite]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="4497742"
                                    title={ReactSystemFunctions.translate(this.ml, 4497742, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="4448855"
                                      value="[datafield:govde]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="5157581"
                                    title={ReactSystemFunctions.translate(this.ml, 5157581, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="6399777"
                                      value="[datafield:altEkstremite]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="2472377"
                                    title={ReactSystemFunctions.translate(this.ml, 2472377, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderColor: "rgba(244, 244, 244, 1)",
                                        borderStyle: "solid",
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="4422350"
                                      value="[datafield:fitSkor]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="885276"
                                    title={ReactSystemFunctions.translate(this.ml, 885276, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    style={
                                      {
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "40px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="745032"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DashboardComponent_745032_onClick();
                                      }}
                                      showCursorPointer
                                      visExField1="[datafield:sinifId]"
                                      visExField2=""
                                      visExRule="isNotEmpty"
                                      iconName="remove_red_eye"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 190, 214, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>
                                </KTableRow>
                              </KTable>
                            </KTabItem>
                          </KTab>
                        </Form.Item>
                      </KPanel>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KDrawer
                id="561935"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible561935: false }, () => {
                    this.callCallbackFunction(561935);
                  });
                }}
                visible={this.state.NavVisible561935}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible561935 && (
                    <DashboardKatilimDetay_Screen
                      onClose={() => {
                        this.setState({ NavVisible561935: false }, () => {
                          this.callCallbackFunction(561935);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="515754"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible515754: false }, () => {
                    this.callCallbackFunction(515754);
                  });
                }}
                visible={this.state.NavVisible515754}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible515754 && (
                    <DashboardFitBilesenDetay_Screen
                      onClose={() => {
                        this.setState({ NavVisible515754: false }, () => {
                          this.callCallbackFunction(515754);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KModal
                id="858440"
                visible={this.state.NavVisible858440}
                onOk={() => {
                  this.setState({ NavVisible858440: false }, () => {
                    this.callCallbackFunction(858440);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible858440: false }, () => {
                    this.callCallbackFunction(858440);
                  });
                }}
                closable={false}
                width="90vw"
                footer={null}
                height="80vh"
                children={
                  this.state.NavVisible858440 && (
                    <KVKK_Screen
                      onClose={() => {
                        this.setState({ NavVisible858440: false }, () => {
                          this.callCallbackFunction(858440);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
              ></KModal>
            </div>
          </Spin>
        </SideBarMenu_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Dashboard_Screen)))))
);
export { tmp as Dashboard_Screen };
//export default tmp;
//export { tmp as Dashboard_Screen };
