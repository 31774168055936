import { Configuration } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "c65e46a9-a8f9-4d8e-bec6-7291aa595eac",
    authority: "https://login.microsoftonline.com/dc46d5bf-6fa5-4e84-900a-5ef89d16d535",
    redirectUri: "/blank.html",
    postLogoutRedirectUri: "/logout",
    knownAuthorities: [],
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

// Add here the endpoints for MS Graph API services you would like to use.
const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid", "profile", "User.Read", "Mail.Read"],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const apiRequest = {
  scopes: ["API-SCOPE"],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};
