import Axios, { AxiosResponse } from "axios";
import { IApplicationInfo } from "../models/dto/application.dto";
import { EApplicationType, ISaveApplicationRequestDTO } from "../models/dto/save-application-request.dto";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();
const projectId = "d72cc1c2-ddb5-4333-c39c-08dbdbf248e0";
const projectName = "WebApp";

export class ApplicationService {
  public static async saveApplication() {
    const virtualDirectoryURL: string = "auto"; // Use by code generation
    return Axios.post<ISaveApplicationRequestDTO, AxiosResponse<any>>(`${backendUrl}/application`, {
      projectId,
      applicationType: EApplicationType.Frontend,
      applicationName: projectName,
      baseUrl: KMainFunctions.windowLocationOrigin,
      description: `${projectName} application frontend`
    });
  }

  public static async getAllApplications(): Promise<AxiosResponse<IApplicationInfo[]>> {
    return Axios.get<IApplicationInfo[]>(`${backendUrl}/application/all`);
  }
}
