import { AxiosResponse } from "axios";
import FileSaver from "file-saver";
import isRelativeUrl from "is-relative-url";
import { store } from "react-notifications-component";
import { v4 as uuid } from "uuid";
import mimeTypeMappings from "../json/mime-types-mappings.json";
import { BASE_64_REGEX } from "./constants";
import { KuikaAppManager } from "./kuika-app-manager";

export class KMainFunctions {
  static virtualDirectoryURL: string = "auto"; // Use by code generation

  static get windowLocationOrigin() {
    let origin = window?.location?.origin;
    if (window?.location?.protocol === "file:") {
      origin = `file://${window.location.pathname}`;
      return origin;
    }
    return KMainFunctions.virtualDirectoryURL === "auto" ? origin : `${origin}${KMainFunctions.virtualDirectoryURL}`;
  }

  static exceptionHandler(e) {
    const msg = e?.response?.data?.message;
    if (msg) {
      this.displayErrorNotification(msg);
    } else if (e?.message) {
      console.error(e.message);
      this.displayErrorNotification(e.message);
    }
    if (e?.stack) {
      console.error(e.stack);
    }
  }

  static displaySuccessNotification(message, params?) {
    store.addNotification({
      title: params?.title ? params.title : "Success!",
      message,
      type: "success",
      container: params?.container ? params.container : "bottom-right",
      animationIn: params?.animationIn ? params.animationIn : ["animated", "flipInX"],
      animationOut: params?.animationOut ? params.animationOut : ["animated", "fadeOut"],
      dismiss: {
        duration: params?.duration ? params.duration : 5000,
        pauseOnHover: params?.pauseOnHover ? params.pauseOnHover : true,
        showIcon: params?.showIcon ? params.showIcon : true
      }
    });
  }

  static displayWarningNotification(message, params?) {
    store.addNotification({
      title: params?.title ? params.title : "Warning!",
      message,
      type: "warning",
      container: params?.container ? params.container : "bottom-right",
      animationIn: params?.animationIn ? params.animationIn : ["animated", "flipInX"],
      animationOut: params?.animationOut ? params.animationOut : ["animated", "fadeOut"],
      dismiss: {
        duration: params?.duration ? params.duration : 5000,
        pauseOnHover: params?.pauseOnHover ? params.pauseOnHover : true,
        showIcon: params?.showIcon ? params.showIcon : true
      }
    });
  }

  static displayErrorNotification(message, params?) {
    if (message) {
      store.addNotification({
        title: params?.title ? params.title : "Error!",
        message,
        type: "danger",
        container: params?.container ? params.container : "bottom-right",
        animationIn: params?.animationIn ? params.animationIn : ["animated", "flipInX"],
        animationOut: params?.animationOut ? params.animationOut : ["animated", "fadeOut"],
        dismiss: {
          duration: params?.duration ? params.duration : 7000,
          pauseOnHover: params?.pauseOnHover ? params.pauseOnHover : true,
          showIcon: params?.showIcon ? params.showIcon : true
        }
      });
    }
  }

  static toCamelCase(value) {
    let result = "";
    let isLetterFound = false;

    for (let i = 0; i < value?.length; i++) {
      if (value[i].length === 1 && value[i].match(/[a-z]/i) && isLetterFound == false) {
        result += value[i].toLowerCase();
        isLetterFound = true;
      } else {
        result += value[i];
      }
    }

    return result;
  }

  static IsResStatus2XX(res: AxiosResponse): boolean {
    return !!(res?.status && res.status >= 200 && res.status < 300);
  }

  static getTextAlignAsJustifyContent(value: string) {
    if (value) {
      if (value.includes("center")) return "center";
      if (value.includes("right")) return "flex-end";
      if (value.includes("left")) return "flex-start";
    }
  }

  static setChildWidthAndAlignment(child) {
    const kuikablockstyle = child.getAttribute("kuikablockstyle");
    const componentname = child.getAttribute("kuikacomponentname");
    const childClassName = child.className;
    if (child.parentElement.className?.includes("ant-table-cell") && child.className?.includes("ant-space")) {
      child.style.height = "auto";
    }
    if (
      kuikablockstyle === "2" ||
      componentname === "Date" ||
      childClassName?.includes("kuika_table__wrapper") ||
      childClassName?.includes("ant-form-item-row") ||
      childClassName?.includes("kFormItem") ||
      childClassName?.includes("ant-row") ||
      childClassName?.startsWith("kuikaStepperComponent") ||
      child?.style?.display === "block"
    ) {
      child.style.width = "100%";
    } else {
      child.style.alignSelf = "center";
    }
  }

  static setKFormItemStyles(child) {
    const imageInputs = child.querySelectorAll(".kuika-image-input");
    for (let i = 0; i < imageInputs.length; i++) {
      const imageInput = imageInputs[i];
      const kFormItemParent = imageInput.closest(".kFormItem");
      if (kFormItemParent) {
        kFormItemParent.style.width = "auto";
        kFormItemParent.style.alignSelf = "center";
      }
    }
  }

  static setDisplayBlockForImages(node) {
    const images = node.querySelectorAll("[kuikacomponentname='Image']");
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (image && image.children && image.children[0]) {
        image.children[0].style.display = "block";
      }
    }
  }

  static handleAlignments(node: any, styles: any, isInline?: boolean) {
    if (styles && styles.alignItems && node && node.style) {
      node.style.display = isInline ? "inline-flex" : "flex";
      node.style.flexWrap = "wrap";
      node.style.placeContent = styles.alignItems;

      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          const child = node.children[i];
          if (child) {
            this.setChildWidthAndAlignment(child);
            this.setKFormItemStyles(child);
          }
        }
        this.setDisplayBlockForImages(node);
      }
    }

    if (styles && styles.alignItems && styles.textAlign && node && node.style) {
      const justify = KMainFunctions.getTextAlignAsJustifyContent(styles.textAlign);
      node.style.justifyContent = justify;
    } else if (styles && styles.alignItems && !styles.textAlign && node && node.style) {
      node.style.justifyContent = "flex-start";
    }

    return node;
  }

  static isValidUrl(url: string) {
    if (!url) return false;

    try {
      // eslint-disable-next-line no-new
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  static isRelativeUrl(url: string): boolean {
    return isRelativeUrl(url);
  }

  static isBase64(str: string) {
    if (!str) return false;
    return BASE_64_REGEX.test(str);
  }

  public static openBase64Pdf(data: string, warnLandscape: boolean, openAs: "newTab" | "currentPage"): void {
    const base64WithoutPrefix = data.substring("data:application/pdf;base64,".length);
    const bytes = atob(base64WithoutPrefix);
    let { length } = bytes;
    const out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    const url = URL.createObjectURL(new Blob([out], { type: "application/pdf" }));
    KMainFunctions.openUrlPdf(url, openAs);
  }

  public static openUrlPdf(url: string, openAs: "newTab" | "currentPage"): void {
    if (openAs === "currentPage") {
      window.open(url, "_self")?.focus();
      return;
    }

    // openAs === "newTab"
    window.open(url, "_blank")?.focus();
  }

  public static downloadFile(data: string | Blob, fileName: string): void {
    FileSaver.saveAs(data, fileName);
  }

  public static async toBlob(dataUrl: string): Promise<Blob> {
    const response = await fetch(dataUrl);
    return response.blob();
  }

  public static getBase64UrlPrefix(mimeType: string): string {
    return `data:${mimeType};base64,`;
  }

  public static getUrlByWithResourceId = (guid: string) => {
    return `${KuikaAppManager.GetBackendUrl()}/resource/runtime/byid?resourceId=${guid}`;
  };

  public static getExtensionByMimeType(mimeType: string): string {
    const extension = mimeTypeMappings[mimeType];

    if (!extension) {
      return "";
    }

    return extension;
  }

  public static generateFilenameForDownloadFile(filename: string, extension: string): string {
    if (!filename) {
      return `${uuid()}.${extension}`;
    }

    if (filename.endsWith(`.${extension}`)) {
      return filename;
    }

    return `${filename}.${extension}`;
  }
}
