import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Icon,
  KCol,
  KDrawer,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SideBarMenu_Screen } from "../sidebarmenu/sidebarmenu";
import { Statistics_ScreenBase } from "./statistics-base";

import { StatisticsDetails_Screen } from "../statisticsdetails/statisticsdetails";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class Statistics_Screen extends Statistics_ScreenBase {
  render() {
    return (
      <>
        <SideBarMenu_Screen
          form={this.props.form}
          initialValues={{ statistics_880623_value: undefined }}
          screenInputs={this.props.screenInputs}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="statistics_body"
              style={
                {
                  backgroundColor: "rgba(245, 247, 250, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 0px)",
                  maxHeight: "calc(100vh - 0px)",
                  height: "calc(100vh - 0px)"
                } as any
              }
            >
              <KRow
                id="571004"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="251514"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <Label
                    id="275629"
                    value={ReactSystemFunctions.translate(this.ml, 275629, "value", this.defaultML)}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 16,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        fontSize: "18pt",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  ></Label>

                  <KPanel
                    id="587979"
                    scrolling="hidden"
                    scrollRadius={false}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        maxWidth: "1000px",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="statistics_880623_value" noStyle>
                      <KTab
                        id="880623"
                        type="line"
                        size="default"
                        tabPosition="top"
                        selectedTabColor="rgba(0, 190, 214, 1)"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KTabItem
                          id="435106"
                          tab={ReactSystemFunctions.translate(this.ml, 435106, "tab", this.defaultML)}
                          key={435106}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="191294"
                            kuikaRef={this.statistics_191294_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.OkulBkiIstatistikleri}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              191294,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            pagination={false}
                            striped={true}
                            stripedColor="#F5F7FA"
                            rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                            insertRowActive={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="321666"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="275099"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="980083"
                                title={ReactSystemFunctions.translate(this.ml, 980083, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="380292"
                                  value="[datafield:sinifAdi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="627446"
                                title={ReactSystemFunctions.translate(this.ml, 627446, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="331282"
                                  value="[datafield:bki_U25_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="428683"
                                title={ReactSystemFunctions.translate(this.ml, 428683, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="946412"
                                  value="[datafield:bki_U51_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="540025"
                                title={ReactSystemFunctions.translate(this.ml, 540025, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="145360"
                                  value="[datafield:bki_U76_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="605086"
                                title={ReactSystemFunctions.translate(this.ml, 605086, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="566779"
                                  value="[datafield:bki_U101_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="756924"
                                title={ReactSystemFunctions.translate(this.ml, 756924, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    width: "40px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="983039"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.StatisticsComponent_983039_onClick();
                                  }}
                                  showCursorPointer
                                  visExField1="[datafield:sinifId]"
                                  visExField2=""
                                  visExRule="isNotEmpty"
                                  iconName="remove_red_eye"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(0, 190, 214, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KTabItem>

                        <KTabItem
                          id="158300"
                          tab={ReactSystemFunctions.translate(this.ml, 158300, "tab", this.defaultML)}
                          key={158300}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="373386"
                            kuikaRef={this.statistics_373386_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.OkulKalpGucuIstatistikleri}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              373386,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            pagination={false}
                            striped={true}
                            stripedColor="#F5F7FA"
                            rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                            insertRowActive={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="886951"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="477570"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="224145"
                                title={ReactSystemFunctions.translate(this.ml, 224145, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="6314"
                                  value="[datafield:sinifAdi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="935762"
                                title={ReactSystemFunctions.translate(this.ml, 935762, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="678631"
                                  value="[datafield:bki_U25_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="318201"
                                title={ReactSystemFunctions.translate(this.ml, 318201, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="640227"
                                  value="[datafield:bki_U51_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="792183"
                                title={ReactSystemFunctions.translate(this.ml, 792183, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="230016"
                                  value="[datafield:bki_U76_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="159968"
                                title={ReactSystemFunctions.translate(this.ml, 159968, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="29033"
                                  value="[datafield:bki_U101_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="952182"
                                title={ReactSystemFunctions.translate(this.ml, 952182, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    width: "40px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="408009"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.StatisticsComponent_408009_onClick();
                                  }}
                                  showCursorPointer
                                  visExField1="[datafield:sinifId]"
                                  visExField2=""
                                  visExRule="isNotEmpty"
                                  iconName="remove_red_eye"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(0, 190, 214, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KTabItem>

                        <KTabItem
                          id="377821"
                          tab={ReactSystemFunctions.translate(this.ml, 377821, "tab", this.defaultML)}
                          key={377821}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="576913"
                            kuikaRef={this.statistics_576913_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.OkulEsneklikIstatistikleri}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              576913,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            pagination={false}
                            striped={true}
                            stripedColor="#F5F7FA"
                            rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                            insertRowActive={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="761706"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="17424"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="28327"
                                title={ReactSystemFunctions.translate(this.ml, 28327, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="465013"
                                  value="[datafield:sinifAdi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="416480"
                                title={ReactSystemFunctions.translate(this.ml, 416480, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="820412"
                                  value="[datafield:bki_U25_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="831952"
                                title={ReactSystemFunctions.translate(this.ml, 831952, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="335129"
                                  value="[datafield:bki_U51_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="502329"
                                title={ReactSystemFunctions.translate(this.ml, 502329, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="254323"
                                  value="[datafield:bki_U76_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="548817"
                                title={ReactSystemFunctions.translate(this.ml, 548817, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="58751"
                                  value="[datafield:bki_U101_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="560675"
                                title={ReactSystemFunctions.translate(this.ml, 560675, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    width: "40px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="806970"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.StatisticsComponent_806970_onClick();
                                  }}
                                  showCursorPointer
                                  visExField1="[datafield:sinifId]"
                                  visExField2=""
                                  visExRule="isNotEmpty"
                                  iconName="remove_red_eye"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(0, 190, 214, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KTabItem>

                        <KTabItem
                          id="212043"
                          tab={ReactSystemFunctions.translate(this.ml, 212043, "tab", this.defaultML)}
                          key={212043}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="659852"
                            kuikaRef={this.statistics_659852_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.OkulUstEkstremiteIstatistikleri}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              659852,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            pagination={false}
                            striped={true}
                            stripedColor="#F5F7FA"
                            rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                            insertRowActive={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="168183"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="289838"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="529156"
                                title={ReactSystemFunctions.translate(this.ml, 529156, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="443123"
                                  value="[datafield:sinifAdi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="180009"
                                title={ReactSystemFunctions.translate(this.ml, 180009, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="936386"
                                  value="[datafield:bki_U25_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="105803"
                                title={ReactSystemFunctions.translate(this.ml, 105803, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="459422"
                                  value="[datafield:bki_U51_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="578535"
                                title={ReactSystemFunctions.translate(this.ml, 578535, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="690456"
                                  value="[datafield:bki_U76_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="457626"
                                title={ReactSystemFunctions.translate(this.ml, 457626, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="435524"
                                  value="[datafield:bki_U101_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="185076"
                                title={ReactSystemFunctions.translate(this.ml, 185076, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="867952"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.StatisticsComponent_867952_onClick();
                                  }}
                                  showCursorPointer
                                  visExField1="[datafield:sinifId]"
                                  visExField2=""
                                  visExRule="isNotEmpty"
                                  iconName="remove_red_eye"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(0, 190, 214, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KTabItem>

                        <KTabItem
                          id="733244"
                          tab={ReactSystemFunctions.translate(this.ml, 733244, "tab", this.defaultML)}
                          key={733244}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="669253"
                            kuikaRef={this.statistics_669253_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.OkulGovdeIstatistikleri}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              669253,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            pagination={false}
                            striped={true}
                            stripedColor="#F5F7FA"
                            rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                            insertRowActive={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="934787"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="211777"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="948845"
                                title={ReactSystemFunctions.translate(this.ml, 948845, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="184992"
                                  value="[datafield:sinifAdi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="369158"
                                title={ReactSystemFunctions.translate(this.ml, 369158, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="842655"
                                  value="[datafield:bki_U25_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="542569"
                                title={ReactSystemFunctions.translate(this.ml, 542569, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="978714"
                                  value="[datafield:bki_U51_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="703454"
                                title={ReactSystemFunctions.translate(this.ml, 703454, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="727374"
                                  value="[datafield:bki_U76_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="72718"
                                title={ReactSystemFunctions.translate(this.ml, 72718, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="884609"
                                  value="[datafield:bki_U101_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="199347"
                                title={ReactSystemFunctions.translate(this.ml, 199347, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    width: "40px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="134800"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.StatisticsComponent_134800_onClick();
                                  }}
                                  showCursorPointer
                                  visExField1="[datafield:sinifId]"
                                  visExField2=""
                                  visExRule="isNotEmpty"
                                  iconName="remove_red_eye"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(0, 190, 214, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KTabItem>

                        <KTabItem
                          id="798438"
                          tab={ReactSystemFunctions.translate(this.ml, 798438, "tab", this.defaultML)}
                          key={798438}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="185326"
                            kuikaRef={this.statistics_185326_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.OkulAltEkstremiteIstatistikleri}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              185326,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            pagination={false}
                            striped={true}
                            stripedColor="#F5F7FA"
                            rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                            insertRowActive={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="977282"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="260200"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="650822"
                                title={ReactSystemFunctions.translate(this.ml, 650822, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="970916"
                                  value="[datafield:sinifAdi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="391742"
                                title={ReactSystemFunctions.translate(this.ml, 391742, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="866493"
                                  value="[datafield:bki_U25_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="638316"
                                title={ReactSystemFunctions.translate(this.ml, 638316, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="714220"
                                  value="[datafield:bki_U51_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="846128"
                                title={ReactSystemFunctions.translate(this.ml, 846128, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="548174"
                                  value="[datafield:bki_U76_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="184976"
                                title={ReactSystemFunctions.translate(this.ml, 184976, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="437264"
                                  value="[datafield:bki_U101_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="895653"
                                title={ReactSystemFunctions.translate(this.ml, 895653, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    width: "40px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="252811"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.StatisticsComponent_252811_onClick();
                                  }}
                                  showCursorPointer
                                  visExField1="[datafield:sinifId]"
                                  visExField2=""
                                  visExRule="isNotEmpty"
                                  iconName="remove_red_eye"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(0, 190, 214, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KTabItem>

                        <KTabItem
                          id="827702"
                          tab={ReactSystemFunctions.translate(this.ml, 827702, "tab", this.defaultML)}
                          key={827702}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="318987"
                            kuikaRef={this.statistics_318987_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.OkulFitskorIstatistikleri}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              318987,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            pagination={false}
                            striped={true}
                            stripedColor="#F5F7FA"
                            rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                            insertRowActive={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="92954"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: "rgba(10, 34, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="629578"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="859194"
                                title={ReactSystemFunctions.translate(this.ml, 859194, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="284458"
                                  value="[datafield:sinifAdi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="64167"
                                title={ReactSystemFunctions.translate(this.ml, 64167, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="859543"
                                  value="[datafield:bki_U25_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="472512"
                                title={ReactSystemFunctions.translate(this.ml, 472512, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="972224"
                                  value="[datafield:bki_U51_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="917934"
                                title={ReactSystemFunctions.translate(this.ml, 917934, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="77542"
                                  value="[datafield:bki_U76_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="993344"
                                title={ReactSystemFunctions.translate(this.ml, 993344, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="905242"
                                  value="[datafield:bki_U101_Adet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(10, 34, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="430231"
                                title={ReactSystemFunctions.translate(this.ml, 430231, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    width: "40px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="24120"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.StatisticsComponent_24120_onClick();
                                  }}
                                  showCursorPointer
                                  visExField1="[datafield:sinifId]"
                                  visExField2=""
                                  visExRule="isNotEmpty"
                                  iconName="remove_red_eye"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(0, 190, 214, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KTabItem>
                      </KTab>
                    </Form.Item>
                  </KPanel>
                </KCol>
              </KRow>

              <KDrawer
                id="128081"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible128081: false }, () => {
                    this.callCallbackFunction(128081);
                  });
                }}
                visible={this.state.NavVisible128081}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible128081 && (
                    <StatisticsDetails_Screen
                      onClose={() => {
                        this.setState({ NavVisible128081: false }, () => {
                          this.callCallbackFunction(128081);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="794678"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible794678: false }, () => {
                    this.callCallbackFunction(794678);
                  });
                }}
                visible={this.state.NavVisible794678}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible794678 && (
                    <StatisticsDetails_Screen
                      onClose={() => {
                        this.setState({ NavVisible794678: false }, () => {
                          this.callCallbackFunction(794678);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="457792"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible457792: false }, () => {
                    this.callCallbackFunction(457792);
                  });
                }}
                visible={this.state.NavVisible457792}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible457792 && (
                    <StatisticsDetails_Screen
                      onClose={() => {
                        this.setState({ NavVisible457792: false }, () => {
                          this.callCallbackFunction(457792);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="205694"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible205694: false }, () => {
                    this.callCallbackFunction(205694);
                  });
                }}
                visible={this.state.NavVisible205694}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible205694 && (
                    <StatisticsDetails_Screen
                      onClose={() => {
                        this.setState({ NavVisible205694: false }, () => {
                          this.callCallbackFunction(205694);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="73654"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible73654: false }, () => {
                    this.callCallbackFunction(73654);
                  });
                }}
                visible={this.state.NavVisible73654}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible73654 && (
                    <StatisticsDetails_Screen
                      onClose={() => {
                        this.setState({ NavVisible73654: false }, () => {
                          this.callCallbackFunction(73654);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="141465"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible141465: false }, () => {
                    this.callCallbackFunction(141465);
                  });
                }}
                visible={this.state.NavVisible141465}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible141465 && (
                    <StatisticsDetails_Screen
                      onClose={() => {
                        this.setState({ NavVisible141465: false }, () => {
                          this.callCallbackFunction(141465);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="472636"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible472636: false }, () => {
                    this.callCallbackFunction(472636);
                  });
                }}
                visible={this.state.NavVisible472636}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible472636 && (
                    <StatisticsDetails_Screen
                      onClose={() => {
                        this.setState({ NavVisible472636: false }, () => {
                          this.callCallbackFunction(472636);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>
            </div>
          </Spin>
        </SideBarMenu_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Statistics_Screen)))))
);
export { tmp as Statistics_Screen };
//export default tmp;
//export { tmp as Statistics_Screen };
