import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAilem_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAilem_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  ReadFromLocalStorage: string;
  SetLanguage: string;
  MyContext: any[];
  NAVIGATE: any;
  VelininOgrencileri: any[];
  isComp529225Visible: "visible" | "hidden";
}

export class Ailem_ScreenBase extends React.PureComponent<IAilem_ScreenProps, any> {
  ailem_688733_value_kuikaGalleryViewRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 53907, PropertyName: "value", Value: "[datafield:name]", TagName: "Label0_0_value" },
        { Id: 181013, PropertyName: "value", Value: "[datafield:school]", TagName: "Label0_0_value" },
        { Id: 746929, PropertyName: "value", Value: "-", TagName: "Label0_1_value" },
        { Id: 569485, PropertyName: "value", Value: "[datafield:class]", TagName: "Label8_value" },
        { Id: 673434, PropertyName: "value", Value: "My Children", TagName: "Label4_0_value" },
        { Id: 196096, PropertyName: "label", Value: "Reports", TagName: "Button1_label" },
        { Id: 231802, PropertyName: "label", Value: "Analysis", TagName: "Button2_label" }
      ]
    },
    {
      Id: "ee606e54-4a63-42f7-8122-2b5c5e3b571a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 673434, PropertyName: "value", Value: "Çocuklarım" },
        { Id: 688733, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 53907, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 181013, PropertyName: "value", Value: "[datafield:okulu]" },
        { Id: 746929, PropertyName: "value", Value: "-" },
        { Id: 569485, PropertyName: "value", Value: "[datafield:sinifi]" },
        { Id: 196096, PropertyName: "label", Value: "RAPORLAR" },
        { Id: 231802, PropertyName: "label", Value: "ANALİZLER" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ailem_688733_value_kuikaGalleryViewRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      ReadFromLocalStorage: "",
      SetLanguage: "",
      MyContext: [],
      NAVIGATE: "",
      VelininOgrencileri: [],
      isComp529225Visible: "hidden"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ailem", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.AilemPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("ailem", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ailem", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.AilemPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  AilemPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("lan");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AilemPageInit1_();
    });

    return isErrorOccurred;
  };
  AilemPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage), null)) {
      stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(
        this,
        ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage)
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.AilemPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.AilemPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  AilemPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Ailem/AilemPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AilemPageInit3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AilemPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kvvkOkudum : null),
        true
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.AilemPageInit4_,
        "Ailem",
        "KVKK",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "296425",
        null,
        null,
        "False",
        "90vw",
        "80vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.AilemPageInit4_();
      });
    }

    return isErrorOccurred;
  };
  AilemPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      veliId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.veliId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Ailem/AilemPageInit4_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VelininOgrencileri = result?.data.velininOgrencileri;

    stateVars.isComp529225Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.VelininOgrencileri?.length > 0 ? stateVars.VelininOgrencileri[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AilemPageInit5_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AilemPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_688733 = this.state.VelininOgrencileri;
    stateVars.isComp529225Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.VelininOgrencileri?.length > 0 ? this.state.VelininOgrencileri[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AilemComponent_196096_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OgrenciIncele",
      "OgrenciId",
      ReactSystemFunctions.value(this, "ailem_688733_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Ailem",
      "OgrenciIncele",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AilemComponent_231802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OgrenciStatistics",
      "OgrenciId",
      ReactSystemFunctions.value(this, "ailem_688733_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Ailem",
      "OgrenciStatistics",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [296425, ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AilemPageInit();
    }
    if (diId == 296425) {
      isErrorOccurred = await this.AilemPageInit4_();
      if (isErrorOccurred) return true;
    }
  }
}
