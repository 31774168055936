import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgretmenList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgretmenList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  OgretmenSearch: any[];
  OgretmenCount: any[];
  SetValueOf: any;
  NAVIGATE: any;
  DisableUser: any;
  MemapUserDelete: number;
  isCompogretmenlist_463984AuthorizationVisible: "visible" | "hidden";
}

export class OgretmenList_ScreenBase extends React.PureComponent<IOgretmenList_ScreenProps, any> {
  ogretmenlist_632646_value_kuikaTableRef: React.RefObject<any>;
  ogretmenlist_512268_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "List of Teachers", TagName: "lblHead_value" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search", TagName: "txtSearch_placeholder" },
        { Id: 814413, PropertyName: "label", Value: "NEW TEACHER", TagName: "btnNew_label" },
        {
          Id: 632646,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table0_0_nodatafoundmessage"
        },
        { Id: 463984, PropertyName: "title", Value: "School", TagName: "TableColumn0_0_title" },
        { Id: 404104, PropertyName: "value", Value: "[datafield:school]", TagName: "Label1_0_value" },
        { Id: 884688, PropertyName: "title", Value: "Name", TagName: "TableColumn0_1_title" },
        { Id: 4215, PropertyName: "value", Value: "[datafield:firstname]", TagName: "Label1_0_value" },
        { Id: 312304, PropertyName: "title", Value: "Surname", TagName: "TableColumn8_title" },
        { Id: 926952, PropertyName: "value", Value: "[datafield:lastname]", TagName: "Label6_0_value" },
        { Id: 725801, PropertyName: "title", Value: "Email", TagName: "TableColumn0_2_title" },
        { Id: 752746, PropertyName: "value", Value: "[datafield:username]", TagName: "Label1_0_value" },
        { Id: 157461, PropertyName: "title", Value: "Phone number", TagName: "TableColumn0_3_title" },
        { Id: 733115, PropertyName: "value", Value: "[datafield:phonenumber]", TagName: "Label1_0_value" },
        { Id: 758131, PropertyName: "title", Value: "The teacher's classes", TagName: "TableColumn0_4_title" },
        { Id: 785074, PropertyName: "value", Value: "[datafield:numberofclasses]", TagName: "Label1_0_value" }
      ]
    },
    {
      Id: "a97b3474-6d85-4d9d-839b-9abaa4959f2f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Öğretmen Listesi" },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama.." },
        { Id: 814413, PropertyName: "label", Value: "YENİ ÖĞRETMEN" },
        { Id: 632646, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 463984, PropertyName: "title", Value: "Okulu" },
        { Id: 404104, PropertyName: "value", Value: "[datafield:okulu]" },
        { Id: 884688, PropertyName: "title", Value: "Adı" },
        { Id: 4215, PropertyName: "value", Value: "[datafield:firstname]" },
        { Id: 312304, PropertyName: "title", Value: "Soyadı" },
        { Id: 926952, PropertyName: "value", Value: "[datafield:lastname]" },
        { Id: 725801, PropertyName: "title", Value: "Eposta" },
        { Id: 752746, PropertyName: "value", Value: "[datafield:username]" },
        { Id: 157461, PropertyName: "title", Value: "Telefon" },
        { Id: 733115, PropertyName: "value", Value: "[datafield:phonenumber]" },
        { Id: 758131, PropertyName: "title", Value: "Sınıfları" },
        { Id: 785074, PropertyName: "value", Value: "[datafield:sinifadedi]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogretmenlist_632646_value_kuikaTableRef = React.createRef();
    this.ogretmenlist_512268_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      OgretmenSearch: [],
      OgretmenCount: [],
      SetValueOf: "",
      NAVIGATE: "",
      DisableUser: "",
      MemapUserDelete: 0,
      isCompogretmenlist_463984AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogretmenlist", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgretmenListPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompogretmenlist_463984AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("ogretmenlist", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogretmenlist", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgretmenListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  OgretmenListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_512268_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_512268_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_416063_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgretmenList/OgretmenListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OgretmenSearch = result?.data.ogretmenSearch;

    stateVars.OgretmenCount = result?.data.ogretmenCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_632646 = this.state.OgretmenSearch;
    formVars.ogretmenlist_512268_total = ReactSystemFunctions.value(
      this,
      this.state.OgretmenCount?.length > 0 ? this.state.OgretmenCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenListComponent_416063_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_512268_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_512268_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_416063_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgretmenList/OgretmenListComponent_416063_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OgretmenSearch = result?.data.ogretmenSearch;

    stateVars.OgretmenCount = result?.data.ogretmenCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenListComponent_416063_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenListComponent_416063_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ogretmenlist_512268_value", 1, null);

    stateVars.dataSource_632646 = this.state.OgretmenSearch;
    formVars.ogretmenlist_512268_total = ReactSystemFunctions.value(
      this,
      this.state.OgretmenCount?.length > 0 ? this.state.OgretmenCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OgretmenForm", "KUserId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("OgretmenForm", "MeMapUserId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OgretmenList",
      "OgretmenForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "375px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenListComponent_528035_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OgretmeninSiniflari",
      "OgretmenKUserId",
      ReactSystemFunctions.value(this, "ogretmenlist_632646_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OgretmenList",
      "OgretmeninSiniflari",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "601852",
      null,
      "right",
      null,
      "720px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenListComponent_818619_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OgretmenForm",
      "KUserId",
      ReactSystemFunctions.value(this, "ogretmenlist_632646_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "OgretmenForm",
      "MeMapUserId",
      ReactSystemFunctions.value(this, "ogretmenlist_632646_value", "memapUserId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OgretmenList",
      "OgretmenForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "620768",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenListComponent_746845_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.DisableUser = await ReactSystemFunctions.disableUser(
      ReactSystemFunctions.value(this, "ogretmenlist_632646_value", "userName")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenListComponent_746845_onClick1_();
    });

    return isErrorOccurred;
  };
  OgretmenListComponent_746845_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ogretmenlist_632646_value", "memapUserId"),
        "Guid"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_512268_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_512268_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OgretmenList/OgretmenListComponent_746845_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MemapUserDelete = result?.data.memapUserDelete;
    stateVars.OgretmenCount = result?.data.ogretmenCount;
    stateVars.OgretmenSearch = result?.data.ogretmenSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenListComponent_746845_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenListComponent_746845_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_632646 = this.state.OgretmenSearch;
    formVars.ogretmenlist_512268_total = ReactSystemFunctions.value(
      this,
      this.state.OgretmenCount?.length > 0 ? this.state.OgretmenCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenListComponent_512268_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_512268_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenlist_512268_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgretmenList/OgretmenListComponent_512268_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OgretmenSearch = result?.data.ogretmenSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenListComponent_512268_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenListComponent_512268_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_632646 = this.state.OgretmenSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 601852, 620768] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OgretmenListPageInit();
    }
  }
}
