import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProfilEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProfilEdit_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
}

export class ProfilEdit_ScreenBase extends React.PureComponent<IProfilEdit_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 511994, PropertyName: "value", Value: "Profile", TagName: "Label1_value" },
        { Id: 95774, PropertyName: "value", Value: "Download", TagName: "Label2_value" },
        { Id: 745053, PropertyName: "value", Value: "Name Surname", TagName: "Label3_value" },
        { Id: 717572, PropertyName: "placeholder", Value: "John Smith", TagName: "TextInput1_placeholder" },
        { Id: 764599, PropertyName: "value", Value: "Email", TagName: "Label2_value" },
        { Id: 273718, PropertyName: "placeholder", Value: "john.smith@memap.com", TagName: "Email1_placeholder" },
        { Id: 164074, PropertyName: "value", Value: "Phone Number", TagName: "Label2_value" },
        { Id: 539987, PropertyName: "placeholder", Value: "+90 532 234 43 32", TagName: "NumberInput1_placeholder" },
        { Id: 564055, PropertyName: "label", Value: "Save", TagName: "Button1_label" }
      ]
    },
    {
      Id: "846b94e2-2321-4a55-8946-df4cecaf2723",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 511994, PropertyName: "value", Value: "Profil" },
        { Id: 95774, PropertyName: "value", Value: "Yükle" },
        { Id: 745053, PropertyName: "value", Value: "Ad Soyad" },
        { Id: 717572, PropertyName: "placeholder", Value: "İbrahim Mavişehir" },
        { Id: 764599, PropertyName: "value", Value: "Email" },
        { Id: 273718, PropertyName: "placeholder", Value: "ibrahim.mavisehir@kuika.com" },
        { Id: 164074, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 539987, PropertyName: "placeholder", Value: "+90555 666 77 88" },
        { Id: 564055, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("profiledit", "profiledit", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();

    KuikaAppManager.calculateAndSetBodyHeight("profiledit", "profiledit", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("profiledit", "profiledit", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      profiledit_539987_value: undefined
    });
  }

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
