import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KGallery,
  KModal,
  KPanel,
  KRow,
  Label,
  VerticalStack
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SideBarMenu_Screen } from "../sidebarmenu/sidebarmenu";
import { Ailem_ScreenBase } from "./ailem-base";

import { KVKK_Screen } from "../kvkk/kvkk";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class Ailem_Screen extends Ailem_ScreenBase {
  render() {
    return (
      <>
        <SideBarMenu_Screen
          form={this.props.form}
          initialValues={{ ailem_360470_value: "2ef988bf-1515-49b2-ae53-08dbe5c3a425" }}
          screenInputs={this.props.screenInputs}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="ailem_body"
              style={
                {
                  backgroundColor: "rgba(245, 247, 250, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 0px)",
                  maxHeight: "calc(100vh - 0px)",
                  height: "calc(100vh - 0px)"
                } as any
              }
            >
              <KRow
                id="17706"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 16,
                    paddingRight: 8,
                    paddingBottom: 0,
                    paddingLeft: 24,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="784654"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <Label
                    id="673434"
                    value={ReactSystemFunctions.translate(this.ml, 673434, "value", this.defaultML)}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        fontSize: "18pt",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  ></Label>
                </KCol>
              </KRow>

              <KRow
                id="906779"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="48981"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <KGallery
                    id="688733"
                    kuikaRef={this.ailem_688733_value_kuikaGalleryViewRef}
                    dataSource={this.state.VelininOgrencileri}
                    loading={false}
                    phoneColumnsCount={1}
                    tabletColumnsCount={2}
                    desktopColumnsCount={2}
                    hoverFontColor="black"
                    hoverBgColor="white"
                    nodatafoundmessage={ReactSystemFunctions.translate(
                      this.ml,
                      688733,
                      "nodatafoundmessage",
                      this.defaultML
                    )}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KPanel
                      id="131132"
                      scrolling="hidden"
                      scrollRadius={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="779889"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="171668"
                          xs={24}
                          sm={5}
                          md={5}
                          lg={5}
                          xl={5}
                          xxl={5}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="582690"
                            iconName="account_circle"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                width: "68px",
                                height: "68px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "68px",
                                color: "rgba(155, 155, 155, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>

                        <KCol
                          id="742147"
                          xs={24}
                          sm={19}
                          md={19}
                          lg={19}
                          xl={19}
                          xxl={19}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <VerticalStack
                            id="68949"
                            direction="vertical"
                            size={6}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="53907"
                              value="[datafield:adi]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "18px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <HorizontalStack
                              id="961112"
                              direction="horizontal"
                              size={4}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="181013"
                                value="[datafield:okulu]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="746929"
                                value={ReactSystemFunctions.translate(this.ml, 746929, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="569485"
                                value="[datafield:sinifi]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </HorizontalStack>
                          </VerticalStack>

                          <KRow
                            id="20343"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="387913"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Button
                                id="196096"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.AilemComponent_196096_onClick();
                                }}
                                showCursorPointer
                                label={ReactSystemFunctions.translate(this.ml, 196096, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(0, 190, 214, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Button>
                            </KCol>

                            <KCol
                              id="884556"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Button
                                id="231802"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.AilemComponent_231802_onClick();
                                }}
                                showCursorPointer
                                label={ReactSystemFunctions.translate(this.ml, 231802, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(0, 190, 214, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Button>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>
                    </KPanel>
                  </KGallery>
                </KCol>
              </KRow>

              <KRow
                id="529225"
                visibility={this.state.isComp529225Visible}
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 120,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="792895"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                ></KCol>

                <KCol
                  id="122439"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-center",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <Form.Item className="kFormItem" name="ailem_360470_value">
                    <Image
                      id="360470"
                      zoomOnClick={false}
                      imageFit="fit"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          width: "180px",
                          height: "180px",
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    ></Image>
                  </Form.Item>
                </KCol>

                <KCol
                  id="668864"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                ></KCol>
              </KRow>

              <KModal
                id="296425"
                visible={this.state.NavVisible296425}
                onOk={() => {
                  this.setState({ NavVisible296425: false }, () => {
                    this.callCallbackFunction(296425);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible296425: false }, () => {
                    this.callCallbackFunction(296425);
                  });
                }}
                closable={false}
                width="90vw"
                footer={null}
                height="80vh"
                children={
                  this.state.NavVisible296425 && (
                    <KVKK_Screen
                      onClose={() => {
                        this.setState({ NavVisible296425: false }, () => {
                          this.callCallbackFunction(296425);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
              ></KModal>
            </div>
          </Spin>
        </SideBarMenu_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Ailem_Screen)))))
);
export { tmp as Ailem_Screen };
//export default tmp;
//export { tmp as Ailem_Screen };
