import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IStatistics_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IStatistics_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  GetLanguage: string;
  OkulBkiIstatistikleri: any[];
  OkulEsneklikIstatistikleri: any[];
  OkulKalpGucuIstatistikleri: any[];
  OkulUstEkstremiteIstatistikleri: any[];
  OkulGovdeIstatistikleri: any[];
  OkulAltEkstremiteIstatistikleri: any[];
  OkulFitskorIstatistikleri: any[];
  NAVIGATE: any;
}

export class Statistics_ScreenBase extends React.PureComponent<IStatistics_ScreenProps, any> {
  statistics_191294_value_kuikaTableRef: React.RefObject<any>;
  statistics_373386_value_kuikaTableRef: React.RefObject<any>;
  statistics_576913_value_kuikaTableRef: React.RefObject<any>;
  statistics_659852_value_kuikaTableRef: React.RefObject<any>;
  statistics_669253_value_kuikaTableRef: React.RefObject<any>;
  statistics_185326_value_kuikaTableRef: React.RefObject<any>;
  statistics_318987_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 275629, PropertyName: "value", Value: "Statistics", TagName: "Label21_0_value" },
        { Id: 827702, PropertyName: "tab", Value: "FitSkor", TagName: "TabItem7_tab" },
        {
          Id: 318987,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table7_nodatafoundmessage"
        },
        { Id: 859194, PropertyName: "title", Value: "Class", TagName: "TableColumn30_0_title" },
        { Id: 64167, PropertyName: "title", Value: "FitSkor <25", TagName: "TableColumn30_1_title" },
        { Id: 472512, PropertyName: "title", Value: "FitSkor 25-50", TagName: "TableColumn30_2_title" },
        { Id: 917934, PropertyName: "title", Value: "FitSkor 50-75", TagName: "TableColumn30_3_title" },
        { Id: 993344, PropertyName: "title", Value: "FitSkor 75-100", TagName: "TableColumn30_4_title" },
        { Id: 435106, PropertyName: "tab", Value: "BMI", TagName: "TabItem1_1_tab" },
        {
          Id: 191294,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table7_nodatafoundmessage"
        },
        { Id: 980083, PropertyName: "title", Value: "Class", TagName: "TableColumn30_0_title" },
        { Id: 627446, PropertyName: "title", Value: "BMI <25", TagName: "TableColumn30_1_title" },
        { Id: 428683, PropertyName: "title", Value: "BMI 25-50", TagName: "TableColumn30_2_title" },
        { Id: 540025, PropertyName: "title", Value: "BMI 50-75", TagName: "TableColumn30_3_title" },
        { Id: 605086, PropertyName: "title", Value: "BMI 75-100", TagName: "TableColumn30_4_title" },
        { Id: 158300, PropertyName: "tab", Value: "Heart Strength", TagName: "TabItem2_tab" },
        {
          Id: 373386,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table2_nodatafoundmessage"
        },
        { Id: 224145, PropertyName: "title", Value: "Class", TagName: "TableColumn5_0_title" },
        { Id: 935762, PropertyName: "title", Value: "Heart Strength >25", TagName: "TableColumn5_1_title" },
        { Id: 318201, PropertyName: "title", Value: "Heart Strength 25-50", TagName: "TableColumn5_2_title" },
        { Id: 792183, PropertyName: "title", Value: "Heart Strength 50-75", TagName: "TableColumn5_3_title" },
        { Id: 159968, PropertyName: "title", Value: "Heart Strength 75-100", TagName: "TableColumn5_4_title" },
        { Id: 377821, PropertyName: "tab", Value: "Flexibility", TagName: "TabItem3_tab" },
        {
          Id: 576913,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table3_nodatafoundmessage"
        },
        { Id: 28327, PropertyName: "title", Value: "Class", TagName: "TableColumn10_0_title" },
        { Id: 416480, PropertyName: "title", Value: "Flexibility >25", TagName: "TableColumn10_1_title" },
        { Id: 831952, PropertyName: "title", Value: "Flexibility 25-50", TagName: "TableColumn10_2_title" },
        { Id: 502329, PropertyName: "title", Value: "Flexibility 25-50", TagName: "TableColumn10_3_title" },
        { Id: 548817, PropertyName: "title", Value: "Flexibility 75-100", TagName: "TableColumn10_4_title" },
        { Id: 212043, PropertyName: "tab", Value: "Upper Extremity", TagName: "TabItem4_tab" },
        {
          Id: 659852,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table4_nodatafoundmessage"
        },
        { Id: 529156, PropertyName: "title", Value: "Class", TagName: "TableColumn15_0_title" },
        { Id: 180009, PropertyName: "title", Value: "Upper Extremity <25", TagName: "TableColumn15_1_title" },
        { Id: 105803, PropertyName: "title", Value: "Upper Extremity 25-50", TagName: "TableColumn15_2_title" },
        { Id: 578535, PropertyName: "title", Value: "Upper Extremity 50-75", TagName: "TableColumn15_3_title" },
        { Id: 457626, PropertyName: "title", Value: "Upper Extremity 75-100", TagName: "TableColumn15_4_title" },
        { Id: 733244, PropertyName: "tab", Value: "Core", TagName: "TabItem5_tab" },
        {
          Id: 669253,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table5_nodatafoundmessage"
        },
        { Id: 948845, PropertyName: "title", Value: "Class", TagName: "TableColumn20_0_title" },
        { Id: 369158, PropertyName: "title", Value: "Core <25", TagName: "TableColumn20_1_title" },
        { Id: 542569, PropertyName: "title", Value: "Core 25-50", TagName: "TableColumn20_2_title" },
        { Id: 703454, PropertyName: "title", Value: "Core 50-75", TagName: "TableColumn20_3_title" },
        { Id: 72718, PropertyName: "title", Value: "Core 75-100", TagName: "TableColumn20_4_title" },
        { Id: 798438, PropertyName: "tab", Value: "Lower Extremity", TagName: "TabItem6_tab" },
        {
          Id: 185326,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table6_nodatafoundmessage"
        },
        { Id: 650822, PropertyName: "title", Value: "Class", TagName: "TableColumn25_0_title" },
        { Id: 391742, PropertyName: "title", Value: "Lower Extremity <25", TagName: "TableColumn25_1_title" },
        { Id: 638316, PropertyName: "title", Value: "Lower Extremity 25-50", TagName: "TableColumn25_2_title" },
        { Id: 846128, PropertyName: "title", Value: "Lower Extremity 50-75", TagName: "TableColumn25_3_title" },
        { Id: 184976, PropertyName: "title", Value: "Lower Extremity 75-100", TagName: "TableColumn25_4_title" },
        { Id: 284458, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label31_0_value" },
        { Id: 859543, PropertyName: "value", Value: "[datafield:bmi_u25_number]", TagName: "Label31_0_value" },
        { Id: 972224, PropertyName: "value", Value: "[datafield:bmi_u51_number]", TagName: "Label31_0_value" },
        { Id: 77542, PropertyName: "value", Value: "[datafield:bmi_u76_number]", TagName: "Label31_0_value" },
        { Id: 905242, PropertyName: "value", Value: "[datafield:bmi_u101_number]", TagName: "Label31_0_value" },
        { Id: 380292, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label31_0_value" },
        { Id: 331282, PropertyName: "value", Value: "[datafield:bmi_u25_number]", TagName: "Label31_0_value" },
        { Id: 946412, PropertyName: "value", Value: "[datafield:bmi_u51_number]", TagName: "Label31_0_value" },
        { Id: 145360, PropertyName: "value", Value: "[datafield:bmi_u76_number]", TagName: "Label31_0_value" },
        { Id: 566779, PropertyName: "value", Value: "[datafield:bmi_u101_number]", TagName: "Label31_0_value" },
        { Id: 6314, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label6_0_value" },
        { Id: 678631, PropertyName: "value", Value: "[datafield:bmi_u25_number]", TagName: "Label6_0_value" },
        { Id: 640227, PropertyName: "value", Value: "[datafield:bmi_u51_number]", TagName: "Label6_0_value" },
        { Id: 230016, PropertyName: "value", Value: "[datafield:bmi_u76_number]", TagName: "Label6_0_value" },
        { Id: 29033, PropertyName: "value", Value: "[datafield:bmi_u101_number]", TagName: "Label6_0_value" },
        { Id: 465013, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label11_0_value" },
        { Id: 820412, PropertyName: "value", Value: "[datafield:bmi_u25_number]", TagName: "Label11_0_value" },
        { Id: 335129, PropertyName: "value", Value: "[datafield:bmi_u51_number]", TagName: "Label11_0_value" },
        { Id: 254323, PropertyName: "value", Value: "[datafield:bmi_u76_number]", TagName: "Label11_0_value" },
        { Id: 58751, PropertyName: "value", Value: "[datafield:bmi_u101_number]", TagName: "Label11_0_value" },
        { Id: 443123, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label16_0_value" },
        { Id: 936386, PropertyName: "value", Value: "[datafield:bmi_u25_number]", TagName: "Label16_0_value" },
        { Id: 459422, PropertyName: "value", Value: "[datafield:bmi_u51_number]", TagName: "Label16_0_value" },
        { Id: 690456, PropertyName: "value", Value: "[datafield:bmi_u76_number]", TagName: "Label16_0_value" },
        { Id: 435524, PropertyName: "value", Value: "[datafield:bmi_u101_number]", TagName: "Label16_0_value" },
        { Id: 184992, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label21_0_value" },
        { Id: 842655, PropertyName: "value", Value: "[datafield:bmi_u25_number]", TagName: "Label21_0_value" },
        { Id: 978714, PropertyName: "value", Value: "[datafield:bmi_u51_number]", TagName: "Label21_0_value" },
        { Id: 727374, PropertyName: "value", Value: "[datafield:bmi_u76_number]", TagName: "Label21_0_value" },
        { Id: 884609, PropertyName: "value", Value: "[datafield:bmi_u101_number]", TagName: "Label21_0_value" },
        { Id: 970916, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label26_0_value" },
        { Id: 866493, PropertyName: "value", Value: "[datafield:bmi_u25_number]", TagName: "Label26_0_value" },
        { Id: 714220, PropertyName: "value", Value: "[datafield:bmi_u51_number]", TagName: "Label26_0_value" },
        { Id: 548174, PropertyName: "value", Value: "[datafield:bmi_u76_number]", TagName: "Label26_0_value" },
        { Id: 437264, PropertyName: "value", Value: "[datafield:bmi_u101_number]", TagName: "Label26_0_value" }
      ]
    },
    {
      Id: "c545f0df-c62b-45b8-9a6a-610d54185115",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 275629, PropertyName: "value", Value: "İstatistikler" },
        { Id: 435106, PropertyName: "tab", Value: "BKI" },
        { Id: 191294, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 980083, PropertyName: "title", Value: "Sınıf" },
        { Id: 380292, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 627446, PropertyName: "title", Value: "Bki < 25" },
        { Id: 331282, PropertyName: "value", Value: "[datafield:bki_u25_adet]" },
        { Id: 428683, PropertyName: "title", Value: "Bki 25-50" },
        { Id: 946412, PropertyName: "value", Value: "[datafield:bki_u51_adet]" },
        { Id: 540025, PropertyName: "title", Value: "Bki 50-75" },
        { Id: 145360, PropertyName: "value", Value: "[datafield:bki_u76_adet]" },
        { Id: 605086, PropertyName: "title", Value: "Bki 75-100" },
        { Id: 566779, PropertyName: "value", Value: "[datafield:bki_u101_adet]" },
        { Id: 158300, PropertyName: "tab", Value: "Kalp Gücü" },
        { Id: 373386, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 224145, PropertyName: "title", Value: "Sınıf" },
        { Id: 6314, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 935762, PropertyName: "title", Value: "Kalp Gücü < 25" },
        { Id: 678631, PropertyName: "value", Value: "[datafield:bki_u25_adet]" },
        { Id: 318201, PropertyName: "title", Value: "Kalp Gücü 25-50" },
        { Id: 640227, PropertyName: "value", Value: "[datafield:bki_u51_adet]" },
        { Id: 792183, PropertyName: "title", Value: "Kalp Gücü 50-75" },
        { Id: 230016, PropertyName: "value", Value: "[datafield:bki_u76_adet]" },
        { Id: 159968, PropertyName: "title", Value: "Kalp Gücü 75-100" },
        { Id: 29033, PropertyName: "value", Value: "[datafield:bki_u101_adet]" },
        { Id: 377821, PropertyName: "tab", Value: "Esneklik" },
        { Id: 576913, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 28327, PropertyName: "title", Value: "Sınıf" },
        { Id: 465013, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 416480, PropertyName: "title", Value: "Esneklik < 25" },
        { Id: 820412, PropertyName: "value", Value: "[datafield:bki_u25_adet]" },
        { Id: 831952, PropertyName: "title", Value: "Esneklik 25-50" },
        { Id: 335129, PropertyName: "value", Value: "[datafield:bki_u51_adet]" },
        { Id: 502329, PropertyName: "title", Value: "Esneklik 50-75" },
        { Id: 254323, PropertyName: "value", Value: "[datafield:bki_u76_adet]" },
        { Id: 548817, PropertyName: "title", Value: "Esneklik 75-100" },
        { Id: 58751, PropertyName: "value", Value: "[datafield:bki_u101_adet]" },
        { Id: 212043, PropertyName: "tab", Value: "Üst Ekstremite" },
        { Id: 659852, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 529156, PropertyName: "title", Value: "Sınıf" },
        { Id: 443123, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 180009, PropertyName: "title", Value: "Üst Ekstremite < 25" },
        { Id: 936386, PropertyName: "value", Value: "[datafield:bki_u25_adet]" },
        { Id: 105803, PropertyName: "title", Value: "Üst Ekstremite 25-50" },
        { Id: 459422, PropertyName: "value", Value: "[datafield:bki_u51_adet]" },
        { Id: 578535, PropertyName: "title", Value: "Üst Ekstremite 50-75" },
        { Id: 690456, PropertyName: "value", Value: "[datafield:bki_u76_adet]" },
        { Id: 457626, PropertyName: "title", Value: "Üst Ekstremite 75-100" },
        { Id: 435524, PropertyName: "value", Value: "[datafield:bki_u101_adet]" },
        { Id: 733244, PropertyName: "tab", Value: "Gövde" },
        { Id: 669253, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 948845, PropertyName: "title", Value: "Sınıf" },
        { Id: 184992, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 369158, PropertyName: "title", Value: "Gövde < 25" },
        { Id: 842655, PropertyName: "value", Value: "[datafield:bki_u25_adet]" },
        { Id: 542569, PropertyName: "title", Value: "Gövde 25-50" },
        { Id: 978714, PropertyName: "value", Value: "[datafield:bki_u51_adet]" },
        { Id: 703454, PropertyName: "title", Value: "Gövde 50-75" },
        { Id: 727374, PropertyName: "value", Value: "[datafield:bki_u76_adet]" },
        { Id: 72718, PropertyName: "title", Value: "Gövde 75-100" },
        { Id: 884609, PropertyName: "value", Value: "[datafield:bki_u101_adet]" },
        { Id: 798438, PropertyName: "tab", Value: "Alt Ekstremite" },
        { Id: 185326, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 650822, PropertyName: "title", Value: "Sınıf" },
        { Id: 970916, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 391742, PropertyName: "title", Value: "Alt Ekstremite < 25" },
        { Id: 866493, PropertyName: "value", Value: "[datafield:bki_u25_adet]" },
        { Id: 638316, PropertyName: "title", Value: "Alt Ekstremite 25-50" },
        { Id: 714220, PropertyName: "value", Value: "[datafield:bki_u51_adet]" },
        { Id: 846128, PropertyName: "title", Value: "Alt Ekstremite 50-75" },
        { Id: 548174, PropertyName: "value", Value: "[datafield:bki_u76_adet]" },
        { Id: 184976, PropertyName: "title", Value: "Alt Ekstremite 75-100" },
        { Id: 437264, PropertyName: "value", Value: "[datafield:bki_u101_adet]" },
        { Id: 827702, PropertyName: "tab", Value: "FitSkor" },
        { Id: 318987, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 859194, PropertyName: "title", Value: "Sınıf" },
        { Id: 284458, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 64167, PropertyName: "title", Value: "FitSkor < 25" },
        { Id: 859543, PropertyName: "value", Value: "[datafield:bki_u25_adet]" },
        { Id: 472512, PropertyName: "title", Value: "FitSkor 25-50" },
        { Id: 972224, PropertyName: "value", Value: "[datafield:bki_u51_adet]" },
        { Id: 917934, PropertyName: "title", Value: "FitSkor 50-75" },
        { Id: 77542, PropertyName: "value", Value: "[datafield:bki_u76_adet]" },
        { Id: 993344, PropertyName: "title", Value: "FitSkor 75-100" },
        { Id: 905242, PropertyName: "value", Value: "[datafield:bki_u101_adet]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.statistics_191294_value_kuikaTableRef = React.createRef();
    this.statistics_373386_value_kuikaTableRef = React.createRef();
    this.statistics_576913_value_kuikaTableRef = React.createRef();
    this.statistics_659852_value_kuikaTableRef = React.createRef();
    this.statistics_669253_value_kuikaTableRef = React.createRef();
    this.statistics_185326_value_kuikaTableRef = React.createRef();
    this.statistics_318987_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      GetLanguage: "",
      OkulBkiIstatistikleri: [],
      OkulEsneklikIstatistikleri: [],
      OkulKalpGucuIstatistikleri: [],
      OkulUstEkstremiteIstatistikleri: [],
      OkulGovdeIstatistikleri: [],
      OkulAltEkstremiteIstatistikleri: [],
      OkulFitskorIstatistikleri: [],
      NAVIGATE: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("statistics", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.StatisticsPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("statistics", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("statistics", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.StatisticsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  StatisticsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Statistics/StatisticsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.StatisticsPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  StatisticsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.StatisticsPageInit2_();
    });

    return isErrorOccurred;
  };
  StatisticsPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      checkupDonemiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      checkupDonemiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      checkupDonemiId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      checkupDonemiId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      checkupDonemiId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      checkupDonemiId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      checkupDonemiId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      kuserId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Statistics/StatisticsPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OkulBkiIstatistikleri = result?.data.okulBkiIstatistikleri;

    stateVars.OkulEsneklikIstatistikleri = result?.data.okulEsneklikIstatistikleri;

    stateVars.OkulKalpGucuIstatistikleri = result?.data.okulKalpGucuIstatistikleri;

    stateVars.OkulUstEkstremiteIstatistikleri = result?.data.okulUstEkstremiteIstatistikleri;

    stateVars.OkulGovdeIstatistikleri = result?.data.okulGovdeIstatistikleri;

    stateVars.OkulAltEkstremiteIstatistikleri = result?.data.okulAltEkstremiteIstatistikleri;

    stateVars.OkulFitskorIstatistikleri = result?.data.okulFitskorIstatistikleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.StatisticsPageInit3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  StatisticsPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_191294 = this.state.OkulBkiIstatistikleri;

    stateVars.dataSource_373386 = this.state.OkulKalpGucuIstatistikleri;

    stateVars.dataSource_576913 = this.state.OkulEsneklikIstatistikleri;

    stateVars.dataSource_659852 = this.state.OkulUstEkstremiteIstatistikleri;

    stateVars.dataSource_669253 = this.state.OkulGovdeIstatistikleri;

    stateVars.dataSource_185326 = this.state.OkulAltEkstremiteIstatistikleri;

    stateVars.dataSource_318987 = this.state.OkulFitskorIstatistikleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_983039_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetails",
      "SinifId",
      ReactSystemFunctions.value(this, "statistics_191294_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetails", "BilesenTipi", "Vücüt Kompozisyonu");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "128081",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_408009_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetails",
      "SinifId",
      ReactSystemFunctions.value(this, "statistics_373386_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetails", "BilesenTipi", "Kalp Gücü");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "794678",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_806970_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetails",
      "SinifId",
      ReactSystemFunctions.value(this, "statistics_576913_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetails", "BilesenTipi", "Esneklik");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "457792",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_867952_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetails",
      "SinifId",
      ReactSystemFunctions.value(this, "statistics_659852_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetails", "BilesenTipi", "Üst Ekstremite");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "205694",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_134800_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetails",
      "SinifId",
      ReactSystemFunctions.value(this, "statistics_669253_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetails", "BilesenTipi", "Gövde");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "141465",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_252811_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetails",
      "SinifId",
      ReactSystemFunctions.value(this, "statistics_185326_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetails", "BilesenTipi", "Alt Ekstremite");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "73654",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_24120_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetails",
      "SinifId",
      ReactSystemFunctions.value(this, "statistics_318987_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetails", "BilesenTipi", "FitSkor");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "472636",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [128081, 794678, 457792, 205694, 141465, 73654, 472636] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.StatisticsPageInit();
    }
  }
}
