import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgrenciStatistics_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgrenciStatistics_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  OgrenciSelectById: any[];
  OgrencininVelileri: any[];
  OgrenciAltEkstremite: any[];
  OgrenciVucutKompozisyonu: any[];
  OgrenciKalpGucu: any[];
  OgrenciEsneklik: any[];
  OgrenciUstEkstremite: any[];
  OgrenciGovde: any[];
  OgrenciFitSkor: any[];
  GoBack: any;
}

export class OgrenciStatistics_ScreenBase extends React.PureComponent<IOgrenciStatistics_ScreenProps, any> {
  ogrencistatistics_2326_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 760420, PropertyName: "value", Value: "-", TagName: "Label5_value" },
        { Id: 584263, PropertyName: "value", Value: "STUDENT'S PARENTS", TagName: "Label9_value" },
        { Id: 2326, PropertyName: "nodatafoundmessage", Value: "Data not found", TagName: "Table2_nodatafoundmessage" },
        { Id: 303098, PropertyName: "title", Value: "Title", TagName: "TableColumn5_0_title" },
        { Id: 373127, PropertyName: "value", Value: "[datafield:name]", TagName: "Label10_value" },
        { Id: 893547, PropertyName: "value", Value: "My children", TagName: "Label13_1_value" },
        { Id: 545951, PropertyName: "tab", Value: "Body Composition", TagName: "TabItem1_0_tab" },
        { Id: 264600, PropertyName: "tab", Value: "Heart Strenght ", TagName: "TabItem1_1_tab" },
        { Id: 275843, PropertyName: "tab", Value: "Flexibility", TagName: "TabItem1_2_tab" },
        { Id: 203822, PropertyName: "tab", Value: "Upper Extremity", TagName: "TabItem4_tab" },
        { Id: 286835, PropertyName: "tab", Value: "Core", TagName: "TabItem5_tab" },
        { Id: 199558, PropertyName: "tab", Value: "Lower Extremity", TagName: "TabItem6_tab" },
        { Id: 590058, PropertyName: "tab", Value: "FitSkor®", TagName: "TabItem7_tab" }
      ]
    },
    {
      Id: "afa5b514-2edf-4346-9d69-da7eb4ff68b0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 893547, PropertyName: "value", Value: "Çocuklarım" },
        { Id: 760420, PropertyName: "value", Value: "-" },
        { Id: 584263, PropertyName: "value", Value: "ÖĞRENCİNİN VELİLERİ" },
        { Id: 2326, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 303098, PropertyName: "title", Value: "Title" },
        { Id: 373127, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 545951, PropertyName: "tab", Value: "Vücut Kompozisyonu" },
        { Id: 264600, PropertyName: "tab", Value: "Kalp Gücü" },
        { Id: 275843, PropertyName: "tab", Value: "Esneklik" },
        { Id: 203822, PropertyName: "tab", Value: "Üst Ekstremite" },
        { Id: 286835, PropertyName: "tab", Value: "Gövde" },
        { Id: 199558, PropertyName: "tab", Value: "Alt Ekstremite" },
        { Id: 590058, PropertyName: "tab", Value: "FitSkor" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogrencistatistics_2326_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      OgrenciSelectById: [],
      OgrencininVelileri: [],
      OgrenciAltEkstremite: [],
      OgrenciVucutKompozisyonu: [],
      OgrenciKalpGucu: [],
      OgrenciEsneklik: [],
      OgrenciUstEkstremite: [],
      OgrenciGovde: [],
      OgrenciFitSkor: [],
      GoBack: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogrencistatistics", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgrenciStatisticsPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("ogrencistatistics", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogrencistatistics", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgrenciStatisticsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  OgrenciStatisticsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      sinifId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sezonId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      ogrenciId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      ogrenciId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      ogrenciId_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      ogrenciId_5: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      ogrenciId_6: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      ogrenciId_7: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      ogrenciId_8: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      ogrenciId_9: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgrenciStatistics/OgrenciStatisticsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OgrenciSelectById = result?.data.ogrenciSelectById;
    formVars.ogrencistatistics_298440_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.adi : null
    );
    formVars.ogrencistatistics_816685_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.dT : null
    );
    formVars.ogrencistatistics_981029_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulu : null
    );
    formVars.ogrencistatistics_181062_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.sinifi : null
    );
    stateVars.OgrencininVelileri = result?.data.ogrencininVelileri;

    stateVars.OgrenciAltEkstremite = result?.data.ogrenciAltEkstremite;

    stateVars.OgrenciVucutKompozisyonu = result?.data.ogrenciVucutKompozisyonu;

    stateVars.OgrenciKalpGucu = result?.data.ogrenciKalpGucu;

    stateVars.OgrenciEsneklik = result?.data.ogrenciEsneklik;

    stateVars.OgrenciUstEkstremite = result?.data.ogrenciUstEkstremite;

    stateVars.OgrenciGovde = result?.data.ogrenciGovde;

    stateVars.OgrenciFitSkor = result?.data.ogrenciFitSkor;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciStatisticsPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciStatisticsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.ogrencistatistics_298440_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.adi : null
    );

    formVars.ogrencistatistics_816685_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.dT : null
    );

    formVars.ogrencistatistics_981029_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.okulu : null
    );

    formVars.ogrencistatistics_181062_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.sinifi : null
    );

    stateVars.dataSource_2326 = this.state.OgrencininVelileri;

    stateVars.dataSource_558430 = this.state.OgrenciVucutKompozisyonu;

    stateVars.dataSource_115345 = this.state.OgrenciKalpGucu;

    stateVars.dataSource_491023 = this.state.OgrenciEsneklik;

    stateVars.dataSource_307738 = this.state.OgrenciUstEkstremite;

    stateVars.dataSource_950723 = this.state.OgrenciGovde;

    stateVars.dataSource_682363 = this.state.OgrenciAltEkstremite;

    stateVars.dataSource_189141 = this.state.OgrenciFitSkor;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciStatisticsComponent_678191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciStatisticsComponent_893547_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
