import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVeliList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVeliList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GetLanguage: string;
  MyContext: any[];
  VeliSearch: any[];
  VeliCount: any[];
  SetValueOf: any;
  NAVIGATE: any;
  VeliRaporKontrol: number;
  CreateUser: string;
  VeliFindIdByUsername: any[];
  VeliUpdateUserId: number;
  FindUsersToken: any[];
  OkulLogo: any[];
  SendMail: boolean;
  CheckupVeliBilgilendirmeKaydet: number;
  Notify: boolean;
  VeliDeleteById: number;
  isCompvelilist_8704962AuthorizationVisible: "visible" | "hidden";
}

export class VeliList_ScreenBase extends React.PureComponent<IVeliList_ScreenProps, any> {
  velilist_399769_value_kuikaTableRef: React.RefObject<any>;
  velilist_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Parent List", TagName: "lblHead_value" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search..", TagName: "txtSearch_placeholder" },
        { Id: 814413, PropertyName: "label", Value: "NEW PARENT", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblVeli_nodatafoundmessage"
        },
        { Id: 8704962, PropertyName: "title", Value: "School", TagName: "clmAdi_title" },
        { Id: 713151, PropertyName: "title", Value: "Parents Name Surname", TagName: "TableColumn7_title" },
        { Id: 6025720, PropertyName: "title", Value: "Email", TagName: "clmEposta_title" },
        { Id: 5221269, PropertyName: "title", Value: "Phone Number", TagName: "clmTelefon_title" },
        { Id: 863575, PropertyName: "title", Value: "Student's Parent", TagName: "TableColumn6_title" },
        { Id: 2932756, PropertyName: "title", Value: "User", TagName: "clmKUserId_title" },
        { Id: 820588, PropertyName: "label", Value: "SEND", TagName: "Button2_label" },
        { Id: 828735, PropertyName: "value", Value: "parent found", TagName: "Label8_value" },
        {
          Id: 41426713,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this parent?",
          TagName: "VeliDeleteById_confirmation",
          IsConfirmation: true
        },
        {
          Id: 58296842,
          PropertyName: "d514f565-95d8-d808-9a25-5be48f164509_notify",
          Value: "The invitation was successfully sent.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        {
          Id: 89766572,
          PropertyName: "ddd59601-4107-16c1-ae32-e5a9081084d0_notify",
          Value: "The parent was informed about the new report.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        { Id: 1189984, PropertyName: "value", Value: "[datafield:school]", TagName: "lblAdi_value" },
        { Id: 734115, PropertyName: "value", Value: "[datafield:name]", TagName: "Label5_0_value" },
        { Id: 2409185, PropertyName: "value", Value: "[datafield:email]", TagName: "lblEposta_value" },
        { Id: 3810966, PropertyName: "value", Value: "[datafield:phonenumber]", TagName: "lblTelefon_value" },
        { Id: 148787, PropertyName: "value", Value: "[datafield:numberofchildrenhe/shehas]", TagName: "Label4_0_value" }
      ]
    },
    {
      Id: "752b82fe-5c7c-4e8d-9388-3f51ea1eb30b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Veli Listesi" },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama.." },
        { Id: 814413, PropertyName: "label", Value: "YENİ VELİ" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 8704962, PropertyName: "title", Value: "Okulu" },
        { Id: 1189984, PropertyName: "value", Value: "[datafield:okulu]" },
        { Id: 713151, PropertyName: "title", Value: "Velinin Adı Soyadı" },
        { Id: 734115, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 6025720, PropertyName: "title", Value: "Eposta" },
        { Id: 2409185, PropertyName: "value", Value: "[datafield:eposta]" },
        { Id: 5221269, PropertyName: "title", Value: "Telefon" },
        { Id: 3810966, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 863575, PropertyName: "title", Value: "Velisi Olduğu Öğrenci" },
        { Id: 148787, PropertyName: "value", Value: "[datafield:velisiolduguogrenciadedi]" },
        { Id: 2932756, PropertyName: "title", Value: "Kullanıcı" },
        { Id: 820588, PropertyName: "label", Value: "GÖNDER" },
        { Id: 828735, PropertyName: "value", Value: "veli bulundu." }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.velilist_399769_value_kuikaTableRef = React.createRef();
    this.velilist_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GetLanguage: "",
      MyContext: [],
      VeliSearch: [],
      VeliCount: [],
      SetValueOf: "",
      NAVIGATE: "",
      VeliRaporKontrol: 0,
      CreateUser: "",
      VeliFindIdByUsername: [],
      VeliUpdateUserId: 0,
      FindUsersToken: [],
      OkulLogo: [],
      SendMail: false,
      CheckupVeliBilgilendirmeKaydet: 0,
      Notify: false,
      VeliDeleteById: 0,
      isCompvelilist_8704962AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("velilist", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.VeliListPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompvelilist_8704962AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("velilist", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("velilist", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.VeliListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  VeliListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListPageInit1_();
    });

    return isErrorOccurred;
  };
  VeliListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.VeliSearch = result?.data.veliSearch;

    stateVars.VeliCount = result?.data.veliCount;
    formVars.velilist_919135_value = ReactSystemFunctions.toString(
      this,
      stateVars.VeliCount?.length > 0 ? stateVars.VeliCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListPageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.VeliSearch;
    formVars.velilist_919135_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliCount?.length > 0 ? this.state.VeliCount[0]?.count : null
    );

    formVars.velilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.VeliCount?.length > 0 ? this.state.VeliCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      ogretmenUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      ogretmenUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_416063_onPressEnter", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VeliSearch = result?.data.veliSearch;

    stateVars.VeliCount = result?.data.veliCount;
    formVars.velilist_919135_value = ReactSystemFunctions.toString(
      this,
      stateVars.VeliCount?.length > 0 ? stateVars.VeliCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_416063_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "velilist_172599_value", 1, null);

    stateVars.dataSource_399769 = this.state.VeliSearch;
    formVars.velilist_919135_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliCount?.length > 0 ? this.state.VeliCount[0]?.count : null
    );

    formVars.velilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.VeliCount?.length > 0 ? this.state.VeliCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("VeliForm", "Id", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VeliList",
      "VeliForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "375px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliListComponent_630458_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "VelininOgrencileri",
      "VeliId",
      ReactSystemFunctions.value(this, "velilist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VeliList",
      "VelininOgrencileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "681663",
      null,
      "right",
      null,
      "760px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliListComponent_820588_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_820588_onClick1_();
    });

    return isErrorOccurred;
  };
  VeliListComponent_820588_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      veliId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_820588_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VeliRaporKontrol = result?.data.veliRaporKontrol;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_820588_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_820588_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.CreateUser = await ReactSystemFunctions.createUser(
      ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
      ReactSystemFunctions.value(this, "velilist_399769_value", "adi"),
      "xxx",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_820588_onClick3_();
    });

    return isErrorOccurred;
  };
  VeliListComponent_820588_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      ),
      veliId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "id"),
        "Guid"
      ),
      okulId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "okulId"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_820588_onClick3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VeliFindIdByUsername = result?.data.veliFindIdByUsername;
    stateVars.VeliUpdateUserId = result?.data.veliUpdateUserId;
    stateVars.FindUsersToken = result?.data.findUsersToken;
    stateVars.OkulLogo = result?.data.okulLogo;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_820588_onClick4_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_820588_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("ac53f077-410d-4042-86d4-08dbdc7f8568", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "emailBaslik"),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      okulLogoUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "okulImgUrl"),
        "string"
      ),
      veliAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "adi"),
        "string"
      ),
      okulAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "okulu"),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.baseUrl : null),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      ),
      pass_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.FindUsersToken?.length > 0 ? this.state.FindUsersToken[0]?.token : null
        ),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR")) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_820588_onClick4_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.VeliListComponent_820588_onClick5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.VeliListComponent_820588_onClick5_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_820588_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("28db206e-42bc-43ca-838d-225100dc6e5a", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "emailBaslik"),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      okulLogoUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "okulImgUrl"),
        "string"
      ),
      veliAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "adi"),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.baseUrl : null),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      ),
      pass_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.FindUsersToken?.length > 0 ? this.state.FindUsersToken[0]?.token : null
        ),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US")) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_820588_onClick5_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.VeliListComponent_820588_onClick6_();
      });
    } else {
      this.setState(stateVars, () => {
        this.VeliListComponent_820588_onClick6_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_820588_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      veliId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "id"),
        "Guid"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      ogretmenUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_820588_onClick6_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupVeliBilgilendirmeKaydet = result?.data.checkupVeliBilgilendirmeKaydet;
    stateVars.VeliSearch = result?.data.veliSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_820588_onClick7_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_820588_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "d514f565_95d8_d808_9a25_5be48f164509_notify",
        this.defaultML,
        "Veliye davet başarı ile gönderildi."
      ),
      "default",
      "bottom-right",
      5
    );

    stateVars.dataSource_399769 = this.state.VeliSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliListComponent_438437_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_438437_onClick1_();
    });

    return isErrorOccurred;
  };
  VeliListComponent_438437_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("b1000411-bc76-4fb4-a7b5-7b8cbe160d88", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "emailBaslik3"),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      okulLogoUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "okulImgUrl"),
        "string"
      ),
      veliAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "adi"),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.baseUrl : null),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR")) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_438437_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.VeliListComponent_438437_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.VeliListComponent_438437_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_438437_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("5909f629-4b90-4621-b18f-2a405abe86b9", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "emailBaslik3"),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      okulLogoUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "okulImgUrl"),
        "string"
      ),
      veliAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "adi"),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.baseUrl : null),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "eposta"),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US")) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_438437_onClick2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.VeliListComponent_438437_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.VeliListComponent_438437_onClick3_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_438437_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      veliId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "id"),
        "Guid"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      ogretmenUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_438437_onClick3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupVeliBilgilendirmeKaydet = result?.data.checkupVeliBilgilendirmeKaydet;
    stateVars.VeliSearch = result?.data.veliSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_438437_onClick4_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_438437_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ddd59601_4107_16c1_ae32_e5a9081084d0_notify",
        this.defaultML,
        "Veliye yeni raporlar hakkında bilgilendirildi."
      ),
      "default",
      "bottom-right",
      5
    );

    stateVars.dataSource_399769 = this.state.VeliSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "VeliForm",
      "Id",
      ReactSystemFunctions.value(this, "velilist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VeliList",
      "VeliForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "375px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "velilist_399769_value", "id"),
        "Guid"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      ogretmenUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      ogretmenUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Bu veliyi silmek istediğine emin misin?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_505853_onClick", localVar);
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.VeliDeleteById = result?.data.veliDeleteById;
        stateVars.VeliSearch = result?.data.veliSearch;

        stateVars.VeliCount = result?.data.veliCount;
        formVars.velilist_919135_value = ReactSystemFunctions.toString(
          this,
          stateVars.VeliCount?.length > 0 ? stateVars.VeliCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.VeliListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.VeliSearch;
    formVars.velilist_919135_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliCount?.length > 0 ? this.state.VeliCount[0]?.count : null
    );

    formVars.velilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.VeliCount?.length > 0 ? this.state.VeliCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      ogretmenUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "velilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliList/VeliListComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VeliSearch = result?.data.veliSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliListComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.VeliSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 681663, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.VeliListPageInit();
    }
  }
}
