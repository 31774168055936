type Dictionary = [{ key: string; value: string }];

const addToDictionary = (dictionary: any | undefined, key: string, value: string): Dictionary => {
  if (dictionary && dictionary.length && dictionary.length > 0) {
    const result = dictionary.find((p) => p.key === key);
    if (result !== undefined) {
      result.value = value;
    } else {
      dictionary.push({ key, value });
    }
  } else {
    dictionary = [{ key, value }];
  }
  return dictionary;
};

export const getDictionaryValueByKey = (dictionary: any | undefined, key: string): string => {
  let result: string | undefined = "";
  if (dictionary && dictionary.length && dictionary.length > 0) {
    result = dictionary.find((p) => p.key === key)?.value;
  }
  if (result === undefined) {
    result = "";
  }
  return result;
};

export interface IWorkflowTaskDto {
  processNo: number;
  processName: string;
  processInstanceId: string;
  processStartDate: any;
  processStartedUserId: string;
  processStartedUserFullName: string;
  taskNo: number;
  stepKey: string;
  stepName: string;
  stepDescription: string;
  stepStartDate: any;
  dueDate: string;
  assignedUserName: string;
  assignedUserFullName: string;
  processVersion: number;
  totalPageCount: number;
}

interface IUserTaskCount {
  onMe: number;
  inPool: number;
}

// export interface IWorkflowStepParametersDto {
//   stepParamaters: Dictionary;
//   inputsMap: Dictionary;
//   // outputsMap: Dictionary;
//   key: string;
//   eventKey: string;
//   assignedUserId: string;
//   steptype: number;
//   // stepId: string;
//   screenId: string;
//   stepName: string;
//   workflowInstanceId: string;
// }

export interface ITaskNavigationParameters {
  stepId: string;
  processId: string;
  assignedUserId: string;
  appId?: string;
}

export interface ITaskDataParameters {
  stepData: Dictionary;
  workflowStepInstanceId: string;
  processInstance: string;
  assignedUserId: string;
}

export interface IProcessConfigData {
  projectId: string;
  processId: string;
  stepId: string;
  screenId: string;
}

interface IApplicationRegistery {
  id: string;
  projectId: string;
  applicationType: number;
  baseUrl: string;
  pingUrl: string;
  description: string;
  createdAt: Date;
  updateAt?: Date;
}

// VTO: Refactor
export interface IWorkflowInstanceDto {
  processNo: number;
  processInstanceId: string;
  processName: string;
  createdAt: Date;
  dueDate: Date;
  currentPendingStep: string;
  currentUserFullName: string;
  status: number;
  wfData: Dictionary;
  history: IWorkflowHistoryDto[];
  totalPageCount: number;
}

export interface IWorkflowHistoryDto {
  taskNo: number;
  stepName: string;
  createdAt: Date;
  completedAt: Date | undefined;
  stepUser: string;
  status: string;
  data: Dictionary;
}

export interface IPendingTasksRequestDto {
  user: string;
  app: string;
  searchKey: string;
  showPool: boolean;
  page: number;
  pageSize: number;
}

export interface IProcessHistoryRequestDto {
  user: string;
  app: string;
  searchKey: string;
  processName: string;
  showCompleted: boolean;
  page: number;
  pageSize: number;
}
