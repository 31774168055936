import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISinifCheckupForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISinifCheckupForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  TopluOlcumById: any[];
  SinifSelectById: any[];
  CheckupDonemiById: any[];
  SinifOlcumGirisi: any[];
  ChangeEnabledOf: any;
  ComponentToPdf: string;
  OpenPdf: any;
  GoBack: any;
  CheckupSave: number;
  TopluOlcumKaydet: number;
  GetLanguage: string;
  NAVIGATE: any;
  isComp71407Enabled: "enabled" | "disabled";
}

export class SinifCheckupForm_ScreenBase extends React.PureComponent<ISinifCheckupForm_ScreenProps, any> {
  sinifcheckupform_71407_value_kuikaDateRef: React.RefObject<any>;
  sinifcheckupform_807745_value_kuikaSelectBoxRef: React.RefObject<any>;
  sinifcheckupform_710827_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Class Fitness Check-Up", TagName: "Label5_value" },
        { Id: 715955, PropertyName: "value", Value: "Check-Up Period", TagName: "label20_value" },
        { Id: 121696, PropertyName: "value", Value: "Check-Up Date", TagName: "label21_value" },
        { Id: 71407, PropertyName: "placeholder", Value: "Choose", TagName: "dtTarih_placeholder" },
        { Id: 495081, PropertyName: "value", Value: "Class", TagName: "Label14_value" },
        { Id: 70763, PropertyName: "value", Value: "Filter", TagName: "label23_value" },
        { Id: 807745, PropertyName: "placeholder", Value: "Girl/Boy", TagName: "selCinsiyet_placeholder" },
        {
          Id: 710827,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 429654, PropertyName: "title", Value: "School number", TagName: "TableColumn1_0_title" },
        { Id: 97038, PropertyName: "value", Value: "[datafield:schoolnumber]", TagName: "Label3_value" },
        { Id: 322449, PropertyName: "title", Value: "Birth Date", TagName: "TableColumn4_title" },
        { Id: 13435, PropertyName: "value", Value: "[datafield:birthdate]", TagName: "Label5_0_value" },
        { Id: 424551, PropertyName: "title", Value: "Name", TagName: "TableColumn2_title" },
        { Id: 421195, PropertyName: "value", Value: "[datafield:name]", TagName: "Label4_value" },
        { Id: 631897, PropertyName: "title", Value: "Age", TagName: "tablecolumn5_title" },
        { Id: 643042, PropertyName: "value", Value: "[datafield:age]", TagName: "label15_0_value" },
        { Id: 265423, PropertyName: "title", Value: "Height", TagName: "EditableTableColumn1_0_title" },
        { Id: 24621, PropertyName: "value", Value: "[datafield:height]", TagName: "Label2_0_value" },
        { Id: 427618, PropertyName: "placeholder", Value: "15", TagName: "numBoy_placeholder" },
        { Id: 356078, PropertyName: "title", Value: "Weight", TagName: "EditableTableColumn4_title" },
        { Id: 771858, PropertyName: "value", Value: "[datafield:weight]", TagName: "Label8_0_value" },
        { Id: 100305, PropertyName: "placeholder", Value: "15", TagName: "numAgirlik_placeholder" },
        { Id: 781165, PropertyName: "title", Value: "Running level", TagName: "EditableTableColumn3_title" },
        { Id: 746016, PropertyName: "value", Value: "[datafield:heartstrenghtlevel]", TagName: "Label7_0_value" },
        { Id: 902685, PropertyName: "placeholder", Value: "15", TagName: "numSeviye_placeholder" },
        { Id: 790072, PropertyName: "title", Value: "Running lap ", TagName: "EditableTableColumn2_title" },
        { Id: 959185, PropertyName: "value", Value: "[datafield:heartstrenghtlap]", TagName: "Label6_0_value" },
        { Id: 86258, PropertyName: "placeholder", Value: "15", TagName: "numTur_placeholder" },
        { Id: 121379, PropertyName: "title", Value: "Flexibility", TagName: "EditableTableColumn8_title" },
        { Id: 378839, PropertyName: "value", Value: "[datafield:flexibility]", TagName: "Label12_0_value" },
        { Id: 742575, PropertyName: "placeholder", Value: "15", TagName: "numEsneklik_placeholder" },
        { Id: 442551, PropertyName: "title", Value: "Sit-up", TagName: "EditableTableColumn7_title" },
        { Id: 832820, PropertyName: "value", Value: "[datafield:trunk]", TagName: "Label11_0_value" },
        { Id: 419830, PropertyName: "placeholder", Value: "15", TagName: "numGovde_placeholder" },
        { Id: 974702, PropertyName: "title", Value: "Push-up", TagName: "EditableTableColumn6_title" },
        { Id: 418457, PropertyName: "value", Value: "[datafield:upperextremity]", TagName: "Label10_0_value" },
        { Id: 831248, PropertyName: "placeholder", Value: "15", TagName: "numUst_placeholder" },
        { Id: 100281, PropertyName: "title", Value: "Long jump", TagName: "EditableTableColumn5_title" },
        { Id: 38965, PropertyName: "value", Value: "[datafield:lowerextremity]", TagName: "Label9_0_value" },
        { Id: 699808, PropertyName: "placeholder", Value: "15", TagName: "numAlt_placeholder" }
      ]
    },
    {
      Id: "09479487-67b7-465e-82ee-5905a23389f4",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Sınıf Fitness Check-Up" },
        { Id: 715955, PropertyName: "value", Value: "Check-Up Dönemi" },
        { Id: 121696, PropertyName: "value", Value: "Check-Up Tarihi" },
        { Id: 71407, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 495081, PropertyName: "value", Value: "Sınıf" },
        { Id: 70763, PropertyName: "value", Value: "Filtre" },
        { Id: 807745, PropertyName: "placeholder", Value: "Kız/Erkek" },
        { Id: 710827, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 429654, PropertyName: "title", Value: "Okul No" },
        { Id: 97038, PropertyName: "value", Value: "[datafield:okulno]" },
        { Id: 322449, PropertyName: "title", Value: "Doğum Tarihi" },
        { Id: 13435, PropertyName: "value", Value: "[datafield:dogumtarihi]" },
        { Id: 424551, PropertyName: "title", Value: "Adı" },
        { Id: 421195, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 631897, PropertyName: "title", Value: "Yaş" },
        { Id: 643042, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 265423, PropertyName: "title", Value: "Boy" },
        { Id: 24621, PropertyName: "value", Value: "[datafield:boy]" },
        { Id: 427618, PropertyName: "placeholder", Value: "15" },
        { Id: 356078, PropertyName: "title", Value: "Ağırlık" },
        { Id: 771858, PropertyName: "value", Value: "[datafield:agirlik]" },
        { Id: 100305, PropertyName: "placeholder", Value: "15" },
        { Id: 781165, PropertyName: "title", Value: "Koşu Seviyesi" },
        { Id: 746016, PropertyName: "value", Value: "[datafield:kalpgucuseviye]" },
        { Id: 902685, PropertyName: "placeholder", Value: "15" },
        { Id: 790072, PropertyName: "title", Value: "Koşu Turu" },
        { Id: 959185, PropertyName: "value", Value: "[datafield:kalpgucutur]" },
        { Id: 86258, PropertyName: "placeholder", Value: "15" },
        { Id: 121379, PropertyName: "title", Value: "Esneklik" },
        { Id: 378839, PropertyName: "value", Value: "[datafield:esneklik]" },
        { Id: 742575, PropertyName: "placeholder", Value: "15" },
        { Id: 442551, PropertyName: "title", Value: "Mekik" },
        { Id: 832820, PropertyName: "value", Value: "[datafield:govde]" },
        { Id: 419830, PropertyName: "placeholder", Value: "15" },
        { Id: 974702, PropertyName: "title", Value: "Şınav" },
        { Id: 418457, PropertyName: "value", Value: "[datafield:ustekstremite]" },
        { Id: 831248, PropertyName: "placeholder", Value: "15" },
        { Id: 100281, PropertyName: "title", Value: "Uzun Atlama" },
        { Id: 38965, PropertyName: "value", Value: "[datafield:altekstremite]" },
        { Id: 699808, PropertyName: "placeholder", Value: "15" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.sinifcheckupform_71407_value_kuikaDateRef = React.createRef();
    this.sinifcheckupform_807745_value_kuikaSelectBoxRef = React.createRef();
    this.sinifcheckupform_710827_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      TopluOlcumById: [],
      SinifSelectById: [],
      CheckupDonemiById: [],
      SinifOlcumGirisi: [],
      ChangeEnabledOf: "",
      ComponentToPdf: "",
      OpenPdf: "",
      GoBack: "",
      CheckupSave: 0,
      TopluOlcumKaydet: 0,
      GetLanguage: "",
      NAVIGATE: "",
      dataSource_807745: [
        { key: "E", text: "Erkek" },
        { key: "K", text: "Kız" }
      ],
      isComp71407Enabled: "enabled"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sinifcheckupform", "sinifcheckupform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.SinifCheckupFormPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("sinifcheckupform", "sinifcheckupform", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("sinifcheckupform", "sinifcheckupform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.SinifCheckupFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      sinifcheckupform_71407_value:
        this.state.TopluOlcumById?.length > 0 ? this.state.TopluOlcumById[0].tarih : undefined
    });
  }

  SinifCheckupFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.TopluOlcumId, "Guid"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sezonId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      checkupDonemiId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiId, "Guid"),
      checkupDonemiId_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiId, "Guid"),
      topluOlcumId_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.TopluOlcumId, "Guid"),
      sinifId_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      Cinsiyet_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sinifcheckupform_807745_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifCheckupForm/SinifCheckupFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.TopluOlcumById = result?.data.topluOlcumById;
    formVars.sinifcheckupform_71407_value = ReactSystemFunctions.value(
      this,
      stateVars.TopluOlcumById?.length > 0 ? stateVars.TopluOlcumById[0]?.tarih : null
    );
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    formVars.sinifcheckupform_959781_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifSelectById?.length > 0 ? stateVars.SinifSelectById[0]?.adi : null
    );
    stateVars.CheckupDonemiById = result?.data.checkupDonemiById;
    formVars.sinifcheckupform_628584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupDonemiById?.length > 0 ? stateVars.CheckupDonemiById[0]?.tanimi : null
    );
    stateVars.SinifOlcumGirisi = result?.data.sinifOlcumGirisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifCheckupFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifCheckupFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.TopluOlcumById?.length > 0 ? this.state.TopluOlcumById[0]?.id : null
        ),
        null
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp71407Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.SinifCheckupFormPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.SinifCheckupFormPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  SinifCheckupFormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.sinifcheckupform_628584_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupDonemiById?.length > 0 ? this.state.CheckupDonemiById[0]?.tanimi : null
    );

    formVars.sinifcheckupform_71407_value = ReactSystemFunctions.value(
      this,
      this.state.TopluOlcumById?.length > 0 ? this.state.TopluOlcumById[0]?.tarih : null
    );

    formVars.sinifcheckupform_959781_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0]?.adi : null
    );

    stateVars.dataSource_710827 = this.state.SinifOlcumGirisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifCheckupFormComponent_917679_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ComponentToPdf = await ReactSystemFunctions.componentToPDF("799009", "MEDIUM", "portrait");
    stateVars.OpenPdf = await ReactSystemFunctions.openPdf(
      ReactSystemFunctions.toString(this, stateVars.ComponentToPdf),
      false,
      "newTab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifCheckupFormComponent_178303_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifCheckupFormComponent_807745_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      checkupDonemiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiId, "Guid"),
      topluOlcumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.TopluOlcumId, "Guid"),
      sinifId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sinifcheckupform_807745_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifCheckupForm/SinifCheckupFormComponent_807745_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifOlcumGirisi = result?.data.sinifOlcumGirisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifCheckupFormComponent_807745_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifCheckupFormComponent_807745_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_710827 = this.state.SinifOlcumGirisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifCheckupFormComponent_710827_onRowEditFinished = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifcheckupform_71407_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "sinifcheckupform_71407_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Agirlik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_710827_agirlik", "value", "", "", "")),
        "number"
      ),
      AltEkstremite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_710827_altEkstremite", "value", "", "", "")
        ),
        "number"
      ),
      Boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_710827_boy", "value", "", "", "")),
        "number"
      ),
      Esneklik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_710827_esneklik", "value", "", "", "")
        ),
        "number"
      ),
      Govde_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_710827_govde", "value", "", "", "")),
        "number"
      ),
      checkupId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifcheckupform_710827_value", ".olcumId"),
        "Guid"
      ),
      KalpGucuSeviye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_710827_kalpGucuSeviye", "value", "", "", "")
        ),
        "number"
      ),
      KalpGucuTur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_710827_kalpGucuTur", "value", "", "", "")
        ),
        "number"
      ),
      OgrenciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifcheckupform_710827_value", ".ogrenciId"),
        "Guid"
      ),
      OlcumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifcheckupform_71407_value", "value", "", "", "")
        ),
        "Date"
      ),
      SinifId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      UstEkstremite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_710827_ustEkstremite", "value", "", "", "")
        ),
        "number"
      ),
      egitmenNotu_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      checkupDonemiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiId, "Guid"),
      CheckupDonemiId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiId, "Guid"),
      SinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      Tarih_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifcheckupform_71407_value", "value", "", "", "")
        ),
        "Date"
      ),
      topluOlcumId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.TopluOlcumId, "Guid"),
      olcumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifcheckupform_710827_value", ".olcumId"),
        "Guid"
      ),
      checkupDonemiId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiId, "Guid"),
      topluOlcumId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.TopluOlcumId, "Guid"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      Cinsiyet_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sinifcheckupform_807745_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifCheckupForm/SinifCheckupFormComponent_710827_onRowEditFinished",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupSave = result?.data.checkupSave;
    stateVars.TopluOlcumKaydet = result?.data.topluOlcumKaydet;
    stateVars.SinifOlcumGirisi = result?.data.sinifOlcumGirisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifCheckupFormComponent_710827_onRowEditFinished1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifCheckupFormComponent_710827_onRowEditFinished1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_710827 = this.state.SinifOlcumGirisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifCheckupFormComponent_968728_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifCheckupFormComponent_968728_onClick1_();
    });

    return isErrorOccurred;
  };
  SinifCheckupFormComponent_968728_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR")) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables("CheckupRaporu", "ShowDownload", true);
      KuikaAppManager.addToPageInputVariables(
        "CheckupRaporu",
        "CheckupId",
        ReactSystemFunctions.value(this, "sinifcheckupform_710827_value", "olcumId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.SinifCheckupFormComponent_968728_onClick2_,
        "SinifCheckupForm",
        "CheckupRaporu",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "153954",
        null,
        "right",
        null,
        "95vw",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.SinifCheckupFormComponent_968728_onClick2_();
      });
    }

    return isErrorOccurred;
  };
  SinifCheckupFormComponent_968728_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US")) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "CheckupRaporuEN",
        "CheckupId",
        ReactSystemFunctions.value(this, "sinifcheckupform_710827_value", "olcumId")
      );
      KuikaAppManager.addToPageInputVariables("CheckupRaporuEN", "ShowDownload", true);

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "SinifCheckupForm",
        "CheckupRaporuEN",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "528947",
        null,
        "right",
        null,
        "95vw",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [153954, 528947] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SinifCheckupFormPageInit();
    }
    if (diId == 153954) {
      isErrorOccurred = await this.SinifCheckupFormComponent_968728_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
