import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KPanel,
  KRow,
  Label,
  LayoutHeader,
  LayoutLeft,
  Menu,
  MenuItem,
  VerticalStack
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SideBarMenu_ScreenBase } from "./sidebarmenu-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class SideBarMenu_Screen extends SideBarMenu_ScreenBase {
  render() {
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              ...this.props.initialValues,
              sidebarmenu_548615_value: "90102f2b-ec0a-4818-fbac-08dbdc675be2",
              sidebarmenu_448238_value: this.state.MyContext?.length > 0 ? this.state.MyContext[0].logo2 : undefined,
              sidebarmenu_618574_value: { collapsed: false, selectedKey: "" }
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <LayoutLeft
                  id="Minus12"
                  id="sidebarmenu_layout_left"
                  layoutPosition={1}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      width: "100px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <VerticalStack
                    id="93744"
                    direction="vertical"
                    size={32}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 16,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KPanel
                      id="846283"
                      scrolling="hidden"
                      scrollRadius={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          textAlign: "-webkit-center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <Form.Item className="kFormItem" name="sidebarmenu_548615_value">
                        <Image
                          id="548615"
                          visibility={this.state.isComp548615Visible}
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              height: "40px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></Image>
                      </Form.Item>

                      <Form.Item className="kFormItem" name="sidebarmenu_448238_value">
                        <Image
                          id="448238"
                          visibility={this.state.isComp448238Visible}
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              height: "40px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </KPanel>

                    <Form.Item className="kFormItem" name="sidebarmenu_618574_value" noStyle>
                      <Menu
                        id="618574"
                        options={this.state.dataSource_618574}
                        mode="vertical"
                        theme="light"
                        primaryColor="rgba(29, 202, 211, 1)"
                        collapsible={true}
                        collapsedButtonColor="rgba(10, 34, 64, 1)"
                        dataidfield=""
                        datatitlefield=""
                        dataparentidfield=""
                        datalinkfield=""
                        dataiconfield=""
                        dataorderfield=""
                        style={{} as any}
                      >
                        <MenuItem
                          id="400097"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_400097_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_400097AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_400097AuthorizationVisible}
                          key={400097}
                          label={ReactSystemFunctions.translate(this.ml, 400097, "label", this.defaultML)}
                          icon="home"
                          key={400097}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="772189"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_772189_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_772189AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_772189AuthorizationVisible}
                          key={772189}
                          label={ReactSystemFunctions.translate(this.ml, 772189, "label", this.defaultML)}
                          icon="insights"
                          key={772189}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="408596"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_408596_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_408596AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_408596AuthorizationVisible}
                          key={408596}
                          label={ReactSystemFunctions.translate(this.ml, 408596, "label", this.defaultML)}
                          icon="apartment"
                          key={408596}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="950837"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_950837_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_950837AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_950837AuthorizationVisible}
                          key={950837}
                          label={ReactSystemFunctions.translate(this.ml, 950837, "label", this.defaultML)}
                          icon="person"
                          key={950837}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="314940"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_314940_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_314940AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_314940AuthorizationVisible}
                          key={314940}
                          label={ReactSystemFunctions.translate(this.ml, 314940, "label", this.defaultML)}
                          icon="person"
                          key={314940}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="756009"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_756009_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_756009AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_756009AuthorizationVisible}
                          key={756009}
                          label={ReactSystemFunctions.translate(this.ml, 756009, "label", this.defaultML)}
                          icon="school"
                          key={756009}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="355172"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_355172_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_355172AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_355172AuthorizationVisible}
                          key={355172}
                          label={ReactSystemFunctions.translate(this.ml, 355172, "label", this.defaultML)}
                          icon="functions"
                          key={355172}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="685183"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_685183_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_685183AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_685183AuthorizationVisible}
                          key={685183}
                          label={ReactSystemFunctions.translate(this.ml, 685183, "label", this.defaultML)}
                          icon="cast_for_education"
                          key={685183}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="438913"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_438913_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_438913AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_438913AuthorizationVisible}
                          key={438913}
                          label={ReactSystemFunctions.translate(this.ml, 438913, "label", this.defaultML)}
                          icon="hearing"
                          key={438913}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="544581"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_544581_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_544581AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_544581AuthorizationVisible}
                          key={544581}
                          label={ReactSystemFunctions.translate(this.ml, 544581, "label", this.defaultML)}
                          icon="family_restroom"
                          key={544581}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="989465"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_989465_onClick();
                          }}
                          showCursorPointer
                          key={989465}
                          label={ReactSystemFunctions.translate(this.ml, 989465, "label", this.defaultML)}
                          icon="people_outline"
                          key={989465}
                          style={{} as any}
                        ></MenuItem>

                        <MenuItem
                          id="598686"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SideBarMenuComponent_598686_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isCompsidebarmenu_598686AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompsidebarmenu_598686AuthorizationVisible}
                          key={598686}
                          label={ReactSystemFunctions.translate(this.ml, 598686, "label", this.defaultML)}
                          icon="cast_for_education"
                          key={598686}
                          style={{} as any}
                        ></MenuItem>
                      </Menu>
                    </Form.Item>
                  </VerticalStack>
                </LayoutLeft>
                <div style={{ flex: "auto" }}>
                  <LayoutHeader
                    id="Minus10"
                    id="sidebarmenu_layout_header"
                    layoutPosition={2}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        height: "40px",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="42808"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="253202"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="169016"
                          value={this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : undefined}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="3534"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            textAlign: "-webkit-right",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="388236"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="55307"
                            value={ReactSystemFunctions.getMyUsername()}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <Icon
                            id="556000"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.SideBarMenuComponent_556000_onClick();
                            }}
                            showCursorPointer
                            iconName="logout"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(155, 155, 155, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </HorizontalStack>
                      </KCol>
                    </KRow>
                  </LayoutHeader>
                  <div style={{ display: "flex" }}>
                    <div
                      id="masterRootDI"
                      style={{
                        width: "0px",
                        flex: "auto",
                        overflow: "auto",
                        height: "calc(100vh - 40px)",
                        maxWidth: "100vw",
                        position: "relative"
                      }}
                    >
                      <>{this.props.children}</>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withHistory(connect(mapStateToProps, mapDispatchToProps)(SideBarMenu_Screen))))
);
export { tmp as SideBarMenu_Screen };
//export default tmp;
//export { tmp as SideBarMenu_Screen };
