import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOkulList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOkulList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  OkulCount: any[];
  OkulSearch: any[];
  SetValueOf: any;
  NAVIGATE: any;
  OkulDeleteById: number;
}

export class OkulList_ScreenBase extends React.PureComponent<IOkulList_ScreenProps, any> {
  okullist_399769_value_kuikaTableRef: React.RefObject<any>;
  okullist_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 25251, PropertyName: "value", Value: "School List", TagName: "Label1_0_value" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search", TagName: "txtSearch_placeholder" },
        { Id: 814413, PropertyName: "label", Value: "NEW SCHOOL", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblOkul_nodatafoundmessage"
        },
        { Id: 7764979, PropertyName: "title", Value: "School Name", TagName: "clmAdi_title" },
        { Id: 436840, PropertyName: "title", Value: "School Name", TagName: "TableColumn5_title" },
        { Id: 90119, PropertyName: "value", Value: "[datafield:name]", TagName: "Label4_0_value" },
        { Id: 551500, PropertyName: "title", Value: "Region", TagName: "TableColumn8_title" },
        { Id: 936012, PropertyName: "value", Value: "[datafield:region]", TagName: "Label6_0_value" },
        { Id: 372889, PropertyName: "value", Value: "[datafield:cityid]", TagName: "Label9_value" },
        { Id: 872235, PropertyName: "value", Value: "[datafield:district]", TagName: "Label8_value" },
        { Id: 280067, PropertyName: "title", Value: "Active Category", TagName: "TableColumn10_title" },
        { Id: 424492, PropertyName: "value", Value: "[datafield:activeseasondescription]", TagName: "Label10_0_value" },
        {
          Id: 879093,
          PropertyName: "value",
          Value: "[datafield:activecheckupseasondescription]",
          TagName: "Label10_1_value"
        },
        { Id: 219779, PropertyName: "title", Value: "Expire Date", TagName: "TableColumn3_title" },
        { Id: 136362, PropertyName: "value", Value: "[datafield:expirationdate]", TagName: "Label2_0_value" },
        { Id: 403368, PropertyName: "title", Value: "Student License", TagName: "TableColumn7_title" },
        { Id: 798181, PropertyName: "value", Value: "[datafield:studentlicensenumber]", TagName: "Label5_0_value" },
        { Id: 568421, PropertyName: "title", Value: "Check-Up License", TagName: "TableColumn9_title" },
        { Id: 867769, PropertyName: "value", Value: "[datafield:checkuplicensenumber]", TagName: "Label9_0_value" },
        { Id: 415587, PropertyName: "title", Value: "Season License", TagName: "TableColumn6_title" },
        { Id: 956568, PropertyName: "value", Value: "[datafield:seasonlicensenumber]", TagName: "Label4_0_value" },
        { Id: 439424, PropertyName: "title", Value: "Teacher License", TagName: "TableColumn4_title" },
        { Id: 951580, PropertyName: "value", Value: "[datafield:teacherlicensenumber]", TagName: "Label3_0_value" },
        {
          Id: 90136658,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this school?",
          TagName: "OkulDeleteById_confirmation",
          IsConfirmation: true
        }
      ]
    },
    {
      Id: "d3ad9f08-560d-483b-bf59-437de7e9be35",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 25251, PropertyName: "value", Value: "Okul Listesi" },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 814413, PropertyName: "label", Value: "YENİ OKUL" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 7764979, PropertyName: "title", Value: "Okul Adı" },
        { Id: 436840, PropertyName: "title", Value: "Okul Adı" },
        { Id: 90119, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 551500, PropertyName: "title", Value: "Konum" },
        { Id: 936012, PropertyName: "value", Value: "[datafield:bolgesi]" },
        { Id: 372889, PropertyName: "value", Value: "[datafield:sehirid]" },
        { Id: 872235, PropertyName: "value", Value: "[datafield:semti]" },
        { Id: 280067, PropertyName: "title", Value: "Aktif Kategori" },
        { Id: 424492, PropertyName: "value", Value: "[datafield:aktifsezontanimi]" },
        { Id: 879093, PropertyName: "value", Value: "[datafield:aktifcheckupdonemitanimi]" },
        { Id: 219779, PropertyName: "title", Value: "Son Kullanma" },
        { Id: 136362, PropertyName: "value", Value: "[datafield:lisansexpirationdate]" },
        { Id: 403368, PropertyName: "title", Value: "Lisans Öğrenci" },
        { Id: 798181, PropertyName: "value", Value: "[datafield:lisansogrenciadedi]" },
        { Id: 568421, PropertyName: "title", Value: "Lisans Ölçüm" },
        { Id: 867769, PropertyName: "value", Value: "[datafield:lisansolcumadedi]" },
        { Id: 415587, PropertyName: "title", Value: "Lisans Dönem" },
        { Id: 956568, PropertyName: "value", Value: "[datafield:lisansdonemadedi]" },
        { Id: 439424, PropertyName: "title", Value: "Lisans Öğretmen" },
        { Id: 951580, PropertyName: "value", Value: "[datafield:lisansogretmenadedi]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.okullist_399769_value_kuikaTableRef = React.createRef();
    this.okullist_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      OkulCount: [],
      OkulSearch: [],
      SetValueOf: "",
      NAVIGATE: "",
      OkulDeleteById: 0
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("okullist", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OkulListPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("okullist", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("okullist", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OkulListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  OkulListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OkulList/OkulListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OkulCount = result?.data.okulCount;
    stateVars.OkulSearch = result?.data.okulSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OkulSearch;
    formVars.okullist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.OkulCount?.length > 0 ? this.state.OkulCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "okullist_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulListComponent_416063_onPressEnter1_();
    });

    return isErrorOccurred;
  };
  OkulListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OkulList/OkulListComponent_416063_onPressEnter1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OkulCount = result?.data.okulCount;
    stateVars.OkulSearch = result?.data.okulSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulListComponent_416063_onPressEnter2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulListComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OkulSearch;
    formVars.okullist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.OkulCount?.length > 0 ? this.state.OkulCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulListComponent_416063_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "okullist_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulListComponent_416063_onBlur1_();
    });

    return isErrorOccurred;
  };
  OkulListComponent_416063_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OkulList/OkulListComponent_416063_onBlur1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OkulCount = result?.data.okulCount;
    stateVars.OkulSearch = result?.data.okulSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulListComponent_416063_onBlur2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulListComponent_416063_onBlur2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OkulSearch;
    formVars.okullist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.OkulCount?.length > 0 ? this.state.OkulCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OkulForm", "Id", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OkulList",
      "OkulForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "375px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OkulForm",
      "Id",
      ReactSystemFunctions.value(this, "okullist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OkulList",
      "OkulForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "375px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "okullist_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Okul kaydını silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(this, "OkulList/OkulListComponent_505853_onClick", localVar);
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.OkulDeleteById = result?.data.okulDeleteById;
        stateVars.OkulCount = result?.data.okulCount;
        stateVars.OkulSearch = result?.data.okulSearch;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.OkulListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OkulSearch;
    formVars.okullist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.OkulCount?.length > 0 ? this.state.OkulCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OkulListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "okullist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OkulList/OkulListComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OkulCount = result?.data.okulCount;
    stateVars.OkulSearch = result?.data.okulSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OkulListComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OkulListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OkulSearch;
    formVars.okullist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.OkulCount?.length > 0 ? this.state.OkulCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OkulListPageInit();
    }
  }
}
