import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgretmenForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgretmenForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  OkulSelectAll: any[];
  KUserById: any[];
  GetLanguage: string;
  OgretmenSaveLisansKontrol: number;
  SaveUser: any;
  MemapUserSave: number;
  FindUsersToken: any[];
  SendMail: boolean;
  isCompogretmenform_559909AuthorizationVisible: "visible" | "hidden";
}

export class OgretmenForm_ScreenBase extends React.PureComponent<IOgretmenForm_ScreenProps, any> {
  ogretmenform_893606_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Teacher's Form", TagName: "lblHead_value" },
        { Id: 818937, PropertyName: "value", Value: "School", TagName: "Label1_0_value" },
        { Id: 893606, PropertyName: "placeholder", Value: "Choose", TagName: "selOkul_placeholder" },
        { Id: 6124627, PropertyName: "value", Value: "Name", TagName: "lblAdi_value" },
        { Id: 769869, PropertyName: "value", Value: "Surname", TagName: "Label1_0_value" },
        { Id: 8221716, PropertyName: "value", Value: "Email", TagName: "lblEposta_value" },
        { Id: 7930811, PropertyName: "value", Value: "Phone number", TagName: "lblTelefon_value" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "a241afa2-1ba8-4538-891c-7e711550851f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Öğretmen Kartı" },
        { Id: 818937, PropertyName: "value", Value: "Okulu" },
        { Id: 893606, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 6124627, PropertyName: "value", Value: "Adı" },
        { Id: 769869, PropertyName: "value", Value: "Soyadı" },
        { Id: 8221716, PropertyName: "value", Value: "Eposta" },
        { Id: 7930811, PropertyName: "value", Value: "Telefon" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogretmenform_893606_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      OkulSelectAll: [],
      KUserById: [],
      GetLanguage: "",
      OgretmenSaveLisansKontrol: 0,
      SaveUser: "",
      MemapUserSave: 0,
      FindUsersToken: [],
      SendMail: false,
      isCompogretmenform_559909AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogretmenform", "ogretmenform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgretmenFormPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompogretmenform_559909AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("ogretmenform", "ogretmenform", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogretmenform", "ogretmenform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgretmenFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ogretmenform_893606_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].okulId : undefined,
      ogretmenform_48486400_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].firstName : undefined,
      ogretmenform_352610_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].lastName : undefined,
      ogretmenform_34683600_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].userName : undefined,
      ogretmenform_26438440_value: this.state.KUserById?.length > 0 ? this.state.KUserById[0].phoneNumber : undefined
    });
  }

  OgretmenFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.KUserId, "Guid"),
      lang_2: ReactSystemFunctions.convertToTypeByName("tr_Tr", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgretmenForm/OgretmenFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OkulSelectAll = result?.data.okulSelectAll;

    formVars.ogretmenform_893606_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.okulId : null;
    formVars.ogretmenform_893606_options = stateVars.OkulSelectAll;
    stateVars.KUserById = result?.data.kUserById;
    formVars.ogretmenform_893606_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.okulId : null;
    formVars.ogretmenform_893606_options = stateVars.OkulSelectAll;
    formVars.ogretmenform_48486400_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.firstName : null
    );
    formVars.ogretmenform_352610_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.lastName : null
    );
    formVars.ogretmenform_34683600_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.userName : null
    );
    formVars.ogretmenform_26438440_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.phoneNumber : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.ogretmenform_893606_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulId : null
    );

    stateVars.dataSource_893606 = this.state.OkulSelectAll;
    formVars.ogretmenform_48486400_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.firstName : null
    );

    formVars.ogretmenform_352610_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.lastName : null
    );

    formVars.ogretmenform_34683600_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.userName : null
    );

    formVars.ogretmenform_26438440_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.phoneNumber : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenFormComponent_9544_onClick1_();
    });

    return isErrorOccurred;
  };
  OgretmenFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_893606_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "ogretmenform_893606_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_34683600_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogretmenform_34683600_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_48486400_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogretmenform_48486400_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_352610_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogretmenform_352610_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_26438440_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogretmenform_26438440_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OkulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_893606_value", "value", "OkulSelectAll", "id", "id")
        ),
        "Guid"
      ),
      userName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_34683600_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_48486400_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_352610_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_1: ReactSystemFunctions.convertToTypeByName("Ogretmen", "string"),
      startingScreenId_1: ReactSystemFunctions.convertToTypeByName("1eff2cfc-ce6e-4ffc-be02-2fee4990cdc4", "Guid"),
      phoneNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_26438440_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      OkulId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_893606_value", "value", "OkulSelectAll", "id", "id")
        ),
        "Guid"
      ),
      Rolename_2: ReactSystemFunctions.convertToTypeByName("Ogretmen", "string"),
      MeMapUserId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MeMapUserId, "Guid"),
      okulId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_893606_value", "value", "OkulSelectAll", "id", "id")
        ),
        "Guid"
      ),
      lang_4: ReactSystemFunctions.convertToTypeByName("tr_Tr", "string")
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgretmenForm/OgretmenFormComponent_9544_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OgretmenSaveLisansKontrol = result?.data.ogretmenSaveLisansKontrol;
    stateVars.SaveUser = result?.data.saveUser;
    stateVars.MemapUserSave = result?.data.memapUserSave;
    stateVars.FindUsersToken = result?.data.findUsersToken;
    stateVars.KUserById = result?.data.kUserById;
    formVars.ogretmenform_893606_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.okulId : null;
    formVars.ogretmenform_48486400_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.firstName : null
    );
    formVars.ogretmenform_352610_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.lastName : null
    );
    formVars.ogretmenform_34683600_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.userName : null
    );
    formVars.ogretmenform_26438440_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.phoneNumber : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenFormComponent_9544_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenFormComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_34683600_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogretmenform_34683600_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("1fad66c5-a467-4833-a111-36ef72ab7023", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_34683600_value", "value", "", "", "")
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.emailBaslik : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_34683600_value", "value", "", "", "")
        ),
        "string"
      ),
      pass_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.FindUsersToken?.length > 0 ? this.state.FindUsersToken[0]?.token : null
        ),
        "string"
      ),
      okulLogoUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulLogoUrl : null
        ),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.baseUrl : null),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR")) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(
        this,
        "OgretmenForm/OgretmenFormComponent_9544_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.OgretmenFormComponent_9544_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.OgretmenFormComponent_9544_onClick3_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenFormComponent_9544_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_34683600_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogretmenform_34683600_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("96ee22b3-e3fc-4880-9534-b68becf068a6", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_34683600_value", "value", "", "", "")
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.emailBaslik : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmenform_34683600_value", "value", "", "", "")
        ),
        "string"
      ),
      pass_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.FindUsersToken?.length > 0 ? this.state.FindUsersToken[0]?.token : null
        ),
        "string"
      ),
      okulLogoUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulLogoUrl : null
        ),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.baseUrl : null),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US")) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(
        this,
        "OgretmenForm/OgretmenFormComponent_9544_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.OgretmenFormComponent_9544_onClick4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.OgretmenFormComponent_9544_onClick4_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenFormComponent_9544_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);
    formVars.ogretmenform_893606_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulId : null
    );

    formVars.ogretmenform_48486400_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.firstName : null
    );

    formVars.ogretmenform_352610_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.lastName : null
    );

    formVars.ogretmenform_34683600_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.userName : null
    );

    formVars.ogretmenform_26438440_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.phoneNumber : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
