import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IForgotPassword_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IForgotPassword_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  ForgotPassword: string;
  goToVerificationCodeActivity: any;
  NAVIGATE: any;
}

export class ForgotPassword_ScreenBase extends React.PureComponent<IForgotPassword_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 574431, PropertyName: "value", Value: "Forget password?", TagName: "lblTitle_value" },
        { Id: 900580, PropertyName: "value", Value: "Please enter your username.", TagName: "lblSubTitle_value" },
        { Id: 420878, PropertyName: "value", Value: "Username", TagName: "Label5_value" },
        { Id: 405510, PropertyName: "placeholder", Value: "john@memap.com", TagName: "txtEmail_placeholder" },
        { Id: 611276, PropertyName: "label", Value: "Send password", TagName: "btnSend_label" },
        { Id: 520648, PropertyName: "value", Value: "Log in screen", TagName: "lnkSignIn_value" },
        { Id: 883899, PropertyName: "value", Value: "Go back to", TagName: "lblBackTo_value" }
      ]
    },
    {
      Id: "834c2709-c349-4772-b350-a357ffed7967",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 574431, PropertyName: "value", Value: "Şifrenizi mi unuttunuz?" },
        { Id: 900580, PropertyName: "value", Value: "Lütfen kullanıcı adınızı giriniz." },
        { Id: 420878, PropertyName: "value", Value: "Kullanıcı Adı" },
        { Id: 405510, PropertyName: "placeholder", Value: "ali.mavisehir@kuika.com" },
        { Id: 611276, PropertyName: "label", Value: "Şifre Gönder" },
        { Id: 520648, PropertyName: "value", Value: "Giriş Ekranı" },
        { Id: 883899, PropertyName: "value", Value: "'na geri dön" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      ForgotPassword: "",
      goToVerificationCodeActivity: "",
      NAVIGATE: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", "forgotpassword", this.props.context);
      return;
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", "forgotpassword", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("611276");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", "forgotpassword", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  ForgotPasswordComponent_611276_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "forgotpassword_405510_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "forgotpassword_405510_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ForgotPassword = await ReactSystemFunctions.forgotPassword(
      this,
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")
      ),
      false
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToVerificationCodeActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ForgotPasswordComponent_520648_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ForgotPassword",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
  }
}
