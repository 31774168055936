import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  HorizontalStack,
  Icon,
  KCol,
  KDatePicker,
  KDrawer,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  NumberInput
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SinifCheckupForm_ScreenBase } from "./sinifcheckupform-base";

import { CheckupRaporu_Screen } from "../checkupraporu/checkupraporu";
import { CheckupRaporuEN_Screen } from "../checkupraporuen/checkupraporuen";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class SinifCheckupForm_Screen extends SinifCheckupForm_ScreenBase {
  render() {
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              sinifcheckupform_71407_value:
                this.state.TopluOlcumById?.length > 0 ? this.state.TopluOlcumById[0].tarih : undefined,
              sinifcheckupform_807745_value: undefined
            }}
          >
            <div
              id="sinifcheckupform_body"
              style={
                {
                  backgroundColor: "rgba(245, 247, 250, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block"
                } as any
              }
            >
              <KRow
                id="331958"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="44993"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <HorizontalStack
                    id="959463"
                    direction="horizontal"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="527823"
                      value={ReactSystemFunctions.translate(this.ml, 527823, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "18pt",
                          color: "rgba(0, 0, 0, 1)"
                        } as any
                      }
                    ></Label>
                  </HorizontalStack>
                </KCol>

                <KCol
                  id="775216"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 16,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "center",
                      textAlign: "-webkit-right",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <HorizontalStack
                    id="710290"
                    direction="horizontal"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="917679"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.SinifCheckupFormComponent_917679_onClick();
                      }}
                      showCursorPointer
                      iconName="download"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>

                    <Icon
                      id="178303"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.SinifCheckupFormComponent_178303_onClick();
                      }}
                      showCursorPointer
                      iconName="clear"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </HorizontalStack>
                </KCol>
              </KRow>

              <KRow
                id="799009"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="571930"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="495716"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="389242"
                      xs={14}
                      sm={14}
                      md={14}
                      lg={14}
                      xl={14}
                      xxl={14}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="272624"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="920000"
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="715955"
                            value={ReactSystemFunctions.translate(this.ml, 715955, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(140, 140, 140, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="88726"
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="628584"
                            value={
                              this.state.CheckupDonemiById?.length > 0
                                ? this.state.CheckupDonemiById[0].tanimi
                                : undefined
                            }
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "18pt",
                                color: "rgba(0, 0, 0, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="564404"
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                          xl={5}
                          xxl={5}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="121696"
                            value={ReactSystemFunctions.translate(this.ml, 121696, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(140, 140, 140, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="639084"
                          xs={7}
                          sm={7}
                          md={7}
                          lg={7}
                          xl={7}
                          xxl={7}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="sinifcheckupform_71407_value">
                            <KDatePicker
                              id="71407"
                              editability={this.state.isComp71407Enabled}
                              kuikaRef={this.sinifcheckupform_71407_value_kuikaDateRef}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                71407,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={true}
                              format="DD/MM/YYYY"
                              style={
                                {
                                  borderTopLeftRadius: 8,
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                  borderBottomLeftRadius: 8,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></KDatePicker>
                          </Form.Item>
                        </KCol>
                      </KRow>
                    </KCol>

                    <KCol
                      id="101683"
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="311436"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="895645"
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          xxl={4}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="495081"
                            value={ReactSystemFunctions.translate(this.ml, 495081, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(140, 140, 140, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="743964"
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="959781"
                            value={
                              this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0].adi : undefined
                            }
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "18pt",
                                color: "rgba(0, 0, 0, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="811792"
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          xxl={4}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="70763"
                            value={ReactSystemFunctions.translate(this.ml, 70763, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(140, 140, 140, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="83645"
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          xxl={10}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="sinifcheckupform_807745_value">
                            <KSelectBox
                              id="807745"
                              onChange={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SinifCheckupFormComponent_807745_onChange();
                              }}
                              kuikaRef={this.sinifcheckupform_807745_value_kuikaSelectBoxRef}
                              options={this.state.dataSource_807745}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                807745,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={true}
                              autoClearSearchValue={true}
                              showSearch={false}
                              widthType="fill"
                              containsNullItem={false}
                              sortBy="none"
                              datavaluefield="key"
                              datatextfield="text"
                              style={
                                {
                                  borderTopLeftRadius: 8,
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                  borderBottomLeftRadius: 8,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></KSelectBox>
                          </Form.Item>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>

                  <KRow
                    id="297447"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="980559"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KTable
                        id="710827"
                        onRowEditFinished={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.SinifCheckupFormComponent_710827_onRowEditFinished();
                        }}
                        kuikaRef={this.sinifcheckupform_710827_value_kuikaTableRef}
                        form={this.props.form}
                        dataSource={this.state.SinifOlcumGirisi}
                        size="middle"
                        bordered={true}
                        showHeader={true}
                        loading={false}
                        nodatafoundmessage={ReactSystemFunctions.translate(
                          this.ml,
                          710827,
                          "nodatafoundmessage",
                          this.defaultML
                        )}
                        sorter={false}
                        pagination={false}
                        striped={true}
                        stripedColor="rgba(255, 255, 255, 1)"
                        rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                        insertRowActive={false}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <KTableHeader
                          id="137880"
                          hideOnMobileResolution={false}
                          textDirection="Default"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "12px",
                              color: "rgba(10, 34, 64, 1)",
                              lineHeight: "20px"
                            } as any
                          }
                        ></KTableHeader>

                        <KTableRow
                          id="21596"
                          hoverFontColor="red"
                          hoverBgColor="#F5F5F5"
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <KTableColumn
                            id="936730"
                            title={ReactSystemFunctions.translate(this.ml, 936730, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                width: "5px",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KPanel
                              id="310039"
                              visExField1="[datafield:cinsiyet]"
                              visExField2="E"
                              visExRule="isEqualTo"
                              scrolling="hidden"
                              scrollRadius={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(0, 0, 0, 1)",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  minHeight: "60px",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="551376"
                                value={ReactSystemFunctions.translate(this.ml, 551376, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KPanel>

                            <KPanel
                              id="896072"
                              visExField1="[datafield:cinsiyet]"
                              visExField2="K"
                              visExRule="isEqualTo"
                              scrolling="hidden"
                              scrollRadius={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(0, 190, 214, 1)",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  minHeight: "60px",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="700927"
                                value={ReactSystemFunctions.translate(this.ml, 700927, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KPanel>
                          </KTableColumn>

                          <KTableColumn
                            id="429654"
                            title={ReactSystemFunctions.translate(this.ml, 429654, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="97038"
                              value="[datafield:okulNo]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="322449"
                            title={ReactSystemFunctions.translate(this.ml, 322449, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "120px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="13435"
                              value="[datafield:dogumTarihi]"
                              formatter="date"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="424551"
                            title={ReactSystemFunctions.translate(this.ml, 424551, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="421195"
                              value="[datafield:adi]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="631897"
                            title={ReactSystemFunctions.translate(this.ml, 631897, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "120px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="643042"
                              value="[datafield:yas]"
                              formatter=""
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="265423"
                            title={ReactSystemFunctions.translate(this.ml, 265423, "title", this.defaultML)}
                            isEditableColumn={true}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                height: "60px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="24621"
                              onChange={(e: any) => {
                                this.props.form.setFieldValue("", e?.target?.value ?? e);
                              }}
                              defaultValue="[datafield:boy]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="Table_710827_boy">
                              <NumberInput
                                id="427618"
                                onChange={(e: any) => {
                                  this.props.form.setFieldValue("Table_710827_boy", e?.target?.value ?? e);
                                }}
                                defaultValue="[datafield:boy]"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  427618,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KTableColumn>

                          <KTableColumn
                            id="356078"
                            title={ReactSystemFunctions.translate(this.ml, 356078, "title", this.defaultML)}
                            isEditableColumn={true}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                height: "60px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="771858"
                              onChange={(e: any) => {
                                this.props.form.setFieldValue("", e?.target?.value ?? e);
                              }}
                              defaultValue="[datafield:agirlik]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="Table_710827_agirlik">
                              <NumberInput
                                id="100305"
                                onChange={(e: any) => {
                                  this.props.form.setFieldValue("Table_710827_agirlik", e?.target?.value ?? e);
                                }}
                                defaultValue="[datafield:agirlik]"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  100305,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KTableColumn>

                          <KTableColumn
                            id="781165"
                            title={ReactSystemFunctions.translate(this.ml, 781165, "title", this.defaultML)}
                            isEditableColumn={true}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                height: "60px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="746016"
                              onChange={(e: any) => {
                                this.props.form.setFieldValue("", e?.target?.value ?? e);
                              }}
                              defaultValue="[datafield:kalpGucuSeviye]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="Table_710827_kalpGucuSeviye">
                              <NumberInput
                                id="902685"
                                onChange={(e: any) => {
                                  this.props.form.setFieldValue("Table_710827_kalpGucuSeviye", e?.target?.value ?? e);
                                }}
                                defaultValue="[datafield:kalpGucuSeviye]"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  902685,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KTableColumn>

                          <KTableColumn
                            id="790072"
                            title={ReactSystemFunctions.translate(this.ml, 790072, "title", this.defaultML)}
                            isEditableColumn={true}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                height: "60px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="959185"
                              onChange={(e: any) => {
                                this.props.form.setFieldValue("", e?.target?.value ?? e);
                              }}
                              defaultValue="[datafield:kalpGucuTur]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="Table_710827_kalpGucuTur">
                              <NumberInput
                                id="86258"
                                onChange={(e: any) => {
                                  this.props.form.setFieldValue("Table_710827_kalpGucuTur", e?.target?.value ?? e);
                                }}
                                defaultValue="[datafield:kalpGucuTur]"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  86258,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KTableColumn>

                          <KTableColumn
                            id="121379"
                            title={ReactSystemFunctions.translate(this.ml, 121379, "title", this.defaultML)}
                            isEditableColumn={true}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                height: "60px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="378839"
                              onChange={(e: any) => {
                                this.props.form.setFieldValue("", e?.target?.value ?? e);
                              }}
                              defaultValue="[datafield:esneklik]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="Table_710827_esneklik">
                              <NumberInput
                                id="742575"
                                onChange={(e: any) => {
                                  this.props.form.setFieldValue("Table_710827_esneklik", e?.target?.value ?? e);
                                }}
                                defaultValue="[datafield:esneklik]"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  742575,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KTableColumn>

                          <KTableColumn
                            id="442551"
                            title={ReactSystemFunctions.translate(this.ml, 442551, "title", this.defaultML)}
                            isEditableColumn={true}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                height: "60px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="832820"
                              onChange={(e: any) => {
                                this.props.form.setFieldValue("", e?.target?.value ?? e);
                              }}
                              defaultValue="[datafield:govde]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="Table_710827_govde">
                              <NumberInput
                                id="419830"
                                onChange={(e: any) => {
                                  this.props.form.setFieldValue("Table_710827_govde", e?.target?.value ?? e);
                                }}
                                defaultValue="[datafield:govde]"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  419830,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KTableColumn>

                          <KTableColumn
                            id="974702"
                            title={ReactSystemFunctions.translate(this.ml, 974702, "title", this.defaultML)}
                            isEditableColumn={true}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                height: "60px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="418457"
                              onChange={(e: any) => {
                                this.props.form.setFieldValue("", e?.target?.value ?? e);
                              }}
                              defaultValue="[datafield:ustEkstremite]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="Table_710827_ustEkstremite">
                              <NumberInput
                                id="831248"
                                onChange={(e: any) => {
                                  this.props.form.setFieldValue("Table_710827_ustEkstremite", e?.target?.value ?? e);
                                }}
                                defaultValue="[datafield:ustEkstremite]"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  831248,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KTableColumn>

                          <KTableColumn
                            id="100281"
                            title={ReactSystemFunctions.translate(this.ml, 100281, "title", this.defaultML)}
                            isEditableColumn={true}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "100px",
                                height: "60px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="38965"
                              onChange={(e: any) => {
                                this.props.form.setFieldValue("", e?.target?.value ?? e);
                              }}
                              defaultValue="[datafield:altEkstremite]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="Table_710827_altEkstremite">
                              <NumberInput
                                id="699808"
                                onChange={(e: any) => {
                                  this.props.form.setFieldValue("Table_710827_altEkstremite", e?.target?.value ?? e);
                                }}
                                defaultValue="[datafield:altEkstremite]"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  699808,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KTableColumn>

                          <KTableColumn
                            id="368439"
                            title={ReactSystemFunctions.translate(this.ml, 368439, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            style={
                              {
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                width: "40px",
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="295367"
                              direction="horizontal"
                              size={12}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="968728"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.SinifCheckupFormComponent_968728_onClick();
                                }}
                                showCursorPointer
                                visExField1="[datafield:yas]"
                                visExField2=""
                                visExRule="isNotEmpty"
                                iconName="remove_red_eye"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(0, 190, 214, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>
                            </HorizontalStack>
                          </KTableColumn>
                        </KTableRow>
                      </KTable>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KDrawer
                id="153954"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible153954: false }, () => {
                    this.callCallbackFunction(153954);
                  });
                }}
                visible={this.state.NavVisible153954}
                width="95vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible153954 && (
                    <CheckupRaporu_Screen
                      onClose={() => {
                        this.setState({ NavVisible153954: false }, () => {
                          this.callCallbackFunction(153954);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="528947"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible528947: false }, () => {
                    this.callCallbackFunction(528947);
                  });
                }}
                visible={this.state.NavVisible528947}
                width="95vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible528947 && (
                    <CheckupRaporuEN_Screen
                      onClose={() => {
                        this.setState({ NavVisible528947: false }, () => {
                          this.callCallbackFunction(528947);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(SinifCheckupForm_Screen))))
  )
);
export { tmp as SinifCheckupForm_Screen };
//export default tmp;
//export { tmp as SinifCheckupForm_Screen };
