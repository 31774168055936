import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgrenciIncele_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgrenciIncele_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GetLanguage: string;
  MyContext: any[];
  OgrenciSelectById: any[];
  OgrencininVelileri: any[];
  CheckupListByOgrenci: any[];
  GoBack: any;
  NAVIGATE: any;
}

export class OgrenciIncele_ScreenBase extends React.PureComponent<IOgrenciIncele_ScreenProps, any> {
  ogrenciincele_2326_value_kuikaTableRef: React.RefObject<any>;
  ogrenciincele_131446_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 760420, PropertyName: "value", Value: "-", TagName: "Label5_value" },
        { Id: 584263, PropertyName: "value", Value: "STUDENT'S PARENTS", TagName: "Label9_value" },
        { Id: 2326, PropertyName: "nodatafoundmessage", Value: "Data not found", TagName: "Table2_nodatafoundmessage" },
        { Id: 303098, PropertyName: "title", Value: "Title", TagName: "TableColumn5_0_title" },
        { Id: 373127, PropertyName: "value", Value: "[datafield:name]", TagName: "Label10_value" },
        {
          Id: 732627,
          PropertyName: "value",
          Value:
            "To see the detailed report, click on the detail button at the end of the list. For the best user experience, open in widescreen.",
          TagName: "Label18_value"
        },
        {
          Id: 131446,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 687890, PropertyName: "title", Value: "Report Date", TagName: "TableColumn3_title" },
        { Id: 912109, PropertyName: "value", Value: "[datafield:reportdate]", TagName: "Label6_value" },
        { Id: 89838, PropertyName: "title", Value: "Age", TagName: "TableColumn6_title" },
        { Id: 738918, PropertyName: "value", Value: "[datafield:age]", TagName: "Label10_0_value" },
        { Id: 786488, PropertyName: "title", Value: "Height", TagName: "TableColumn4_title" },
        { Id: 801128, PropertyName: "value", Value: "[datafield:height]", TagName: "Label7_value" },
        { Id: 341730, PropertyName: "title", Value: "Weight", TagName: "TableColumn7_title" },
        { Id: 305354, PropertyName: "value", Value: "[datafield:weight]", TagName: "Label11_0_value" },
        { Id: 211970, PropertyName: "title", Value: "BMI", TagName: "TableColumn12_title" },
        { Id: 162454, PropertyName: "value", Value: "[datafield:bodycompositionclass]", TagName: "Label16_0_value" },
        { Id: 903485, PropertyName: "title", Value: "Heart Strength", TagName: "TableColumn11_title" },
        { Id: 720371, PropertyName: "value", Value: "[datafield:heartstrenghtclass]", TagName: "Label15_0_value" },
        { Id: 242859, PropertyName: "title", Value: "Flexibility", TagName: "TableColumn10_title" },
        { Id: 628034, PropertyName: "value", Value: "[datafield:flexibilityclass]", TagName: "Label14_0_value" },
        { Id: 415214, PropertyName: "title", Value: "Upper Extremity", TagName: "TableColumn9_title" },
        { Id: 509013, PropertyName: "value", Value: "[datafield:upperextremityclass]", TagName: "Label13_0_value" },
        { Id: 477804, PropertyName: "title", Value: "Core", TagName: "TableColumn8_title" },
        { Id: 793417, PropertyName: "value", Value: "[datafield:trunkclass]", TagName: "Label12_0_value" },
        { Id: 675348, PropertyName: "title", Value: "FitSkor®", TagName: "TableColumn5_title" },
        { Id: 350522, PropertyName: "value", Value: "[datafield:fitscore]", TagName: "Label9_0_value" },
        { Id: 975100, PropertyName: "title", Value: "Report", TagName: "TableColumn2_title" },
        { Id: 564441, PropertyName: "title", Value: "Propmt Text", TagName: "Tooltip1_title" },
        { Id: 407205, PropertyName: "value", Value: "My Children", TagName: "Label20_value" },
        { Id: 698381, PropertyName: "title", Value: "Lower Extremity", TagName: "TableColumn13_title" },
        { Id: 170103, PropertyName: "value", Value: "[datafield:classlowerextremity]", TagName: "Label18_0_value" }
      ]
    },
    {
      Id: "517fe514-e225-4170-a3f2-feea5f424792",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 407205, PropertyName: "value", Value: "Ailem" },
        { Id: 760420, PropertyName: "value", Value: "-" },
        { Id: 584263, PropertyName: "value", Value: "ÖĞRENCİNİN VELİLERİ" },
        { Id: 2326, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 303098, PropertyName: "title", Value: "Title" },
        { Id: 373127, PropertyName: "value", Value: "[datafield:adi]" },
        {
          Id: 732627,
          PropertyName: "value",
          Value:
            "Detaylı raporu görmek için listenin sonundaki detay butonuna tıklayın. En iyi kullanıcı deneyimi için geniş ekranda açın."
        },
        { Id: 131446, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 687890, PropertyName: "title", Value: "Rapor Tarihi" },
        { Id: 912109, PropertyName: "value", Value: "[datafield:olcumtarihi]" },
        { Id: 89838, PropertyName: "title", Value: "Yaş" },
        { Id: 738918, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 786488, PropertyName: "title", Value: "Boy" },
        { Id: 801128, PropertyName: "value", Value: "[datafield:boy]" },
        { Id: 341730, PropertyName: "title", Value: "Ağırlık" },
        { Id: 305354, PropertyName: "value", Value: "[datafield:agirlik]" },
        { Id: 211970, PropertyName: "title", Value: "Beden Kitle Endeksi" },
        { Id: 162454, PropertyName: "value", Value: "[datafield:sinifvucutkompozisyonu]" },
        { Id: 903485, PropertyName: "title", Value: "Kalp Gücü" },
        { Id: 720371, PropertyName: "value", Value: "[datafield:sinifkalpgucu]" },
        { Id: 242859, PropertyName: "title", Value: "Esneklik" },
        { Id: 628034, PropertyName: "value", Value: "[datafield:sinifesneklik]" },
        { Id: 415214, PropertyName: "title", Value: "Üst Ekstremite" },
        { Id: 509013, PropertyName: "value", Value: "[datafield:sinifustekstremite]" },
        { Id: 477804, PropertyName: "title", Value: "Gövde" },
        { Id: 793417, PropertyName: "value", Value: "[datafield:sinifgovde]" },
        { Id: 698381, PropertyName: "title", Value: "Alt Ekstremite" },
        { Id: 170103, PropertyName: "value", Value: "[datafield:sinifaltekstremite]" },
        { Id: 675348, PropertyName: "title", Value: "FitSkor®" },
        { Id: 350522, PropertyName: "value", Value: "[datafield:fitskor]" },
        { Id: 975100, PropertyName: "title", Value: "Rapor" },
        { Id: 564441, PropertyName: "title", Value: "Propmt Text" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogrenciincele_2326_value_kuikaTableRef = React.createRef();
    this.ogrenciincele_131446_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GetLanguage: "",
      MyContext: [],
      OgrenciSelectById: [],
      OgrencininVelileri: [],
      CheckupListByOgrenci: [],
      GoBack: "",
      NAVIGATE: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogrenciincele", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgrenciIncelePageInit();

    KuikaAppManager.calculateAndSetBodyHeight("ogrenciincele", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogrenciincele", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgrenciIncelePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  OgrenciIncelePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciIncelePageInit1_();
    });

    return isErrorOccurred;
  };
  OgrenciIncelePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      sinifId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sezonId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      ogrenciId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      ogrenciId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgrenciIncele/OgrenciIncelePageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OgrenciSelectById = result?.data.ogrenciSelectById;
    formVars.ogrenciincele_298440_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.adi : null
    );
    formVars.ogrenciincele_816685_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.dT : null
    );
    formVars.ogrenciincele_981029_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulu : null
    );
    formVars.ogrenciincele_181062_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.sinifi : null
    );
    stateVars.OgrencininVelileri = result?.data.ogrencininVelileri;

    stateVars.CheckupListByOgrenci = result?.data.checkupListByOgrenci;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciIncelePageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciIncelePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.ogrenciincele_298440_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.adi : null
    );

    formVars.ogrenciincele_816685_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.dT : null
    );

    formVars.ogrenciincele_981029_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.okulu : null
    );

    formVars.ogrenciincele_181062_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.sinifi : null
    );

    stateVars.dataSource_2326 = this.state.OgrencininVelileri;

    stateVars.dataSource_131446 = this.state.CheckupListByOgrenci;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciInceleComponent_335828_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciInceleComponent_407205_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciInceleComponent_126759_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciInceleComponent_126759_onClick1_();
    });

    return isErrorOccurred;
  };
  OgrenciInceleComponent_126759_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR")) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables("CheckupRaporu", "ShowDownload", false);
      KuikaAppManager.addToPageInputVariables(
        "CheckupRaporu",
        "CheckupId",
        ReactSystemFunctions.value(this, "ogrenciincele_131446_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.OgrenciInceleComponent_126759_onClick2_,
        "OgrenciIncele",
        "CheckupRaporu",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "233170",
        null,
        "right",
        null,
        "1048px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.OgrenciInceleComponent_126759_onClick2_();
      });
    }

    return isErrorOccurred;
  };
  OgrenciInceleComponent_126759_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US")) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "CheckupRaporuEN",
        "CheckupId",
        ReactSystemFunctions.value(this, "ogrenciincele_131446_value", "id")
      );
      KuikaAppManager.addToPageInputVariables("CheckupRaporuEN", "ShowDownload", false);

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "OgrenciIncele",
        "CheckupRaporuEN",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "775407",
        null,
        "right",
        null,
        "1048px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [233170, 775407] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OgrenciIncelePageInit();
    }
    if (diId == 233170) {
      isErrorOccurred = await this.OgrenciInceleComponent_126759_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
