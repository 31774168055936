import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgrenciList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgrenciList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  OgrenciCount: any[];
  OgrenciSearch: any[];
  SetValueOf: any;
  NAVIGATE: any;
  OgrenciDeleteById: number;
  isCompogrencilist_7117297AuthorizationVisible: "visible" | "hidden";
}

export class OgrenciList_ScreenBase extends React.PureComponent<IOgrenciList_ScreenProps, any> {
  ogrencilist_399769_value_kuikaTableRef: React.RefObject<any>;
  ogrencilist_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Student List", TagName: "Label5_value" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search", TagName: "txtSearch_placeholder" },
        { Id: 814413, PropertyName: "label", Value: "NEW STUDENT", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblOgrenci_nodatafoundmessage"
        },
        { Id: 7117297, PropertyName: "title", Value: "School", TagName: "clmAdi_title" },
        { Id: 1965042, PropertyName: "value", Value: "[datafield:schoolname]", TagName: "lblAdi_value" },
        { Id: 188509, PropertyName: "title", Value: "Class", TagName: "TableColumn7_title" },
        { Id: 32578, PropertyName: "value", Value: "[datafield:classnme]", TagName: "Label6_0_value" },
        { Id: 893668, PropertyName: "title", Value: "School Number", TagName: "TableColumn5_title" },
        { Id: 862047, PropertyName: "value", Value: "[datafield:schoolnumber]", TagName: "Label4_0_value" },
        { Id: 909962, PropertyName: "title", Value: "Student's Name", TagName: "TableColumn10_title" },
        { Id: 937771, PropertyName: "value", Value: "[datafield:name]", TagName: "Label11_0_value" },
        { Id: 1197933, PropertyName: "title", Value: "Gender", TagName: "clmCinsiyet_title" },
        { Id: 8464216, PropertyName: "value", Value: "[datafield:gender]", TagName: "lblCinsiyet_value" },
        { Id: 4259169, PropertyName: "title", Value: "Birth Date", TagName: "clmDogumTarihi_title" },
        { Id: 6999863, PropertyName: "value", Value: "[datafield:birthdate]", TagName: "lblDogumTarihi_value" },
        { Id: 640482, PropertyName: "title", Value: "Parents", TagName: "TableColumn9_title" },
        { Id: 873978, PropertyName: "value", Value: "[datafield:numberofparent]", TagName: "Label8_0_value" },
        { Id: 868192, PropertyName: "title", Value: "Evaluations", TagName: "TableColumn8_title" },
        { Id: 328603, PropertyName: "value", Value: "[datafield:evaluationcount]", TagName: "Label7_0_value" },
        { Id: 603188, PropertyName: "value", Value: "student found", TagName: "Label11_value" },
        {
          Id: 74530890,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this student?",
          TagName: "OgrenciDeleteById_confirmation",
          IsConfirmation: true
        }
      ]
    },
    {
      Id: "90570faa-d5f4-4bfd-a074-0c3b799feab1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Öğrenci Listesi" },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 814413, PropertyName: "label", Value: "YENİ ÖĞRENCİ" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 7117297, PropertyName: "title", Value: "Okul" },
        { Id: 1965042, PropertyName: "value", Value: "[datafield:okuladi]" },
        { Id: 188509, PropertyName: "title", Value: "Sınıf" },
        { Id: 32578, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 893668, PropertyName: "title", Value: "Okul No" },
        { Id: 862047, PropertyName: "value", Value: "[datafield:okulno]" },
        { Id: 909962, PropertyName: "title", Value: "Öğrencinin Adı" },
        { Id: 937771, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 1197933, PropertyName: "title", Value: "Cinsiyet" },
        { Id: 8464216, PropertyName: "value", Value: "[datafield:cinsiyet]" },
        { Id: 4259169, PropertyName: "title", Value: "Doğum Tarihi" },
        { Id: 6999863, PropertyName: "value", Value: "[datafield:dogumtarihi]" },
        { Id: 640482, PropertyName: "title", Value: "Veliler" },
        { Id: 873978, PropertyName: "value", Value: "[datafield:veliadedi]" },
        { Id: 868192, PropertyName: "title", Value: "Ölçümler" },
        { Id: 328603, PropertyName: "value", Value: "[datafield:olcumadedi]" },
        { Id: 603188, PropertyName: "value", Value: "öğrenci bulundu." }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogrencilist_399769_value_kuikaTableRef = React.createRef();
    this.ogrencilist_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      OgrenciCount: [],
      OgrenciSearch: [],
      SetValueOf: "",
      NAVIGATE: "",
      OgrenciDeleteById: 0,
      isCompogrencilist_7117297AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogrencilist", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgrenciListPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompogrencilist_7117297AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("ogrencilist", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogrencilist", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgrenciListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  OgrenciListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      isActiveSezon_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      isActiveSezon_2: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgrenciList/OgrenciListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OgrenciCount = result?.data.ogrenciCount;
    formVars.ogrencilist_619624_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciCount?.length > 0 ? stateVars.OgrenciCount[0]?.count : null
    );
    stateVars.OgrenciSearch = result?.data.ogrenciSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OgrenciSearch;
    formVars.ogrencilist_619624_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciCount?.length > 0 ? this.state.OgrenciCount[0]?.count : null
    );

    formVars.ogrencilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.OgrenciCount?.length > 0 ? this.state.OgrenciCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ogrencilist_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciListComponent_416063_onPressEnter1_();
    });

    return isErrorOccurred;
  };
  OgrenciListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      isActiveSezon_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      isActiveSezon_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OgrenciList/OgrenciListComponent_416063_onPressEnter1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OgrenciCount = result?.data.ogrenciCount;
    formVars.ogrencilist_619624_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciCount?.length > 0 ? stateVars.OgrenciCount[0]?.count : null
    );
    stateVars.OgrenciSearch = result?.data.ogrenciSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciListComponent_416063_onPressEnter2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciListComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OgrenciSearch;
    formVars.ogrencilist_619624_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciCount?.length > 0 ? this.state.OgrenciCount[0]?.count : null
    );

    formVars.ogrencilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.OgrenciCount?.length > 0 ? this.state.OgrenciCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OgrenciForm", "Id", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "OkulId",
      ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null)
    );
    KuikaAppManager.addToPageInputVariables("OgrenciForm", "SinifId", null);
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "SezonId",
      ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifSezonId : null)
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OgrenciList",
      "OgrenciForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "380px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciListComponent_842177_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OgrencininVelileri",
      "OgrenciId",
      ReactSystemFunctions.value(this, "ogrencilist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OgrenciList",
      "OgrencininVelileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "439531",
      null,
      "right",
      null,
      "700px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciListComponent_948694_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CheckupList",
      "OgrenciId",
      ReactSystemFunctions.value(this, "ogrencilist_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CheckupList",
      "SinifId",
      ReactSystemFunctions.value(this, "ogrencilist_399769_value", "mevcutSinifId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OgrenciList",
      "CheckupList",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "220797",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "Id",
      ReactSystemFunctions.value(this, "ogrencilist_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "OkulId",
      ReactSystemFunctions.value(this, "ogrencilist_399769_value", "okulId")
    );
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "SinifId",
      ReactSystemFunctions.value(this, "ogrencilist_399769_value", "mevcutSinifId")
    );
    KuikaAppManager.addToPageInputVariables(
      "OgrenciForm",
      "SezonId",
      ReactSystemFunctions.value(this, "ogrencilist_399769_value", "sezonId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OgrenciList",
      "OgrenciForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "380px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ogrencilist_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      isActiveSezon_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      isActiveSezon_2: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Bu öğrenciyi silmek istediğine emin misin?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "OgrenciList/OgrenciListComponent_505853_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.OgrenciDeleteById = result?.data.ogrenciDeleteById;
        stateVars.OgrenciCount = result?.data.ogrenciCount;
        formVars.ogrencilist_619624_value = ReactSystemFunctions.toString(
          this,
          stateVars.OgrenciCount?.length > 0 ? stateVars.OgrenciCount[0]?.count : null
        );
        stateVars.OgrenciSearch = result?.data.ogrenciSearch;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.OgrenciListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OgrenciSearch;
    formVars.ogrencilist_619624_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciCount?.length > 0 ? this.state.OgrenciCount[0]?.count : null
    );

    formVars.ogrencilist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.OgrenciCount?.length > 0 ? this.state.OgrenciCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null
        ),
        "string"
      ),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      isActiveSezon_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrencilist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgrenciList/OgrenciListComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OgrenciSearch = result?.data.ogrenciSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciListComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OgrenciSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 439531, 220797, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OgrenciListPageInit();
    }
  }
}
