import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISignup_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISignup_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  Signup: any;
  goToNextScreenAfterSignup: any;
  NAVIGATE: any;
}

export class Signup_ScreenBase extends React.PureComponent<ISignup_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 717912, PropertyName: "value", Value: "Create an account", TagName: "lblTitle_value" },
        { Id: 997730, PropertyName: "value", Value: "Let's start", TagName: "lblSubTitle_value" },
        { Id: 718374, PropertyName: "value", Value: "Name Surname", TagName: "Label9_value" },
        { Id: 388216, PropertyName: "placeholder", Value: "James Smith ", TagName: "txtName_placeholder" },
        { Id: 684518, PropertyName: "value", Value: "Email", TagName: "Label10_value" },
        { Id: 298012, PropertyName: "placeholder", Value: "james.smith@memap.com.tr", TagName: "txtEmail_placeholder" },
        { Id: 360308, PropertyName: "value", Value: "Password", TagName: "Label11_value" },
        { Id: 717714, PropertyName: "placeholder", Value: "*********", TagName: "txtPassword_placeholder" },
        { Id: 807378, PropertyName: "value", Value: "You are accepting the following:", TagName: "lblYouAre_value" },
        { Id: 513929, PropertyName: "value", Value: "Terms of service", TagName: "lnkTermof_value" },
        { Id: 782186, PropertyName: "value", Value: "and", TagName: "lblAnd_value" },
        { Id: 486824, PropertyName: "value", Value: "Privacy policy", TagName: "lnkPwPolicy_value" },
        { Id: 387716, PropertyName: "label", Value: "Sign Up", TagName: "btnGetStarted_label" },
        { Id: 593542, PropertyName: "value", Value: "Allready have an acount?", TagName: "lblQ_value" },
        { Id: 687615, PropertyName: "value", Value: "Log in", TagName: "lnkSignIn_value" }
      ]
    },
    {
      Id: "70694a3c-4eac-48b8-a774-b3d151286ef6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 717912, PropertyName: "value", Value: "Bir hesap oluşturun" },
        { Id: 997730, PropertyName: "value", Value: "Başlayalım" },
        { Id: 718374, PropertyName: "value", Value: "Ad Soyad" },
        { Id: 388216, PropertyName: "placeholder", Value: "Ali Mavişehir" },
        { Id: 684518, PropertyName: "value", Value: "Eposta" },
        { Id: 298012, PropertyName: "placeholder", Value: "ali.mavisehir@kuika.com" },
        { Id: 360308, PropertyName: "value", Value: "Şifre" },
        { Id: 717714, PropertyName: "placeholder", Value: "*******" },
        { Id: 807378, PropertyName: "value", Value: "Şunları kabul ediyorsunuz:" },
        { Id: 513929, PropertyName: "value", Value: "Hizmet şartları" },
        { Id: 782186, PropertyName: "value", Value: "ve" },
        { Id: 486824, PropertyName: "value", Value: "Gizlilik Politikası" },
        { Id: 387716, PropertyName: "label", Value: "Kayıt Ol" },
        { Id: 593542, PropertyName: "value", Value: "Zagen bir hesabın var mı?" },
        { Id: 687615, PropertyName: "value", Value: "Giriş yap" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      Signup: "",
      goToNextScreenAfterSignup: "",
      NAVIGATE: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("signup", "signup", this.props.context);
      return;
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("signup", "signup", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("387716");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("signup", "signup", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  SignupComponent_387716_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_717714_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "signup_717714_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "signup_388216_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Signup = (
      (await ReactSystemFunctions.signup(
        this,
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_717714_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")),
        "",
        true,
        null
      )) as any
    ).data;
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterSignup(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_687615_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Signup",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
  }
}
