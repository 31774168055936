import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUyelikSozlesme_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUyelikSozlesme_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  NAVIGATE: any;
}

export class UyelikSozlesme_ScreenBase extends React.PureComponent<IUyelikSozlesme_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 603977, PropertyName: "value", Value: "MeMap MEMBERSHIP AGREEMENT", TagName: "Label1_value" },
        { Id: 438127, PropertyName: "value", Value: "ARTICLE 1. PARTIES", TagName: "Label2_value" },
        {
          Id: 401148,
          PropertyName: "value",
          Value:
            'This Client Agreement ("Agreement") has been established between the fitness client ("Client") who becomes a member of the MeMap website (memap.com.tr) and "ScoreSoft Teknoloji Hizmetleri A.Ş.", regulating the terms of use of MeMap and the rights and obligations of the parties.',
          TagName: "Label3_value"
        },
        { Id: 984934, PropertyName: "value", Value: "Consultant:", TagName: "Label0_0_value" },
        { Id: 637542, PropertyName: "value", Value: "Name Surname: ____________", TagName: "Label0_0_value" },
        { Id: 571915, PropertyName: "value", Value: "Email Adress: ______________", TagName: "Label0_0_value" },
        { Id: 263941, PropertyName: "value", Value: "Phone Number: ____________", TagName: "Label0_0_value" },
        { Id: 183356, PropertyName: "value", Value: "ARTICLE 2. DEFINITIONS", TagName: "Label0_0_value" },
        { Id: 854466, PropertyName: "value", Value: "MeMap Web Site:", TagName: "Label4_value" },
        {
          Id: 770961,
          PropertyName: "value",
          Value: "It refers to the website located at the URL address www.memap.com.tr.",
          TagName: "Label0_1_value"
        },
        { Id: 299385, PropertyName: "value", Value: "MeMap Platform:", TagName: "Label0_0_value" },
        {
          Id: 845058,
          PropertyName: "value",
          Value:
            "It is a digital fitness platform where the distribution of FitScore licenses for the center, management of appointments related to the service, and tracking of clients' physical fitness scoring (FitScore) are performed.",
          TagName: "Label0_1_value"
        },
        { Id: 721473, PropertyName: "value", Value: "MeMap Services:", TagName: "Label0_0_value" },
        {
          Id: 948908,
          PropertyName: "value",
          Value:
            "These are the services provided to the Client by Instructors who have MeMap Service Certification, as detailed on memap.com.tr.",
          TagName: "Label0_1_value"
        },
        { Id: 727260, PropertyName: "value", Value: "MeMap Service Certificate", TagName: "Label0_0_value" },
        {
          Id: 734043,
          PropertyName: "value",
          Value:
            "The MeMap Service Certificate is awarded to ScoreSoft Users who have demonstrated through an exam that they can provide the relevant MeMap Service. The certificates held by the ScoreSoft User and the MeMap Services they can provide based on these certificates are listed on the respective ScoreSoft User's profile page.",
          TagName: "Label0_1_value"
        },
        { Id: 224373, PropertyName: "value", Value: "Member:", TagName: "Label0_0_value" },
        {
          Id: 464841,
          PropertyName: "value",
          Value: "It refers to the person who registers on the MeMap platform.",
          TagName: "Label0_1_value"
        },
        { Id: 534839, PropertyName: "value", Value: "Consultant:", TagName: "Label0_0_value" },
        {
          Id: 655775,
          PropertyName: "value",
          Value: "It refers to a MeMap member who holds a valid FitScore License.",
          TagName: "Label0_1_value"
        },
        { Id: 90294, PropertyName: "value", Value: "FitScore:", TagName: "Label0_0_value" },
        { Id: 882271, PropertyName: "value", Value: "FitScore License:", TagName: "Label0_0_value" },
        {
          Id: 539071,
          PropertyName: "value",
          Value:
            "It is a digital service, charged for individual FitScore calculation and reporting purposes, exclusive to the email addresses of individuals who register on the MeMap Digital Platform.",
          TagName: "Label0_1_value"
        },
        { Id: 215169, PropertyName: "value", Value: "ScoreSoft Software:", TagName: "Label0_0_value" },
        {
          Id: 317741,
          PropertyName: "value",
          Value:
            "It is a software that can be used with a ScoreSoft License, which evaluates individuals' physical and physiological data to determine their physical fitness score based on age and gender, and subsequently creates recommended exercise strategies for improving fitness components, providing digital content.",
          TagName: "Label0_1_value"
        },
        { Id: 951355, PropertyName: "value", Value: "ScoreSoft License:", TagName: "Label0_0_value" },
        {
          Id: 805867,
          PropertyName: "value",
          Value:
            "It is a digital service, charged annually for the purpose of using the individual ScoreSoft Software exclusively for the email address of ScoreSoft Users.",
          TagName: "Label0_1_value"
        },
        { Id: 340401, PropertyName: "value", Value: "ScoreSoft User:", TagName: "Label0_0_value" },
        {
          Id: 204177,
          PropertyName: "value",
          Value: "It refers to the user who provides FitScore Licenses and holds a valid ScoreSoft License.",
          TagName: "Label0_1_value"
        },
        { Id: 766369, PropertyName: "value", Value: "MeMap Trainer", TagName: "Label0_0_value" },
        {
          Id: 416973,
          PropertyName: "value",
          Value:
            "It refers to the instructor who holds a valid ScoreSoft License purchased by themselves or by the center, meets the training criteria set by MeMap Fitness Academy, and proves their ability to provide MeMap Services through an examination, but is not publicly listed on the MeMap platform and provides FitScore Licenses.",
          TagName: "Label0_1_value"
        },
        { Id: 298844, PropertyName: "value", Value: "MeMap Platform Trainer", TagName: "Label0_0_value" },
        {
          Id: 456217,
          PropertyName: "value",
          Value:
            "It refers to the instructor who holds a valid ScoreSoft License purchased by themselves, meets the training criteria set by MeMap Fitness Academy, proves their ability to provide MeMap Services through an examination, is publicly listed on the MeMap platform, and provides FitScore Licenses.",
          TagName: "Label0_1_value"
        },
        { Id: 312670, PropertyName: "value", Value: "MeMap Ambassador", TagName: "Label0_0_value" },
        {
          Id: 897001,
          PropertyName: "value",
          Value:
            "It is the person who conveys the MeMap philosophy, standards, and business model to license providers and manages the business relationships between these providers and MeMap.",
          TagName: "Label0_1_value"
        },
        { Id: 10391, PropertyName: "value", Value: "Gym License:", TagName: "Label0_0_value" },
        {
          Id: 903305,
          PropertyName: "value",
          Value:
            "It is a digital service charged annually for the purpose of obtaining the right to use the MeMap Digital Platform in order to distribute FitScore Licenses and provide MeMap Services.",
          TagName: "Label0_1_value"
        },
        { Id: 313861, PropertyName: "value", Value: "ScoreSoft Center", TagName: "Label0_0_value" },
        {
          Id: 875657,
          PropertyName: "value",
          Value:
            "It refers to a commercial enterprise that holds a valid Center License and provides FitScore Licenses.",
          TagName: "Label0_1_value"
        },
        { Id: 417259, PropertyName: "value", Value: "MeMap Locations:", TagName: "Label0_0_value" },
        {
          Id: 410563,
          PropertyName: "value",
          Value:
            "It refers to a commercial enterprise that holds a valid Center License, meets MeMap Center standards to offer MeMap Services, hosts at least one MeMap Instructor, but is not publicly listed on the MeMap Platform and provides MeMap FitScore Licenses.",
          TagName: "Label0_1_value"
        },
        { Id: 966885, PropertyName: "value", Value: "MeMap Platform Location:", TagName: "Label0_0_value" },
        {
          Id: 777164,
          PropertyName: "value",
          Value:
            "It refers to a commercial enterprise that holds a valid Center License, meets MeMap Center standards to offer MeMap Services, hosts at least one MeMap Instructor, and is publicly listed on the MeMap Platform while providing MeMap FitScore Licenses.",
          TagName: "Label0_1_value"
        },
        { Id: 6675, PropertyName: "value", Value: "Institution", TagName: "Label0_0_value" },
        {
          Id: 316475,
          PropertyName: "value",
          Value:
            "It refers to a commercial enterprise that provides FitScore Licenses in agreement with one or more MeMap Centers.",
          TagName: "Label0_1_value"
        },
        { Id: 505909, PropertyName: "value", Value: "MeMap Fitness Academy:", TagName: "Label0_0_value" },
        {
          Id: 653409,
          PropertyName: "value",
          Value:
            "It refers to the online Fitness Training Platform, available at www.yeninesilfitnessegitimi.com, where instructors can receive training to provide standardized MeMap Services.",
          TagName: "Label0_1_value"
        },
        { Id: 828917, PropertyName: "value", Value: "Exercise Prescription:", TagName: "Label0_0_value" },
        {
          Id: 968990,
          PropertyName: "value",
          Value:
            "It refers to the prioritization and sequencing of individualized fitness components, based on the number of days and duration allocated for exercise per week, for individuals whose FitScore has been calculated.",
          TagName: "Label0_1_value"
        },
        { Id: 130226, PropertyName: "value", Value: "Data:", TagName: "Label0_0_value" },
        {
          Id: 941953,
          PropertyName: "value",
          Value:
            "The information provided by the consultant and/or instructor for ScoreSoft software to make calculations.",
          TagName: "Label0_1_value"
        },
        { Id: 314061, PropertyName: "value", Value: "Know-How:", TagName: "Label0_0_value" },
        {
          Id: 106537,
          PropertyName: "value",
          Value:
            "This Agreement refers to the ScoreSoft Software, as it exists in the areas allocated for the use of ScoreSoft Users under this Agreement, and MeMap's business systems and methods.",
          TagName: "Label0_1_value"
        },
        { Id: 505817, PropertyName: "value", Value: "Under this agreement", TagName: "Label51_value" },
        {
          Id: 635319,
          PropertyName: "value",
          Value: "ARTICLE 3. SUBJECT MATTER OF THE AGREEMENT",
          TagName: "Label0_0_value"
        },
        {
          Id: 116826,
          PropertyName: "value",
          Value:
            "The subject matter of this agreement is to determine the general terms and conditions of the website after the Member opens an account on the MeMap website, the purchase of the FitScore License by the Member through the MeMap Website, the acquisition of the status of 'Client' by the Member following this purchase, and the determination of the mutual rights and obligations of the Parties regarding the purchased FitScore License service.",
          TagName: "Label0_1_value"
        },
        {
          Id: 851661,
          PropertyName: "value",
          Value: "ARTICLE 4. GENERAL INFORMATION ABOUT MEMAP PLATFORM",
          TagName: "Label0_0_value"
        },
        {
          Id: 361878,
          PropertyName: "value",
          Value:
            "MeMap is a digital fitness platform that offers services related to physical fitness. Individuals who become members of the MeMap Platform obtain a FitScore® license to access MeMap services. Members with FitScore® licenses are referred to as clients. Clients receive MeMap services from MeMap Instructors who use the ScoreSoft® software, affiliated with the MeMap Centers to which the respective MeMap Instructor belongs.",
          TagName: "Label0_1_value"
        },
        {
          Id: 730278,
          PropertyName: "value",
          Value:
            "The MeMap Instructors on the MeMap Platform are instructors who meet the criteria set by the MeMap Fitness Academy and have demonstrated their ability to provide MeMap services through an examination. The centers on the MeMap Platform are centers that meet the MeMap center standards and have at least one MeMap Instructor on their staff. MeMap Instructors and Centers also serve as providers of FitScore® licenses.",
          TagName: "Label14_value"
        },
        {
          Id: 687769,
          PropertyName: "value",
          Value:
            "A ScoreSoft User refers to a user with a valid ScoreSoft License who provides FitScore Licenses. However, a ScoreSoft User is not committed to providing MeMap Services according to the criteria and standards set by the MeMap Fitness Academy, despite having a ScoreSoft License. Therefore, a ScoreSoft User is not present on the MeMap Digital Platform and cannot provide MeMap Services.",
          TagName: "Label13_value"
        },
        {
          Id: 935893,
          PropertyName: "value",
          Value:
            "The ScoreSoft software is a digital service software that evaluates a person's physical and physiological data to calculate their physical fitness score (FitScore®) based on their age and gender, and creates a recommended exercise strategy accordingly.",
          TagName: "Label12_value"
        },
        {
          Id: 836061,
          PropertyName: "value",
          Value:
            "In order for the ScoreSoft software to perform calculations according to its intended purpose, the age and gender information entered by the client during registration on the platform is used. Other health information provided by the client is recorded by the instructor based on the reports and statements provided by the client. In addition to these, all data used for calculations is obtained from measurements taken by the instructor. The instructor enters physical data obtained from body composition, muscle strength, and flexibility measurements, as well as physiological data obtained from cardiopulmonary capacity measurements, into the software. After all data is entered, the software calculates the client's FitScore®. The exercise prescription strategy is fully personalized based on the client's FitScore®, the number of weekly days allocated for exercise, and the exercise duration.",
          TagName: "Label11_value"
        },
        {
          Id: 479269,
          PropertyName: "value",
          Value: "ARTICLE 5: RIGHTS AND OBLIGATIONS OF THE COMPANY",
          TagName: "Label0_0_value"
        },
        { Id: 424278, PropertyName: "value", Value: "5.1.", TagName: "Label0_0_value" },
        {
          Id: 258428,
          PropertyName: "value",
          Value:
            "The Company, in order to provide better service to the Client who is a member of MeMap, accepts, declares, and undertakes that it will process all personal data provided by the Client to the MeMap Platform in accordance with the Personal Data Protection Law numbered 6698.",
          TagName: "Label0_0_value"
        },
        { Id: 33657, PropertyName: "value", Value: "In this regard,", TagName: "Label15_value" },
        {
          Id: 478975,
          PropertyName: "value",
          Value: "the MeMap Website Information Notice (ANNEX-1) ",
          TagName: "Label0_1_value"
        },
        {
          Id: 633490,
          PropertyName: "value",
          Value: "attached to this agreement is accepted as an integral part of the agreement.",
          TagName: "Label0_2_value"
        },
        { Id: 49155, PropertyName: "value", Value: "5.2.", TagName: "Label0_0_value" },
        {
          Id: 912769,
          PropertyName: "value",
          Value:
            "Following the purchase of the FitScore License, personal data belonging to the Client, which was presented to the Member before the purchase,",
          TagName: "Label0_0_value"
        },
        {
          Id: 256681,
          PropertyName: "value",
          Value: "In accordance with the Information Notice on the Sharing of Personal Data,",
          TagName: "Label0_2_value"
        },
        {
          Id: 100804,
          PropertyName: "value",
          Value: "it is shared with the ScoreSoft User and",
          TagName: "Label0_3_value"
        },
        {
          Id: 704848,
          PropertyName: "value",
          Value:
            "The FitScore and related individual exercise prescription advice are entered into the system by the ScoreSoft User and provided to the Client. The information and content provided by the Company are of an advisory nature, and the Company cannot be held responsible for any other application, evaluation, or determination in this regard. In this regard, the Member and/or Client acknowledge, declare, and undertake that all information, exercises, and content in the ScoreSoft Software and the website are of an advisory nature and do not provide diagnosis or treatment for any disease or health condition. They accept full responsibility for whether or not to use this content, and the Company cannot be held responsible for the program chosen and implemented by the Client in accordance with the recommendation of the ScoreSoft User.",
          TagName: "Label16_value"
        },
        { Id: 801589, PropertyName: "value", Value: "5.3.", TagName: "Label0_0_value" },
        {
          Id: 943647,
          PropertyName: "value",
          Value:
            "The Company keeps the data communicated by the Client to the ScoreSoft User in writing and/or verbally for the use of the ScoreSoft Software. In case of any discrepancy or error in the data provided by the Client based on their own statement, the Client acknowledges, declares, and undertakes that all responsibility arising from this belongs to them, without recourse to the Company.",
          TagName: "Label0_1_value"
        },
        { Id: 777698, PropertyName: "value", Value: "5.4.", TagName: "Label0_0_value" },
        {
          Id: 654518,
          PropertyName: "value",
          Value:
            "The Company may request the personal information of the Member and/or Client (name, email address, phone number) in order to provide better service to the Member and invite them to various campaigns. For this purpose, the Company may require the Member and Client to fill out a survey or information form. The information collected on the Company's servers and computers may be used by the Company for periodic campaign activities, the development of special promotional activities for user profiles, and the classification of Members for unwanted messages",
          TagName: "Label0_0_value"
        },
        {
          Id: 975923,
          PropertyName: "value",
          Value:
            "The Company declares and undertakes that it will carry out these activities with the approval of the Internet Site Information Notice",
          TagName: "Label0_2_value"
        },
        { Id: 648065, PropertyName: "value", Value: "and", TagName: "Label17_value" },
        { Id: 824462, PropertyName: "value", Value: "the Commercial Communication ", TagName: "Label18_value" },
        {
          Id: 467870,
          PropertyName: "value",
          Value: "and Commercial Electronic Message Consent Form (ANNEX-2).",
          TagName: "Label0_3_value"
        },
        { Id: 372223, PropertyName: "value", Value: "5.5.", TagName: "Label0_0_value" },
        {
          Id: 355784,
          PropertyName: "value",
          Value:
            "The Company is not responsible for interruptions or malfunctions that may occur in the ScoreSoft Software and the Internet Site due to force majeure, connection problems, internet interruptions, reasons originating from suppliers, user errors, and reasons related to the device on which the Member uses the ScoreSoft Software or the version of the device's operating system.",
          TagName: "Label0_1_value"
        },
        { Id: 708518, PropertyName: "value", Value: "5.6.", TagName: "Label0_0_value" },
        {
          Id: 47225,
          PropertyName: "value",
          Value:
            "The Company may develop, change, and make updates for the ScoreSoft Software, and can make visual, content, and application-related changes without any prior notice whenever it deems necessary. The Company can unilaterally change the membership fee, in-app prices, campaigns, and packages for Danışan’s MeMap membership after obtaining the FitSkor License, additional payments may occur, and the Member accepts, declares, and undertakes that they need to regularly update and pay for updates to benefit from current changes. Members who accept and install these software updates will continue to receive services. In this regard, the provisions in Article 13 of this agreement are reserved.",
          TagName: "Label0_1_value"
        },
        { Id: 860239, PropertyName: "value", Value: "5.7.", TagName: "Label0_0_value" },
        {
          Id: 292475,
          PropertyName: "value",
          Value:
            "The Company, in order to ensure the continuity of the services, may make changes unilaterally to this Agreement without any further notice, and at any time, without showing any reason, has the right to unilaterally suspend the provided service either permanently or temporarily, change the content of the service, or cancel it. In case of any changes, the updated Membership Agreement will be published on the ScoreSoft Software and Internet Site with a new date update under the same internet connection, and if deemed necessary, will be notified to the Danışan via e-mail. The updated User Agreement will be effective from the moment it is published on the ScoreSoft Software and/or Internet Site, and the use of the Internet Site and/or ScoreSoft Software will be subject to the renewed Membership Agreement from that moment.",
          TagName: "Label0_1_value"
        },
        { Id: 149767, PropertyName: "value", Value: "5.8.", TagName: "Label0_0_value" },
        {
          Id: 49300,
          PropertyName: "value",
          Value:
            "Membership to ScoreSoft Software and Internet Site is for personal and individual use only. The Company reserves the right to suspend, suspend or terminate membership if it determines that the ScoreSoft Software or Internet Site is being used in violation of the articles specified in this Agreement. In this case, the Member accepts, declares and undertakes that he/she cannot make any claims against the Company under any name.",
          TagName: "Label0_1_value"
        },
        { Id: 688133, PropertyName: "value", Value: "5.9.", TagName: "Label0_0_value" },
        {
          Id: 127201,
          PropertyName: "value",
          Value:
            "In the event that the services covered by this Agreement are provided by a MeMap Instructor affiliated with the Center, the Company, without prejudice to the exclusive liability of the Center for the obligations attributed to the Center, shall have the right and authority to inspect at any time, without any notice and without the need for additional authorization, its compliance with this Agreement, with its sole discretion or by its authorized MeMap Ambassador.",
          TagName: "Label0_1_value"
        },
        {
          Id: 944658,
          PropertyName: "value",
          Value: "ARTICLE 6: RIGHTS AND OBLIGATIONS OF THE MEMBER AND THE CONSULTANT",
          TagName: "Label0_0_value"
        },
        { Id: 424193, PropertyName: "value", Value: "6.1", TagName: "Label0_0_value" },
        {
          Id: 390410,
          PropertyName: "value",
          Value:
            "The email address provided by the Consultant upon registering for MeMap must belong to them and be an active account. Unless there is a system error, the Consultant can only link one email address to the software. The email address provided during registration is considered the primary contact address for all activation, information, and correspondence related to membership. The Company cannot be held responsible for delays and potential direct or indirect damages arising from incorrect provision of the contact address. Consultants are solely responsible for the protection, duplication, or theft of email addresses and passwords. Therefore, the Consultant acknowledges, accepts, and undertakes to waive any recourse against the Company for damages that may arise.",
          TagName: "Label0_0_value"
        },
        {
          Id: 675255,
          PropertyName: "value",
          Value:
            "The Member can use the FitSkor License with the password they have chosen after purchasing it and becomes a Consultant during the membership period. The Consultant can view their personal data registered on the website and the physical fitness results generated as a result of the evaluation of this data. The Consultant acknowledges, declares, and undertakes that the personal data they have provided is correct based on their own statements. In case the Consultant notices an error or deficiency in their personal data, they must promptly inform the ScoreSoft User and ensure the registration of the corrected relevant personal data into the system. The Consultant is solely responsible for any damage resulting from the incomplete or inaccurate filling of information and/or personal data.",
          TagName: "Label0_0_value"
        },
        { Id: 737916, PropertyName: "value", Value: "6.3.", TagName: "Label0_0_value" },
        {
          Id: 953367,
          PropertyName: "value",
          Value:
            "The Consultant can schedule appointments with the MeMap Trainer available on the MeMap platform. In accordance with the provisions of Article 7.4 of this Agreement, the Consultant has the right to cancel their appointment.",
          TagName: "Label0_1_value"
        },
        { Id: 893797, PropertyName: "value", Value: "6.4.", TagName: "Label0_0_value" },
        {
          Id: 21597,
          PropertyName: "value",
          Value:
            "The Consultant, of their own volition, provides their personal data (which must be processed in a limited manner for the purpose of performing the service covered by this Agreement) to the ScoreSoft User from whom they have selected and scheduled an appointment (via the ScoreSoft Software).",
          TagName: "Label0_0_value"
        },
        {
          Id: 88982,
          PropertyName: "value",
          Value:
            "that He/She accepts that his/her personal data will be shared in accordance with the Information Text on the Sharing of Personal Data.",
          TagName: "Label0_2_value"
        },
        {
          Id: 385105,
          PropertyName: "value",
          Value: "accordance with the Information Text on the Sharing of Personal Data.",
          TagName: "Label0_3_value"
        },
        {
          Id: 390945,
          PropertyName: "value",
          Value: "The Consultant has the right to change their ScoreSoft User at any time.",
          TagName: "Label0_5_value"
        },
        { Id: 670380, PropertyName: "value", Value: "6.5.", TagName: "Label0_0_value" },
        {
          Id: 846703,
          PropertyName: "value",
          Value:
            "The Client, during the term of this agreement and after the termination of the contract, accepts to receive notifications for informational, marketing, and/or advertising purposes through other means of communication such as fax, email, and text messages by approving the Commercial Communication and Commercial Electronic Message Consent Form. The Client will send their opt-out request to the Company's info@memap.com.tr address. From the moment this email reaches the Company, within 48 hours, the Company will cease sending informational messages.",
          TagName: "Label0_1_value"
        },
        { Id: 873551, PropertyName: "value", Value: "6.6.", TagName: "Label0_0_value" },
        {
          Id: 646345,
          PropertyName: "value",
          Value:
            "Following the activation of the Danışan membership with the purchase of a FitSkor License after becoming a member, the Company provides one FitSkor calculation and reporting service for the fee collected.",
          TagName: "Label0_1_value"
        },
        { Id: 411245, PropertyName: "value", Value: "6.7.", TagName: "Label0_0_value" },
        {
          Id: 233984,
          PropertyName: "value",
          Value: "The Company will provide free platform usage for Cosultans for one year from the date of membership.",
          TagName: "Label0_1_value"
        },
        { Id: 77009, PropertyName: "value", Value: "6.8.", TagName: "Label0_0_value" },
        {
          Id: 20668,
          PropertyName: "value",
          Value:
            "The Consultan who benefits from the service through the Location acknowledges, in accordance with Article 5.9 of this contract, that the Company or its authorized MeMap Ambassador has the authority to audit the Location.",
          TagName: "Label0_1_value"
        },
        {
          Id: 320547,
          PropertyName: "value",
          Value: "ARTICLE 7: SCORESOFT USER AND FITSKOR LICENSE",
          TagName: "Label0_0_value"
        },
        { Id: 842291, PropertyName: "value", Value: "7.1.", TagName: "Label0_0_value" },
        {
          Id: 177261,
          PropertyName: "value",
          Value:
            "The right to use and manage the FitSkor License belongs to the Consultant who has paid the license fee.",
          TagName: "Label0_1_value"
        },
        { Id: 303802, PropertyName: "value", Value: "7.2.", TagName: "Label0_0_value" },
        {
          Id: 663202,
          PropertyName: "value",
          Value:
            "If the Consultant has obtained the FitSkor License through a ScoreSoft User, this license will only be valid as long as they work with the respective ScoreSoft User. In other words, the Consultant who benefits from the system through the Center or ScoreSoft User without paying any membership fee to the Company will be automatically removed from the membership when the Center or ScoreSoft User leaves the system.",
          TagName: "Label0_1_value"
        },
        { Id: 462734, PropertyName: "value", Value: "7.3.", TagName: "Label0_0_value" },
        {
          Id: 557563,
          PropertyName: "value",
          Value:
            "Upon purchasing the FitSkor License and becoming a Consultant, the Member has the right to request their rights under the Personal Data Protection Law No. 6698 from the Company during the license period.",
          TagName: "Label0_1_value"
        },
        { Id: 453138, PropertyName: "value", Value: "7.4.", TagName: "Label0_0_value" },
        {
          Id: 120197,
          PropertyName: "value",
          Value:
            "The Consultant acknowledges that if they wish to manage their personal data entered into the ScoreSoft Software themselves through the system, they must pay an additional fee to obtain a ScoreSoft License.",
          TagName: "Label0_0_value"
        },
        { Id: 941989, PropertyName: "value", Value: "7.5.", TagName: "Label0_0_value" },
        {
          Id: 632695,
          PropertyName: "value",
          Value:
            "A Consultant who holds a FitScore License may request their rights regarding the personal data stored by the Company under Article 11 of the Personal Data Protection Law No. 6698.",
          TagName: "Label0_1_value"
        },
        { Id: 861235, PropertyName: "value", Value: "ARTICLE 8: PURCHASES AND PAYMENTS", TagName: "Label0_0_value" },
        { Id: 645917, PropertyName: "value", Value: "8.1", TagName: "Label0_0_value" },
        { Id: 484102, PropertyName: "value", Value: "The Consultant,", TagName: "Label0_0_value" },
        {
          Id: 772691,
          PropertyName: "value",
          Value: "after completing all the necessary information for membership on the",
          TagName: "Label0_1_value"
        },
        { Id: 608902, PropertyName: "value", Value: "memap.com.tr website,", TagName: "Label0_2_value" },
        {
          Id: 159709,
          PropertyName: "value",
          Value:
            "clicks on the purchase button and enters payment information to purchase the right to use the FitScore License. After the purchase is made by the Consultant, a confirmation email indicating that the purchase was successful will be sent to the Consultant by the Company.",
          TagName: "Label0_3_value"
        },
        { Id: 224414, PropertyName: "value", Value: "8.2", TagName: "Label0_0_value" },
        {
          Id: 791905,
          PropertyName: "value",
          Value:
            "Membership fees can be paid in advance or in installments. The payment terms are subject to the Payment Method chosen by the Consultants and the agreements between credit card providers or financial institutions. The Company may use a third-party Payment Processor to invoice the Consultants through the payment account for the use of MeMap services. The payment process will be subject to the terms, conditions, and privacy provisions stipulated by the Payment Processor, in addition to this Agreement. The Consultant agrees not to make any claims against the Company for indirect and/or direct damages that may arise due to the Payment Processor. The Consultant agrees to make payment using the selected Payment Method. The Consultant acknowledges that the Company reserves the right to correct any errors or issues, even if a request has been submitted or payment has been made. The terms of the agreement between the Consultant and the Payment Provider will apply to the Payment Method. The Consultant agrees that the Company may invoice transactions made by the Consultant immediately after the transaction, during billing periods, or at the end of the period.",
          TagName: "Label0_1_value"
        },
        { Id: 914991, PropertyName: "value", Value: "8.3", TagName: "Label0_0_value" },
        {
          Id: 555332,
          PropertyName: "value",
          Value:
            "In case of changes in the billing address, credit card number, or credit card expiration date, or in the event of a security breach such as the cancellation of payment methods (loss or theft, etc.), unauthorized closure, or unauthorized use of the Consultant's name or password, the Consultant must immediately inform the Company or the payment processor. The Consultant may change payment information if deemed necessary.",
          TagName: "Label0_1_value"
        },
        { Id: 955139, PropertyName: "value", Value: "8.4.", TagName: "Label0_0_value" },
        {
          Id: 117219,
          PropertyName: "value",
          Value:
            "If the amount to be deducted from the billing account of the Consultant changes compared to the previously approved amount, except for changes in tax amounts or obligations, the Consultant has the right to request to be notified one day before the day of payment and the amount to be deducted on the day the transaction will take place.",
          TagName: "Label0_1_value"
        },
        { Id: 991906, PropertyName: "value", Value: "8.5.", TagName: "Label0_0_value" },
        {
          Id: 851,
          PropertyName: "value",
          Value:
            "The Company will send an email to the Consultant 7 days before the end of the MeMap Membership period, confirming in writing that the Consultant will continue to use the FitSkor License and will continue to be billed in the new period. Except for negative responses to the information email sent by the Company, the Consultant acknowledges, declares, and undertakes that he/she has given explicit consent to continue using the services related to the FitSkor License.",
          TagName: "Label0_1_value"
        },
        { Id: 417375, PropertyName: "value", Value: "8.6.", TagName: "Label0_0_value" },
        {
          Id: 666456,
          PropertyName: "value",
          Value:
            "The Company will inform the Consultant through the MeMap Platform a short time before the expiration date of the FitSkor License that the license period is about to end. Following this notification, if the FitSkor License is not renewed/extended by making the payment during the remaining period, the Consultant will not be able to benefit from the MeMap platform as of the expiration date of the FitSkor License period.",
          TagName: "Label0_1_value"
        },
        {
          Id: 342720,
          PropertyName: "value",
          Value: "ARTICLE 9: CONSULTANT'S RIGHT OF WITHDRAWAL",
          TagName: "Label0_0_value"
        },
        { Id: 480108, PropertyName: "value", Value: "9.1.", TagName: "Label0_0_value" },
        {
          Id: 617143,
          PropertyName: "value",
          Value:
            "The Consultant, in accordance with the provisions of the Law No. 6502 on Consumer Protection and Article 23 of this Agreement, has the right to withdraw from this Agreement within 14 days from the date this Agreement comes into effect, provided that no measurement or contractual service is received. The Company is obligated to refund the payments to the Consultant within 14 days from the date the notice of withdrawal is received by sending an email to info@memap.com.tr, indicating that the Consultant has exercised their right of withdrawal.",
          TagName: "Label0_1_value"
        },
        { Id: 49829, PropertyName: "value", Value: "9.2.", TagName: "Label0_0_value" },
        {
          Id: 188108,
          PropertyName: "value",
          Value:
            "The Consultant acknowledges, declares, and undertakes that they will not hold the Company responsible for any indirect/direct damages that may arise from the fact that their email containing the right of withdrawal has not reached the Company and/or has reached the Company late.",
          TagName: "Label0_1_value"
        },
        { Id: 903915, PropertyName: "value", Value: "9.3.", TagName: "Label0_0_value" },
        {
          Id: 445284,
          PropertyName: "value",
          Value:
            "The right of withdrawal under the Distance Contracts Regulation Article 15/I/ğ shall be reserved for contracts related to services provided instantly in electronic environment and/or non-material goods delivered instantly to the consumer.",
          TagName: "Label0_1_value"
        },
        { Id: 131003, PropertyName: "value", Value: "ARTICLE 10: DISCLAIMER", TagName: "Label0_0_value" },
        { Id: 739963, PropertyName: "value", Value: "10.1.", TagName: "Label0_0_value" },
        {
          Id: 158098,
          PropertyName: "value",
          Value:
            "ScoreSoft Software and Website contain all kinds of information, exercises, and content prepared as advisory in nature based on the information provided by the Client in the 'Pre-Exercise Participation Health Assessment Guide,' assuming that the Client does not have any medical or psychological conditions on average. The Client is aware of this situation when purchasing the FitSkor License and is responsible for obtaining the approval of their doctor if deemed necessary before use. The Company cannot be held responsible for any results arising from the Client's medical or psychological condition.",
          TagName: "Label0_1_value"
        },
        { Id: 798077, PropertyName: "value", Value: "10.2.", TagName: "Label0_0_value" },
        {
          Id: 61160,
          PropertyName: "value",
          Value:
            "The Client acknowledges, declares, and undertakes that the FitSkor License and all kinds of information, exercises, and content on the website are advisory in nature, do not provide diagnosis or treatment for any disease or health condition, and that the Client has complete discretion and responsibility for using this content or not. The Company cannot be held responsible for the program selected and applied by the Client. In this regard, the Client acknowledges, declares, and undertakes that they have been informed that the content and exercise programs provided by ScoreSoft Software, based on the assumption that the personal data provided by the Client is complete and accurate, should be consulted with a specialist or applied under the supervision of a specialist. ScoreSoft Software does not provide diagnosis or treatment for any disease or health condition. The Client accepts, declares, and undertakes that the Company is not responsible for any direct or indirect damages that may occur due to any discomfort, disability, weakness, or similar reasons arising from unreported, incomplete, or incorrect data.",
          TagName: "Label0_1_value"
        },
        { Id: 283749, PropertyName: "value", Value: "10.3.", TagName: "Label0_0_value" },
        {
          Id: 792819,
          PropertyName: "value",
          Value:
            "The Company does not guarantee that the information and recommendations provided to the Client who registers on the Website will lead to achieving the targeted weight and body measurements, or that they will be suitable for the Client's environmental and health conditions, or that the results will be successful. The Client acknowledges, declares, and undertakes that they will not make any claims for material or moral damages in this regard.",
          TagName: "Label0_1_value"
        },
        { Id: 420069, PropertyName: "value", Value: "10.4.", TagName: "Label0_0_value" },
        {
          Id: 599547,
          PropertyName: "value",
          Value:
            "The services provided by the MeMap Platform can only be offered by MeMap Instructors who have obtained the 'MeMap Service Certificate' demonstrating their competence in this regard. ScoreSoft Users who do not possess the MeMap Service Certificate are not authorized to provide the services covered by this agreement. In this context, if the Client receives the services covered by the agreement from a ScoreSoft User who does not possess the MeMap Service Certificate, without the knowledge of the Company or the center providing the service, the Company shall not be liable for any damages that may arise.",
          TagName: "Label0_1_value"
        },
        {
          Id: 407317,
          PropertyName: "value",
          Value: "ARTICLE 11: CONFIDENTIALITY AND PERSONAL DATA PROTECTION",
          TagName: "Label0_0_value"
        },
        { Id: 637520, PropertyName: "value", Value: "11.1. Privacy", TagName: "Label0_0_value" },
        {
          Id: 323102,
          PropertyName: "value",
          Value:
            "The Parties, even after the termination of this Agreement, agree to keep each other's financial, commercial, technological, etc. information and secrets confidential, not to disclose any direct or indirect confidential information owned by each other, in whole or in part, to third parties, not to provide third parties with documents and records related to these, and, if requested by the other party, to return all copies, duplicates, or other means of such confidential information or to destroy them. The Parties agree to take all necessary measures to ensure that confidential information is not disclosed or distributed in violation of the provisions of this Agreement by their employees or third parties. The Parties undertake to process personal data of each other's employees, customers, and other relevant individuals in accordance with the legal processing conditions and rules, and to take all administrative and technical measures to protect their confidentiality.",
          TagName: "Label0_1_value"
        },
        { Id: 71234, PropertyName: "value", Value: "11.2. Information Obligation", TagName: "Label0_0_value" },
        {
          Id: 419857,
          PropertyName: "value",
          Value:
            "The contracting parties shall process the data of each other's shareholders, partners, officials, and employees (collectively referred to as the 'party representative') solely for the purposes and conditions of the establishment and direct performance of this Agreement and in compliance with applicable legal requirements. Each party shall be responsible for informing its own party representative on behalf of the other party and for documenting this process regarding the personal data to be used and transferred throughout the process.",
          TagName: "Label0_1_value"
        },
        {
          Id: 909711,
          PropertyName: "value",
          Value: "11.3. Rules for Processing and Transferring Personal Data",
          TagName: "Label0_0_value"
        },
        {
          Id: 66868,
          PropertyName: "value",
          Value:
            "The parties shall securely transfer to each other the minimum categories of personal data necessary for the performance of this Agreement. These data shall only be used for purposes beyond the performance of the Agreement if the data subject is separately informed by the party representative and when required by legal grounds provided by the legislation. The parties shall store this data in secure environments and shall be obliged to destroy them within ten years in accordance with storage and destruction policies, commencing from the termination of this Agreement. In case of non-compliance, each party shall be liable for its unlawful actions.",
          TagName: "Label0_1_value"
        },
        {
          Id: 583706,
          PropertyName: "value",
          Value: "ARTICLE 12: VERSION CHANGES AND MAINTENANCE",
          TagName: "Label0_0_value"
        },
        {
          Id: 988127,
          PropertyName: "value",
          Value:
            "The Company has the right to make changes in the system and/or written documents without prior notice, and to release new versions and/or versions of system products. The Consultant can use this new system and/or new written documents only by paying the 'update' fee. The Company may offer updates for free at its discretion.",
          TagName: "Label0_1_value"
        },
        {
          Id: 483821,
          PropertyName: "value",
          Value: "ARTICLE 13: USE OF INTELLECTUAL PROPERTY RIGHTS",
          TagName: "Label0_0_value"
        },
        {
          Id: 432268,
          PropertyName: "value",
          Value:
            'The intellectual and industrial property rights of the ScoreSoft Software and the Website belong to the Company. The structure, layout, design, visuals, all kinds of exercises, content, and information contained in the software, as well as the source codes of the software, constitute the intellectual and industrial property assets owned by the Company. All of these assets are protected under the Law on Industrial Property Numbered 6769 (IP Law) and the Law on Intellectual and Artistic Works Numbered 5846 (IAW Law). This Agreement does not grant any intellectual property rights to the ScoreSoft User and/or the Consultant for the software and/or content, and all rights in this regard are reserved by the Company. In this context, the Company is the exclusive and perpetual sole right holder over all industrial products (briefly "Industrial Products") that may arise within the scope of the IP Law, such as trademark, patent, utility model, trade secret, process, production method, idea, invention, draft, design, model research result, test result, production information, performance information, plan, method, specification, and all intellectual products (briefly "Intellectual Products") that may arise within the scope of copyright law according to the Law on Intellectual and Artistic Works Numbered 5846 and all related financial rights and other related rights. Unauthorized copying actions and transactions outside the scope of the usage license right are subject to criminal sanctions depending on the nature of the action.',
          TagName: "Label0_1_value"
        },
        { Id: 238573, PropertyName: "value", Value: "ARTICLE 14: MISCELLANEOUS PROVISIONS", TagName: "Label0_0_value" },
        { Id: 914338, PropertyName: "value", Value: "14.1. Non-Waiver", TagName: "Label0_0_value" },
        {
          Id: 285179,
          PropertyName: "value",
          Value:
            "The parties shall transfer to each other the minimum category of personal data necessary for the performance of this contract using secure methods. These data shall only be used for purposes other than the performance of the contract if the respective party's representative is separately informed and in accordance with legal reasons prescribed by legislation. The parties are obliged to keep these data in secure environments and to destroy them within a maximum period of ten years in accordance with storage and disposal policies starting from the termination of the contract. In case of non-compliance, each party shall be responsible for its own unlawful actions.",
          TagName: "Label0_1_value"
        },
        { Id: 915207, PropertyName: "value", Value: "14.2. Severability", TagName: "Label0_0_value" },
        {
          Id: 578764,
          PropertyName: "value",
          Value:
            "The parties shall securely transfer to each other the minimum category of personal data required for the performance of this Agreement. These data shall be used only outside the performance of the contract if expressly informed by the party representative and within the legal reasons stipulated by the legislation. The parties shall be obliged to keep these data in secure environments and to destroy them in accordance with the storage and destruction policies within at most ten years from the termination of the contract. In cases to the contrary, each party shall be responsible for its unlawful actions.",
          TagName: "Label0_1_value"
        },
        { Id: 90, PropertyName: "value", Value: "14.3. Changes", TagName: "Label0_0_value" },
        {
          Id: 827875,
          PropertyName: "value",
          Value:
            "Both parties shall securely transfer to each other the minimum category of personal data necessary for the performance of this Agreement. These data shall be used only for the performance of the Agreement and, if required by law, after separate notification of the party representative. The parties are obliged to store these data in secure environments and to destroy them in accordance with the storage and destruction policies within ten years from the termination of the Agreement. In case of non-compliance, each party shall be responsible for its unlawful actions.",
          TagName: "Label0_1_value"
        },
        { Id: 443554, PropertyName: "value", Value: "14.4. TRANSFER and ASSIGNMENT", TagName: "Label0_0_value" },
        {
          Id: 574723,
          PropertyName: "value",
          Value:
            "The parties shall securely transfer to each other the minimum category of personal data required for the performance of this Agreement. These data may only be used for purposes other than the performance of the contract if the respective representative of the party is additionally informed and in accordance with the legal reasons foreseen by the legislation. The parties undertake to keep these data in secure environments and to destroy them in accordance with storage and destruction policies within a maximum of ten years from the termination of the contract. Otherwise, each party shall be responsible for its unlawful actions.",
          TagName: "Label0_1_value"
        },
        { Id: 207158, PropertyName: "value", Value: "14.5. EVIDENCE AGREEMENT", TagName: "Label0_0_value" },
        {
          Id: 874950,
          PropertyName: "value",
          Value:
            "The Parties acknowledge and agree that in the performance, interpretation, or in the event of any disputes arising from or in connection with this Agreement, the books, records, email correspondences, and computer records of both parties shall constitute exclusive evidence in accordance with Article 193 of the Code of Civil Procedure (HMK).",
          TagName: "Label0_1_value"
        },
        { Id: 452086, PropertyName: "value", Value: "14.6. FORCE MAJEURE", TagName: "Label0_0_value" },
        {
          Id: 836334,
          PropertyName: "value",
          Value:
            "This agreement shall be terminated without any liability if the performance of the obligations becomes impossible due to reasons beyond the control of the parties and not attributable to their own will, as specified in the Turkish Code of Obligations No. 6098. Such reasons may include, but are not limited to, earthquakes, floods, states of emergency, terrorism, war, the declaration of a global pandemic by the World Health Organization, and the subsequent declaration of a national epidemic by state authorities or official institutions of the country or economic crises officially recognized by the Istanbul Chamber of Commerce, strikes, lockouts, etc., provided that the force majeure situation lasts for more than three (3) months, and the parties have fulfilled their obligations until that date.",
          TagName: "Label0_1_value"
        },
        { Id: 344494, PropertyName: "value", Value: "ARTICLE 15: NOTIFICATION ADDRESS", TagName: "Label0_0_value" },
        {
          Id: 803796,
          PropertyName: "value",
          Value:
            "The parties agree that in case of disputes arising from this Agreement and other matters, notifications made to the addresses specified in Article 1 of this Agreement shall be deemed to have been made to their legal residences. They acknowledge that if they do not notify each other in writing of any address changes within 2 (two) days from the date of the change, the previous addresses shall remain valid. Notices related to default, termination notices, and notices and warnings in response to such matters within the scope of this Agreement shall not be binding unless sent through a notary public.",
          TagName: "Label0_1_value"
        },
        {
          Id: 489652,
          PropertyName: "value",
          Value: "Article 16: TERMINATION OF THE AGREEMENT",
          TagName: "Label0_0_value"
        },
        {
          Id: 96714,
          PropertyName: "value",
          Value:
            "Without prejudice to our rights for excess, in case the Client fails to pay the MeMap software and/or license fee or makes an incomplete payment, or if the Company violates copyright rights, this agreement shall automatically terminate without the need for any other provision, notice, or warning.",
          TagName: "Label0_1_value"
        },
        {
          Id: 351513,
          PropertyName: "value",
          Value: "Article 17: CONTRACT RETENTION PERIOD",
          TagName: "Label0_0_value"
        },
        {
          Id: 539891,
          PropertyName: "value",
          Value:
            "This contract will be retained electronically by the Company for a period of 3 years in accordance with the provisions of the 6698 Personal Data Protection Law, the 6502 Consumer Protection Law, the Distance Contracts Regulation, and other relevant legislation. During this period, the Client will have access to the contract in electronic format.",
          TagName: "Label0_1_value"
        },
        {
          Id: 432846,
          PropertyName: "value",
          Value: "ARTICLE 18: NOTICES AND EVIDENCE AGREEMENT",
          TagName: "Label0_0_value"
        },
        {
          Id: 279393,
          PropertyName: "value",
          Value:
            "This provision states that all correspondence between the parties regarding this Agreement will be conducted via email, except in cases where statutory requirements dictate otherwise. The consultant acknowledges that in case of disputes arising from this Agreement, the Company's official records, commercial records, database, electronic information stored on its servers, and computer records will serve as conclusive and exclusive evidence. This provision is considered as a 'delil sözleşmesi' (evidence agreement) within the meaning of Article 193 of the Code of Civil Procedure. The consultant accepts, declares, and undertakes this.",
          TagName: "Label0_1_value"
        },
        { Id: 433811, PropertyName: "value", Value: "ARTICLE 19: COMPETENT COURT", TagName: "Label0_0_value" },
        {
          Id: 971384,
          PropertyName: "value",
          Value:
            "In the interpretation of this Agreement and in all disputes arising from or related to this Agreement, the courts and enforcement offices of Istanbul (Anadolu and Beykoz) shall have jurisdiction, and Turkish law shall apply. Consumer members acting for non-commercial or non-professional purposes may file their complaints and objections with the Consumer Problems Provincial and District Arbitration Committees or the Consumer Court in the place where the member purchased the goods or services or where their residence is located, within the monetary limits determined by the Ministry of Trade in December each year.",
          TagName: "Label0_1_value"
        }
      ]
    },
    {
      Id: "4322111a-56dc-4006-8ee3-76887faaccb2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 603977, PropertyName: "value", Value: "MeMap ÜYELİK SÖZLEŞMESİ" },
        { Id: 438127, PropertyName: "value", Value: "MADDE 1. TARAFLAR" },
        {
          Id: 401148,
          PropertyName: "value",
          Value:
            'İşbu Danışan Sözleşmesi ("Sözleşme") MeMap web sitesine (memap.com.tr) üye olan fitness danışanı ****************(“Üye”) ile “FitSkor Hizmet Teknolojileri - Merve Yerdelen Mehmet") arasında kurulmuş olup, MeMap’in kullanım koşulları ve tarafların hak ve yükümlülüklerini düzenler.'
        },
        { Id: 984934, PropertyName: "value", Value: "Danışan:" },
        { Id: 637542, PropertyName: "value", Value: "Adı Soyadı: _________" },
        { Id: 571915, PropertyName: "value", Value: "E-Posta Adresi: _________" },
        { Id: 263941, PropertyName: "value", Value: "Telefon: _________" },
        { Id: 183356, PropertyName: "value", Value: "MADDE 2. TANIMLAR" },
        { Id: 854466, PropertyName: "value", Value: "MeMap İnternet Sitesi:" },
        { Id: 770961, PropertyName: "value", Value: "www.memap.com.tr URL adresli internet sitesini ifade eder." },
        { Id: 299385, PropertyName: "value", Value: "MeMap Platformu:" },
        {
          Id: 845058,
          PropertyName: "value",
          Value:
            "Merkezin FitSkor lisanslarının dağıtılması, hizmete ilişkin randevuların yönetilmesi ve danışanların fiziksel uygunluk puanlamasını (FitSkor) takip etmesine dair işlemlerin yapıldığı dijital fitness platformudur."
        },
        { Id: 721473, PropertyName: "value", Value: "MeMap Hizmetleri:" },
        {
          Id: 948908,
          PropertyName: "value",
          Value:
            "Detayları memap.com.tr adresinde bulunan ve MeMap Hizmet Sertifikası’na sahip Eğitmenler tarafından Danışan’a verilen hizmetlerdir."
        },
        { Id: 727260, PropertyName: "value", Value: "MeMap Hizmet Sertifikası:" },
        {
          Id: 734043,
          PropertyName: "value",
          Value:
            "MeMap Fitness Akademisi tarafından, ilgili MeMap Hizmetini verebileceğini sınav ile kanıtlamış ScoreSoft Kullanıcısına verilen sertifikadır. ScoreSoft Kullanıcısının sahip olduğu sertifikalar ve bu sertifikalara istinaden verebileceği MeMap Hizmetleri ilgili ScoreSoft Kullanıcısının profil sayfasında yer alır."
        },
        { Id: 224373, PropertyName: "value", Value: "Üye:" },
        { Id: 464841, PropertyName: "value", Value: "MeMap platformuna kaydolan kişiyi ifade eder." },
        { Id: 534839, PropertyName: "value", Value: "Danışan:" },
        { Id: 655775, PropertyName: "value", Value: "Geçerli FitSkor Lisansına sahip, MeMap üyesini ifade eder." },
        { Id: 90294, PropertyName: "value", Value: "FitSkor:" },
        {
          Id: 401246,
          PropertyName: "value",
          Value:
            "Kişinin yaş ve cinsiyetine göre fiziksel uygunluk seviyesini sayısal olarak ifade eden puanlama sistemidir."
        },
        { Id: 882271, PropertyName: "value", Value: "FitSkor Lisansı:" },
        {
          Id: 539071,
          PropertyName: "value",
          Value:
            "MeMap Dijital Platformuna kaydolan kişilerin, e-posta adreslerine özel, tek kişilik, FitSkor hesaplama ve raporlama amaçlı ücretlendirilen dijital hizmettir."
        },
        { Id: 215169, PropertyName: "value", Value: "ScoreSoft Yazılımı:" },
        {
          Id: 317741,
          PropertyName: "value",
          Value:
            "ScoreSoft Lisansı ile kullanılabilen, fitness bileşenlerini geliştirmek için, kişilerin fiziksel ve fizyolojik verilerini değerlendirerek kişinin yaşa ve cinsiyete göre fiziksel uygunluk notunu belirleyen ve buna bağlı olarak tavsiye niteliğinde egzersiz stratejisi oluşturan dijital içerikli hizmet sunan bir yazılımdır."
        },
        { Id: 951355, PropertyName: "value", Value: "ScoreSoft Lisansı:" },
        {
          Id: 805867,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcıları için, e-posta adresine özel, tek kişilik ScoreSoft Yazılımını kullanma amaçlı yıllık ücretlendirilen dijital hizmettir."
        },
        { Id: 340401, PropertyName: "value", Value: "ScoreSoft Kullanıcısı:" },
        {
          Id: 204177,
          PropertyName: "value",
          Value: "FitSkor Lisanslarını sağlayan, geçerli ScoreSoft Lisansına sahip kullanıcıyı ifade eder."
        },
        { Id: 766369, PropertyName: "value", Value: "MeMap Eğitmeni:" },
        {
          Id: 416973,
          PropertyName: "value",
          Value:
            "Kendisi veya merkez tarafından satın alınmış geçerli ScoreSoft Lisansına sahip, MeMap Fitness Akademisi tarafından belirlenen eğitim kriterlerini karşılayan ve MeMap Hizmetlerini verebileceğini sınav ile ispatlayarak halka açık MeMap platformunda yer almayan ve FitSkor Lisanslarını sağlayan eğitmeni ifade eder."
        },
        { Id: 298844, PropertyName: "value", Value: "MeMap Platform Eğitmeni:" },
        {
          Id: 456217,
          PropertyName: "value",
          Value:
            "Kendisi tarafından satın alınmış geçerli ScoreSoft Lisansına sahip, MeMap Fitness Akademisi tarafından belirlenen eğitim kriterlerini karşılayan ve MeMap Hizmetlerini verebileceğini sınav ile ispatlayarak halka açık MeMap platformunda yer alan ve FitSkor Lisanslarını sağlayan eğitmeni ifade eder."
        },
        { Id: 312670, PropertyName: "value", Value: "MeMap Elçisi:" },
        {
          Id: 897001,
          PropertyName: "value",
          Value:
            "MeMap felsefesini, standartlarını ve iş modelini lisans sağlayıcılarına aktaran ve bu sağlayıcılar ile MeMap arasındaki iş ilişkilerini yürüten kişidir."
        },
        { Id: 10391, PropertyName: "value", Value: "Merkez Lisansı:" },
        {
          Id: 903305,
          PropertyName: "value",
          Value:
            "FitSkor Lisanslarını dağıtmak ve MeMap Hizmetlerini verebilmek için MeMap Dijital Platformunu kullanma hakkını temin etmek amaçlı yıllık ücretlendirilen dijital hizmettir."
        },
        { Id: 313861, PropertyName: "value", Value: "ScoreSoft Merkezi:" },
        {
          Id: 875657,
          PropertyName: "value",
          Value: "Geçerli Merkez Lisansına sahip, FitSkor Lisanslarını sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 417259, PropertyName: "value", Value: "MeMap Merkezi:" },
        {
          Id: 410563,
          PropertyName: "value",
          Value:
            "Geçerli Merkez Lisansına sahip, MeMap Hizmetlerini sunmak üzere MeMap Merkez standartlarını karşılamış ve bünyesinde minimum bir MeMap Eğitmeni barındıran, ancak halka açık MeMap Platformunda yer almayan ve MeMap FitSkor Lisanslarını sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 966885, PropertyName: "value", Value: "MeMap Platform Merkezi:" },
        {
          Id: 777164,
          PropertyName: "value",
          Value:
            "Geçerli Merkez Lisansına sahip, MeMap Hizmetlerini sunmak üzere MeMap Merkez standartlarını karşılamış ve bünyesinde minimum bir MeMap Eğitmeni barındıran, ancak halka açık MeMap Platformunda yer alan ve MeMap FitSkor Lisanslarını sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 6675, PropertyName: "value", Value: "Kurum:" },
        {
          Id: 316475,
          PropertyName: "value",
          Value:
            "Bir ya da birden fazla MeMap Merkezi ile anlaşmalı FitSkor Lisanslarını sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 505909, PropertyName: "value", Value: "MeMap Fitness Akademisi:" },
        {
          Id: 653409,
          PropertyName: "value",
          Value:
            "www.yeninesilfitnessegitimi.com URL adresli, eğitmenlerin standardize edilmiş MeMap Hizmetlerini verebilmek amaçlı eğitimler alabildikleri çevrimiçi Fitness Eğitim Platformunu ifade eder."
        },
        { Id: 828917, PropertyName: "value", Value: "Egzersiz Reçetelendirme:" },
        {
          Id: 968990,
          PropertyName: "value",
          Value:
            "FitSkor’u hesaplanmış kişilere, haftada egzersize ayrılacak gün ve süresine bağlı, kişiye özel tavsiye niteliğindeki fitness bileşenlerinin önceliklendirilirek sıralanışını ifade eder."
        },
        { Id: 130226, PropertyName: "value", Value: "Veri:" },
        {
          Id: 941953,
          PropertyName: "value",
          Value:
            "ScoreSoft yazılımının hesaplamalar yapabilmesi için danışan ve/veya eğitmen tarafından sağlanan bilgilerdir."
        },
        { Id: 314061, PropertyName: "value", Value: "Know-How:" },
        {
          Id: 106537,
          PropertyName: "value",
          Value:
            "İşbu Sözleşme ile ScoreSoft Kullanıcısının kullanımına tahsis edilen alanlarda bulunduğu şekli ile ScoreSoft Yazılımı, MeMap’in işletme sistemleri ve metotlarını ifade eder."
        },
        { Id: 505817, PropertyName: "value", Value: "İşbu sözleşme kapsamında" },
        { Id: 80865, PropertyName: "value", Value: "ScoreSoft Kullanıcısı, MeMap Eğitmeni" },
        { Id: 334301, PropertyName: "value", Value: "ve" },
        { Id: 194666, PropertyName: "value", Value: "MeMap Platform Eğitmeni" },
        { Id: 802412, PropertyName: "value", Value: "birlikte kullanıldığında" },
        { Id: 46902, PropertyName: "value", Value: "“ScoreSoft Kullanıcısı”" },
        { Id: 890712, PropertyName: "value", Value: "olarak anılacaktır." },
        { Id: 993129, PropertyName: "value", Value: "İşbu sözleşme kapsamında" },
        { Id: 266240, PropertyName: "value", Value: "MeMap Eğitmeni" },
        { Id: 132930, PropertyName: "value", Value: "ve" },
        { Id: 886851, PropertyName: "value", Value: "MeMap Platform Eğitmeni" },
        { Id: 90836, PropertyName: "value", Value: "birlikte kullanıldığında" },
        { Id: 446552, PropertyName: "value", Value: '"MeMap Eğitmeni”' },
        { Id: 523592, PropertyName: "value", Value: "olarak anılacaktır." },
        { Id: 793162, PropertyName: "value", Value: "İşbu sözleşme kapsamında" },
        { Id: 393853, PropertyName: "value", Value: "MeMap Merkezi" },
        { Id: 262084, PropertyName: "value", Value: "ve" },
        { Id: 877119, PropertyName: "value", Value: "MeMap Platform Merkezi" },
        { Id: 707610, PropertyName: "value", Value: "birlikte kullanıldığında" },
        { Id: 457575, PropertyName: "value", Value: '"MeMap Merkezleri"' },
        { Id: 696133, PropertyName: "value", Value: "olarak anılacaktır." },
        { Id: 228501, PropertyName: "value", Value: "İşbu sözleşme kapsamında" },
        {
          Id: 638543,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcısı, MeMap Eğitmeni, MeMap Platform Eğitmeni, ScoreSoft Merkezi, MeMap Merkezi, MeMap Platform Merkezi"
        },
        { Id: 310194, PropertyName: "value", Value: "ve" },
        { Id: 569224, PropertyName: "value", Value: "Kurum" },
        { Id: 70002, PropertyName: "value", Value: "birlikte kullanıldığında" },
        { Id: 777026, PropertyName: "value", Value: '"FitSkor Lisans Sağlayıcıları"' },
        { Id: 454389, PropertyName: "value", Value: "olarak anılacaktır." },
        { Id: 635319, PropertyName: "value", Value: "MADDE 3. SÖZLEŞMENİN KONUSU" },
        {
          Id: 116826,
          PropertyName: "value",
          Value:
            "İşbu sözleşmenin konusu, Üyenin MeMap İnternet Sitesinde hesap açmasına müteakip internet sitesi genel kullanım koşullarının belirlenmesi, Üye tarafından MeMap İnternet Sitesi aracılığıyla FitSkor Lisansının satın alınması, bu satın alma işlemi sonunda Üyenin “Danışan” sıfatını kazanması ve satın alınan FitSkor Lisansı hizmetine dair Tarafların karşılıklı hak ve yükümlülüklerin tespitidir."
        },
        { Id: 851661, PropertyName: "value", Value: "MADDE 4. MEMAP PLATFORMU HAKKINDA GENEL BİLGİLENDİRME" },
        {
          Id: 361878,
          PropertyName: "value",
          Value:
            "MeMap, fiziksel uygunluk ile ilgili hizmetler sunan dijital fitness platformudur. MeMap Platformu’na üye olan kişiler, MeMap hizmetlerinden yararlanabilmek için FitSkor® lisansı edinirler. FitSkor® lisansına sahip üyeler danışan olarak adlandırılırlar. Danışanlar MeMap hizmetlerini, ScoreSoft® yazılımını kullanan MeMap Eğitmenleri tarafından, ilgili MeMap Eğitmeninin bağlı olduğu MeMap Merkezlerinden alırlar."
        },
        {
          Id: 730278,
          PropertyName: "value",
          Value:
            "MeMap Platformunda yer alan MeMap Eğitmenleri, MeMap Fitness Akademisi tarafından belirlenmiş kriterleri karşılayan ve MeMap hizmetlerini verebileceğini sınav ile ispatlamış eğitmenlerdir. MeMap Platformu’nda yer alan merkezler ise MeMap merkez standartlarını karşılayan ve bünyesinde en az bir MeMap Eğitmeni bulunduran merkezlerdir. MeMap Eğitmenleri ve Merkezleri, aynı zamanda FitSkor® lisanslarının sağlayıcılarıdır."
        },
        {
          Id: 687769,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcısı ise FitSkor Lisanslarını sağlayan, geçerli ScoreSoft Lisansına sahip kullanıcıyı ifade eder. ScoreSoft Kullanıcısının ScoreSoft Lisansına sahip olmasına rağmen MeMap Hizmetlerini, MeMap Fitness Akademisi tarafından belirlenmiş kriter ve standartlara göre verme taahhüdü bulunmamaktadır. Bu bakımdan ScoreSoft Kullanıcısı MeMap Dijital Platformu’nda yer almamaktadır ve MeMap Hizmetlerini verememektedir."
        },
        {
          Id: 935893,
          PropertyName: "value",
          Value:
            "ScoreSoft yazılımı, kişinin fiziksel ve fizyolojik verilerini değerlendirerek, yaşına ve cinsiyetine göre fiziksel uygunluk notunu (FitSkor®) hesaplayan ve buna bağlı olarak tavsiye niteliğinde egzersiz stratejisi oluşturan bir dijital içerikli hizmet yazılımıdır."
        },
        {
          Id: 836061,
          PropertyName: "value",
          Value:
            "ScoreSoft yazılımının tasarlandığı amaca uygun hesaplamalar yapabilmesi için, danışanın platforma kayıt esnasında girilen yaş ve cinsiyet bilgisi kullanılır. Danışan tarafından sağlanan diğer sağlık bilgileri danışanın ibraz ettiği raporlar ve beyanına istinaden eğitmen tarafından kaydedilir. Bunlar dışında hesaplamalar için kullanılan tüm veriler, eğitmenin yaptığı ölçümlerden elde edilir. Eğitmen, vücut kompozisyonu, kas kuvveti ve esneklik ölçümlerinden elde ettiği fiziksel veriler ile kardiyorespiratuar kapasite ölçümlerinden elde ettiği fizyolojik verileri yazılıma girer. Tüm veriler girildikten sonra yazılım, danışanın FitSkor®’unu hesaplar. Egzersiz reçetelendirme stratejisi, danışanın FitSkor®’una, egzersize ayıracağı haftalık gün sayısına ve egzersiz süresine göre tamamen kişiselleştirilmiş olarak oluşturulur."
        },
        { Id: 479269, PropertyName: "value", Value: "MADDE 5: ŞİRKET’İN HAK VE YÜKÜMLÜLÜKLERİ" },
        { Id: 424278, PropertyName: "value", Value: "5.1." },
        {
          Id: 258428,
          PropertyName: "value",
          Value:
            "Şirket, MeMap’e üye olan Danışana daha iyi hizmet verebilmek amacıyla Danışanın, MeMap Platformu’na sunmuş olduğu tüm kişisel verilerini 6698 sayılı Kişisel Verilerin Korunması Kanunu’na uygun şekilde işleyeceğini kabul, beyan ve taahhüt eder."
        },
        { Id: 33657, PropertyName: "value", Value: "Bu doğrultuda işbu sözleşmenin ekinde yer alan" },
        { Id: 478975, PropertyName: "value", Value: "MeMap İnternet Sitesi Aydınlatma Metni" },
        { Id: 633490, PropertyName: "value", Value: "(EK-1) sözleşmenin ayrılmaz bir parçası olarak kabul edilir." },
        { Id: 49155, PropertyName: "value", Value: "5.2." },
        {
          Id: 912769,
          PropertyName: "value",
          Value:
            "FitSkor Lisansının satın alınmasının akabinde Danışan’a ait kişisel veriler satın alma öncesinde Üyenin bilgisine sunulan"
        },
        { Id: 256681, PropertyName: "value", Value: "Kişisel Verilerin Paylaşımına İlişkin Aydınlatma Metni" },
        { Id: 100804, PropertyName: "value", Value: "doğrultusunda ScoreSoft Kullanıcısı ile paylaşılır ve" },
        {
          Id: 704848,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcısı tarafından sisteme girilir, Danışan’a ait fiziksel uygunluk notunu (FitSkor) ve buna bağlı belirlenmiş bireysel egzersiz reçetelendirme tavsiyesi sağlanır. Şirket’in sunduğu bilgi ve içerik, tavsiye niteliğinde olup, buna ilişkin sair uygulama, değerlendirme ve tespitten dolayı Şirket sorumlu tutulamaz. Bu doğrultuda, Üye ve/veya Danışan, ScoreSoft Yazılımı ve İnternet sitesi içindeki her türlü bilgi, egzersiz ve içeriğin tavsiye niteliğinde olduğunu ve hiçbir hastalık veya sağlık durumunun teşhis ve tedavisini sağlamadığını, bu içerikleri kullanıp kullanmaması konusundaki bütün takdir ve sorumluluğun kendisinde olduğunu gayri kabili rücu kabul, beyan ve taahhüt eder. Şirket, ScoreSoft Kullanıcısı önerisi doğrultusunda Danışan’ın seçerek uyguladığı programla ilgili olarak sorumlu tutulamaz."
        },
        { Id: 801589, PropertyName: "value", Value: "5.3." },
        {
          Id: 943647,
          PropertyName: "value",
          Value:
            "Şirket, Danışanın ScoreSoft Yazılımından faydalanmak için ScoreSoft Kullanıcısına vermiş olduğu yazılı ve/veya sözlü olarak bildirilen verileri yazılım içerisinde bulundurur. Danışan, kendi beyanına dayalı olarak vermiş olduğu ilgili verilerde herhangi bir farklılık ya da hata bulunması halinde bundan doğabilecek tüm sorumluluğun kendisine ait olduğu gayri kabili rücu kabul, beyan ve taahhüt eder."
        },
        { Id: 777698, PropertyName: "value", Value: "5.4." },
        {
          Id: 654518,
          PropertyName: "value",
          Value:
            "Şirket, Üye ve/veya Danışmana daha iyi bir hizmet verebilmek ya da Üyeyi ve Danışmanı çeşitli kampanyalara davet edebilmek amacıyla Üyenin ve Danışmanın kişisel bilgilerini (ad ve soyad, elektronik posta adresi, telefon numarası) talep edebilir ve bu amaçla Üyeye ve Danışmana anket ya da bilgi formu doldurtabilir. Şirket sunucu ve bilgisayarlarında toplanan bu bilgiler, dönemsel kampanya çalışmaları, kullanıcı profillerine yönelik özel promosyon faaliyetlerinin kurgulanması ve istenmeyen iletilere yönelik Üye sınıflandırma çalışmalarında Şirket tarafından kullanılabilecektir."
        },
        { Id: 345340, PropertyName: "value", Value: "Şirket bu faaliyetlerini" },
        { Id: 975923, PropertyName: "value", Value: "İnternet Sitesi Aydınlatma Metni" },
        { Id: 648065, PropertyName: "value", Value: "ile" },
        { Id: 824462, PropertyName: "value", Value: "Ticari İletişim ve Ticari Elektronik İleti Onay Formu" },
        {
          Id: 467870,
          PropertyName: "value",
          Value: "'nun (EK-2) onaylanması suretiyle yürüteceğini beyan ve taahhüt eder."
        },
        { Id: 372223, PropertyName: "value", Value: "5.5." },
        {
          Id: 355784,
          PropertyName: "value",
          Value:
            "Şirket, mücbir sebepler, bağlantı sorunları, internet kesintileri, tedarikçilerden kaynaklanan nedenler, kullanım hataları ve Üyenin ScoreSoft Yazılımı ya da İnternet Sitesi’ni kullandığı cihaza veya cihazın işletim sistemi versiyonuna bağlı olan nedenler ile ScoreSoft Yazılımı’nda ve İnternet Sitesi’nde oluşabilecek kesinti ya da aksaklıklardan sorumlu değildir."
        },
        { Id: 708518, PropertyName: "value", Value: "5.6." },
        {
          Id: 47225,
          PropertyName: "value",
          Value:
            "Şirket, uygun gördüğü zamanlarda önceden hiçbir bildirimde bulunmadan ScoreSoft Yazılımını geliştirebilir, değiştirebilir ve bazı hizmetler için güncelleştirmeler yapabilir, yazılımla ilgili görsel, içeriksel ve uygulamaya yönelik değişikliğe gidebilir. Şirket, Üye’nin FitSkor Lisansını aldıktan sonra Danışan olmasına müteakip Danışan’ın MeMap üyeliği için öngördüğü üyelik bedelini, yazılım içi fiyatları, kampanya ve paketleri her zaman tek taraflı olarak değiştirebileceğini, ek ödemeler oluşabileceğini, güncel değişikliklerden yararlanabilmesi için güncellemeleri düzenli olarak yüklemesi ve bunun için bedel ödemesi gerektiğini kabul, beyan ve taahhüt eder. Söz konusu yazılım güncellemelerini kabul edip yükleyen üyeler hizmet almaya devam eder. Bu doğrultuda, işbu sözleşmenin 13. maddesinde yer alan hükümler saklıdır."
        },
        { Id: 860239, PropertyName: "value", Value: "5.7." },
        {
          Id: 292475,
          PropertyName: "value",
          Value:
            "Şirket hizmetlerin sürekliliğini sağlamak için, işbu Sözleşme üzerinde ayrıca herhangi bir bildirimde bulunmaksızın tek taraflı olarak değişiklik yapabilir ve her zaman herhangi bir gerekçe göstermeksizin, tek taraflı olarak verdiği hizmeti sürekli veya geçici olarak durdurma, hizmetin içeriğini değiştirme veya iptal etme hakkına sahiptir. Şirket, herhangi bir değişiklik olması halinde güncel Üyelik Sözleşmesi’ni aynı internet bağlantısı altında yeni tarih güncellemesi ile ScoreSoft Yazılımı ve İnternet Sitesinde yayınlayacak, gerek görmesi halinde elektronik posta ile Danışana bildirecektir. Güncel Kullanıcı Sözleşmesi, ScoreSoft Yazılımı ve İnternet Sitesi'nde yayınlandığı andan itibaren geçerli olacak ve İnternet Sitesi’nin ve/veya ScoreSoft Yazılımının kullanımı o andan itibaren yenilenmiş Üyelik Sözleşmesi’ne tabi olacaktır."
        },
        { Id: 149767, PropertyName: "value", Value: "5.8." },
        {
          Id: 49300,
          PropertyName: "value",
          Value:
            "ScoreSoft Yazılımı ve İnternet Sitesine üyelik kişiye özel ve bireysel kullanım içindir. Şirket, ScoreSoft Yazılımının veya İnternet Sitesi’nin işbu Sözleşme’de yer alan maddelere aykırı olarak kullandığını tespit etmesi durumunda üyeliği durdurabilir, askıya alabilir ya da tamamen sonlandırabilir. Bu halde Üye Şirket’ten herhangi bir isim altında hiçbir talepte bulunamayacağını kabul, beyan ve taahhüt etmiştir."
        },
        { Id: 688133, PropertyName: "value", Value: "5.9." },
        {
          Id: 127201,
          PropertyName: "value",
          Value:
            "Şirket, işbu Sözleşme kapsamındaki hizmetin Merkez’e bağlı MeMap Eğitmeni tarafından verilmesi halinde, Merkez’e atfedilen yükümlülükler bakımından Merkez’in münhasır sorumluluğuna halel getirmeksizin, takdir hakkı münhasıran kendisine ait olmak üzere, kendisi veya yetkilendirdiği MeMap Elçisi tarafından işbu Sözleşmeye uygunluğunu hiçbir ihbarda bulunmaksızın ve ek yetkilendirmeye gerek olmaksızın her zaman denetleme hak ve yetkisine sahiptir."
        },
        { Id: 944658, PropertyName: "value", Value: "MADDE 6: ÜYE VE DANIŞAN’NIN HAK VE YÜKÜMLÜLÜKLERİ" },
        { Id: 424193, PropertyName: "value", Value: "6.1." },
        {
          Id: 390410,
          PropertyName: "value",
          Value:
            "Danışan, MeMap’e üye olurken verdiği e-posta adresi kendisine ait ve aktif bir hesap olmalıdır. Danışan sistemsel bir arıza olmadıkça yalnızca bir e-posta adresini yazılıma tanıtabilir. Üyelikle ilgili tüm aktivasyon, bilgilendirme ve yazışmalarda sisteme üye olurken verilen e-posta adresi iletişim adresi olarak esas alınır. İletişim adresinin yanlış verilmesinden dolayı meydana gelecek gecikmelerden ve olası, doğrudan ve/veya dolaylı zararlardan Şirket sorumlu tutulamaz.  E-posta adresleri ile şifrelerin korunması, kopyalanması ya da çalınmasıyla ilgili oluşabilecek zararlardan münhasıran Danışan sorumludur. İşbu nedenle, oluşabilecek zararlardan dolayı Şirkete karşı rücu hakkından feragat ettiğini kabul, beyan ve taahhüt eder."
        },
        { Id: 850069, PropertyName: "value", Value: "6.2." },
        {
          Id: 675255,
          PropertyName: "value",
          Value:
            "Üye, FitSkor Lisansını satın almasına müteakip kendi belirlediği şifre ile üyelik süresi boyunca söz konusu lisansı kullanabilir ve Danışan sıfatına haiz olur. Danışan, internet sitesine kaydettirdiği kişisel verilerini ve bunların değerlendirilmesi sonucunda oluşturulan fiziksel uygunluk sonuçlarını görüntüleyebilir. Danışan, kendi beyanına dayalı olarak ilettiği kişisel verilerin doğru olduğunu kabul, beyan ve taahhüt eder. Danışan, kişisel verileriyle ilgili bir hata veya eksiklik gördüğünde ivedi olarak ScoreSoft Kullanıcısına bildirmeli ve düzeltilmiş ilgili kişisel verinin sisteme kaydını temin etmelidir. Danışan, bilgi ve/veya kişisel verilerinin eksik veya gerçeğe uygun olarak doldurulmaması durumundan kaynaklanacak her türlü zarardan kendisi sorumludur."
        },
        { Id: 737916, PropertyName: "value", Value: "6.3." },
        {
          Id: 953367,
          PropertyName: "value",
          Value:
            "Danışan, MeMap platformunda yer alan MeMap Eğitmeninden randevu alabilir. İşbu sözleşmenin 7.4. hükümleri uyarınca Danışan randevusunu iptal etme hakkına sahiptir."
        },
        { Id: 893797, PropertyName: "value", Value: "6.4." },
        {
          Id: 21597,
          PropertyName: "value",
          Value:
            "Danışan kendi iradesiyle seçerek randevu aldığı ScoreSoft Kullanıcısına, işbu sözleşmeye konu hizmetin ifa edilmesi amacıyla sınırlı şekilde işlenmesi gereken kişisel verilerini (ScoreSoft Yazılımı üzerinden)"
        },
        { Id: 88982, PropertyName: "value", Value: "Kişisel Verilerin Paylaşımına İlişkin Aydınlatma Metni" },
        { Id: 385105, PropertyName: "value", Value: "uyarınca paylaştığı kabul etmektedir." },
        {
          Id: 390945,
          PropertyName: "value",
          Value: "Danışan, ScoreSoft Kullanıcısını dilediği zaman değiştirme hakkına sahiptir."
        },
        { Id: 670380, PropertyName: "value", Value: "6.5." },
        {
          Id: 846703,
          PropertyName: "value",
          Value:
            "Danışan, sözleşme süresince ve sözleşme sona erdikten sonra, Şirket ve/veya Şirket’in işbirlikçileri ve/veya iştirakleri tarafından gönderilecek faks, elektronik posta, kısa mesaj gibi sair iletişim vasıtaları ile bilgilendirme, pazarlama ve/veya reklam amacıyla bildirimler almayı Ticari İletişim ve Ticari Elektronik İleti Onay Formu’nu onaylamak suretiyle kabul eder. Danışan, işbu bilgilendirme sisteminden çıkma talebini Şirket’in info@memap.com.tr adresine gönderecektir. İşbu e-postanın Şirkete ulaşmasından itibaren 48 saat içinde Şirket bilgilendirme mesajlarına son verilecektir."
        },
        { Id: 873551, PropertyName: "value", Value: "6.6." },
        {
          Id: 646345,
          PropertyName: "value",
          Value:
            "Üye olunmasına müteakip FitSkor Lisans alımıyla birlikte Danışan üyeliğinin aktifleştirilmesinden itibaren Şirket, tahsil edilen ücret karşılığı olan bir adet FitSkor hesaplama ve raporlama hizmeti sunmaktadır."
        },
        { Id: 411245, PropertyName: "value", Value: "6.7." },
        {
          Id: 233984,
          PropertyName: "value",
          Value:
            "Danışanlara üyelik tarihinden itibaren bir yıl boyunca platform kullanımı Şirket tarafından ücretsiz olarak verecektir."
        },
        { Id: 77009, PropertyName: "value", Value: "6.8." },
        {
          Id: 20668,
          PropertyName: "value",
          Value:
            "Sözleşme konusu hizmetten Merkez aracılığıyla faydalanan Danışan, işbu sözleşmenin 5.9. maddesi uyarınca Şirketin kendisi veya yetkilendirdiği MeMap Elçisi tarafından Merkez üzerinde denetim yetkisi olduğunu kabul eder."
        },
        { Id: 320547, PropertyName: "value", Value: "MADDE 7: SCORESOFT KULLANICISI VE FİTSKOR LİSANSI" },
        { Id: 842291, PropertyName: "value", Value: "7.1." },
        {
          Id: 177261,
          PropertyName: "value",
          Value: "Danışan’ın FitSkor Lisansını kullanma ve yönetme hakkı, lisans ücretini ödeyen Danışan’a aittir."
        },
        { Id: 303802, PropertyName: "value", Value: "7.2." },
        {
          Id: 663202,
          PropertyName: "value",
          Value:
            "Danışan, ScoreSoft Kullanıcısı vasıtası ile FitSkor Lisansı elde etmiş ise işbu lisans ancak ilgili ScoreSoft Kullanıcısı ile çalıştığı süre boyunca geçerli olacaktır. Başkaca bir deyişle, Şirket’e herhangi bir üyelik ücreti ödemeksizin, Merkez ya da ScoreSoft Kullanıcısı vasıtası ile sistemden yararlanan Danışan, Merkez ya da ScoreSoft Kullanıcısının sisteminden çıkmasıyla beraber otomatik olarak üyelikten çıkartılacaktır."
        },
        { Id: 462734, PropertyName: "value", Value: "7.3." },
        {
          Id: 557563,
          PropertyName: "value",
          Value:
            "FitSkor Lisansının satın alınmasına müteakip Danışan sıfatına haiz olan Üye, lisans süresi boyunca 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamındaki haklarını Şirket’ten talep edebilir."
        },
        { Id: 453138, PropertyName: "value", Value: "7.4." },
        {
          Id: 120197,
          PropertyName: "value",
          Value:
            "Danışan, ScoreSoft Yazılımına gireceği kişisel verilerini sistem üzerinden kendisinin yönetmesini istemesi halinde ek bedel ödeyerek ScoreSoft Lisansına sahip olması gerektiğini kabul eder."
        },
        { Id: 941989, PropertyName: "value", Value: "7.5." },
        {
          Id: 632695,
          PropertyName: "value",
          Value:
            "FitSkor Lisansına sahip olan Danışan, Şirket’ten kendisi ile ilgili olarak saklanan kişisel verilerle ilgili haklarını 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesi kapsamında talep edebilir."
        },
        { Id: 861235, PropertyName: "value", Value: "MADDE 8: SATIN ALMA VE ÖDEMELER" },
        { Id: 645917, PropertyName: "value", Value: "8.1." },
        { Id: 484102, PropertyName: "value", Value: "Danışan," },
        { Id: 772691, PropertyName: "value", Value: "memap.com.tr" },
        { Id: 608902, PropertyName: "value", Value: "sitesine girerek, üyelik işlemleri için gerekli tüm bilgileri" },
        {
          Id: 159709,
          PropertyName: "value",
          Value:
            "tamamladıktan sonra satın alma butonunu tıklayıp ödeme bilgilerini girerek FitSkor Lisansını kullanma hakkını satın alır. Danışan tarafından satın alma gerçekleştirildikten sonra, satın alma işleminin başarıyla gerçekleştiğini gösterir bilgilendirme maili Şirket tarafından Danışana gönderilecektir."
        },
        { Id: 224414, PropertyName: "value", Value: "8.2." },
        {
          Id: 791905,
          PropertyName: "value",
          Value:
            "Üyelik ücretleri peşin veya taksitle ödenebilir. Ödemelere ilişkin şartlar, Danışanların seçmiş olduğu Ödeme Metodu, kredi kartı sağlayıcıları veya finansal kuruluşlarıyla aralarındaki sözleşme hükümlerine bağlı olacaktır. Şirket; MeMap hizmetlerinin kullanımı karşılığında ödeme hesabı yoluyla Danışana fatura kesmek için bir üçüncü şahıs Ödeme İşlemcisi kullanabilir. Ödeme süreci, işbu Sözleşmeye ek olarak Ödeme İşlemcisinin öngördüğü hüküm, koşul ve gizlilik şartlarına tabi olacaktır. Danışan, Ödeme İşlemcisi sebebiyle meydana gelebilecek dolaylı ve/veya doğrudan zararlara ilişkin Şirket’e karşı hiçbir hak talebinde bulunmayacağını kabul, beyan ve taahhüt eder. Danışan, seçilmiş Ödeme Metodu’ nu kullanarak ödeme yapmayı kabul eder. Talep gönderilmiş veya ödeme yapılmış olsa dahi, Danışan; Şirket’in herhangi bir hata veya sorunu düzeltme hakkını saklı tuttuğunu kabul ve beyan eder. Danışanın Ödeme Sağlayıcısıyla arasındaki anlaşma hükümleri Ödeme Metoduna uygulanacaktır. Danışan, yapmış olduğu işlemleri Şirket’in işlemden hemen sonra, fatura dönemleri boyunca ya da dönem sonunda topluca faturalandırabileceğini kabul eder."
        },
        { Id: 914991, PropertyName: "value", Value: "8.3." },
        {
          Id: 555332,
          PropertyName: "value",
          Value:
            "Danışan; fatura adresinde, kredi kartı numarasında veya kredi kartı son kullanma tarihinde değişiklik olması hali ve ödeme metotlarının iptal etmesi (kayıp veya çalınma vb.), onaysız kapanma veya onaysız Danışan adı ya da şifre kullanımı gibi bir güvenlik ihlali hallerinde derhal Şirketi veya ödeme işlemcisini bu konuda bilgilendirmelidir. Danışan gerekli gördüğü takdirde ödeme bilgilerini değiştirebilir."
        },
        { Id: 955139, PropertyName: "value", Value: "8.4." },
        {
          Id: 117219,
          PropertyName: "value",
          Value:
            "Danışanın fatura hesabından çekilecek ödeme miktarı daha önceden alınması için onaylanan miktara nazaran değişime uğrarsa, (vergi miktarlarındaki değişiklik veya yükümlülükten farklı olarak) Danışan, işlemin gerçekleşeceği günden bir gün önce ödeme gününün ve çekilecek miktarın kendisine bildirilmesini isteme hakkına sahiptir."
        },
        { Id: 991906, PropertyName: "value", Value: "8.5." },
        {
          Id: 851,
          PropertyName: "value",
          Value:
            "Şirket; Danışana MeMap Üyeliğinin sona ereceği dönem sonundan 7 gün önce, e-posta göndererek, Danışanın FitSkor Lisansını kullanmaya devam edeceğini ve yeni dönemde faturalandırmaya devam edileceği hususunu yazılı olarak teyit edecektir. Danışanın; Şirket tarafından gönderilen bilgilendirme e-postasına olumsuz geri dönüşü haricinde, Danışan; FitSkor Lisansına bağlı hizmetlerden yararlanmaya devam etmek için açık onay vermiş olduğu kabul, beyan ve taahhüt eder."
        },
        { Id: 417375, PropertyName: "value", Value: "8.6." },
        {
          Id: 666456,
          PropertyName: "value",
          Value:
            "Şirket; Danışanın FitSkor Lisansının sona ereceği tarihe kısa bir süre kala MeMap Platformu üzerinden lisans süresinin sona ereceğine dair kendisine bilgilendirme yapacaktır. Bilgilendirme yapılmasına müteakip, kalan sürede FitSkor Lisansının ödeme yapılarak yenilenmemesi/ uzatılmaması halinde, Danışan FitSkor Lisans süresinin sona erme tarihinden itibaren MeMap platformundan faydalanamayacaktır."
        },
        { Id: 342720, PropertyName: "value", Value: "MADDE 9: DANIŞANIN CAYMA HAKKI" },
        { Id: 480108, PropertyName: "value", Value: "9.1." },
        {
          Id: 617143,
          PropertyName: "value",
          Value:
            "Danışan, 6502 sayılı Tüketicinin Korunması Hakkındaki Kanun hükümleri ve işbu sözleşmenin 23.maddesi gereğince sözleşmenin yürürlüğe girmesinden itibaren 14 gün içerisinde herhangi bir ölçüm ya da sözleşmesel hizmet alınmadığı takdirde, Şirket’in info@memap.com.tr hesabına e-posta göndererek işbu sözleşmeden cayabilir. Şirket, Danışanın cayma hakkını kullandığına ilişkin bildirimin kendisine ulaştığı tarihten itibaren 14 gün içinde Danışana ödemelerini iade etmekle yükümlüdür."
        },
        { Id: 49829, PropertyName: "value", Value: "9.2." },
        {
          Id: 188108,
          PropertyName: "value",
          Value:
            "Danışan cayma hakkını içeren e-mailinin, Şirkete ulaşmamış olması ve/veya geç ulaşmış olması gibi sebeplerden doğacak dolayı/doğrudan zararlarından Şirketi sorumlu tutmayacağını kabul, beyan ve taahhüt eder."
        },
        { Id: 903915, PropertyName: "value", Value: "9.3." },
        {
          Id: 445284,
          PropertyName: "value",
          Value:
            "Şirket’in elektronik ortamda anında ifa edilen hizmetler ve/veya tüketiciye anında teslim edilen gayri maddi mallara ilişkin sözleşmelerden kaynaklanan cayma hakkı istisnasından Mesafeli Sözleşmeler Yönetmeliği madde 15/I/ğ’den gereğince yararlanma hakkı saklıdır."
        },
        { Id: 131003, PropertyName: "value", Value: "MADDE 10: SORUMSUZLUK" },
        { Id: 739963, PropertyName: "value", Value: "10.1" },
        {
          Id: 158098,
          PropertyName: "value",
          Value:
            "ScoreSoft Yazılımı ve İnternet Sitesi içindeki her türlü bilgi, egzersiz ve içerik Danışanın “Egzersiz Katılım Öncesi Sağlık Değerlendirme Rehberi”nde beyan ettiği bilgilere istinaden ortalama olarak herhangi bir tıbbi veya psikolojik rahatsızlığı olmadığı varsayılarak tavsiye niteliğinde hazırlanmıştır. Danışan, bu durumu bilerek FitSkor Lisansını satın almakta ve kullanım öncesi gerek görüyorsa doktorunun onayını almakla yükümlüdür. Şirket, Danışanın tıbbi veya psikolojik rahatsızlığı nedeni ile ortaya çıkacak herhangi bir sonuçtan sorumlu tutulamaz."
        },
        { Id: 798077, PropertyName: "value", Value: "10.2." },
        {
          Id: 61160,
          PropertyName: "value",
          Value:
            "Danışan, FitSkor Lisansı ve İnternet sitesi içindeki her türlü bilgi, egzersiz ve içeriğin tavsiye niteliğinde olduğunu ve hiçbir hastalık veya sağlık durumunun teşhis ve tedavisini sağlamadığını, bu içerikleri kullanıp kullanmaması konusundaki bütün takdir ve sorumluluğun kendisinde olduğunu kabul, beyan ve taahhüt eder. Şirket, Danışan’ın seçerek uyguladığı programla ilgili olarak sorumlu tutulamaz. Bu doğrultuda, Danışan’ın kişisel verilerini tam ve doğru olarak bildirildiği faraziyesiyle hazırlanan ve ScoreSoft Yazılımı tarafından sunulan içerik ve egzersiz programlarının bir uzmana danışılması ya da uzman eşliğinde uygulanması gerektiğinin kendisine bildirilmiş olduğunu gayri kabili rücu kabul, taahhüt ve beyan eder. ScoreSoft Yazılımı, hastalık veya sağlık durumunun teşhisi veya tedavisini sağlamamaktadır. Danışan, bildirmediği veya eksik ya da yanlış bildirdiği verilerinden kaynaklı rahatsızlık, sakatlık, zafiyet ve benzeri sebeplerden dolayı meydana gelebilecek doğrudan ya da dolaylı zararlardan Şirketin sorumlu olmadığını kabul, beyan ve taahhüt eder."
        },
        { Id: 283749, PropertyName: "value", Value: "10.3." },
        {
          Id: 792819,
          PropertyName: "value",
          Value:
            "Şirket, İnternet Sitesi’ne kaydolan Danışana verdiği bilgilerle ve önerilerle hedeflenen kilo ve vücut ölçülerine ulaşma ya da bunların Danışanın sahip olduğu çevre ve sağlık koşullarına uygunluğuna veya sonuçlarının başarılı olacağına dair garanti vermez. Aksi iddia edilemeyeceği gibi, bu hususa dair Danışan maddi ve manevi herhangi bir zarar talebinde bulunmayacağını gayri kabili rücu kabul, beyan ve taahhüt eder."
        },
        { Id: 420069, PropertyName: "value", Value: "10.4." },
        {
          Id: 599547,
          PropertyName: "value",
          Value:
            "MeMap Platformunun sağladığı hizmetler, bu konuda yeterliliği belirlenmiş “MeMap Hizmet Sertifikası”na sahip MeMap Eğitmenleri tarafından verilebilir. MeMap Hizmet Sertifikasına sahip olmayan ScoreSoft Kullanıcılarının işbu sözleşme kapsamına giren hizmetleri vermeye yetkisi yoktur. Bu kapsamda, Danışanın sözleşmeye konu hizmeti, Şirketin veya hizmeti aldığı Merkezin bilgisi dışında MeMap Hizmet Sertifikasına sahip olmayan ScoreSoft Kullanıcısı ve/veya ScoreSoft Kullanıcısılarından alması halinde oluşabilecek herhangi bir zarara ilişkin Şirket’in sorumluluğu bulunmamaktadır."
        },
        { Id: 407317, PropertyName: "value", Value: "MADDE 11: GİZLİLİK VE KİŞİSEL VERİLERİN KORUNMASI" },
        { Id: 637520, PropertyName: "value", Value: "11.1.Gizlilik" },
        {
          Id: 323102,
          PropertyName: "value",
          Value:
            "Taraflar işbu Sözleşme sona erse dahi, birbirlerinin finansal, ticari, teknolojik vb. bilgi ve sırlarını saklamayı, herhangi bir şekilde elde edecekleri birbirlerine ait dolaylı veya dolaysız gizli bilgiyi kısmen veya tamamen üçüncü şahıslara açıklamayacaklarını, bunlara ilişkin belge ve dokümanları üçüncü şahıslara vermeyeceklerini, tüm gizli bilgilerin kopyalarını, nüshalarını veya sair araçlarını diğer taraf istediği takdirde geri vereceklerini veya bunları yok edeceklerini kabul ve taahhüt ederler. Taraflar gizli bilgilerin kendi çalışanları ya da üçüncü şahıslar tarafından bu Sözleşme’nin hükümlerine aykırı şekilde ifşa edilmemesini ya da dağıtılmamasını temin etmek için kendilerinden beklenen tüm önlemleri almayı kabul ve taahhüt ederler. Taraflar birbirlerinin çalışanlarına, müşterilerine ve muhatap oldukları diğer kategorideki ilgili kişilere dair kişisel verileri yasal işleme şartları ve kuralları doğrultusunda kullanacaklarını, bunların gizliliğine dair her tür idari ve teknik tedbiri alacaklarını taahhüt ederler."
        },
        { Id: 71234, PropertyName: "value", Value: "11.2. Aydınlatma Yükümlülüğü" },
        {
          Id: 419857,
          PropertyName: "value",
          Value:
            "Sözleşme tarafları, birbirlerinin hissedarlarının, ortaklarının, yetkililerinin ve çalışanlarının (hepsi birlikte “taraf temsilcisi” olarak anılır) verilerini faaliyetlerin mevzuata uygun yürütülmesi ve sözleşmenin kuruluşu ve doğrudan ifası amaçları ve şartlarıyla sınırlı olarak işler. Her taraf süreç boyunca kullanılacak ve aktarılacak kişisel verilerle ilgili olarak kendi taraf temsilcisini diğer taraf adına aydınlatmakla ve bu işlemi tevsik etmekle yükümlüdür."
        },
        { Id: 909711, PropertyName: "value", Value: "11.3. Kişisel Verileri İşleme ve Aktarma Kuralları" },
        {
          Id: 66868,
          PropertyName: "value",
          Value:
            "Taraflar sözleşmenin ifası için gerekli olan asgari kategorideki kişisel verileri güvenli yöntemlerle birbirlerine aktarırlar. Bu veriler, sözleşmenin ifası dışında yalnızca taraf temsilcisinin ayrıca aydınlatılması halinde ve mevzuatla öngörülen hukuki sebepler doğrultusunda kullanılır. Taraflar bu verileri güvenli ortamlarda saklamak ve sözleşmenin sona ermesinden itibaren saklama ve imha politikalarına uygun olarak ve en geç on yıl içerisinde imha etmekle yükümlüdürler. Aksi durumlarda her bir taraf kendi hukuka aykırı eyleminden sorumludur."
        },
        { Id: 583706, PropertyName: "value", Value: "MADDE 12: SÜRÜM/VERSİYON DEĞİŞİMİ VE BAKIM" },
        {
          Id: 988127,
          PropertyName: "value",
          Value:
            'Şirket önceden haber vermeksizin sistemde ve/veya yazılı dokümanlarında değişiklikler yapma, sistem ürünlerinin yeni versiyon ve/veya sürümlerini çıkarma hakkına sahiptir, Danışan bu yeni sistemi ve/veya yeni yazılı dokümanları ancak "güncelleme” bedelini ödeyerek güncel sistemi kullanabilir. Şirket isterse güncellemeleri belli sürelerle ücretsiz şekilde yapabilir.'
        },
        { Id: 483821, PropertyName: "value", Value: "MADDE 13: FİKRİ MÜLKİYET HAKLARININ KULLANIMI" },
        {
          Id: 432268,
          PropertyName: "value",
          Value:
            "ScoreSoft Yazılımı ve İnternet Sitesi’nin bütün fikri ve sınai mülkiyet hakları Şirket’e aittir. Yazılımın yapısı, düzeni, tasarımı, görselleri, içinde yer alan her türlü egzersiz, içerik ve bilgi ile yazılımın kaynak kodları Şirket’e ait fikri ve sınai mülkiyet varlıklarını oluşturur. Tüm bu varlıklar, 6769 sayılı Sınai Mülkiyet Kanunu (SMK) ve 5846 sayılı Fikir ve Sanat Eserleri Kanunu’na göre korunmaktadır. İşbu Sözleşme, yazılımın ve/veya içeriğin herhangi bir fikri mülkiyet hakkını ScoreSoft Kullanıcısına ve/veya Danışana vermemekte ve buna ilişkin tüm haklar Şirket tarafından saklı tutulmaktadır. Bu doğrultuda, Şirket, 6769 sayılı Sınai Mülkiyet Kanunu’na (SMK) göre ortaya çıkabilecek marka, patent, faydalı model, ticari sır, süreç, üretim yöntemi, fikir, buluş, taslak, tasarım, model araştırma sonucu, test sonucu, üretim bilgisi, performans bilgisi, plan, metot, spesifikasyon gibi SMK kapsamındaki tüm sınai ürünler (kısaca “Sınai Ürünler”) ve 5846 sayılı Fikir ve Sanat Eserleri Kanunu’na göre ortaya çıkabilecek telif hukukunun kapsamına giren tüm fikri ürünler (kısaca “Fikri Ürünler”) ile bunların mali haklarının ve diğer yan haklarının tamamı üzerinde münhasır ve süresiz olarak tek hak sahibidir. Kullanım lisans hakkı dışında kalan hukuka aykırı kopya eylemi ve işlemler eylemin nev'ine göre cezai yaptırıma sahiptir."
        },
        { Id: 238573, PropertyName: "value", Value: "MADDE 14: MUHTELİF HÜKÜMLER" },
        { Id: 914338, PropertyName: "value", Value: "14.1. FERAGAT OLMAMASI" },
        {
          Id: 285179,
          PropertyName: "value",
          Value:
            "Taraflar sözleşmenin ifası için gerekli olan asgari kategorideki kişisel verileri güvenli yöntemlerle birbirlerine aktarırlar. Bu veriler, sözleşmenin ifası dışında yalnızca taraf temsilcisinin ayrıca aydınlatılması halinde ve mevzuatla öngörülen hukuki sebepler doğrultusunda kullanılır. Taraflar bu verileri güvenli ortamlarda saklamak ve sözleşmenin sona ermesinden itibaren saklama ve imha politikalarına uygun olarak ve en geç on yıl içerisinde imha etmekle yükümlüdürler. Aksi durumlarda her bir taraf kendi hukuka aykırı eyleminden sorumludur."
        },
        { Id: 915207, PropertyName: "value", Value: "14.2. BÖLÜNEBİLİRLİK" },
        {
          Id: 578764,
          PropertyName: "value",
          Value:
            "Taraflar sözleşmenin ifası için gerekli olan asgari kategorideki kişisel verileri güvenli yöntemlerle birbirlerine aktarırlar. Bu veriler, sözleşmenin ifası dışında yalnızca taraf temsilcisinin ayrıca aydınlatılması halinde ve mevzuatla öngörülen hukuki sebepler doğrultusunda kullanılır. Taraflar bu verileri güvenli ortamlarda saklamak ve sözleşmenin sona ermesinden itibaren saklama ve imha politikalarına uygun olarak ve en geç on yıl içerisinde imha etmekle yükümlüdürler. Aksi durumlarda her bir taraf kendi hukuka aykırı eyleminden sorumludur."
        },
        { Id: 90, PropertyName: "value", Value: "14.3. DEĞİŞİKLİKLER" },
        {
          Id: 827875,
          PropertyName: "value",
          Value:
            "Taraflar sözleşmenin ifası için gerekli olan asgari kategorideki kişisel verileri güvenli yöntemlerle birbirlerine aktarırlar. Bu veriler, sözleşmenin ifası dışında yalnızca taraf temsilcisinin ayrıca aydınlatılması halinde ve mevzuatla öngörülen hukuki sebepler doğrultusunda kullanılır. Taraflar bu verileri güvenli ortamlarda saklamak ve sözleşmenin sona ermesinden itibaren saklama ve imha politikalarına uygun olarak ve en geç on yıl içerisinde imha etmekle yükümlüdürler. Aksi durumlarda her bir taraf kendi hukuka aykırı eyleminden sorumludur."
        },
        { Id: 443554, PropertyName: "value", Value: "14.4. DEVİR ve TEMLİK" },
        {
          Id: 574723,
          PropertyName: "value",
          Value:
            "Taraflar sözleşmenin ifası için gerekli olan asgari kategorideki kişisel verileri güvenli yöntemlerle birbirlerine aktarırlar. Bu veriler, sözleşmenin ifası dışında yalnızca taraf temsilcisinin ayrıca aydınlatılması halinde ve mevzuatla öngörülen hukuki sebepler doğrultusunda kullanılır. Taraflar bu verileri güvenli ortamlarda saklamak ve sözleşmenin sona ermesinden itibaren saklama ve imha politikalarına uygun olarak ve en geç on yıl içerisinde imha etmekle yükümlüdürler. Aksi durumlarda her bir taraf kendi hukuka aykırı eyleminden sorumludur."
        },
        { Id: 207158, PropertyName: "value", Value: "14.5. DELİL SÖZLEŞMESİ" },
        {
          Id: 874950,
          PropertyName: "value",
          Value:
            "Taraflar, işbu sözleşmenin yürütülmesi, yorumu veya doğacak her türlü ihtilaflarda her iki tarafın defter, evrak, eposta yazışmaları ve bilgisayar kayıtlarının HMK 193. maddesi gereğince münhasır delil teşkil edeceğini kabul ve taahhüt eder."
        },
        { Id: 452086, PropertyName: "value", Value: "14.6. MÜCBİR SEBEP" },
        {
          Id: 836334,
          PropertyName: "value",
          Value:
            "İşbu sözleşme, 6098 sayılı Türk Borçlar Kanunu’nda belirtilen ve tarafların kendi iradeleri dışındaki veya kendilerine isnat olunamayacak sebeplerle (deprem, sel, olağanüstü hal, terör, savaş, Dünya Sağlık Örgütü tarafında küresel ölçekte salgın hastalık-pandemi ilan edilmesi ve bu ilana bağlı olarak devlet makamları tarafından ülkesel salgın hastalık hali veya devletin resmi kurumlarınca ya da İstanbul Ticaret Odası tarafından ekonomik kriz olarak kabul edilen ekonomik dalgalanmalar, grev, lokavt, vb.) borçların ifası imkânsız hale gelirse ve mücbir sebep durumu üç (3) ayı aşarsa tarafların, o güne kadarki yükümlülüklerini karşılıklı olarak yerine getirmesi şartıyla, Sözleşmeyi tazminatsız olarak feshetme hakkı doğar."
        },
        { Id: 344494, PropertyName: "value", Value: "MADDE 15: TEBLİĞ ADRESİ" },
        {
          Id: 803796,
          PropertyName: "value",
          Value:
            "Taraflar, işbu sözleşmeden doğan uyuşmazlıklarda ve diğer hususlarda İşbu Sözleşme’nin 1. Maddesinde belirtilen adreslerine yapılacak tebligatların kanuni ikametgahlarına yapılmış sayılacağını, adres değişikliklerini değişiklikten itibaren 2 (iki) gün içinde diğer tarafa yazılı olarak bildirmedikleri takdirde bu hususun geçerli olmaya devam edeceğini kabul ve beyan ederler. İşbu Sözleşme çerçevesinde temerrüde ilişkin ihbarlar, fesih ihbarları ve bu konulara cevaben yapılacak ihbar ve ihtarlar noter kanalı ile yapılmadıkça bağlayıcı olmaz."
        },
        { Id: 489652, PropertyName: "value", Value: "MADDE 16: SÖZLEŞMENİN SONA ERMESİ" },
        {
          Id: 96714,
          PropertyName: "value",
          Value:
            "Fazlaya ilişkin haklarımız saklı kalmak kaydıyla, Danışanın MeMap yazılım ve/veya lisans bedelinin ödememesi veya eksik ödemesi, Şirket’in telif haklarını ihlal etmesi halinde işbu sözleşmesi başka bir hükme, ihtara, ihbara gerek olmaksızın bozucu şartla kendiliğinden sona erecektir."
        },
        { Id: 351513, PropertyName: "value", Value: "MADDE 17. SÖZLEŞME SAKLAMA SÜRESİ" },
        {
          Id: 539891,
          PropertyName: "value",
          Value:
            "İşbu sözleşme 6698 sayılı Kişisel Verilerin Korunması Kanunu, 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Yönetmeliği ile diğer sair ilgili mevzuat hükümleri dikkate alınarak Şirket tarafından elektronik ortamda 3 yıl süre ile saklanacaktır, Danışan işbu süre boyunca, elektronik ortamda sözleşmeye erişecektir."
        },
        { Id: 432846, PropertyName: "value", Value: "MADDE 18: BİLDİRİMLER ve DELİL SÖZLEŞMESİ" },
        {
          Id: 279393,
          PropertyName: "value",
          Value:
            "İşbu Sözleşme tahtında Taraflar arasında yapılacak her türlü yazışma, mevzuatta sayılan zorunlu haller dışında, e-mail aracılığıyla yapılacaktır. Danışan, işbu Sözleşme’ den doğabilecek ihtilaflarda Şirket’in resmi defter, ticari kayıtlarının, veri tabanının, sunucularında tuttuğu elektronik bilgilerin ve bilgisayar kayıtlarının, bağlayıcı, kesin ve münhasır delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu'nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder."
        },
        { Id: 433811, PropertyName: "value", Value: "MADDE 19: YETKİLİ MAHKEME" },
        {
          Id: 971384,
          PropertyName: "value",
          Value:
            "İşbu Sözleşmenin yorumunda ve işbu Sözleşme sebebiyle ortaya çıkacak olan tüm uyuşmazlıklarda İstanbul (Anadolu ve Beykoz) Mahkemeleri ve İcra Daireleri yetkili olup, Türk hukuku uygulanır. Ticari veya mesleki olmayan amaçlarla hareket eden tüketici niteliğindeki Üye şikâyet ve itirazları konusunda başvurularını, Ticaret Bakanlığı tarafından her yıl Aralık ayında belirlenen parasal sınırlar dâhilinde Üye’nin mal veya hizmeti satın aldığı veya ikametgâhının bulunduğu yerdeki Tüketici Sorunları İl ve İlçe Hakem Heyetine veya Tüketici Mahkemesine yapabilir."
        }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      NAVIGATE: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uyeliksozlesme", "uyeliksozlesme", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();

    KuikaAppManager.calculateAndSetBodyHeight("uyeliksozlesme", "uyeliksozlesme", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("uyeliksozlesme", "uyeliksozlesme", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  UyelikSozlesmeComponent_272037_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UyelikSozlesmeComponent_478975_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UyelikSozlesme",
      "KVKK",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "969069",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UyelikSozlesmeComponent_256681_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UyelikSozlesme",
      "KVKK",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "596643",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UyelikSozlesmeComponent_975923_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UyelikSozlesme",
      "KVKK",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "977093",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UyelikSozlesmeComponent_824462_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UyelikSozlesme",
      "TicariIletisimOnay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "163502",
      null,
      "right",
      null,
      "370px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UyelikSozlesmeComponent_88982_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UyelikSozlesme",
      "KVKK",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "367922",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [969069, 596643, 977093, 163502, 367922] as number[];
  }
}
