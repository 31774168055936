import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProfil_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProfil_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
}

export class Profil_ScreenBase extends React.PureComponent<IProfil_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 511994, PropertyName: "value", Value: "Profile", TagName: "Label1_value" },
        { Id: 95774, PropertyName: "value", Value: "Update", TagName: "Label2_value" },
        { Id: 745053, PropertyName: "value", Value: "Name Surname", TagName: "Label3_value" },
        { Id: 390194, PropertyName: "value", Value: "John Smith", TagName: "Label4_value" },
        { Id: 764599, PropertyName: "value", Value: "Email", TagName: "Label2_value" },
        { Id: 822562, PropertyName: "value", Value: "john.smith@memap.com", TagName: "Label6_value" },
        { Id: 164074, PropertyName: "value", Value: "Phone Number", TagName: "Label2_value" },
        { Id: 907377, PropertyName: "value", Value: "+90 532 234 43 23", TagName: "Label8_value" }
      ]
    },
    {
      Id: "bbe6b82d-9f30-4f41-afb3-59f71027b936",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 511994, PropertyName: "value", Value: "Profil" },
        { Id: 95774, PropertyName: "value", Value: "Güncelle" },
        { Id: 745053, PropertyName: "value", Value: "Ad Soyad" },
        { Id: 390194, PropertyName: "value", Value: "İbrahim Mavişehir" },
        { Id: 764599, PropertyName: "value", Value: "Email" },
        { Id: 822562, PropertyName: "value", Value: "ibrahim.mavisehir@kuika.com" },
        { Id: 164074, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 907377, PropertyName: "value", Value: "+90555 666 77 88" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("profil", "profil", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();

    KuikaAppManager.calculateAndSetBodyHeight("profil", "profil", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("profil", "profil", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
