const screenInputData = {
  SezonForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	OgrenciIncele: [{"name":"OgrenciId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	DashboardFitBilesenDetay: [{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupRaporuEN: [{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null}],
	OgretmenSinifEkle: [{"name":"OgretmenSinifId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"OgretmenKUserId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"OkulId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SinifOgrenciList: [{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupForm: [{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"OgrenciId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CheckupDonemiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	VeliOgrenciEkleme: [{"name":"VeliId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"OgrenciId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	OgretmeninSiniflari: [{"name":"OgretmenKUserId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SinifTopluOlcumList: [{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	StatisticsDetails: [{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"BilesenTipi","type":"String","cardinality":1,"isExpandedPanel":null}],
	OgretmenForm: [{"name":"KUserId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MeMapUserId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	OgrenciForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"OkulId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SezonId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SinifForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	DashboardKatilimDetay: [{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupDonemiForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	SinifCheckupForm: [{"name":"CheckupDonemiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"TopluOlcumId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupList: [{"name":"OgrenciId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SinifStatistics: [{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	VeliForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	OkulForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	KullaniciForm: [{"name":"KUserId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MeMapUserId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	VelininOgrencileri: [{"name":"VeliId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	OgrencininVelileri: [{"name":"OgrenciId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	OgrenciStatistics: [{"name":"OgrenciId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupRaporu: [{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
