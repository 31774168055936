import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardFitBilesenDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardFitBilesenDetay_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  SinifSelectById: any[];
  SinifFitnessIstatistigi: any[];
}

export class DashboardFitBilesenDetay_ScreenBase extends React.PureComponent<
  IDashboardFitBilesenDetay_ScreenProps,
  any
> {
  dashboardfitbilesendetay_231797_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboardfitbilesendetay_918431_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboardfitbilesendetay_739488_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 920071, PropertyName: "value", Value: "Fitness Components", TagName: "Label18_value" },
        { Id: 429560, PropertyName: "value", Value: "Class:", TagName: "Label0_0_value" },
        {
          Id: 739488,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 366145, PropertyName: "title", Value: "School Number", TagName: "TableColumn0_1_title" },
        { Id: 579525, PropertyName: "value", Value: "[datafield:schoolname]", TagName: "Label1_0_value" },
        { Id: 893157, PropertyName: "title", Value: "Student Name", TagName: "TableColumn2_title" },
        { Id: 535055, PropertyName: "value", Value: "[datafield:studentname]", TagName: "Label1_0_value" },
        { Id: 808036, PropertyName: "title", Value: "FitSkor®", TagName: "TableColumn1_title" },
        { Id: 675133, PropertyName: "value", Value: "[datafield:fitskor", TagName: "Label1_0_value" },
        { Id: 130030, PropertyName: "title", Value: "Heart Strength", TagName: "TableColumn0_2_title" },
        { Id: 775792, PropertyName: "value", Value: "[datafield:heartstrenght]", TagName: "Label1_0_value" },
        { Id: 316799, PropertyName: "title", Value: "Flexibility", TagName: "TableColumn0_3_title" },
        { Id: 978108, PropertyName: "value", Value: "[datafield:flexibility]", TagName: "Label1_0_value" },
        { Id: 419936, PropertyName: "title", Value: "Upper Extremity", TagName: "TableColumn0_4_title" },
        { Id: 917558, PropertyName: "value", Value: "[datafield:upperextremity]", TagName: "Label1_0_value" },
        { Id: 856275, PropertyName: "title", Value: "Core", TagName: "TableColumn0_5_title" },
        { Id: 887673, PropertyName: "value", Value: "[datafield:core]", TagName: "Label1_0_value" },
        { Id: 499618, PropertyName: "title", Value: "Lower Extremity", TagName: "TableColumn0_6_title" },
        { Id: 781351, PropertyName: "value", Value: "[datafield:lowerextremity]", TagName: "Label1_0_value" },
        { Id: 656279, PropertyName: "title", Value: "BMI", TagName: "TableColumn0_7_title" },
        { Id: 444819, PropertyName: "value", Value: "[datafield:bodycomposition]", TagName: "Label1_0_value" },
        { Id: 217742, PropertyName: "value", Value: "Sort:", TagName: "Label1_0_value" },
        { Id: 231797, PropertyName: "placeholder", Value: "Column", TagName: "selOrderCol_placeholder" },
        { Id: 918431, PropertyName: "placeholder", Value: "Order", TagName: "selOrderDirection_placeholder" }
      ]
    },
    {
      Id: "60ddb8bb-55c5-42c5-a107-ecb76aeb4aa0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 920071, PropertyName: "value", Value: "Fitness Bileşenleri" },
        { Id: 429560, PropertyName: "value", Value: "Sınıf:" },
        { Id: 217742, PropertyName: "value", Value: "Sıralama:" },
        { Id: 231797, PropertyName: "placeholder", Value: "Kolon" },
        { Id: 918431, PropertyName: "placeholder", Value: "Yön" },
        { Id: 739488, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 366145, PropertyName: "title", Value: "Okul No" },
        { Id: 579525, PropertyName: "value", Value: "[datafield:okulno]" },
        { Id: 893157, PropertyName: "title", Value: "Öğrenci Adı" },
        { Id: 535055, PropertyName: "value", Value: "[datafield:ogrenciadi]" },
        { Id: 656279, PropertyName: "title", Value: "BKİ" },
        { Id: 444819, PropertyName: "value", Value: "[datafield:vucutkompozisyonu]" },
        { Id: 130030, PropertyName: "title", Value: "Kalp Gücü" },
        { Id: 775792, PropertyName: "value", Value: "[datafield:kalpgucu]" },
        { Id: 316799, PropertyName: "title", Value: "Esneklik" },
        { Id: 978108, PropertyName: "value", Value: "[datafield:esneklik]" },
        { Id: 419936, PropertyName: "title", Value: "Üst Ekstremite" },
        { Id: 917558, PropertyName: "value", Value: "[datafield:ustekstremite]" },
        { Id: 856275, PropertyName: "title", Value: "Gövde" },
        { Id: 887673, PropertyName: "value", Value: "[datafield:govde]" },
        { Id: 499618, PropertyName: "title", Value: "Alt Ekstremite" },
        { Id: 781351, PropertyName: "value", Value: "[datafield:altekstremite]" },
        { Id: 808036, PropertyName: "title", Value: "FitSkor®" },
        { Id: 675133, PropertyName: "value", Value: "[datafield:fitskor]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardfitbilesendetay_231797_value_kuikaSelectBoxRef = React.createRef();
    this.dashboardfitbilesendetay_918431_value_kuikaSelectBoxRef = React.createRef();
    this.dashboardfitbilesendetay_739488_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      SinifSelectById: [],
      SinifFitnessIstatistigi: [],
      dataSource_231797: [
        { key: "OkulNo", text: "Okul No" },
        { key: "OgrenciAdi", text: "Öğrenci Adı" },
        { key: "FitSkor", text: "FitSkor" },
        { key: "KalpGucu", text: "Kalp Gücü" },
        { key: "Esneklik", text: "Esneklik" },
        { key: "UstEkstremite", text: "Üst Ekstremite" },
        { key: "Govde", text: "Gövde" },
        { key: "AltEkstremite", text: "Alt Ekstremite" },
        { key: "BedenKitleEndeksi", text: "Beden Kitle Endeksi" }
      ],
      dataSource_918431: [
        { key: "AZ", text: "A -> Z" },
        { key: "ZA", text: "Z -> A" }
      ]
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight(
        "dashboardfitbilesendetay",
        "dashboardfitbilesendetay",
        this.props.context
      );
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.DashboardFitBilesenDetayPageInit();

    KuikaAppManager.calculateAndSetBodyHeight(
      "dashboardfitbilesendetay",
      "dashboardfitbilesendetay",
      this.props.context
    );
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight(
      "dashboardfitbilesendetay",
      "dashboardfitbilesendetay",
      this.props.context
    );
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.DashboardFitBilesenDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  DashboardFitBilesenDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      orderColumn_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "dashboardfitbilesendetay_231797_value", "value", "", "key", "")
        ),
        "string"
      ),
      orderDirection_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "dashboardfitbilesendetay_918431_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardFitBilesenDetay/DashboardFitBilesenDetayPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    formVars.dashboardfitbilesendetay_435167_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifSelectById?.length > 0 ? stateVars.SinifSelectById[0]?.adi : null
    );
    stateVars.SinifFitnessIstatistigi = result?.data.sinifFitnessIstatistigi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardFitBilesenDetayPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardFitBilesenDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardfitbilesendetay_435167_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0]?.adi : null
    );

    stateVars.dataSource_739488 = this.state.SinifFitnessIstatistigi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardFitBilesenDetayComponent_603965_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardFitBilesenDetayComponent_231797_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sinifId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      checkupDonemiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      orderColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "dashboardfitbilesendetay_231797_value", "value", "", "key", "")
        ),
        "string"
      ),
      orderDirection_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "dashboardfitbilesendetay_918431_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardFitBilesenDetay/DashboardFitBilesenDetayComponent_231797_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifFitnessIstatistigi = result?.data.sinifFitnessIstatistigi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardFitBilesenDetayComponent_231797_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardFitBilesenDetayComponent_231797_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_739488 = this.state.SinifFitnessIstatistigi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardFitBilesenDetayComponent_918431_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sinifId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      checkupDonemiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.aktifCheckupDonemiId : null
        ),
        "Guid"
      ),
      orderColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "dashboardfitbilesendetay_231797_value", "value", "", "key", "")
        ),
        "string"
      ),
      orderDirection_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "dashboardfitbilesendetay_918431_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardFitBilesenDetay/DashboardFitBilesenDetayComponent_918431_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifFitnessIstatistigi = result?.data.sinifFitnessIstatistigi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardFitBilesenDetayComponent_918431_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardFitBilesenDetayComponent_918431_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_739488 = this.state.SinifFitnessIstatistigi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
