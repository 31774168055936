import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgretmenSinifEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgretmenSinifEkle_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  OgretmenSinifById: any[];
  SinifbySezon: any[];
  SetFocusTo: any;
  OgretmenSinifSave: number;
}

export class OgretmenSinifEkle_ScreenBase extends React.PureComponent<IOgretmenSinifEkle_ScreenProps, any> {
  ogretmensinifekle_630545_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Add Class to Teacher", TagName: "lblHead_value" },
        { Id: 9336317, PropertyName: "value", Value: "Class Name/Branch", TagName: "lblAdi_value" },
        { Id: 630545, PropertyName: "placeholder", Value: "Choose", TagName: "selSinif_placeholder" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "5ee579e6-e851-48aa-97b1-3c31e8e9cc15",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Öğretmen Sınıf Ekle" },
        { Id: 9336317, PropertyName: "value", Value: "Sınıf Adı / Şube" },
        { Id: 630545, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogretmensinifekle_630545_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      OgretmenSinifById: [],
      SinifbySezon: [],
      SetFocusTo: "",
      OgretmenSinifSave: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogretmensinifekle", "ogretmensinifekle", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgretmenSinifEklePageInit();

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("ogretmensinifekle", "ogretmensinifekle", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogretmensinifekle", "ogretmensinifekle", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgretmenSinifEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ogretmensinifekle_630545_value:
        this.state.OgretmenSinifById?.length > 0 ? this.state.OgretmenSinifById[0].sinifId : undefined
    });
  }

  OgretmenSinifEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgretmenSinifId, "Guid"),
      okulId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OkulId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgretmenSinifEkle/OgretmenSinifEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OgretmenSinifById = result?.data.ogretmenSinifById;
    formVars.ogretmensinifekle_630545_value =
      stateVars.OgretmenSinifById?.length > 0 ? stateVars.OgretmenSinifById[0]?.sinifId : null;
    formVars.ogretmensinifekle_630545_options = stateVars.SinifbySezon;
    stateVars.SinifbySezon = result?.data.sinifbySezon;

    formVars.ogretmensinifekle_630545_value =
      stateVars.OgretmenSinifById?.length > 0 ? stateVars.OgretmenSinifById[0]?.sinifId : null;
    formVars.ogretmensinifekle_630545_options = stateVars.SinifbySezon;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenSinifEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenSinifEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("417343");
    formVars.ogretmensinifekle_630545_value = ReactSystemFunctions.toString(
      this,
      this.state.OgretmenSinifById?.length > 0 ? this.state.OgretmenSinifById[0]?.sinifId : null
    );

    stateVars.dataSource_630545 = this.state.SinifbySezon;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenSinifEkleComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenSinifEkleComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmensinifekle_630545_value", "value", "SinifbySezon", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "ogretmensinifekle_630545_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KUserId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgretmenKUserId, "Guid"),
      SinifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogretmensinifekle_630545_value", "value", "SinifbySezon", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgretmenSinifId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OgretmenSinifEkle/OgretmenSinifEkleComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OgretmenSinifSave = result?.data.ogretmenSinifSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmenSinifEkleComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenSinifEkleComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
