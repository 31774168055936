import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVeliForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVeliForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  VeliSelectById: any[];
  OgrenciSelectAll: any[];
  KUserSelectAll: any[];
  OkulSelectAll: any[];
  VeliSave: number;
  isCompveliform_559909AuthorizationVisible: "visible" | "hidden";
}

export class VeliForm_ScreenBase extends React.PureComponent<IVeliForm_ScreenProps, any> {
  veliform_893606_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Parent Form", TagName: "lblHead_value" },
        { Id: 818937, PropertyName: "value", Value: "School", TagName: "Label1_0_value" },
        { Id: 893606, PropertyName: "placeholder", Value: "Choose", TagName: "selOkul_placeholder" },
        { Id: 6124627, PropertyName: "value", Value: "Parent Name Surname", TagName: "lblAdi_value" },
        { Id: 8221716, PropertyName: "value", Value: "Email", TagName: "lblEposta_value" },
        { Id: 7930811, PropertyName: "value", Value: "Phone Number", TagName: "lblTelefon_value" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "37153a0c-91f6-42a7-9797-d2b60a29a403",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Veli Kartı" },
        { Id: 818937, PropertyName: "value", Value: "Okulu" },
        { Id: 893606, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 6124627, PropertyName: "value", Value: "Veli Adı Soyadı" },
        { Id: 8221716, PropertyName: "value", Value: "Eposta" },
        { Id: 7930811, PropertyName: "value", Value: "Telefon" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.veliform_893606_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      VeliSelectById: [],
      OgrenciSelectAll: [],
      KUserSelectAll: [],
      OkulSelectAll: [],
      VeliSave: 0,
      isCompveliform_559909AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("veliform", "veliform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.VeliFormPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompveliform_559909AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("veliform", "veliform", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("veliform", "veliform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.VeliFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      veliform_893606_value: this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0].okulId : undefined,
      veliform_48486400_value: this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0].adi : undefined,
      veliform_34683600_value: this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0].eposta : undefined,
      veliform_26438440_value: this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0].telefon : undefined
    });
  }

  VeliFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliForm/VeliFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.VeliSelectById = result?.data.veliSelectById;
    formVars.veliform_893606_value = stateVars.VeliSelectById?.length > 0 ? stateVars.VeliSelectById[0]?.okulId : null;
    formVars.veliform_893606_options = stateVars.OkulSelectAll;
    formVars.veliform_48486400_value = ReactSystemFunctions.toString(
      this,
      stateVars.VeliSelectById?.length > 0 ? stateVars.VeliSelectById[0]?.adi : null
    );
    formVars.veliform_34683600_value = ReactSystemFunctions.toString(
      this,
      stateVars.VeliSelectById?.length > 0 ? stateVars.VeliSelectById[0]?.eposta : null
    );
    formVars.veliform_26438440_value = ReactSystemFunctions.toString(
      this,
      stateVars.VeliSelectById?.length > 0 ? stateVars.VeliSelectById[0]?.telefon : null
    );
    stateVars.OgrenciSelectAll = result?.data.ogrenciSelectAll;
    stateVars.KUserSelectAll = result?.data.kUserSelectAll;
    stateVars.OkulSelectAll = result?.data.okulSelectAll;

    formVars.veliform_893606_value = stateVars.VeliSelectById?.length > 0 ? stateVars.VeliSelectById[0]?.okulId : null;
    formVars.veliform_893606_options = stateVars.OkulSelectAll;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.veliform_893606_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0]?.okulId : null
    );

    stateVars.dataSource_893606 = this.state.OkulSelectAll;
    formVars.veliform_48486400_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0]?.adi : null
    );

    formVars.veliform_34683600_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0]?.eposta : null
    );

    formVars.veliform_26438440_value = ReactSystemFunctions.toString(
      this,
      this.state.VeliSelectById?.length > 0 ? this.state.VeliSelectById[0]?.telefon : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VeliFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "veliform_48486400_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "veliform_48486400_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "veliform_34683600_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "veliform_34683600_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "veliform_893606_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "veliform_893606_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "veliform_26438440_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "veliform_26438440_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "veliform_48486400_value", "value", "", "", "")
        ),
        "string"
      ),
      Eposta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "veliform_34683600_value", "value", "", "", "")
        ),
        "string"
      ),
      OkulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "veliform_893606_value", "value", "OkulSelectAll", "id", "id")
        ),
        "Guid"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "veliform_26438440_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid"),
      kuserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "VeliForm/VeliFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VeliSave = result?.data.veliSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VeliFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VeliFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
