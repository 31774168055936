import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKullaniciList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKullaniciList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  UserSearch: any[];
  UserCount: any[];
  NAVIGATE: any;
  DisableUser: any;
  MemapUserDelete: number;
  isCompkullanicilist_547866AuthorizationVisible: "visible" | "hidden";
}

export class KullaniciList_ScreenBase extends React.PureComponent<IKullaniciList_ScreenProps, any> {
  kullanicilist_5142765_value_kuikaTableRef: React.RefObject<any>;
  kullanicilist_617798_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 170206, PropertyName: "value", Value: "Users list", TagName: "Label4_0_value" },
        { Id: 616325, PropertyName: "label", Value: "NEW USER", TagName: "Button0_0_label" },
        {
          Id: 5142765,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblUsersearch_5142765_nodatafoundmessage"
        },
        { Id: 547866, PropertyName: "title", Value: "Role", TagName: "TableColumn8_title" },
        { Id: 143056, PropertyName: "value", Value: "[datafield:rolename]", TagName: "Label6_0_value" },
        { Id: 178395, PropertyName: "title", Value: "School", TagName: "TableColumn6_title" },
        { Id: 168136, PropertyName: "value", Value: "[datafield:school]", TagName: "Label5_0_value" },
        { Id: 4212702, PropertyName: "title", Value: "User email", TagName: "clmUserName_4212702_title" },
        { Id: 7867440, PropertyName: "value", Value: "[datafield:username]", TagName: "lblUserName_7867440_value" },
        { Id: 1078434, PropertyName: "title", Value: "First name", TagName: "clmFirstName_1078434_title" },
        { Id: 7952176, PropertyName: "value", Value: "[datafield:firstname]", TagName: "lblFirstName_7952176_value" },
        { Id: 7247949, PropertyName: "title", Value: "Last name", TagName: "clmLastName_7247949_title" },
        { Id: 6656439, PropertyName: "value", Value: "[datafield:lastname]", TagName: "lblLastName_6656439_value" },
        { Id: 116250, PropertyName: "title", Value: "Phone number", TagName: "TableColumn7_title" },
        { Id: 911412, PropertyName: "value", Value: "[datafield:phonenumber]", TagName: "Label6_0_value" },
        {
          Id: 52314437,
          PropertyName: "85e29e00-bb1a-5c7b-177a-c7365d2fb572_confirmation",
          Value: "Are you sure you want to delete this user?",
          TagName: "DisableUser_confirmation",
          IsConfirmation: true
        }
      ]
    },
    {
      Id: "3b778c59-453a-4a90-8a4a-5073390cc99c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 170206, PropertyName: "value", Value: "Kullanıcı Listesi" },
        { Id: 616325, PropertyName: "label", Value: "YENİ KULLANICI" },
        { Id: 5142765, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 547866, PropertyName: "title", Value: "Rolü" },
        { Id: 143056, PropertyName: "value", Value: "[datafield:rolename]" },
        { Id: 178395, PropertyName: "title", Value: "Okulu" },
        { Id: 168136, PropertyName: "value", Value: "[datafield:okulu]" },
        { Id: 4212702, PropertyName: "title", Value: "Kullanıcı epostası" },
        { Id: 7867440, PropertyName: "value", Value: "[datafield:username]" },
        { Id: 1078434, PropertyName: "title", Value: "Adı" },
        { Id: 7952176, PropertyName: "value", Value: "[datafield:firstname]" },
        { Id: 7247949, PropertyName: "title", Value: "Soyadı" },
        { Id: 6656439, PropertyName: "value", Value: "[datafield:lastname]" },
        { Id: 116250, PropertyName: "title", Value: "Telefon numarası" },
        { Id: 911412, PropertyName: "value", Value: "[datafield:phonenumber]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.kullanicilist_5142765_value_kuikaTableRef = React.createRef();
    this.kullanicilist_617798_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      UserSearch: [],
      UserCount: [],
      NAVIGATE: "",
      DisableUser: "",
      MemapUserDelete: 0,
      isCompkullanicilist_547866AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kullanicilist", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.KullaniciListPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullanicilist_547866AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("kullanicilist", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("kullanicilist", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.KullaniciListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  KullaniciListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_617798_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_617798_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "KullaniciList/KullaniciListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.UserSearch = result?.data.userSearch;

    stateVars.UserCount = result?.data.userCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5142765 = this.state.UserSearch;
    formVars.kullanicilist_617798_total = ReactSystemFunctions.value(
      this,
      this.state.UserCount?.length > 0 ? this.state.UserCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_616325_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("KullaniciForm", "KUserId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("KullaniciForm", "MeMapUserId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KullaniciList",
      "KullaniciForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "820469",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_190524_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "KullaniciForm",
      "KUserId",
      ReactSystemFunctions.value(this, "kullanicilist_5142765_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "KullaniciForm",
      "MeMapUserId",
      ReactSystemFunctions.value(this, "kullanicilist_5142765_value", "memapUserId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KullaniciList",
      "KullaniciForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "684613",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_56278_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "85e29e00_bb1a_5c7b_177a_c7365d2fb572_confirmation",
        this.defaultML,
        "Kullanıcının silinmesini istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);

        stateVars.DisableUser = await ReactSystemFunctions.disableUser(
          ReactSystemFunctions.value(this, "kullanicilist_5142765_value", "userName")
        );

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.KullaniciListComponent_56278_onClick1_();
        });
      }
    });
    return isErrorOccurred;
  };
  KullaniciListComponent_56278_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "kullanicilist_5142765_value", "memapUserId"),
        "Guid"
      ),
      okulId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_617798_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_617798_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      okulId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciList/KullaniciListComponent_56278_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MemapUserDelete = result?.data.memapUserDelete;
    stateVars.UserSearch = result?.data.userSearch;

    stateVars.UserCount = result?.data.userCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListComponent_56278_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListComponent_56278_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5142765 = this.state.UserSearch;
    formVars.kullanicilist_617798_total = ReactSystemFunctions.value(
      this,
      this.state.UserCount?.length > 0 ? this.state.UserCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_617798_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      okulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.okulId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_617798_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_617798_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciList/KullaniciListComponent_617798_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UserSearch = result?.data.userSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListComponent_617798_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListComponent_617798_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5142765 = this.state.UserSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [820469, 684613] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.KullaniciListPageInit();
    }
  }
}
