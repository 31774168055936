import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupRaporuEN_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupRaporuEN_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  ComponentToPdf: string;
  OpenPdf: any;
  GoBack: any;
  GetLanguage: string;
  MyContext: any[];
  MeMapAll: any[];
  GetChartLevels: any[];
  CheckupSelectById: any[];
  OgrenciSelectById: any[];
  OkulLogo: any[];
  GetCheckupChart: any[];
  KalpGucuLine: any[];
  AltEkstremiteLine: any[];
  UstEkstremiteLine: any[];
  GovdeLine: any[];
  EsneklikLine: any[];
  VucutKompozisyonuLine: any[];
  OpenInBrowser: any;
  isComp921799Visible: "visible" | "hidden";
  isComp984311Visible: "visible" | "hidden";
  isComp980645Visible: "visible" | "hidden";
  isComp620411Visible: "visible" | "hidden";
  isComp385281Visible: "visible" | "hidden";
  isComp205531Visible: "visible" | "hidden";
  isComp763573Visible: "visible" | "hidden";
  isComp411037Visible: "visible" | "hidden";
  isComp385766Visible: "visible" | "hidden";
  isComp835064Visible: "visible" | "hidden";
  isComp799063Visible: "visible" | "hidden";
  isComp821975Visible: "visible" | "hidden";
  isComp470912Visible: "visible" | "hidden";
  isComp748069Visible: "visible" | "hidden";
  isComp114331Visible: "visible" | "hidden";
  isComp905999Visible: "visible" | "hidden";
  isComp996542Visible: "visible" | "hidden";
  isComp624519Visible: "visible" | "hidden";
  isComp109509Visible: "visible" | "hidden";
  isComp241632Visible: "visible" | "hidden";
  isComp568237Visible: "visible" | "hidden";
  isComp422144Visible: "visible" | "hidden";
  isComp525421Visible: "visible" | "hidden";
  isComp139495Visible: "visible" | "hidden";
  isComp978176Visible: "visible" | "hidden";
  isComp502207Visible: "visible" | "hidden";
  isComp92254Visible: "visible" | "hidden";
  isComp623297Visible: "visible" | "hidden";
  isComp884836Visible: "visible" | "hidden";
  isComp254208Visible: "visible" | "hidden";
  isComp386363Visible: "visible" | "hidden";
  isComp830218Visible: "visible" | "hidden";
  isComp586327Visible: "visible" | "hidden";
  isComp748471Visible: "visible" | "hidden";
  isComp189839Visible: "visible" | "hidden";
  isComp907915Visible: "visible" | "hidden";
  isComp823314Visible: "visible" | "hidden";
  isComp78528Visible: "visible" | "hidden";
  isComp353767Visible: "visible" | "hidden";
  isComp344455Visible: "visible" | "hidden";
  isComp438142Visible: "visible" | "hidden";
  isComp438785Visible: "visible" | "hidden";
  isComp803641Visible: "visible" | "hidden";
  isComp299514Visible: "visible" | "hidden";
  isComp997606Visible: "visible" | "hidden";
  isComp758973Visible: "visible" | "hidden";
  isComp973465Visible: "visible" | "hidden";
  isComp955675Visible: "visible" | "hidden";
  isComp144742Visible: "visible" | "hidden";
  isComp250116Visible: "visible" | "hidden";
  isComp713707Visible: "visible" | "hidden";
  isComp613902Visible: "visible" | "hidden";
  isComp606654Visible: "visible" | "hidden";
  isComp383285Visible: "visible" | "hidden";
  isComp481996Visible: "visible" | "hidden";
  isComp400374Visible: "visible" | "hidden";
  isComp331319Visible: "visible" | "hidden";
  isComp209834Visible: "visible" | "hidden";
  isComp922873Visible: "visible" | "hidden";
}

export class CheckupRaporuEN_ScreenBase extends React.PureComponent<ICheckupRaporuEN_ScreenProps, any> {
  ml = [
    { Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "09a4f147-1805-4dd7-a102-84017217604f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 347690, PropertyName: "value", Value: "Fitness Check-Up Report" },
        { Id: 903221, PropertyName: "value", Value: "Health related Junior FitSkor®" },
        { Id: 406271, PropertyName: "value", Value: "Full Name :" },
        { Id: 455626, PropertyName: "value", Value: "Age :" },
        { Id: 141488, PropertyName: "value", Value: "Class :" },
        { Id: 847908, PropertyName: "value", Value: "School :" },
        { Id: 351959, PropertyName: "value", Value: "Report Date :" },
        { Id: 401881, PropertyName: "value", Value: "What is FitSkor®?" },
        {
          Id: 277319,
          PropertyName: "value",
          Value:
            "FitSkor® is a purpose-specific physical fitness score. All fitness components related to the purpose contribute to the creation of FitSkor® based on their contribution ratios. The FitSkor® pie chart shows how fit you are on a scale of 100. The completeness levels of component slices indicate your current status and how much progress you can make. In addition to health-related Fitness Check-Up protocols for children, adults, and individuals aged 65 and above, sports performance and occupational fitness protocols have also been developed. FitSkor®, the world's first and only fitness evaluation system, is an innovation patented in the United States, Europe, and Turkey."
        },
        { Id: 704330, PropertyName: "value", Value: "FitSkor® Pie Chart" },
        { Id: 251957, PropertyName: "value", Value: "What does your child's FitSkor® indicate?" },
        {
          Id: 526010,
          PropertyName: "value",
          Value:
            "A health-related Fitness Check-Up protocol has been conducted for your child, and a physical fitness score of"
        },
        { Id: 461684, PropertyName: "value", Value: "has" },
        { Id: 279840, PropertyName: "value", Value: "been calculated. This score places your child the" },
        { Id: 541155, PropertyName: "value", Value: "very low" },
        { Id: 842293, PropertyName: "value", Value: "category." },
        {
          Id: 651099,
          PropertyName: "value",
          Value: "Based on the evaluation results, in terms of physical activity, your child is considered"
        },
        { Id: 559889, PropertyName: "value", Value: "very inactive." },
        { Id: 213213, PropertyName: "value", Value: "Especially for the" },
        {
          Id: 16933,
          PropertyName: "value",
          Value:
            "prevention of cardiovascular and metabolic diseases in the coming years, increasing phisical fitness levels is strongly"
        },
        {
          Id: 494526,
          PropertyName: "value",
          Value:
            "recommended. You can elevate your child's physical fitness score to a higher level simply by increasing your child's daily physical activity."
        },
        {
          Id: 605082,
          PropertyName: "value",
          Value:
            "A health-related Fitness Check-Up protocol has been conducted for your child, and a physical fitness score of"
        },
        { Id: 100627, PropertyName: "value", Value: "has" },
        { Id: 950074, PropertyName: "value", Value: "been calculated. This score places your child into the" },
        { Id: 197080, PropertyName: "value", Value: "low" },
        { Id: 398752, PropertyName: "value", Value: "category." },
        {
          Id: 425065,
          PropertyName: "value",
          Value: "Based on the evaluation results, in terms of physical activity, your child is considered"
        },
        { Id: 284210, PropertyName: "value", Value: "inactive." },
        { Id: 62558, PropertyName: "value", Value: "Especially for the prevention" },
        {
          Id: 384827,
          PropertyName: "value",
          Value:
            "of cardiovascular and metabolic diseases that may occur in the coming years, increasing the level of physical fitness is strongly recommended. You can elevate your child's physical fitness score to an average level simply by increasing your child's daily physical activity and by following a planned exercise strategy for 2-3 days a week."
        },
        {
          Id: 464070,
          PropertyName: "value",
          Value:
            "A health-related Fitness Check-Up protocol has been conducted for your child, and a physical fitness score of"
        },
        { Id: 913908, PropertyName: "value", Value: "has" },
        { Id: 952305, PropertyName: "value", Value: "been calculated. This score places your child into the" },
        { Id: 901809, PropertyName: "value", Value: "average" },
        { Id: 234200, PropertyName: "value", Value: "category." },
        {
          Id: 667082,
          PropertyName: "value",
          Value: "Based on the evaluation results, in terms of physical activity, your child is considered"
        },
        { Id: 83409, PropertyName: "value", Value: "not active enough." },
        { Id: 197459, PropertyName: "value", Value: "To further" },
        {
          Id: 628273,
          PropertyName: "value",
          Value:
            "reduce the risk of preventable diseases in the coming years, it is recommended to increase the level of physical fitness. To elevate your child's physical fitness score to a satisfactory level, a planned exercise strategy tailored to your child’s physical needs, scheduled for 3-4 days a week, is necessary."
        },
        {
          Id: 346879,
          PropertyName: "value",
          Value:
            "A health-related Fitness Check-Up protocol has been conducted for your child, and a physical fitness score of"
        },
        { Id: 514433, PropertyName: "value", Value: "has" },
        { Id: 221303, PropertyName: "value", Value: "been calculated. This score places your child into the" },
        { Id: 532242, PropertyName: "value", Value: "satisfactory" },
        { Id: 921432, PropertyName: "value", Value: "category." },
        {
          Id: 703881,
          PropertyName: "value",
          Value: "Based on the evaluation results, in terms of physical activity, your child is considered"
        },
        { Id: 319779, PropertyName: "value", Value: "moderately active." },
        { Id: 159707, PropertyName: "value", Value: "To further" },
        {
          Id: 467309,
          PropertyName: "value",
          Value:
            "reduce the risk of preventable diseases in the coming years it is recommended to increase the level of physical fitness. To elevate your child's physical fitness score to a good level, a planned exercise strategy tailored to your child’s physical needs, scheduled for 4-5 days a week, is necessary."
        },
        {
          Id: 374139,
          PropertyName: "value",
          Value:
            "A health-related Fitness Check-Up protocol has been conducted for your child, and a physical fitness score of"
        },
        { Id: 756514, PropertyName: "value", Value: "has" },
        { Id: 523187, PropertyName: "value", Value: "been calculated. This score places your child into the" },
        { Id: 499807, PropertyName: "value", Value: "good" },
        { Id: 869172, PropertyName: "value", Value: "category." },
        {
          Id: 279582,
          PropertyName: "value",
          Value: "Based on the evaluation results, in terms of physical activity, your child is considered"
        },
        { Id: 617834, PropertyName: "value", Value: "active enough." },
        { Id: 283160, PropertyName: "value", Value: "Your child is seen to" },
        {
          Id: 852841,
          PropertyName: "value",
          Value:
            "be exercising. We congratulate you for this investment in your child's health! To elevate your child's physical fitness score to  a very good level, more efficient and carefully selected exercises are now required. You may need to seek assistance from exercise experts for this."
        },
        {
          Id: 596814,
          PropertyName: "value",
          Value:
            "A health-related Fitness Check-Up protocol has been conducted for your child, and a physical fitness score of"
        },
        { Id: 17134, PropertyName: "value", Value: "has" },
        { Id: 239357, PropertyName: "value", Value: "been calculated. This score places your child into the" },
        { Id: 911463, PropertyName: "value", Value: "very good" },
        { Id: 52605, PropertyName: "value", Value: "category." },
        {
          Id: 801877,
          PropertyName: "value",
          Value: "Based on the evaluation results, in terms of physical activity, your child is considered"
        },
        { Id: 358338, PropertyName: "value", Value: "very active." },
        { Id: 318490, PropertyName: "value", Value: "Your child is seen to be" },
        {
          Id: 757702,
          PropertyName: "value",
          Value:
            "exercising. We congratulate you for this investment in your child's health! If your child is not a professional athlete, there is no need to push their limits further for additional development. It is sufficient for their health to maintain this level of exercise. If you want your child to reach their full potential, you may need to seek assistance from exercise experts."
        },
        {
          Id: 917221,
          PropertyName: "value",
          Value:
            "A health-related Fitness Check-Up protocol has been conducted for your child, and a physical fitness score of"
        },
        { Id: 994807, PropertyName: "value", Value: "has" },
        { Id: 148536, PropertyName: "value", Value: "been calculated. This score places your child into the" },
        { Id: 493081, PropertyName: "value", Value: "perfect" },
        { Id: 293978, PropertyName: "value", Value: "category." },
        {
          Id: 273950,
          PropertyName: "value",
          Value: "Based on the evaluation results, in terms of physical activity, your child is considered"
        },
        { Id: 667667, PropertyName: "value", Value: "extremely active." },
        { Id: 980272, PropertyName: "value", Value: "We congratulate" },
        {
          Id: 824035,
          PropertyName: "value",
          Value:
            "you and your child for reaching the perfect level! Your child should now be assessed with a Fitness Check-Up specific to the sport your child is interested in. If you have further development goals, you should seek assistance from exercise experts to protect the joints and prevent overuse injuries."
        },
        { Id: 158973, PropertyName: "value", Value: "FITNESS COMPONENT DETAILS" },
        { Id: 212396, PropertyName: "value", Value: "Heart Strength Score:" },
        { Id: 38669, PropertyName: "value", Value: "( age" },
        { Id: 351829, PropertyName: "value", Value: ")" },
        {
          Id: 877323,
          PropertyName: "value",
          Value:
            "Heart strength refers to cardiorespiratory endurance and is the most crucial fitness component affecting physical fitness related to health. As cardiorespiratory capacity increases, the risk of various diseases in later life, especially cardiovascular diseases, decreases. Due to its significant contribution to overall health, heart strength plays a crucial role in exercise strategies."
        },
        { Id: 636487, PropertyName: "value", Value: "Important information:" },
        {
          Id: 129125,
          PropertyName: "value",
          Value:
            "The test protocol used to assess heart strength assesses your child's level of cardioresiratory capacity."
        },
        {
          Id: 553191,
          PropertyName: "value",
          Value: "Even an extremely healthy heart can have reduced capacity due to inactivity."
        },
        { Id: 732728, PropertyName: "value", Value: "The evaluation of heart health should only be" },
        { Id: 644844, PropertyName: "value", Value: "conducted by doctors who specialize in cardiology." },
        { Id: 206588, PropertyName: "value", Value: "The" },
        { Id: 827799, PropertyName: "value", Value: "Shuttle Run" },
        {
          Id: 706648,
          PropertyName: "value",
          Value: "test was conducted to assess cardiorespiratory capacity, and the test was completed in the"
        },
        { Id: 488030, PropertyName: "value", Value: "stage" },
        { Id: 700920, PropertyName: "value", Value: "of" },
        { Id: 723817, PropertyName: "value", Value: "level" },
        { Id: 863519, PropertyName: "value", Value: "." },
        { Id: 941480, PropertyName: "value", Value: "The test results has been calculated as" },
        { Id: 637008, PropertyName: "value", Value: "ml/kg/min." },
        { Id: 105601, PropertyName: "value", Value: "This result places your child into the" },
        { Id: 941373, PropertyName: "value", Value: "category for the age group." },
        { Id: 447540, PropertyName: "value", Value: "Lower Extremity Score:" },
        { Id: 958658, PropertyName: "value", Value: "( age" },
        { Id: 652121, PropertyName: "value", Value: ")" },
        {
          Id: 462460,
          PropertyName: "value",
          Value:
            "Lower extremity strength represents the strength of your leg and hip muscles. The lower extremities are the largest and most crucial muscle group in the body. They play a critical role in strengthening the heart and are essential for healthy bone development in children. Additionally, they contribute to increased energy expenditure and improved balance."
        },
        { Id: 318774, PropertyName: "value", Value: "The" },
        { Id: 996216, PropertyName: "value", Value: "Standing Long Jump" },
        {
          Id: 688408,
          PropertyName: "value",
          Value: "test was conducted to assess lower extremity strength. The best jumped distance has been determined"
        },
        { Id: 978874, PropertyName: "value", Value: "as" },
        { Id: 360654, PropertyName: "value", Value: "cm." },
        { Id: 748338, PropertyName: "value", Value: "This result places your" },
        { Id: 440710, PropertyName: "value", Value: "child into the" },
        { Id: 827390, PropertyName: "value", Value: "category for the age group." },
        { Id: 647884, PropertyName: "value", Value: "Upper Extremity Score:" },
        { Id: 420784, PropertyName: "value", Value: "( age" },
        { Id: 802116, PropertyName: "value", Value: ")" },
        {
          Id: 40595,
          PropertyName: "value",
          Value: "Upper extremity strength refers to the strength of your arm, chest, and shoulder muscles."
        },
        { Id: 957414, PropertyName: "value", Value: "The" },
        { Id: 424738, PropertyName: "value", Value: "Maximal Push Up" },
        { Id: 563781, PropertyName: "value", Value: "test was conducted to assess upper extremity strength." },
        { Id: 161873, PropertyName: "value", Value: "The maximum repetition has been determined as" },
        { Id: 650353, PropertyName: "value", Value: "in the test. This result pşaces your child into the" },
        { Id: 494588, PropertyName: "value", Value: "category for the age group." },
        { Id: 453756, PropertyName: "value", Value: "Core Endurance Score:" },
        { Id: 8072, PropertyName: "value", Value: "( age" },
        { Id: 947667, PropertyName: "value", Value: ")" },
        {
          Id: 5464,
          PropertyName: "value",
          Value:
            "Core endurance represents the strength of the coordinated muscles in the hip, lower back, and abdominal area. Having strong muscles in these areas is necessary for maintaining good posture, improving spinal health, and preventing lower back and neck pain."
        },
        { Id: 975519, PropertyName: "value", Value: "The" },
        { Id: 268733, PropertyName: "value", Value: "30 sec. max. Sit-Up" },
        { Id: 809410, PropertyName: "value", Value: "test was conducted to assess core endurance." },
        { Id: 384608, PropertyName: "value", Value: "The maximum repetition has been determined as" },
        { Id: 784225, PropertyName: "value", Value: "in the" },
        { Id: 715687, PropertyName: "value", Value: "test. This result places your child into the" },
        { Id: 247351, PropertyName: "value", Value: "category for the age group." },
        { Id: 534166, PropertyName: "value", Value: "Flexibility Score:" },
        { Id: 131327, PropertyName: "value", Value: "( age" },
        { Id: 572314, PropertyName: "value", Value: ")" },
        {
          Id: 256429,
          PropertyName: "value",
          Value:
            "Flexibility refers to the ability of muscles and joints to stretch to their normal range of motion. While flexibility may not contribute to your health as significantly as other fitness components, it enhances comfort in daily life activities by enabling more comfortable movement."
        },
        { Id: 180142, PropertyName: "value", Value: "The" },
        { Id: 567048, PropertyName: "value", Value: "Sit & Reach" },
        {
          Id: 428059,
          PropertyName: "value",
          Value: "test was conducted to determine flexibility of the hamstrings and lower"
        },
        { Id: 669743, PropertyName: "value", Value: "back. The maximum streching distance has" },
        { Id: 121066, PropertyName: "value", Value: "been  determined as" },
        { Id: 653315, PropertyName: "value", Value: "cm" },
        { Id: 122042, PropertyName: "value", Value: "in the test. This result places your child into the" },
        { Id: 768134, PropertyName: "value", Value: "category for the age group." },
        { Id: 307542, PropertyName: "value", Value: "Body Mass Index Score:" },
        {
          Id: 17674,
          PropertyName: "value",
          Value:
            "Body Mass Index (BMI) indicates whether body weight is at an ideal level relative to height. Being overweight can lead to health issues such as diabetes, insulin resistance, musculoskeletal disorders, and cardiovascular diseases. Conversely, having a very low BMI can negatively affect health due to hormonal imbalances."
        },
        { Id: 667110, PropertyName: "value", Value: "With a height of" },
        { Id: 444833, PropertyName: "value", Value: "cm" },
        { Id: 521187, PropertyName: "value", Value: "and a body weight of" },
        { Id: 381442, PropertyName: "value", Value: "kg," },
        { Id: 886650, PropertyName: "value", Value: "the Body Mass Index (BMI) has been calculated as" },
        { Id: 613804, PropertyName: "value", Value: "." },
        { Id: 299514, PropertyName: "value", Value: "Your child's body weight is" },
        { Id: 997606, PropertyName: "value", Value: "significantly high" },
        {
          Id: 758973,
          PropertyName: "value",
          Value: "relative to their height. This body weight poses a health risk. We recommend"
        },
        {
          Id: 973465,
          PropertyName: "value",
          Value: "increasing your child's physical activity and seeking assistance from nutrition experts."
        },
        { Id: 955675, PropertyName: "value", Value: "Your child's body weight is" },
        { Id: 144742, PropertyName: "value", Value: "high" },
        {
          Id: 250116,
          PropertyName: "value",
          Value: "relative to their height. This body weight poses health risks. We recommend increasing your child's"
        },
        {
          Id: 713707,
          PropertyName: "value",
          Value: "physical activity and seeking assistance from nutrition experts."
        },
        { Id: 613902, PropertyName: "value", Value: "Your child's body weight is" },
        { Id: 606654, PropertyName: "value", Value: "slightly above the healthy level" },
        {
          Id: 383285,
          PropertyName: "value",
          Value: "relative to their height. We recommend increasing your child's physical"
        },
        { Id: 481996, PropertyName: "value", Value: "activity and paying attention to their nutrition." },
        { Id: 400374, PropertyName: "value", Value: "Your child's body weight is" },
        { Id: 331319, PropertyName: "value", Value: "within the healthy range" },
        {
          Id: 209834,
          PropertyName: "value",
          Value: "relative to their height. We recommend continuing to pay attention to your"
        },
        { Id: 922873, PropertyName: "value", Value: "child's physical activity and nutrition to maintain this level." },
        { Id: 829080, PropertyName: "value", Value: "Teacher's Mark" },
        {
          Id: 91953,
          PropertyName: "value",
          Value:
            "To learn how exercise improves your child's health and to get more information about health-related fitness components, click or scan the square code below."
        },
        { Id: 478049, PropertyName: "value", Value: "http://memap.com.tr/en/page/cocuk-saglik" },
        {
          Id: 593244,
          PropertyName: "value",
          Value:
            "You can seek assistance from your school's physical education teachers or MeMap instructors to improve your child's physical fitness."
        }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      ComponentToPdf: "",
      OpenPdf: "",
      GoBack: "",
      GetLanguage: "",
      MyContext: [],
      MeMapAll: [],
      GetChartLevels: [],
      CheckupSelectById: [],
      OgrenciSelectById: [],
      OkulLogo: [],
      GetCheckupChart: [],
      KalpGucuLine: [],
      AltEkstremiteLine: [],
      UstEkstremiteLine: [],
      GovdeLine: [],
      EsneklikLine: [],
      VucutKompozisyonuLine: [],
      OpenInBrowser: "",
      isComp921799Visible: "hidden",
      isComp984311Visible: "hidden",
      isComp980645Visible: "hidden",
      isComp620411Visible: "hidden",
      isComp385281Visible: "hidden",
      isComp205531Visible: "hidden",
      isComp763573Visible: "hidden",
      isComp411037Visible: "hidden",
      isComp385766Visible: "hidden",
      isComp835064Visible: "hidden",
      isComp799063Visible: "hidden",
      isComp821975Visible: "hidden",
      isComp470912Visible: "hidden",
      isComp748069Visible: "hidden",
      isComp114331Visible: "hidden",
      isComp905999Visible: "hidden",
      isComp996542Visible: "hidden",
      isComp624519Visible: "hidden",
      isComp109509Visible: "hidden",
      isComp241632Visible: "hidden",
      isComp568237Visible: "hidden",
      isComp422144Visible: "hidden",
      isComp525421Visible: "hidden",
      isComp139495Visible: "hidden",
      isComp978176Visible: "hidden",
      isComp502207Visible: "hidden",
      isComp92254Visible: "hidden",
      isComp623297Visible: "hidden",
      isComp884836Visible: "hidden",
      isComp254208Visible: "hidden",
      isComp386363Visible: "hidden",
      isComp830218Visible: "hidden",
      isComp586327Visible: "hidden",
      isComp748471Visible: "hidden",
      isComp189839Visible: "hidden",
      isComp907915Visible: "hidden",
      isComp823314Visible: "hidden",
      isComp78528Visible: "hidden",
      isComp353767Visible: "hidden",
      isComp344455Visible: "hidden",
      isComp438142Visible: "hidden",
      isComp438785Visible: "hidden",
      isComp803641Visible: "hidden",
      isComp299514Visible: "hidden",
      isComp997606Visible: "hidden",
      isComp758973Visible: "hidden",
      isComp973465Visible: "hidden",
      isComp955675Visible: "hidden",
      isComp144742Visible: "hidden",
      isComp250116Visible: "hidden",
      isComp713707Visible: "hidden",
      isComp613902Visible: "hidden",
      isComp606654Visible: "hidden",
      isComp383285Visible: "hidden",
      isComp481996Visible: "hidden",
      isComp400374Visible: "hidden",
      isComp331319Visible: "hidden",
      isComp209834Visible: "hidden",
      isComp922873Visible: "hidden"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkupraporuen", "checkupraporuen", this.props.context);
      return;
    }

    await this.CheckupRaporuENPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("checkupraporuen", "checkupraporuen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkupraporuen", "checkupraporuen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.CheckupRaporuENPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      checkupraporuen_106109_value: this.state.MyContext?.length > 0 ? this.state.MyContext[0].meMapLogo : undefined,
      checkupraporuen_63879_value: this.state.OkulLogo?.length > 0 ? this.state.OkulLogo[0].dinamikLogo : undefined
    });
  }

  CheckupRaporuENPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupRaporuENPageInit1_();
    });

    return isErrorOccurred;
  };
  CheckupRaporuENPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      olcumId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId, "Guid"),
      checkupId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId, "Guid"),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      sinifId_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sezonId_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      checkupId_6: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId, "Guid"),
      lang_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "CheckupRaporuEN/CheckupRaporuENPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.checkupraporuen_106109_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.meMapLogo : null
    );
    stateVars.MeMapAll = result?.data.meMapAll;
    stateVars.GetChartLevels = result?.data.getChartLevels;
    stateVars.CheckupSelectById = result?.data.checkupSelectById;
    formVars.checkupraporuen_117694_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yas : null
    );
    formVars.checkupraporuen_855199_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.olcumTarihiStr : null
    );
    formVars.checkupraporuen_803234_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporuen_536790_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporuen_145257_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporuen_20021_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporuen_361236_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporuen_389757_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporuen_595320_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.fitskorParantez : null
    );
    formVars.checkupraporuen_110991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucu : null
    );
    formVars.checkupraporuen_32945_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiKalpGucu : null
    );
    formVars.checkupraporuen_34991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifKalpGucu : null
    );
    formVars.checkupraporuen_835064_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporuen_799063_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporuen_821975_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporuen_470912_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporuen_748069_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanKalpGucuLine : null
    );
    formVars.checkupraporuen_525257_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.kalpGucuTur : null
    );
    formVars.checkupraporuen_310175_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.kalpGucuSeviye : null
    );
    formVars.checkupraporuen_689188_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.kalpGucuTespit : null
    );
    formVars.checkupraporuen_736688_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifKalpGucu : null
    );
    formVars.checkupraporuen_238454_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremite : null
    );
    formVars.checkupraporuen_212640_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiAltEkstremite : null
    );
    formVars.checkupraporuen_325532_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifAltEkstremite : null
    );
    formVars.checkupraporuen_905999_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporuen_996542_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporuen_624519_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporuen_109509_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporuen_241632_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );
    formVars.checkupraporuen_523110_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.altEkstremite : null
    );
    formVars.checkupraporuen_575800_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifAltEkstremite : null
    );
    formVars.checkupraporuen_268380_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremite : null
    );
    formVars.checkupraporuen_73735_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiUstEkstremite : null
    );
    formVars.checkupraporuen_853972_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifUstEkstremite : null
    );
    formVars.checkupraporuen_422144_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporuen_525421_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporuen_139495_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporuen_978176_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporuen_502207_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );
    formVars.checkupraporuen_650765_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.ustEkstremite : null
    );
    formVars.checkupraporuen_10824_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifUstEkstremite : null
    );
    formVars.checkupraporuen_117523_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovde : null
    );
    formVars.checkupraporuen_563400_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiGovde : null
    );
    formVars.checkupraporuen_2012_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifGovde : null
    );
    formVars.checkupraporuen_623297_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporuen_884836_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporuen_254208_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporuen_386363_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporuen_830218_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanGovdeLine : null
    );
    formVars.checkupraporuen_862551_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.govde : null
    );
    formVars.checkupraporuen_562817_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifGovde : null
    );
    formVars.checkupraporuen_508262_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklik : null
    );
    formVars.checkupraporuen_643145_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiEsneklik : null
    );
    formVars.checkupraporuen_130023_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifEsneklik : null
    );
    formVars.checkupraporuen_748471_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporuen_189839_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporuen_907915_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporuen_823314_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporuen_78528_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanEsneklikLine : null
    );
    formVars.checkupraporuen_722765_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.esneklik : null
    );
    formVars.checkupraporuen_751474_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifEsneklik : null
    );
    formVars.checkupraporuen_371218_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonu : null
    );
    formVars.checkupraporuen_175454_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonu : null
    );
    formVars.checkupraporuen_353767_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporuen_344455_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporuen_438142_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporuen_438785_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporuen_803641_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );
    formVars.checkupraporuen_829840_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.boy : null
    );
    formVars.checkupraporuen_810514_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.agirlik : null
    );
    formVars.checkupraporuen_139108_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.bmi : null
    );
    formVars.checkupraporuen_723989_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.egitmenNotu : null
    );

    stateVars.isComp984311Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ÇOK DÜŞÜK"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp980645Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "DÜŞÜK"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp620411Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ORTALAMA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385281Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "TATMİN EDİCİ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp205531Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "İYİ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ÇOK İYİ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp411037Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "MÜKEMMEL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385766Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiKalpGucu : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiAltEkstremite : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp568237Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiUstEkstremite : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp92254Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiGovde : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586327Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupSelectById?.length > 0 ? stateVars.CheckupSelectById[0]?.yaslandirmaYasiEsneklik : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299514Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758973Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955675Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144742Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp250116Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp713707Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp613902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp606654Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp383285Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp481996Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp400374Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp331319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp209834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp922873Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupSelectById?.length > 0
            ? stateVars.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.OgrenciSelectById = result?.data.ogrenciSelectById;
    formVars.checkupraporuen_792525_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.adi : null
    );
    formVars.checkupraporuen_250564_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.sinifi : null
    );
    formVars.checkupraporuen_828779_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulu : null
    );
    stateVars.OkulLogo = result?.data.okulLogo;
    formVars.checkupraporuen_63879_value = ReactSystemFunctions.toString(
      this,
      stateVars.OkulLogo?.length > 0 ? stateVars.OkulLogo[0]?.dinamikLogo : null
    );
    stateVars.GetCheckupChart = result?.data.getCheckupChart;
    stateVars.KalpGucuLine = result?.data.kalpGucuLine;
    stateVars.isComp835064Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821975Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp470912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp748069Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KalpGucuLine?.length > 0 ? stateVars.KalpGucuLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.AltEkstremiteLine = result?.data.altEkstremiteLine;
    stateVars.isComp905999Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624519Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp109509Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp241632Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AltEkstremiteLine?.length > 0 ? stateVars.AltEkstremiteLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.UstEkstremiteLine = result?.data.ustEkstremiteLine;
    stateVars.isComp422144Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp525421Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp978176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp502207Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UstEkstremiteLine?.length > 0 ? stateVars.UstEkstremiteLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GovdeLine = result?.data.govdeLine;
    stateVars.isComp623297Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.zeroLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp884836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.firstLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp254208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.secondLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386363Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.thirdLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830218Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GovdeLine?.length > 0 ? stateVars.GovdeLine[0]?.fourthLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.EsneklikLine = result?.data.esneklikLine;
    stateVars.isComp748471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp189839Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp907915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp823314Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78528Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EsneklikLine?.length > 0 ? stateVars.EsneklikLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.VucutKompozisyonuLine = result?.data.vucutKompozisyonuLine;
    stateVars.isComp353767Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344455Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438142Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438785Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp803641Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VucutKompozisyonuLine?.length > 0 ? stateVars.VucutKompozisyonuLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupRaporuENPageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupRaporuENPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp921799Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.ShowDownload, true) === true ? "visible" : "hidden";

    formVars.checkupraporuen_106109_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.meMapLogo : null
    );

    formVars.checkupraporuen_63879_value = ReactSystemFunctions.toString(
      this,
      this.state.OkulLogo?.length > 0 ? this.state.OkulLogo[0]?.dinamikLogo : null
    );

    formVars.checkupraporuen_792525_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.adi : null
    );

    formVars.checkupraporuen_117694_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yas : null
    );

    formVars.checkupraporuen_250564_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.sinifi : null
    );

    formVars.checkupraporuen_828779_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.okulu : null
    );

    formVars.checkupraporuen_855199_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.olcumTarihiStr : null
    );

    formVars.checkupraporuen_31410_chartText = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitSkorLbl : null
    );

    stateVars.dataSource_31410 = this.state.GetCheckupChart;
    stateVars.isComp984311Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ÇOK DÜŞÜK"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_803234_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp980645Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "DÜŞÜK"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_536790_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp620411Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ORTALAMA"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_145257_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp385281Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "TATMİN EDİCİ"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_20021_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp205531Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "İYİ"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_361236_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp763573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "ÇOK İYİ"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_389757_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    stateVars.isComp411037Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifFitSkor : null
        ),
        "MÜKEMMEL"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_595320_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.fitskorParantez : null
    );

    formVars.checkupraporuen_110991_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucu : null
    );

    stateVars.isComp385766Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiKalpGucu : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_32945_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiKalpGucu : null
    );

    formVars.checkupraporuen_34991_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifKalpGucu : null
    );

    formVars.checkupraporuen_835064_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp835064Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_799063_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp799063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_821975_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp821975Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_470912_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp470912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_748069_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanKalpGucuLine : null
    );

    stateVars.isComp748069Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KalpGucuLine?.length > 0 ? this.state.KalpGucuLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_525257_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.kalpGucuTur : null
    );

    formVars.checkupraporuen_310175_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.kalpGucuSeviye : null
    );

    formVars.checkupraporuen_689188_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.kalpGucuTespit : null
    );

    formVars.checkupraporuen_736688_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifKalpGucu : null
    );

    formVars.checkupraporuen_238454_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremite : null
    );

    stateVars.isComp114331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.yaslandirmaYasiAltEkstremite
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_212640_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiAltEkstremite : null
    );

    formVars.checkupraporuen_325532_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifAltEkstremite : null
    );

    formVars.checkupraporuen_905999_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp905999Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_996542_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp996542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_624519_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp624519Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_109509_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp109509Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_241632_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanAltEkstremiteLine : null
    );

    stateVars.isComp241632Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AltEkstremiteLine?.length > 0 ? this.state.AltEkstremiteLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_523110_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.altEkstremite : null
    );

    formVars.checkupraporuen_575800_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifAltEkstremite : null
    );

    formVars.checkupraporuen_268380_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremite : null
    );

    stateVars.isComp568237Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.yaslandirmaYasiUstEkstremite
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_73735_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiUstEkstremite : null
    );

    formVars.checkupraporuen_853972_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifUstEkstremite : null
    );

    formVars.checkupraporuen_422144_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp422144Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_525421_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp525421Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_139495_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp139495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_978176_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp978176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_502207_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanUstEkstremiteLine : null
    );

    stateVars.isComp502207Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UstEkstremiteLine?.length > 0 ? this.state.UstEkstremiteLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_650765_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.ustEkstremite : null
    );

    formVars.checkupraporuen_10824_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifUstEkstremite : null
    );

    formVars.checkupraporuen_117523_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovde : null
    );

    stateVars.isComp92254Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiGovde : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_563400_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiGovde : null
    );

    formVars.checkupraporuen_2012_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifGovde : null
    );

    formVars.checkupraporuen_623297_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp623297Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.zeroLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_884836_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp884836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.firstLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_254208_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp254208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.secondLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_386363_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp386363Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.thirdLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_830218_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanGovdeLine : null
    );

    stateVars.isComp830218Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GovdeLine?.length > 0 ? this.state.GovdeLine[0]?.fourthLine : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_862551_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.govde : null
    );

    formVars.checkupraporuen_562817_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifGovde : null
    );

    formVars.checkupraporuen_508262_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklik : null
    );

    stateVars.isComp586327Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiEsneklik : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_643145_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.yaslandirmaYasiEsneklik : null
    );

    formVars.checkupraporuen_130023_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifEsneklik : null
    );

    formVars.checkupraporuen_748471_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp748471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_189839_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp189839Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_907915_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp907915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_823314_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp823314Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_78528_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanEsneklikLine : null
    );

    stateVars.isComp78528Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EsneklikLine?.length > 0 ? this.state.EsneklikLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_722765_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.esneklik : null
    );

    formVars.checkupraporuen_751474_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifEsneklik : null
    );

    formVars.checkupraporuen_371218_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonu : null
    );

    formVars.checkupraporuen_175454_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonu : null
    );

    formVars.checkupraporuen_353767_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp353767Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.zeroLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_344455_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp344455Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.firstLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_438142_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp438142Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.secondLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_438785_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp438785Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.thirdLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_803641_percent = ReactSystemFunctions.value(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.puanVucutKompozisyonuLine : null
    );

    stateVars.isComp803641Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VucutKompozisyonuLine?.length > 0 ? this.state.VucutKompozisyonuLine[0]?.fourthLine : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_829840_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.boy : null
    );

    formVars.checkupraporuen_810514_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.agirlik : null
    );

    formVars.checkupraporuen_139108_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.bmi : null
    );

    stateVars.isComp299514Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758973Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "ÇOK FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955675Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144742Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp250116Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp713707Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "FAZLA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp613902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp606654Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp383285Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp481996Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMALİN AZ ÜSTÜ"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp400374Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp331319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp209834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp922873Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CheckupSelectById?.length > 0
            ? this.state.CheckupSelectById[0]?.sinifVucutKompozisyonuAciklama
            : null
        ),
        "NORMAL"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupraporuen_723989_value = ReactSystemFunctions.toString(
      this,
      this.state.CheckupSelectById?.length > 0 ? this.state.CheckupSelectById[0]?.egitmenNotu : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupRaporuENComponent_921799_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ComponentToPdf = await ReactSystemFunctions.componentToPDF("462381", "HIGH", "portrait");
    stateVars.OpenPdf = await ReactSystemFunctions.openPdf(
      ReactSystemFunctions.toString(this, stateVars.ComponentToPdf),
      false,
      "newTab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupRaporuENComponent_447149_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupRaporuENComponent_478049_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("http://memap.com.tr/tr/page/cocuk-saglik");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
