import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  HorizontalStack,
  Icon,
  KCol,
  KDrawer,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SideBarMenu_Screen } from "../sidebarmenu/sidebarmenu";
import { SezonList_ScreenBase } from "./sezonlist-base";

import { SezonForm_Screen } from "../sezonform/sezonform";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class SezonList_Screen extends SezonList_ScreenBase {
  render() {
    return (
      <>
        <SideBarMenu_Screen
          form={this.props.form}
          initialValues={{ sezonlist_416063_value: undefined }}
          screenInputs={this.props.screenInputs}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="sezonlist_body"
              style={
                {
                  backgroundColor: "rgba(245, 247, 250, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 0px)",
                  maxHeight: "calc(100vh - 0px)",
                  height: "calc(100vh - 0px)"
                } as any
              }
            >
              <KRow
                id="331958"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="44993"
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <HorizontalStack
                    id="362740"
                    direction="horizontal"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="377460"
                      value={ReactSystemFunctions.translate(this.ml, 377460, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "18pt",
                          color: "rgba(0, 0, 0, 1)"
                        } as any
                      }
                    ></Label>

                    <Button
                      id="520994"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.SezonListComponent_520994_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 520994, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 4,
                          paddingRight: 8,
                          paddingBottom: 4,
                          paddingLeft: 8,
                          textAlign: "-webkit-center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(0, 190, 214, 1)",
                          textDecoration: "underline"
                        } as any
                      }
                    ></Button>
                  </HorizontalStack>
                </KCol>

                <KCol
                  id="775216"
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      textAlign: "-webkit-right",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="706694"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="643308"
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <Form.Item className="kFormItem" name="sezonlist_416063_value">
                        <TextInput
                          id="416063"
                          onPressEnter={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SezonListComponent_416063_onPressEnter();
                          }}
                          placeholder={ReactSystemFunctions.translate(this.ml, 416063, "placeholder", this.defaultML)}
                          allowClear={false}
                          bordered={true}
                          disabled={false}
                          type="text"
                          iconColor="rgba(0, 0, 0, 1)"
                          formatter="None"
                          isCharOnly={false}
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(255, 255, 255, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></TextInput>
                      </Form.Item>
                    </KCol>

                    <KCol
                      id="710712"
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <Button
                        id="814413"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.SezonListComponent_814413_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 814413, "label", this.defaultML)}
                        size="middle"
                        loading={false}
                        ghost={false}
                        block={false}
                        htmlType="button"
                        iconPosition="left"
                        danger={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            backgroundColor: "rgba(0, 190, 214, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "rgba(255, 255, 255, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Button>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KRow
                id="720564"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="769800"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                      borderBottomLeftRadius: 8,
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(10, 34, 64, 1)"
                    } as any
                  }
                >
                  <KTable
                    id="399769"
                    kuikaRef={this.sezonlist_399769_value_kuikaTableRef}
                    form={this.props.form}
                    dataSource={this.state.SezonSearch}
                    size="middle"
                    bordered={true}
                    showHeader={true}
                    loading={false}
                    nodatafoundmessage={ReactSystemFunctions.translate(
                      this.ml,
                      399769,
                      "nodatafoundmessage",
                      this.defaultML
                    )}
                    sorter={false}
                    pagination={false}
                    striped={true}
                    stripedColor="rgba(255, 255, 255, 1)"
                    rowHoverBgColor="rgba(0, 190, 214, 0.1)"
                    insertRowActive={false}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  >
                    <KTableHeader
                      id="500856"
                      hideOnMobileResolution={false}
                      textDirection="Default"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "12px",
                          color: "rgba(10, 34, 64, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></KTableHeader>

                    <KTableRow
                      id="489075"
                      hoverFontColor="red"
                      hoverBgColor="#F5F5F5"
                      style={
                        {
                          borderTopWidth: 1,
                          borderRightWidth: 1,
                          borderBottomWidth: 1,
                          borderLeftWidth: 1,
                          borderColor: "rgba(218, 218, 218, 1)",
                          borderStyle: "solid",
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          height: "30px",
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 34, 64, 1)"
                        } as any
                      }
                    >
                      <KTableColumn
                        id="8551970"
                        visibility={this.state.isCompsezonlist_8551970AuthorizationVisible}
                        visibilityByAuthorization={this.state.isCompsezonlist_8551970AuthorizationVisible}
                        title={ReactSystemFunctions.translate(this.ml, 8551970, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        style={
                          {
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="5776885"
                          value="[datafield:okulAdi]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="7784155"
                        title={ReactSystemFunctions.translate(this.ml, 7784155, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        style={
                          {
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="8372275"
                          value="[datafield:tanim]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="742116"
                        title={ReactSystemFunctions.translate(this.ml, 742116, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        style={
                          {
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <Icon
                          id="936138"
                          visExField1="[datafield:aktifMi]"
                          visExField2="1"
                          visExRule="isEqualTo"
                          iconName="check"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "20px",
                              color: "rgba(0, 190, 214, 1)",
                              letterSpacing: "1px"
                            } as any
                          }
                        ></Icon>

                        <Button
                          id="713030"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.SezonListComponent_713030_onClick();
                          }}
                          showCursorPointer
                          visExField1="[datafield:aktifMi]"
                          visExField2="0"
                          visExRule="isEqualTo"
                          label={ReactSystemFunctions.translate(this.ml, 713030, "label", this.defaultML)}
                          size="middle"
                          loading={false}
                          ghost={false}
                          block={false}
                          htmlType="button"
                          iconPosition="left"
                          danger={false}
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(0, 190, 214, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 4,
                              paddingRight: 8,
                              paddingBottom: 4,
                              paddingLeft: 8,
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "16px",
                              color: "rgba(255, 255, 255, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Button>
                      </KTableColumn>

                      <KTableColumn
                        id="792509"
                        title={ReactSystemFunctions.translate(this.ml, 792509, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        style={
                          {
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            width: "120px",
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(10, 34, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="893506"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="207745"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.SezonListComponent_207745_onClick();
                            }}
                            showCursorPointer
                            visExField1="[datafield:kayitSayisi]"
                            visExField2="0"
                            visExRule="isEqualTo"
                            iconName="upload"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(155, 155, 155, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>

                          <Icon
                            id="860665"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.SezonListComponent_860665_onClick();
                            }}
                            showCursorPointer
                            iconName="create"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(155, 155, 155, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>

                          <Icon
                            id="505853"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.SezonListComponent_505853_onClick();
                            }}
                            showCursorPointer
                            iconName="delete"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(155, 155, 155, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </HorizontalStack>
                      </KTableColumn>
                    </KTableRow>
                  </KTable>

                  <KPagination
                    id="172599"
                    onChange={(e?: any) => {
                      if (e && e.stopPropagation) e.stopPropagation();
                      this.SezonListComponent_172599_onChange();
                    }}
                    kuikaRef={this.sezonlist_172599_value_kuikaPaginationRef}
                    total={this.state.SezonCount?.length > 0 ? this.state.SezonCount[0].count : undefined}
                    pageSize={10}
                    defaultCurrent={1}
                    current={1}
                    defaultPageSize={10}
                    disabled={false}
                    hideOnSinglePage={true}
                    showLessItems={false}
                    showQuickJumper={false}
                    showSizeChanger={false}
                    showFirstPageButton={true}
                    showLastPageButton={true}
                    showNumbers={false}
                    showText={false}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 34, 64, 1)"
                      } as any
                    }
                  ></KPagination>
                </KCol>
              </KRow>

              <KDrawer
                id="256479"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible256479: false }, () => {
                    this.callCallbackFunction(256479);
                  });
                }}
                visible={this.state.NavVisible256479}
                width="375px"
                push={true}
                children={
                  this.state.NavVisible256479 && (
                    <SezonForm_Screen
                      onClose={() => {
                        this.setState({ NavVisible256479: false }, () => {
                          this.callCallbackFunction(256479);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="486227"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible486227: false }, () => {
                    this.callCallbackFunction(486227);
                  });
                }}
                visible={this.state.NavVisible486227}
                width="375px"
                height="vh"
                push={true}
                children={
                  this.state.NavVisible486227 && (
                    <SezonForm_Screen
                      onClose={() => {
                        this.setState({ NavVisible486227: false }, () => {
                          this.callCallbackFunction(486227);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(10, 34, 64, 1)"
                  } as any
                }
              ></KDrawer>
            </div>
          </Spin>
        </SideBarMenu_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(SezonList_Screen)))))
);
export { tmp as SezonList_Screen };
//export default tmp;
//export { tmp as SezonList_Screen };
