import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAyarlar_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAyarlar_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  SinifSelectAll: any[];
  GetLanguage: string;
  ReadFromLocalStorage: string;
  SendMail: boolean;
  PhotoFromGallery: string;
  SetValueOf: any;
  MeMapSave: number;
  Notify: boolean;
  SetLanguage: string;
  WriteToLocalStorage: any;
  UploadFile: any;
  ImportExcelBase64: any[];
}

export class Ayarlar_ScreenBase extends React.PureComponent<IAyarlar_ScreenProps, any> {
  ayarlar_8676401_value_kuikaTableRef: React.RefObject<any>;
  ayarlar_614386_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 461915, PropertyName: "placeholder", Value: "to:", TagName: "TextInput1_placeholder" },
        { Id: 228661, PropertyName: "label", Value: "SEND MAIL", TagName: "Button1_label" },
        { Id: 841528, PropertyName: "value", Value: "Logo", TagName: "Label1_value" },
        { Id: 534343, PropertyName: "value", Value: "QR Code", TagName: "Label2_value" },
        { Id: 313622, PropertyName: "label", Value: "SAVE", TagName: "Button2_label" },
        { Id: 997499, PropertyName: "tab", Value: "Tab Item", TagName: "TabItem1_0_tab" },
        {
          Id: 8676401,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblSinifselectall_8676401_nodatafoundmessage"
        },
        { Id: 2812573, PropertyName: "title", Value: "Season ID", TagName: "clmSezonId_2812573_title" },
        { Id: 8010890, PropertyName: "value", Value: "[datafield:seasonid]", TagName: "lblSezonId_8010890_value" },
        { Id: 2553616, PropertyName: "title", Value: "Name", TagName: "clmAdi_2553616_title" },
        { Id: 6282357, PropertyName: "value", Value: "[datafield:name]", TagName: "lblAdi_6282357_value" },
        { Id: 106991, PropertyName: "tab", Value: "Tab Item", TagName: "TabItem1_1_tab" },
        {
          Id: 614386,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "Table2_nodatafoundmessage"
        },
        { Id: 539445, PropertyName: "title", Value: "Season ID", TagName: "TableColumn2_0_title" },
        { Id: 812347, PropertyName: "value", Value: "[datafield:seasonid]", TagName: "Label4_0_value" },
        { Id: 793691, PropertyName: "title", Value: "Name", TagName: "TableColumn2_1_title" },
        { Id: 192751, PropertyName: "value", Value: "[datafield:name]", TagName: "Label4_0_value" },
        { Id: 359624, PropertyName: "tab", Value: "Tab Item", TagName: "TabItem1_2_tab" },
        { Id: 18116, PropertyName: "value", Value: "to:", TagName: "Label10_value" },
        { Id: 290483, PropertyName: "placeholder", Value: "Type here...", TagName: "txtLang_placeholder" },
        { Id: 542168, PropertyName: "label", Value: "SET LANGUAGE", TagName: "Button3_label" },
        { Id: 217190, PropertyName: "value", Value: "get language", TagName: "Label9_value" },
        { Id: 708831, PropertyName: "placeholder", Value: "Type here...", TagName: "TextInput3_placeholder" },
        { Id: 496528, PropertyName: "value", Value: "local storage", TagName: "Label8_value" },
        { Id: 905678, PropertyName: "placeholder", Value: "Type here...", TagName: "TextInput4_placeholder" }
      ]
    },
    {
      Id: "b5f9b3fc-3f90-4208-a2e9-c0a6e32af0f2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 18116, PropertyName: "value", Value: "kime:" },
        { Id: 461915, PropertyName: "placeholder", Value: "kime:" },
        { Id: 228661, PropertyName: "label", Value: "EPOSTA GÖNDER" },
        { Id: 841528, PropertyName: "value", Value: "Logo" },
        { Id: 534343, PropertyName: "value", Value: "KareKod" },
        { Id: 313622, PropertyName: "label", Value: "KAYDET" },
        { Id: 997499, PropertyName: "tab", Value: "Tab Item" },
        { Id: 8676401, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 2812573, PropertyName: "title", Value: "Sezon id" },
        { Id: 8010890, PropertyName: "value", Value: "[datafield:sezonid]" },
        { Id: 2553616, PropertyName: "title", Value: "Adi" },
        { Id: 6282357, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 106991, PropertyName: "tab", Value: "Tab Item" },
        { Id: 614386, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 539445, PropertyName: "title", Value: "Sezon id" },
        { Id: 812347, PropertyName: "value", Value: "[datafield:sezonid]" },
        { Id: 793691, PropertyName: "title", Value: "Adi" },
        { Id: 192751, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 359624, PropertyName: "tab", Value: "Tab Item" },
        { Id: 290483, PropertyName: "placeholder", Value: "Buraya yazın..." },
        { Id: 542168, PropertyName: "label", Value: "SET LANGUAGE" },
        { Id: 217190, PropertyName: "value", Value: "dil ekle" },
        { Id: 708831, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 496528, PropertyName: "value", Value: "local depo" },
        { Id: 905678, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 424003, PropertyName: "label", Value: "XLS Upload" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ayarlar_8676401_value_kuikaTableRef = React.createRef();
    this.ayarlar_614386_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      SinifSelectAll: [],
      GetLanguage: "",
      ReadFromLocalStorage: "",
      SendMail: false,
      PhotoFromGallery: "",
      SetValueOf: "",
      MeMapSave: 0,
      Notify: false,
      SetLanguage: "",
      WriteToLocalStorage: "",
      UploadFile: "",
      ImportExcelBase64: []
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ayarlar", "sidebarmenu_screen", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.AyarlarPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("ayarlar", "sidebarmenu_screen", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ayarlar", "sidebarmenu_screen", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.AyarlarPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ayarlar_708831_value: this.state.GetLanguage,
      ayarlar_905678_value: this.state.ReadFromLocalStorage
    });
  }

  AyarlarPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifSelectAll = result?.data.sinifSelectAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AyarlarPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AyarlarPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    formVars.ayarlar_708831_value = ReactSystemFunctions.toString(this, stateVars.GetLanguage);
    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("lan");
    formVars.ayarlar_905678_value = ReactSystemFunctions.toString(this, stateVars.ReadFromLocalStorage);

    stateVars.dataSource_8676401 = this.state.SinifSelectAll;

    stateVars.dataSource_614386 = this.state.SinifSelectAll;
    formVars.ayarlar_708831_value = ReactSystemFunctions.toString(this, stateVars.GetLanguage);

    formVars.ayarlar_905678_value = ReactSystemFunctions.toString(this, stateVars.ReadFromLocalStorage);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_228661_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_461915_value", "value", "", "", "")),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("MEMAP TEST", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      body_0: ReactSystemFunctions.convertToTypeByName("TEST", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_228661_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AyarlarComponent_919676_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ayarlar_296695_value",
      ReactSystemFunctions.value(this, stateVars.PhotoFromGallery),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_636111_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ayarlar_255763_value",
      ReactSystemFunctions.value(this, stateVars.PhotoFromGallery),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_313622_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KareKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_255763_value", "value", "", "", "")),
        "string"
      ),
      Logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_296695_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_313622_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MeMapSave = result?.data.meMapSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AyarlarComponent_542168_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    formVars.ayarlar_708831_value = ReactSystemFunctions.toString(this, stateVars.GetLanguage);
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.toString(this, stateVars.GetLanguage),
      "default",
      "bottom-right",
      0
    );
    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("lan");
    formVars.ayarlar_905678_value = ReactSystemFunctions.toString(this, stateVars.ReadFromLocalStorage);
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.toString(this, stateVars.ReadFromLocalStorage),
      "default",
      "bottom-right",
      0
    );
    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(
      this,
      ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_290483_value", "value", "", "", ""))
    );
    stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage(
      "lan",
      ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ayarlar_290483_value", "value", "", "", ""))
    );
    formVars.ayarlar_708831_value = ReactSystemFunctions.toString(this, stateVars.GetLanguage);

    formVars.ayarlar_905678_value = ReactSystemFunctions.toString(this, stateVars.ReadFromLocalStorage);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AyarlarComponent_424003_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AyarlarComponent_424003_onClick1_();
    });

    return isErrorOccurred;
  };
  AyarlarComponent_424003_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      excelBase64_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Ayarlar/AyarlarComponent_424003_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
