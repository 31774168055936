import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { SezonForm_Screen } from "../screens/sezonform/sezonform";
import { OgrenciIncele_Screen } from "../screens/ogrenciincele/ogrenciincele";
import { Signin_Screen } from "../screens/signin/signin";
import { Ailem_Screen } from "../screens/ailem/ailem";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { Profil_Screen } from "../screens/profil/profil";
import { OgrenciList_Screen } from "../screens/ogrencilist/ogrencilist";
import { OgretmenList_Screen } from "../screens/ogretmenlist/ogretmenlist";
import { SinifList_Screen } from "../screens/siniflist/siniflist";
import { Signup_Screen } from "../screens/signup/signup";
import { DashboardFitBilesenDetay_Screen } from "../screens/dashboardfitbilesendetay/dashboardfitbilesendetay";
import { CheckupRaporuEN_Screen } from "../screens/checkupraporuen/checkupraporuen";
import { OgretmenSinifEkle_Screen } from "../screens/ogretmensinifekle/ogretmensinifekle";
import { SinifOgrenciList_Screen } from "../screens/sinifogrencilist/sinifogrencilist";
import { CheckupForm_Screen } from "../screens/checkupform/checkupform";
import { VeliOgrenciEkleme_Screen } from "../screens/veliogrenciekleme/veliogrenciekleme";
import { OgretmeninSiniflari_Screen } from "../screens/ogretmeninsiniflari/ogretmeninsiniflari";
import { SinifTopluOlcumList_Screen } from "../screens/siniftopluolcumlist/siniftopluolcumlist";
import { VeliList_Screen } from "../screens/velilist/velilist";
import { Statistics_Screen } from "../screens/statistics/statistics";
import { SezonList_Screen } from "../screens/sezonlist/sezonlist";
import { ProfilEdit_Screen } from "../screens/profiledit/profiledit";
import { TicariIletisimOnay_Screen } from "../screens/ticariiletisimonay/ticariiletisimonay";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";
import { StatisticsDetails_Screen } from "../screens/statisticsdetails/statisticsdetails";
import { OgretmenForm_Screen } from "../screens/ogretmenform/ogretmenform";
import { OgrenciForm_Screen } from "../screens/ogrenciform/ogrenciform";
import { SinifForm_Screen } from "../screens/sinifform/sinifform";
import { DashboardKatilimDetay_Screen } from "../screens/dashboardkatilimdetay/dashboardkatilimdetay";
import { CheckupDonemiForm_Screen } from "../screens/checkupdonemiform/checkupdonemiform";
import { SinifCheckupForm_Screen } from "../screens/sinifcheckupform/sinifcheckupform";
import { Ayarlar_Screen } from "../screens/ayarlar/ayarlar";
import { CheckupDonemiList_Screen } from "../screens/checkupdonemilist/checkupdonemilist";
import { CheckupList_Screen } from "../screens/checkuplist/checkuplist";
import { SinifStatistics_Screen } from "../screens/sinifstatistics/sinifstatistics";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { VeliForm_Screen } from "../screens/veliform/veliform";
import { SideBarMenu_Screen } from "../screens/sidebarmenu/sidebarmenu";
import { OkulForm_Screen } from "../screens/okulform/okulform";
import { OkulList_Screen } from "../screens/okullist/okullist";
import { KullaniciForm_Screen } from "../screens/kullaniciform/kullaniciform";
import { VelininOgrencileri_Screen } from "../screens/velininogrencileri/velininogrencileri";
import { OgrencininVelileri_Screen } from "../screens/ogrencininvelileri/ogrencininvelileri";
import { OgrenciStatistics_Screen } from "../screens/ogrencistatistics/ogrencistatistics";
import { KullaniciList_Screen } from "../screens/kullanicilist/kullanicilist";
import { Dashboard_Screen } from "../screens/dashboard/dashboard";
import { VerificationCodecopy_Screen } from "../screens/verificationcodecopy/verificationcodecopy";
import { UyelikSozlesme_Screen } from "../screens/uyeliksozlesme/uyeliksozlesme";
import { KVKK_Screen } from "../screens/kvkk/kvkk";
import { CheckupRaporu_Screen } from "../screens/checkupraporu/checkupraporu";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={Ailem_Screen} />
                        <PrivateRoute exact path="/sezonform" component={SezonForm_Screen} />
            <PrivateRoute exact path="/ogrenciincele" component={OgrenciIncele_Screen} />
            <Route exact path="/signin" component={Signin_Screen} />
            <PrivateRoute exact path="/ailem" component={Ailem_Screen} />
            <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <PrivateRoute exact path="/profil" component={Profil_Screen} />
            <PrivateRoute exact path="/ogrencilist" component={OgrenciList_Screen} />
            <PrivateRoute exact path="/ogretmenlist" component={OgretmenList_Screen} />
            <PrivateRoute exact path="/siniflist" component={SinifList_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <PrivateRoute exact path="/dashboardfitbilesendetay" component={DashboardFitBilesenDetay_Screen} />
            <Route exact path="/checkupraporuen" component={CheckupRaporuEN_Screen} />
            <PrivateRoute exact path="/ogretmensinifekle" component={OgretmenSinifEkle_Screen} />
            <PrivateRoute exact path="/sinifogrencilist" component={SinifOgrenciList_Screen} />
            <PrivateRoute exact path="/checkupform" component={CheckupForm_Screen} />
            <PrivateRoute exact path="/veliogrenciekleme" component={VeliOgrenciEkleme_Screen} />
            <PrivateRoute exact path="/ogretmeninsiniflari" component={OgretmeninSiniflari_Screen} />
            <PrivateRoute exact path="/siniftopluolcumlist" component={SinifTopluOlcumList_Screen} />
            <PrivateRoute exact path="/velilist" component={VeliList_Screen} />
            <PrivateRoute exact path="/statistics" component={Statistics_Screen} />
            <PrivateRoute exact path="/sezonlist" component={SezonList_Screen} />
            <PrivateRoute exact path="/profiledit" component={ProfilEdit_Screen} />
            <PrivateRoute exact path="/ticariiletisimonay" component={TicariIletisimOnay_Screen} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />
            <PrivateRoute exact path="/statisticsdetails" component={StatisticsDetails_Screen} />
            <PrivateRoute exact path="/ogretmenform" component={OgretmenForm_Screen} />
            <PrivateRoute exact path="/ogrenciform" component={OgrenciForm_Screen} />
            <PrivateRoute exact path="/sinifform" component={SinifForm_Screen} />
            <PrivateRoute exact path="/dashboardkatilimdetay" component={DashboardKatilimDetay_Screen} />
            <PrivateRoute exact path="/checkupdonemiform" component={CheckupDonemiForm_Screen} />
            <PrivateRoute exact path="/sinifcheckupform" component={SinifCheckupForm_Screen} />
            <PrivateRoute exact path="/ayarlar" component={Ayarlar_Screen} />
            <PrivateRoute exact path="/checkupdonemilist" component={CheckupDonemiList_Screen} />
            <PrivateRoute exact path="/checkuplist" component={CheckupList_Screen} />
            <PrivateRoute exact path="/sinifstatistics" component={SinifStatistics_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <PrivateRoute exact path="/veliform" component={VeliForm_Screen} />
            <PrivateRoute exact path="/okulform" component={OkulForm_Screen} />
            <PrivateRoute exact path="/okullist" component={OkulList_Screen} />
            <PrivateRoute exact path="/kullaniciform" component={KullaniciForm_Screen} />
            <PrivateRoute exact path="/velininogrencileri" component={VelininOgrencileri_Screen} />
            <PrivateRoute exact path="/ogrencininvelileri" component={OgrencininVelileri_Screen} />
            <PrivateRoute exact path="/ogrencistatistics" component={OgrenciStatistics_Screen} />
            <PrivateRoute exact path="/kullanicilist" component={KullaniciList_Screen} />
            <PrivateRoute exact path="/dashboard" component={Dashboard_Screen} />
            <Route exact path="/verificationcodecopy" component={VerificationCodecopy_Screen} />
            <PrivateRoute exact path="/uyeliksozlesme" component={UyelikSozlesme_Screen} />
            <PrivateRoute exact path="/kvkk" component={KVKK_Screen} />
            <Route exact path="/checkupraporu" component={CheckupRaporu_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }
    if (this.props.authType === "Kuika") {
      return Login;
    }
    return <></>;
  };
}
