import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  GetLanguage: string;
  MyContext: any[];
  OgrenciSelectById: any[];
  SinifSelectById: any[];
  OkulSelectById: any[];
  CheckupListByOgrenci: any[];
  CheckupExport: any[];
  ExportExcel: any;
  NAVIGATE: any;
  CheckupDeleteById: number;
}

export class CheckupList_ScreenBase extends React.PureComponent<ICheckupList_ScreenProps, any> {
  checkuplist_399769_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 832576, PropertyName: "value", Value: "Check-Up List", TagName: "Label10_0_value" },
        { Id: 490878, PropertyName: "value", Value: "Student", TagName: "Label11_value" },
        { Id: 318236, PropertyName: "label", Value: "Export", TagName: "Button2_label" },
        { Id: 814413, PropertyName: "label", Value: "NEW CHECK-UP", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data Not Found",
          TagName: "tblOlcum_nodatafoundmessage"
        },
        { Id: 1142521, PropertyName: "title", Value: "Evaluation Date", TagName: "clmOlcumTarihi_title" },
        { Id: 6206830, PropertyName: "value", Value: "[datafield:evaluationdate]", TagName: "lblOlcumTarihi_value" },
        { Id: 1770690, PropertyName: "title", Value: "Age", TagName: "clmAgirlik_title" },
        { Id: 8956927, PropertyName: "value", Value: "[datafield:age]", TagName: "lblAgirlik_value" },
        { Id: 487476, PropertyName: "title", Value: "Weight", TagName: "TableColumn6_title" },
        { Id: 194480, PropertyName: "value", Value: "[datafield:weight]", TagName: "Label1_0_value" },
        { Id: 2058824, PropertyName: "title", Value: "Height", TagName: "clmBoy_title" },
        { Id: 3231944, PropertyName: "value", Value: "[datafield:height]", TagName: "lblBoy_value" },
        { Id: 871118, PropertyName: "title", Value: "BMI", TagName: "TableColumn1_title" },
        { Id: 695768, PropertyName: "value", Value: "[datafield:bmi]", TagName: "Label1_0_value" },
        { Id: 802235, PropertyName: "title", Value: "Body Composition", TagName: "TableColumn9_title" },
        { Id: 655008, PropertyName: "value", Value: "[datafield:bodycomposition]", TagName: "Label1_0_value" },
        { Id: 230171, PropertyName: "title", Value: "Heart Strength", TagName: "TableColumn7_title" },
        { Id: 452893, PropertyName: "value", Value: "[datafield:heartstrenghtscore]", TagName: "Label1_0_value" },
        { Id: 28878, PropertyName: "title", Value: "Flexibility", TagName: "TableColumn2_title" },
        { Id: 330794, PropertyName: "value", Value: "[datafield:flexibilityscore]", TagName: "Label1_0_value" },
        { Id: 207551, PropertyName: "title", Value: "Upper Extremity", TagName: "TableColumn3_title" },
        { Id: 965769, PropertyName: "value", Value: "[datafield:upperextremityscore]", TagName: "Label1_0_value" },
        { Id: 248240, PropertyName: "title", Value: "Core", TagName: "TableColumn4_title" },
        { Id: 187001, PropertyName: "value", Value: "[datafield:trunkscore]", TagName: "Label1_0_value" },
        { Id: 498184, PropertyName: "title", Value: "Lower Extremity", TagName: "TableColumn5_title" },
        { Id: 534507, PropertyName: "value", Value: "[datafield:lowerextremityscore]", TagName: "Label1_0_value" },
        { Id: 939430, PropertyName: "title", Value: "FitSkor®", TagName: "TableColumn8_title" },
        { Id: 352970, PropertyName: "value", Value: "[datafield:fitskor]", TagName: "Label1_0_value" },
        {
          Id: 95917502,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this measurement?",
          TagName: "CheckupDeleteById_confirmation",
          IsConfirmation: true
        }
      ]
    },
    {
      Id: "f388ff43-0636-45bd-9bee-b4de997ff8bc",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 832576, PropertyName: "value", Value: "Check-Up Listesi" },
        { Id: 490878, PropertyName: "value", Value: "Öğrenci" },
        { Id: 318236, PropertyName: "label", Value: "Export" },
        { Id: 814413, PropertyName: "label", Value: "YENİ CHECK-UP" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 1142521, PropertyName: "title", Value: "Ölçüm Tarihi" },
        { Id: 6206830, PropertyName: "value", Value: "[datafield:olcumtarihi]" },
        { Id: 1770690, PropertyName: "title", Value: "Yaş" },
        { Id: 8956927, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 487476, PropertyName: "title", Value: "Ağırlık" },
        { Id: 194480, PropertyName: "value", Value: "[datafield:agirlik]" },
        { Id: 2058824, PropertyName: "title", Value: "Boy" },
        { Id: 3231944, PropertyName: "value", Value: "[datafield:boy]" },
        { Id: 871118, PropertyName: "title", Value: "BMI" },
        { Id: 695768, PropertyName: "value", Value: "[datafield:bmi]" },
        { Id: 802235, PropertyName: "title", Value: "Bed. Kit. İndeksi" },
        { Id: 655008, PropertyName: "value", Value: "[datafield:puanvucutkompozisyonu]" },
        { Id: 230171, PropertyName: "title", Value: "Kalp Gücü" },
        { Id: 452893, PropertyName: "value", Value: "[datafield:puankalpgucu]" },
        { Id: 28878, PropertyName: "title", Value: "Esneklik" },
        { Id: 330794, PropertyName: "value", Value: "[datafield:puanesneklik]" },
        { Id: 207551, PropertyName: "title", Value: "Üst Ekstremite" },
        { Id: 965769, PropertyName: "value", Value: "[datafield:puanustekstremite]" },
        { Id: 248240, PropertyName: "title", Value: "Gövde" },
        { Id: 187001, PropertyName: "value", Value: "[datafield:puangovde]" },
        { Id: 498184, PropertyName: "title", Value: "Alt Ekstremite" },
        { Id: 534507, PropertyName: "value", Value: "[datafield:puanaltekstremite]" },
        { Id: 939430, PropertyName: "title", Value: "FitSkor" },
        { Id: 352970, PropertyName: "value", Value: "[datafield:fitskor]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.checkuplist_399769_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      GetLanguage: "",
      MyContext: [],
      OgrenciSelectById: [],
      SinifSelectById: [],
      OkulSelectById: [],
      CheckupListByOgrenci: [],
      CheckupExport: [],
      ExportExcel: "",
      NAVIGATE: "",
      CheckupDeleteById: 0
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkuplist", "checkuplist", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.CheckupListPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("checkuplist", "checkuplist", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkuplist", "checkuplist", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.CheckupListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  CheckupListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupListPageInit1_();
    });

    return isErrorOccurred;
  };
  CheckupListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      sinifId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sezonId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sezonId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      ogrenciId_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      lang_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "CheckupList/CheckupListPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OgrenciSelectById = result?.data.ogrenciSelectById;
    formVars.checkuplist_828205_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.adi : null
    );
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    stateVars.OkulSelectById = result?.data.okulSelectById;
    stateVars.CheckupListByOgrenci = result?.data.checkupListByOgrenci;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupListPageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.checkuplist_828205_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.adi : null
    );

    stateVars.dataSource_399769 = this.state.CheckupListByOgrenci;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupListComponent_928392_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupListComponent_318236_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ogrenciId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid"),
      header_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "CheckupList/CheckupListComponent_318236_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupExport = result?.data.checkupExport;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  CheckupListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CheckupForm", "OgrenciId", this.props.screenInputs.OgrenciId);
    KuikaAppManager.addToPageInputVariables("CheckupForm", "SinifId", this.props.screenInputs.SinifId);
    KuikaAppManager.addToPageInputVariables("CheckupForm", "CheckupId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "CheckupForm",
      "CheckupDonemiId",
      ReactSystemFunctions.value(
        this,
        this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.aktifCheckupDonemiId : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CheckupList",
      "CheckupForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "375px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupListComponent_736424_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CheckupListComponent_736424_onClick1_();
    });

    return isErrorOccurred;
  };
  CheckupListComponent_736424_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR")) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables("CheckupRaporu", "ShowDownload", true);
      KuikaAppManager.addToPageInputVariables(
        "CheckupRaporu",
        "CheckupId",
        ReactSystemFunctions.value(this, "checkuplist_399769_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.CheckupListComponent_736424_onClick2_,
        "CheckupList",
        "CheckupRaporu",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "986930",
        null,
        "right",
        null,
        "1048px",
        "vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.CheckupListComponent_736424_onClick2_();
      });
    }

    return isErrorOccurred;
  };
  CheckupListComponent_736424_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US")) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "CheckupRaporuEN",
        "CheckupId",
        ReactSystemFunctions.value(this, "checkuplist_399769_value", "id")
      );
      KuikaAppManager.addToPageInputVariables("CheckupRaporuEN", "ShowDownload", true);

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "CheckupList",
        "CheckupRaporuEN",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "589307",
        null,
        "right",
        null,
        "1048px",
        "vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  CheckupListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CheckupForm",
      "OgrenciId",
      ReactSystemFunctions.value(this, "checkuplist_399769_value", "ogrenciId")
    );
    KuikaAppManager.addToPageInputVariables(
      "CheckupForm",
      "SinifId",
      ReactSystemFunctions.value(this, "checkuplist_399769_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables(
      "CheckupForm",
      "CheckupId",
      ReactSystemFunctions.value(this, "checkuplist_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CheckupForm",
      "CheckupDonemiId",
      ReactSystemFunctions.value(this, "checkuplist_399769_value", "checkupDonemiId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CheckupList",
      "CheckupForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "375px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "checkuplist_399769_value", "id"),
        "Guid"
      ),
      ogrenciId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "checkuplist_399769_value", "ogrenciId"),
        "Guid"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Bu ölçüm silmek istediğine emin misin?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "CheckupList/CheckupListComponent_505853_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.CheckupDeleteById = result?.data.checkupDeleteById;
        stateVars.CheckupListByOgrenci = result?.data.checkupListByOgrenci;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.CheckupListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CheckupListByOgrenci;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 986930, 589307, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CheckupListPageInit();
    }
    if (diId == 986930) {
      isErrorOccurred = await this.CheckupListComponent_736424_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
