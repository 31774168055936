import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITicariIletisimOnay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITicariIletisimOnay_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
}

export class TicariIletisimOnay_ScreenBase extends React.PureComponent<ITicariIletisimOnay_ScreenProps, any> {
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        {
          Id: 470693,
          PropertyName: "value",
          Value: "Permission for Message Sending under Law No. 6698 and Law No. 6563",
          TagName: "Label1_value"
        },
        {
          Id: 596038,
          PropertyName: "value",
          Value:
            "I hereby consent to the use of my contact information for sending newsletters and other commercial electronic communications to me, as specified in the Privacy Notice under Law No. 6698.",
          TagName: "Label2_value"
        }
      ]
    },
    {
      Id: "3d7ed462-8817-4184-acbb-0ee643bae5c9",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 470693, PropertyName: "value", Value: "6698 SK ve 6563 SK KAPASAMINDA İLETİ GÖNDERİM İZNİ" },
        {
          Id: 596038,
          PropertyName: "value",
          Value:
            "https://memap.com.tr/- 6698 SK kapsamında Aydınlatma Bildirimine belirtildiği şekilde iletişim verilerimin tarafıma bülten ve sair ticari e-ileti gönderiminde kullanılmasına izin veriyorum."
        }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ticariiletisimonay", "ticariiletisimonay", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();

    KuikaAppManager.calculateAndSetBodyHeight("ticariiletisimonay", "ticariiletisimonay", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ticariiletisimonay", "ticariiletisimonay", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  TicariIletisimOnayComponent_516590_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
