import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISezonForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISezonForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  SezonSelectById: any[];
  OkulSelectAll: any[];
  SetFocusTo: any;
  SezonSave: number;
  isCompsezonform_2562758AuthorizationVisible: "visible" | "hidden";
}

export class SezonForm_ScreenBase extends React.PureComponent<ISezonForm_ScreenProps, any> {
  sezonform_65683230_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Season Description", TagName: "lblHead_value" },
        { Id: 6261052, PropertyName: "value", Value: "School", TagName: "lblOkulId_value" },
        { Id: 65683230, PropertyName: "placeholder", Value: "Choose", TagName: "selOkulId_placeholder" },
        { Id: 8370206, PropertyName: "value", Value: "Season Name", TagName: "lblTanim_value" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "d48d9bc1-bd6b-4802-bd53-e34827b94c78",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Sezon Tanımı" },
        { Id: 6261052, PropertyName: "value", Value: "Okul" },
        { Id: 65683230, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 8370206, PropertyName: "value", Value: "Sezon Adı" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.sezonform_65683230_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      SezonSelectById: [],
      OkulSelectAll: [],
      SetFocusTo: "",
      SezonSave: 0,
      isCompsezonform_2562758AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sezonform", "sezonform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.SezonFormPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsezonform_2562758AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("sezonform", "sezonform", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("sezonform", "sezonform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.SezonFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      sezonform_65683230_value:
        this.state.SezonSelectById?.length > 0 ? this.state.SezonSelectById[0].okulId : undefined,
      sezonform_90147480_value: this.state.SezonSelectById?.length > 0 ? this.state.SezonSelectById[0].tanim : undefined
    });
  }

  SezonFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sezonId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SezonForm/SezonFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SezonSelectById = result?.data.sezonSelectById;
    formVars.sezonform_65683230_value =
      stateVars.SezonSelectById?.length > 0 ? stateVars.SezonSelectById[0]?.okulId : null;
    formVars.sezonform_65683230_options = stateVars.OkulSelectAll;
    formVars.sezonform_90147480_value = ReactSystemFunctions.toString(
      this,
      stateVars.SezonSelectById?.length > 0 ? stateVars.SezonSelectById[0]?.tanim : null
    );
    stateVars.OkulSelectAll = result?.data.okulSelectAll;

    formVars.sezonform_65683230_value =
      stateVars.SezonSelectById?.length > 0 ? stateVars.SezonSelectById[0]?.okulId : null;
    formVars.sezonform_65683230_options = stateVars.OkulSelectAll;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SezonFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SezonFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("65683230");
    formVars.sezonform_65683230_value = ReactSystemFunctions.toString(
      this,
      this.state.SezonSelectById?.length > 0 ? this.state.SezonSelectById[0]?.okulId : null
    );

    stateVars.dataSource_65683230 = this.state.OkulSelectAll;
    formVars.sezonform_90147480_value = ReactSystemFunctions.toString(
      this,
      this.state.SezonSelectById?.length > 0 ? this.state.SezonSelectById[0]?.tanim : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SezonFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SezonFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sezonform_65683230_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "sezonform_65683230_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sezonform_90147480_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "sezonform_90147480_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OkulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sezonform_65683230_value", "value", "OkulSelectAll", "id", "")
        ),
        "Guid"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sezonform_90147480_value", "value", "", "", "")
        ),
        "string"
      ),
      SezonId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SezonForm/SezonFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SezonSave = result?.data.sezonSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SezonFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SezonFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
