import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgrencininVelileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgrencininVelileri_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  OgrencininVelileri: any[];
  GoBack: any;
}

export class OgrencininVelileri_ScreenBase extends React.PureComponent<IOgrencininVelileri_ScreenProps, any> {
  ogrencininvelileri_399769_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Student's Parents", TagName: "Label5_value" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblOgrenci_nodatafoundmessage"
        },
        { Id: 809041, PropertyName: "title", Value: "Name", TagName: "TableColumn6_title" },
        { Id: 305733, PropertyName: "value", Value: "[datafield:name]", TagName: "Label5_0_value" },
        { Id: 893668, PropertyName: "title", Value: "Email", TagName: "TableColumn5_title" },
        { Id: 862047, PropertyName: "value", Value: "[datafield:email]", TagName: "Label4_0_value" },
        { Id: 1197933, PropertyName: "title", Value: "Phone number", TagName: "clmCinsiyet_title" },
        { Id: 8464216, PropertyName: "value", Value: "[datafield:phone]", TagName: "lblCinsiyet_value" }
      ]
    },
    {
      Id: "1b64087d-5fa5-469f-9f03-3c0aab992d21",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Öğrencinin Velileri" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 809041, PropertyName: "title", Value: "Adı" },
        { Id: 305733, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 893668, PropertyName: "title", Value: "Eposta" },
        { Id: 862047, PropertyName: "value", Value: "[datafield:eposta]" },
        { Id: 1197933, PropertyName: "title", Value: "Telefon" },
        { Id: 8464216, PropertyName: "value", Value: "[datafield:telefon]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogrencininvelileri_399769_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      OgrencininVelileri: [],
      GoBack: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogrencininvelileri", "ogrencininvelileri", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgrencininVelileriPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("ogrencininvelileri", "ogrencininvelileri", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogrencininvelileri", "ogrencininvelileri", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgrencininVelileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  OgrencininVelileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      ogrenciId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgrenciId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgrencininVelileri/OgrencininVelileriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.OgrencininVelileri = result?.data.ogrencininVelileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrencininVelileriPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrencininVelileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OgrencininVelileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrencininVelileriComponent_647140_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
