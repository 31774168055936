import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISinifForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISinifForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  SinifSelectById: any[];
  OkulSelectAll: any[];
  SetFocusTo: any;
  SinifSave: number;
  isCompsinifform_461350AuthorizationVisible: "visible" | "hidden";
}

export class SinifForm_ScreenBase extends React.PureComponent<ISinifForm_ScreenProps, any> {
  sinifform_417343_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Class Description", TagName: "lblHead_value" },
        { Id: 117779, PropertyName: "value", Value: "School", TagName: "Label1_0_value" },
        { Id: 417343, PropertyName: "placeholder", Value: "Choose", TagName: "selOkul_placeholder" },
        { Id: 9336317, PropertyName: "value", Value: "Class Name/Branch", TagName: "lblAdi_value" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    {
      Id: "4acccaf1-5a76-4c6b-81bd-56e12ee2bae0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Sınıf Tanımı" },
        { Id: 117779, PropertyName: "value", Value: "Okul" },
        { Id: 417343, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9336317, PropertyName: "value", Value: "Sınıf Adı / Şube" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.sinifform_417343_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      SinifSelectById: [],
      OkulSelectAll: [],
      SetFocusTo: "",
      SinifSave: 0,
      isCompsinifform_461350AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sinifform", "sinifform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.SinifFormPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsinifform_461350AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("sinifform", "sinifform", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("sinifform", "sinifform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.SinifFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      sinifform_417343_value: this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0].okulId : undefined,
      sinifform_19747450_value: this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0].adi : undefined
    });
  }

  SinifFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifForm/SinifFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    formVars.sinifform_417343_value =
      stateVars.SinifSelectById?.length > 0 ? stateVars.SinifSelectById[0]?.okulId : null;
    formVars.sinifform_417343_options = stateVars.OkulSelectAll;
    formVars.sinifform_19747450_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifSelectById?.length > 0 ? stateVars.SinifSelectById[0]?.adi : null
    );
    stateVars.OkulSelectAll = result?.data.okulSelectAll;

    formVars.sinifform_417343_value =
      stateVars.SinifSelectById?.length > 0 ? stateVars.SinifSelectById[0]?.okulId : null;
    formVars.sinifform_417343_options = stateVars.OkulSelectAll;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("86205030");
    formVars.sinifform_417343_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0]?.okulId : null
    );

    stateVars.dataSource_417343 = this.state.OkulSelectAll;
    formVars.sinifform_19747450_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0]?.adi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sinifform_19747450_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "sinifform_19747450_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sinifform_417343_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "sinifform_417343_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "sinifform_19747450_value", "value", "", "", "")
        ),
        "string"
      ),
      SezonId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifform_417343_value", "value", "OkulSelectAll", "id", "aktifSezonId")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifForm/SinifFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifSave = result?.data.sinifSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
