import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IValidationData } from "../../models/dto/validation-data";
import { KMainFunctions } from "./main-functions";

declare let window: any;
const confJson = require("../json/conf.json");

export class KuikaAppManager {
  public static addToPageInputVariables(screen: any, paramName: string, val: any) {
    if (!window.kuika) window.kuika = {};
    if (!window.kuika?.navigationScreenInputs) window.kuika.navigationScreenInputs = {};
    window.kuika.navigationScreenInputs[paramName] = val;
  }

  public static increaseServerRequestCount() {
    if (!window.kuika) window.kuika = {};
    if (window.kuika?.serverRequestCount === undefined) window.kuika.serverRequestCount = 0;
    window.kuika.serverRequestCount++;
  }

  public static resetServerRequestCount() {
    if (!window.kuika) window.kuika = {};
    window.kuika.serverRequestCount = 0;
  }

  public static prepareForNavigation() {
    KuikaAppManager.resetServerRequestCount();
    window.kuika.navigationScreenInputs = {} as any;
  }

  static GetBackendUrl(forOnlyInfo = false) {
    if (window._env_ && window._env_.BACKEND_URL) {
      return window._env_.BACKEND_URL;
    }

    let backendUrl = "";

    // JSON case
    if (backendUrl === "" && confJson?.backendUrlOverride?.length > 1) {
      backendUrl = confJson.backendUrlOverride;
      if (!backendUrl.endsWith("/v1/api")) {
        backendUrl = `${backendUrl}/v1/api`;
      }
    }

    // Web component case
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app?.manifest?.appName === confJson.applicationName && app?.manifest?.workspace === confJson?.domainName) {
          if (app?.manifest?.backendUrlOverride?.length && app?.manifest?.backendUrlOverride.length > 0) {
            backendUrl = KuikaAppManager.removeTrailingSlash(app?.manifest?.backendUrlOverride);
            if (!backendUrl.endsWith("/v1/api")) {
              backendUrl = `${backendUrl}/v1/api`;
            }
          } else {
            backendUrl = KuikaAppManager.getBackendUrlFromFullUrl(app?.appConfig?.frontEndUrl);
          }
        }
      });
    }

    if (backendUrl === "") {
      backendUrl = KuikaAppManager.getBackendUrlFromFullUrl(KMainFunctions.windowLocationOrigin);
    }

    if (backendUrl.indexOf("localhost") > -1 && backendUrl.indexOf("https") === 0) {
      const port = this.getPortFromURL(backendUrl);
      if (port !== undefined && port.length > 0) {
        let difference: number = 0;
        if (forOnlyInfo === false) {
          difference = 200;
        }
        const sslPort = (Number(port) + difference).toString();
        backendUrl = backendUrl.replace(port, sslPort);
      }
    }

    return KuikaAppManager.removeTrailingSlash(backendUrl);
  }

  static getPortFromURL(url: string) {
    const regex = /^(http|https):\/\/[^:\/]+(?::(\d+))?/;
    const match = url.match(regex);
    if (match === null || match === undefined) {
      return undefined;
    }
    return match[2] ? match[2] : undefined;
  }

  static IsWebComponent() {
    let result = false;
    // Web component case
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app?.manifest?.appName === confJson.applicationName && app?.manifest?.workspace === confJson?.domainName) {
          result = true;
        }
      });
    }
    return result;
  }

  static GetFrontEndSourceUrl() {
    let result = "";
    // Web component case
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app?.manifest?.appName === confJson.applicationName && app?.manifest?.workspace === confJson?.domainName) {
          result = KuikaAppManager.removeTrailingSlash(app?.appConfig?.frontEndUrl);
        }
      });
    }
    if (result === "") {
      result = KuikaAppManager.GetFrontEndUrl();
    }
    result = KuikaAppManager.removeTrailingSlash(result);
    return result;
  }

  static GetFrontEndUrl() {
    let origin = KMainFunctions.windowLocationOrigin;
    let result = KuikaAppManager.removeTrailingSlash(origin);
    return result;
  }

  public static getBackendUrlFromFullUrl = (baseUrl: string) => {
    baseUrl = KuikaAppManager.removeTrailingSlash(baseUrl);
    if (baseUrl.indexOf("localhost") > -1)
      return `${window.location.protocol === "file:" ? "http:" : window.location.protocol}//localhost:20001/v1/api`;
    let kuikaUrls = ["https://localhost", "https://demo.kuika.com", "https://beta.kuika.com", "https://designerdev.kuika.com", "https://designerdev2.kuika.com", "https://alpha.kuika.com", "https://preprod.kuika.com", "https://platform.kuika.com", "https://izzymobility.kuika.com", "https://idata.kuika.com", "https://alpha.kuika.com", "https://designerdev1.kuika.com","https://test.kuika.com","https://test1.kuika.com","https://test2.kuika.com","https://perftest.kuika.com"];
    if (kuikaUrls.indexOf(baseUrl) > -1) return baseUrl + ":20001/v1/api";
    return KuikaAppManager.getApiUrlFromBaseUrl(baseUrl);
  };

  public static getApiUrlFromBaseUrl = (baseUrl: string) => {
    baseUrl = KuikaAppManager.removeTrailingSlash(baseUrl);
    const re = new RegExp("(.*//)([a-zA-Z0-9-]+)(..*)");
    let result = "";
    const isDesignTime = window.kuika && window.kuika.isDesignTime;
    if (isDesignTime === true || baseUrl.indexOf(".kuika.com/") === -1) {
      // Design, UAT, Prod etc. Env.
      result = baseUrl.replace(re, "$1$2api$3/v1/api");
    } else {
      // Kuika Preview Env (Run)
      result = baseUrl.replace(re, "$1$2$3-api/v1/api");
    }
    return result;
  };

  public static removeTrailingSlash(inputString) {
    if (inputString.endsWith("/")) {
      return inputString.slice(0, -1);
    }
    return inputString;
  }

  public static ApiRequest = async (screen: any, requestPath, vars?: any, httpMethod = "post") => {
    try {
      let backendUrl = KuikaAppManager.GetBackendUrl();
      if (!backendUrl.endsWith("/")) backendUrl += "/";
      const url = backendUrl + requestPath;
      const config: AxiosRequestConfig = {
        headers: {
          projectHash: "446f404abfd1e72db76798ebd95238009355926a49418cdc0668b435feeca213" || ""
        }
      };

      if (httpMethod === "post") {
        const res = await Axios.post<any, AxiosResponse>(url, vars, config);
        return res;
      }
      if (httpMethod === "get") {
        const res = await Axios.get<any, AxiosResponse>(url, config);
        return res;
      }
      if (httpMethod === "put") {
        const res = await Axios.put<any, AxiosResponse>(url, vars, config);
        return res;
      }
      if (httpMethod === "delete") {
        const res = await Axios.delete<any, AxiosResponse>(url, config);
        return res;
      }
    } catch (ex: any) {
      KuikaAppManager.hideSpinner(screen);
      KMainFunctions.exceptionHandler(ex);
      return ex;
    }
  };

  public static showSpinner(screen: any) {
    screen.setState({ spinnerCount: screen.state.spinnerCount + 1 });
  }

  public static hideSpinner(screen: any) {
    screen.setState({ spinnerCount: 0 });
  }

  public static calculateAndSetBodyHeight(screenName: string, masterScreenName: string) {
    setTimeout(() => {
      const body = window.document.getElementById(`${screenName}_body`);
      if (!body) return;
      let headerHeight: number = 0;
      let footerHeight: number = 0;

      const header = window.document.getElementById(`${screenName}_header`);
      const footer = window.document.getElementById(`${screenName}_footer`);
      if (!header && !footer) {
        const layout_header = window.document.getElementById(
          `${masterScreenName.replace("_screen", "")}_layout_header`
        );
        const layout_footer = window.document.getElementById(
          `${masterScreenName.replace("_screen", "")}_layout_footer`
        );
        // const layout_left = window.document.getElementById(`${masterScreenName.replace("_screen", "")}_layout_left`);
        // const layout_right = window.document.getElementById(`${masterScreenName.replace("_screen", "")}_layout_right`);
        if (layout_header) headerHeight = layout_header ? parseFloat(window.getComputedStyle(layout_header).height) : 0;
        if (layout_footer) footerHeight = layout_footer ? parseFloat(window.getComputedStyle(layout_footer).height) : 0;
      } else {
        headerHeight = header ? parseFloat(window.getComputedStyle(header).height) : 0;
        footerHeight = footer ? parseFloat(window.getComputedStyle(footer).height) : 0;
      }

      const layout_right = window.document.getElementById(`${masterScreenName.replace("_screen", "")}_layout_right`);
      const layout_footer = window.document.getElementById(`${masterScreenName.replace("_screen", "")}_layout_footer`);
      const layout_header = window.document.getElementById(`${masterScreenName.replace("_screen", "")}_layout_header`);
      const layout_left = window.document.getElementById(`${masterScreenName.replace("_screen", "")}_layout_left`);
      const layout_left_rect = layout_left ? layout_left.getBoundingClientRect() : undefined;
      const layout_right_rect = layout_right ? layout_right.getBoundingClientRect() : undefined;
      const layout_footer_rect = layout_footer ? layout_footer.getBoundingClientRect() : undefined;
      const isChildOfMasterRootDI = (element: HTMLElement) => {
        if (!element) return false;
        if (element.id === "masterRootDI") return true;
        return isChildOfMasterRootDI(element.parentElement);
      };
      const isHeaderExistInMasterDI = isChildOfMasterRootDI(header);
      const isFooterExistInMasterDI = isChildOfMasterRootDI(footer);
      if (header && header.style && isHeaderExistInMasterDI) {
        const leftAndRightLayoutsWidth = layout_left_rect?.width + layout_right_rect?.width;
        header.style.zIndex = 1;
        header.style.width = leftAndRightLayoutsWidth > 0 ? `calc(100% - ${leftAndRightLayoutsWidth}px)` : "100%";
        if (layout_right && layout_right.style) {
          layout_right.style.zIndex = 1;
        }
      }

      if (footer && footer.style && isFooterExistInMasterDI) {
        const leftAndRightLayoutsWidth = layout_left_rect?.width + layout_right_rect?.width;
        footer.style.zIndex = 1;
        footer.style.width = leftAndRightLayoutsWidth > 0 ? `calc(100% - ${leftAndRightLayoutsWidth}px)` : "100%";
        footer.style.bottom = layout_footer && layout_footer_rect ? `${layout_footer_rect.height}px` : "0px";
        if (layout_right && layout_right.style) {
          layout_right.style.zIndex = 1;
        }
      }

      if (body && body.style) {
        const parentModal = body.closest(".ant-modal-body");
        if (parentModal) {
          const modalComputedStyle = window.getComputedStyle(parentModal);
          const modalHeight = parseFloat(modalComputedStyle.height);
          const modalPadding = parseFloat(modalComputedStyle.padding);
          body.style.height = `calc(${modalHeight}px - ${headerHeight + footerHeight + modalPadding}px)`;
          body.style.minHeight = `calc(${modalHeight}px - ${headerHeight + footerHeight + modalPadding}px)`;
          body.style.maxHeight = `calc(${modalHeight}px - ${headerHeight + footerHeight + modalPadding}px)`;
          body.style.overflowY = "auto";
        } else {
          body.style.height = `calc(100vh - ${headerHeight + footerHeight}px)`;
          body.style.minHeight = `calc(100vh - ${headerHeight + footerHeight}px)`;
          body.style.maxHeight = `calc(100vh - ${headerHeight + footerHeight}px)`;
          body.style.overflow = "auto";
          if (header && layout_header) {
            body.style.paddingTop = `${header.getBoundingClientRect()?.height ?? 0}px`;
          }
          if (footer && layout_footer) {
            body.style.paddingBottom = `${footer.getBoundingClientRect()?.height ?? 0}px`;
          }
        }
      }
    }, 1);
  }

  public static handleValidations(screen: any, data: IValidationData[]): boolean {
    const validationsErrorsToRemove = data.filter(
      (x) => x.condition === false || (x.condition === true && x.isValid === true)
    );
    const validationsErrorsToShow = data.filter((x) => x.condition === true && x.isValid === false);
    const hasValidationError = validationsErrorsToShow.length > 0;

    validationsErrorsToRemove.forEach((x) => {
      screen.props.form.setFields([
        {
          name: x.formName,
          errors: []
        }
      ]);
    });

    validationsErrorsToShow.forEach((x) => {
      screen.props.form.setFields([
        {
          name: x.formName,
          errors: [x.message]
        }
      ]);
    });

    if (hasValidationError) {
      this.hideSpinner(screen);
    }
    return hasValidationError;
  }

  public static isPageInitPrevented(): boolean {
    if (!window?.kuika?.pageInitCallers) return false;
    // TODO:TURENC FURKAN, CAGAN bu bölüm Virtual directoryler için ayrı normal hosting için ayrı test edilmeli!
    const hash = window.location.hash?.substring(2);
    const path = window.location.pathname?.substring(1);
    if (hash && window.kuika.pageInitCallers[hash] !== undefined && window.kuika.pageInitCallers[hash] === false)
      return true;
    if (path && window.kuika.pageInitCallers[path] !== undefined && window.kuika.pageInitCallers[path] === false)
      return true;
    return false;
  }
}
