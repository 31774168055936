// MSAL imports
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../authConfig";
import { EExternalTokenProviderTypes, IExternalTokenRequest } from "../../models/dto/login.dto";
import { UserService } from "../../services/user-service";
import { withHistory } from "../../shared/hoc/with-history";

const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

let popupOpened = false;
let kuikaRestApiCalled = false;
const MsalLogin = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  if (instance !== undefined && isAuthenticated === true && kuikaRestApiCalled === false) {
    const cache: any = instance.getTokenCache();
    let accesstoken = "";
    if (
      cache !== undefined &&
      cache.storage !== undefined &&
      cache.storage.browserStorage.windowStorage !== undefined
    ) {
      Object.entries(cache.storage.browserStorage.windowStorage).forEach((entry) => {
        const [k, v] = entry;
        if (k !== undefined && v !== undefined && k.indexOf("accesstoken") > -1) {
          accesstoken = JSON.parse(v as any).secret;
        }
      });

      let refreshtoken = "";
      Object.entries(cache.storage.browserStorage.windowStorage).forEach((entry) => {
        const [k, v] = entry;
        if (k !== undefined && v !== undefined && k.indexOf("refreshtoken") > -1) {
          refreshtoken = JSON.parse(v as any).secret;
        }
      });

      if (
        accesstoken !== null &&
        accesstoken !== undefined &&
        accesstoken !== "" &&
        refreshtoken !== null &&
        refreshtoken !== undefined &&
        refreshtoken !== ""
      ) {
        const externalTokenRequest: IExternalTokenRequest = {
          providerType: EExternalTokenProviderTypes.AzureAD,
          refreshToken: refreshtoken,
          tokenString: accesstoken,
          username: ""
        };

        kuikaRestApiCalled = true;
        UserService.LoginWithExternalToken(externalTokenRequest).then(
          (res) => {
            if (res && res.status === 200) {
              if (props?.from?.pathname?.length > 0) {
                props.navigator.gotoUrl(props.from.pathname);
              } else {
                props.navigator.gotoStartingScreen();
              }
            }
          },
          (error) => {
            if (error.response?.data?.message) {
              alert(error.response.data.message);
              window.location.reload();
            }
          }
        );
      }
    }
  }

  if (isAuthenticated !== true && popupOpened == false) {
    popupOpened = true;
    instance.loginPopup(loginRequest);
    // instance.loginRedirect(loginRequest);
  }
  return <>Conneting azure active directory, please wait...</>;
};

const msalLogin = withHistory(MsalLogin);
export { msalLogin as MsalLogin };
