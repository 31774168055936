import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardKatilimDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardKatilimDetay_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  SinifSelectById: any[];
  SinifIstatistik: any[];
  SinifSezonIstatistikleri: any[];
  SinifSezonIstatistikleriHeader: any[];
  isComp929642Visible: "visible" | "hidden";
  isComp599288Visible: "visible" | "hidden";
  isComp978882Visible: "visible" | "hidden";
  isComp466823Visible: "visible" | "hidden";
  isComp898685Visible: "visible" | "hidden";
}

export class DashboardKatilimDetay_ScreenBase extends React.PureComponent<IDashboardKatilimDetay_ScreenProps, any> {
  dashboardkatilimdetay_292008_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        {
          Id: 292008,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 407697, PropertyName: "title", Value: "Class", TagName: "TableColumn1_0_title" },
        { Id: 895341, PropertyName: "value", Value: "[datafield:classname]", TagName: "Label13_value" },
        { Id: 827704, PropertyName: "title", Value: "School Number", TagName: "TableColumn9_title" },
        { Id: 955438, PropertyName: "value", Value: "[datafield:schoolnumber]", TagName: "Label0_0_value" },
        { Id: 836138, PropertyName: "title", Value: "Student Name", TagName: "TableColumn7_title" },
        { Id: 233094, PropertyName: "value", Value: "[datafield:studentname]", TagName: "Label0_0_value" },
        { Id: 688239, PropertyName: "title", Value: "FitSkor® 1", TagName: "TableColumn2_title" },
        { Id: 833105, PropertyName: "value", Value: "[datafield:fitskor1]", TagName: "Label14_value" },
        { Id: 929642, PropertyName: "title", Value: "FitSkor® 2", TagName: "TableColumn3_title" },
        { Id: 916321, PropertyName: "value", Value: "[datafield:fitskor2]", TagName: "Label15_value" },
        { Id: 599288, PropertyName: "title", Value: "FitSkor® 3", TagName: "TableColumn4_title" },
        { Id: 403215, PropertyName: "value", Value: "[datafield:fitskor3]", TagName: "Label16_value" },
        { Id: 978882, PropertyName: "title", Value: "FitSkor® 4", TagName: "TableColumn5_title" },
        { Id: 661945, PropertyName: "value", Value: "[datafield:fitskor4]", TagName: "Label17_value" },
        { Id: 466823, PropertyName: "title", Value: "FitSkor® 5", TagName: "TableColumn6_title" },
        { Id: 807996, PropertyName: "value", Value: "[datafield:fitskor5]", TagName: "Label0_0_value" },
        { Id: 898685, PropertyName: "title", Value: "FitSkor® 6", TagName: "TableColumn1_title" },
        { Id: 183846, PropertyName: "value", Value: "[datafield:fitskor6]", TagName: "Label0_0_value" },
        { Id: 920071, PropertyName: "value", Value: "Participation Report", TagName: "Label18_value" },
        { Id: 429560, PropertyName: "value", Value: "Class:", TagName: "Label0_0_value" },
        { Id: 413420, PropertyName: "value", Value: "Report:", TagName: "Label0_0_value" },
        { Id: 48329, PropertyName: "value", Value: "Participation", TagName: "Label0_1_value" }
      ]
    },
    {
      Id: "ba0b9744-714c-4b65-bfd3-9f1944d894c5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 920071, PropertyName: "value", Value: "Katılım Raporu" },
        { Id: 429560, PropertyName: "value", Value: "Sınıf:" },
        { Id: 413420, PropertyName: "value", Value: "Rapor:" },
        { Id: 48329, PropertyName: "value", Value: "Katılım" },
        { Id: 292008, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 407697, PropertyName: "title", Value: "Sınıf" },
        { Id: 895341, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 827704, PropertyName: "title", Value: "Okul No" },
        { Id: 955438, PropertyName: "value", Value: "[datafield:okulno]" },
        { Id: 836138, PropertyName: "title", Value: "Öğrenci Adı" },
        { Id: 233094, PropertyName: "value", Value: "[datafield:ogrenciadi]" },
        { Id: 688239, PropertyName: "title", Value: "FitSkor® 1" },
        { Id: 833105, PropertyName: "value", Value: "[datafield:fitskor1]" },
        { Id: 929642, PropertyName: "title", Value: "FitSkor® 2" },
        { Id: 916321, PropertyName: "value", Value: "[datafield:fitskor2]" },
        { Id: 599288, PropertyName: "title", Value: "FitSkor® 3" },
        { Id: 403215, PropertyName: "value", Value: "[datafield:fitskor3]" },
        { Id: 978882, PropertyName: "title", Value: "FitSkor® 4" },
        { Id: 661945, PropertyName: "value", Value: "[datafield:fitskor4]" },
        { Id: 466823, PropertyName: "title", Value: "FitSkor® 5" },
        { Id: 807996, PropertyName: "value", Value: "[datafield:fitskor5]" },
        { Id: 898685, PropertyName: "title", Value: "FitSkor® 6" },
        { Id: 183846, PropertyName: "value", Value: "[datafield:fitskor6]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardkatilimdetay_292008_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      SinifSelectById: [],
      SinifIstatistik: [],
      SinifSezonIstatistikleri: [],
      SinifSezonIstatistikleriHeader: [],
      isComp929642Visible: "hidden",
      isComp599288Visible: "hidden",
      isComp978882Visible: "hidden",
      isComp466823Visible: "hidden",
      isComp898685Visible: "hidden"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardkatilimdetay", "dashboardkatilimdetay", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.DashboardKatilimDetayPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("dashboardkatilimdetay", "dashboardkatilimdetay", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardkatilimdetay", "dashboardkatilimdetay", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.DashboardKatilimDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  DashboardKatilimDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sinifId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardKatilimDetay/DashboardKatilimDetayPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    formVars.dashboardkatilimdetay_435167_value = ReactSystemFunctions.toString(
      this,
      stateVars.SinifSelectById?.length > 0 ? stateVars.SinifSelectById[0]?.adi : null
    );
    stateVars.SinifIstatistik = result?.data.sinifIstatistik;
    stateVars.isComp929642Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu2Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu3Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp978882Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu4Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466823Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu5Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898685Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SinifIstatistik?.length > 0 ? stateVars.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SinifSezonIstatistikleri = result?.data.sinifSezonIstatistikleri;

    stateVars.SinifSezonIstatistikleriHeader = result?.data.sinifSezonIstatistikleriHeader;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DashboardKatilimDetayPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardKatilimDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardkatilimdetay_435167_value = ReactSystemFunctions.toString(
      this,
      this.state.SinifSelectById?.length > 0 ? this.state.SinifSelectById[0]?.adi : null
    );

    stateVars.dataSource_292008 = this.state.SinifSezonIstatistikleri;
    formVars.dashboardkatilimdetay_372430_columnTitles = ReactSystemFunctions.toString(
      this,
      this.state.SinifSezonIstatistikleriHeader?.length > 0 ? this.state.SinifSezonIstatistikleriHeader[0]?.ad : null
    );

    stateVars.isComp929642Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu2Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu3Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp978882Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu4Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466823Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu5Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898685Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SinifIstatistik?.length > 0 ? this.state.SinifIstatistik[0]?.cu6Id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardKatilimDetayComponent_603965_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
