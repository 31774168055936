import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgretmeninSiniflari_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgretmeninSiniflari_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  KUserById: any[];
  OgretmeninSiniflari: any[];
  GoBack: any;
  NAVIGATE: any;
  OgretmenSinifDelete: number;
  isCompogretmeninsiniflari_814413AuthorizationVisible: "visible" | "hidden";
}

export class OgretmeninSiniflari_ScreenBase extends React.PureComponent<IOgretmeninSiniflari_ScreenProps, any> {
  ogretmeninsiniflari_399769_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Teacher's Classes", TagName: "Label5_value" },
        { Id: 814413, PropertyName: "label", Value: "ADD CLASS", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblOgrenci_nodatafoundmessage"
        },
        { Id: 809041, PropertyName: "title", Value: "School number", TagName: "TableColumn6_title" },
        { Id: 305733, PropertyName: "value", Value: "[datafield:school]", TagName: "Label5_0_value" },
        { Id: 893668, PropertyName: "title", Value: "Season", TagName: "TableColumn5_title" },
        { Id: 862047, PropertyName: "value", Value: "[datafield:season]", TagName: "Label4_0_value" },
        { Id: 1197933, PropertyName: "title", Value: "Class/Branch", TagName: "clmCinsiyet_title" },
        { Id: 8464216, PropertyName: "value", Value: "[datafield:name]", TagName: "lblCinsiyet_value" },
        {
          Id: 97280608,
          PropertyName: "f4fa7790-518e-cc8f-3416-f6c9969e4b06_confirmation",
          Value: "Are you sure you want to delete the teacher's relationship with this class?",
          TagName: "OgretmenSinifDelete_confirmation",
          IsConfirmation: true
        }
      ]
    },
    {
      Id: "2e5de38b-0b8e-493f-b490-0519a80ed780",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Öğretmenin Sınıfları" },
        { Id: 814413, PropertyName: "label", Value: "SINIF EKLE" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 809041, PropertyName: "title", Value: "Okul No" },
        { Id: 305733, PropertyName: "value", Value: "[datafield:okulu]" },
        { Id: 893668, PropertyName: "title", Value: "Sezon" },
        { Id: 862047, PropertyName: "value", Value: "[datafield:sezon]" },
        { Id: 1197933, PropertyName: "title", Value: "Sınıf / Şube" },
        { Id: 8464216, PropertyName: "value", Value: "[datafield:adi]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogretmeninsiniflari_399769_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      KUserById: [],
      OgretmeninSiniflari: [],
      GoBack: "",
      NAVIGATE: "",
      OgretmenSinifDelete: "",
      isCompogretmeninsiniflari_814413AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogretmeninsiniflari", "ogretmeninsiniflari", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgretmeninSiniflariPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompogretmeninsiniflari_814413AuthorizationVisible", [
      "6b10b235-9cb1-853b-a664-6605fa303c8d",
      "Okul"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("ogretmeninsiniflari", "ogretmeninsiniflari", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogretmeninsiniflari", "ogretmeninsiniflari", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgretmeninSiniflariPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  OgretmeninSiniflariPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgretmenKUserId, "Guid"),
      lang_1: ReactSystemFunctions.convertToTypeByName("tr_Tr", "string"),
      ogretmenId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgretmenKUserId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgretmeninSiniflari/OgretmeninSiniflariPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.KUserById = result?.data.kUserById;
    stateVars.OgretmeninSiniflari = result?.data.ogretmeninSiniflari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgretmeninSiniflariPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmeninSiniflariPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OgretmeninSiniflari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmeninSiniflariComponent_323966_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmeninSiniflariComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OgretmenSinifEkle", "OgretmenSinifId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "OgretmenSinifEkle",
      "OgretmenKUserId",
      this.props.screenInputs.OgretmenKUserId
    );
    KuikaAppManager.addToPageInputVariables(
      "OgretmenSinifEkle",
      "OkulId",
      ReactSystemFunctions.value(this, this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.okulId : null)
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OgretmeninSiniflari",
      "OgretmenSinifEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "380px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmeninSiniflariComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ogretmeninsiniflari_399769_value", "ogretmenSinifId"),
        "Guid"
      ),
      ogretmenId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OgretmenKUserId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f4fa7790_518e_cc8f_3416_f6c9969e4b06_confirmation",
        this.defaultML,
        "Öğretmenin bu sınıf ile olan ilişkisini silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "OgretmeninSiniflari/OgretmeninSiniflariComponent_505853_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.OgretmenSinifDelete = result?.data.ogretmenSinifDelete;
        stateVars.OgretmeninSiniflari = result?.data.ogretmeninSiniflari;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.OgretmeninSiniflariComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmeninSiniflariComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.OgretmeninSiniflari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OgretmeninSiniflariPageInit();
    }
  }
}
