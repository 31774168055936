// MSAL imports
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import "animate.css/animate.min.css";
// import "antd/dist/antd.min.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import "react-notifications-component/dist/theme.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import KuikaAuthApp from "./app-types/kuika-auth-app";
import MsalApp from "./app-types/msal-auth-app";
import { msalConfig } from "./authConfig";
import { EApplicationType } from "./models/dto/save-application-request.dto";
import { IRouterType } from "./routes/router";
import { ApplicationService } from "./services/application-service";
import { KuikaAppManager } from "./shared/utilty/kuika-app-manager";
import "./styles/components/badge.scss";
import "./styles/components/divider.scss";
import "./styles/form.scss";
import "./styles/general.scss";
import "./styles/registration.scss";
// bootstrapper-imports-placeholder

export type IAuthType = "MSAL" | "Kuika";
export type IAppMode = "WebComponent" | "WebSite";

interface IBootStrapperProps {
  authType: IAuthType;
  routerType?: IRouterType;
  tagName?: string;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;

interface IBootstrapperState {
  // session-service-state-placeholder
  isMounted: boolean;
  remainingSessionTime: number;
  msalInstance: PublicClientApplication | null;
}

export class AppBootstrapper extends React.Component<IBootStrapperProps, IBootstrapperState> {
  // session-service-declaration-placeholder

  constructor(props: IBootStrapperProps) {
    super(props);
    this.state = {
      isMounted: false,
      // Initial value is set to 99999999999 to prevent the session warning modal from showing up on the first render.
      remainingSessionTime: 99999999999,
      msalInstance: null
    };
  }

  getProps = () => {
    if (this.props == undefined) return "";
    return JSON.stringify(this.props);
  };

  webComponentAttached() {
    console.log(`attachedCallback:${this.getProps()}`);
  }

  webComponentConnected() {
    console.log(`connectedCallback:${this.getProps()}`);
  }

  webComponentDisconnected() {
    console.log(`disconnectedCallback:${this.getProps()}`);
  }

  webComponentAttributeChanged() {
    console.log(`attributeChangedCallback:${this.getProps()}`);
  }

  webComponentAdopted() {
    console.log(`adoptedCallback:${this.getProps()}`);
  }

  saveAllApplicationsToSessionStorage = async () => {
    if (sessionStorage.getItem("all-applications") && sessionStorage.getItem("front-end-applications")) {
      return;
    }

    const response = await ApplicationService.getAllApplications();

    if (response.status !== 200) return;

    sessionStorage.setItem("all-applications", JSON.stringify(response.data));
    sessionStorage.setItem(
      "front-end-applications",
      JSON.stringify(response.data.filter((app) => app.applicationType === EApplicationType.Frontend))
    );
  };

  async componentDidMount(): Promise<void> {
    // CODE GENERATION FLAG!!!
    const IS_SAVE_APPLICATION_ENABLED: boolean = true;

    if (this.props.appMode === "WebSite" && IS_SAVE_APPLICATION_ENABLED) {
      await this.handleRequestSaveApplication();
      await this.saveAllApplicationsToSessionStorage();
    }

    this.setKuikaInfo();

    if (this.props.authType === "MSAL") {
      this.setState({ msalInstance: this.createMSALInstance() });
    }

    this.setState({ isMounted: true }, () => {
      localStorage.removeItem("isGoogleLoginInProgress");
      localStorage.removeItem("isGoogleLoginAborted");
    });

    // session-service-initialization-placeholder
  }

  componentWillUnmount() {
    // session-service-dispose-placeholder
  }

  setKuikaInfo() {
    if (this.props === undefined) {
      return;
    }
    if (!window.kuika) {
      window.kuika = {};
    }
    window.kuika.info = {};
    const appName = "webApp";
    const workspace = "meMap";
    const webComponentTag = "me-map-web-app";
    const frontEndUrl = KuikaAppManager.GetFrontEndSourceUrl();
    const backEndUrl = KuikaAppManager.GetBackendUrl(true);
    const { authType } = this.props;
    const html = String.raw`
      <html>
        <head>
        <script src='https://files.kuika.com/cdn/kuika-mfe_1_0_0.js'></script>
        <script>
        CreateKuikaMfeApp({frontEndUrl:'${frontEndUrl}'});
        </script>
        </head>
        <body>
          <me-map-web-app></me-map-web-app>
        </body>
      </html>`;
    window.kuika.info.workspace = workspace;
    window.kuika.info.applicationName = appName;
    window.kuika.info.webComponentTag = webComponentTag;
    window.kuika.info.frontEndUrl = frontEndUrl;
    window.kuika.info.backEndUrl = backEndUrl;
    window.kuika.info.authType = authType;
    window.kuika.html = html;
    window.kuika.getModule = () => {
      console.log(window.kuika.html);
    };
  }

  createMSALInstance(): PublicClientApplication {
    const msalInstance = new PublicClientApplication(msalConfig);
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const { account } = payload;
        msalInstance.setActiveAccount(account);
      }
    });

    return msalInstance;
  }

  handleRequestSaveApplication = async () => {
    const SAVE_APP_REQUEST_KEY = "SAVE_APP_REQUESTED";

    const isRequestedBefore: string = sessionStorage.getItem(SAVE_APP_REQUEST_KEY);

    if (isRequestedBefore) {
      return;
    }

    try {
      await ApplicationService.saveApplication();
      sessionStorage.setItem(SAVE_APP_REQUEST_KEY, "true");
    } catch (e) {
      setTimeout(this.handleRequestSaveApplication.bind(this), 10000);
    }
  };

  // session-timeout-methods-placeholder

  updateRemainingSessionTime = (remainingSessionTime: number) => {
    this.setState({ remainingSessionTime });
  };

  public render() {
    return (
      <GoogleOAuthProvider clientId="948495596316-cssed34v3s9qs7nc44e5b4csdba16kum.apps.googleusercontent.com">
        {this.props.authType === "MSAL" && this.state.msalInstance && (
          <MsalApp pca={this.state.msalInstance} routerType={this.props.routerType} appMode={this.props.appMode} />
        )}
        {this.props.authType === "Kuika" && (
          <KuikaAuthApp
            authType={this.props.authType}
            routerType={this.props.routerType}
            appMode={this.props.appMode}
            external_user={this.props.external_user}
            external_access_token={this.props.external_access_token}
            external_refresh_token={this.props.external_refresh_token}
          />
        )}
        {/* session-service-modal-placeholder */}
      </GoogleOAuthProvider>
    );
  }
}

export default AppBootstrapper;
