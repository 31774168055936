import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISinifTopluOlcumList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISinifTopluOlcumList_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  MyContext: any[];
  SinifSelectById: any[];
  OkulSelectById: any[];
  TopluOlcumListesiBySinif: any[];
  GoBack: any;
  NAVIGATE: any;
}

export class SinifTopluOlcumList_ScreenBase extends React.PureComponent<ISinifTopluOlcumList_ScreenProps, any> {
  siniftopluolcumlist_5249104_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Check-Up List", TagName: "Label5_value" },
        { Id: 494563, PropertyName: "label", Value: "NEW CHECK-UP", TagName: "Button1_label" },
        {
          Id: 5249104,
          PropertyName: "nodatafoundmessage",
          Value: "Data not found",
          TagName: "tblSinifinolcumleri_5249104_nodatafoundmessage"
        },
        { Id: 253055, PropertyName: "title", Value: "Check-Up Date", TagName: "TableColumn4_title" },
        { Id: 547233, PropertyName: "value", Value: "[datafield:date]", TagName: "Label3_0_value" },
        { Id: 2110045, PropertyName: "title", Value: "Evaluated Students", TagName: "clmOlcumAdedi_2110045_title" },
        { Id: 5775405, PropertyName: "value", Value: "[datafield:count]", TagName: "lblOlcumAdedi_5775405_value" }
      ]
    },
    {
      Id: "05ac2e05-3bda-4405-99f4-f6b46db90137",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 527823, PropertyName: "value", Value: "Ölçüm Listesi" },
        { Id: 494563, PropertyName: "label", Value: "YENİ ÖLÇÜM" },
        { Id: 5249104, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 253055, PropertyName: "title", Value: "Ölçüm Tarihi" },
        { Id: 547233, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 2110045, PropertyName: "title", Value: "Ölçülen Öğrenci" },
        { Id: 5775405, PropertyName: "value", Value: "[datafield:adet]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.siniftopluolcumlist_5249104_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      MyContext: [],
      SinifSelectById: [],
      OkulSelectById: [],
      TopluOlcumListesiBySinif: [],
      GoBack: "",
      NAVIGATE: ""
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siniftopluolcumlist", "siniftopluolcumlist", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.SinifTopluOlcumListPageInit();

    KuikaAppManager.calculateAndSetBodyHeight("siniftopluolcumlist", "siniftopluolcumlist", this.props.context);
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("siniftopluolcumlist", "siniftopluolcumlist", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.SinifTopluOlcumListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  SinifTopluOlcumListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sezonId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      okulId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sinifId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SinifTopluOlcumList/SinifTopluOlcumListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    stateVars.OkulSelectById = result?.data.okulSelectById;
    stateVars.TopluOlcumListesiBySinif = result?.data.topluOlcumListesiBySinif;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SinifTopluOlcumListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifTopluOlcumListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5249104 = this.state.TopluOlcumListesiBySinif;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifTopluOlcumListComponent_528764_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifTopluOlcumListComponent_494563_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SinifCheckupForm", "SinifId", this.props.screenInputs.SinifId);
    KuikaAppManager.addToPageInputVariables(
      "SinifCheckupForm",
      "CheckupDonemiId",
      ReactSystemFunctions.value(
        this,
        this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.aktifCheckupDonemiId : null
      )
    );
    KuikaAppManager.addToPageInputVariables("SinifCheckupForm", "TopluOlcumId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifTopluOlcumList",
      "SinifCheckupForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "508380",
      null,
      "right",
      null,
      "90vw",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifTopluOlcumListComponent_432852_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SinifCheckupForm", "SinifId", this.props.screenInputs.SinifId);
    KuikaAppManager.addToPageInputVariables(
      "SinifCheckupForm",
      "CheckupDonemiId",
      ReactSystemFunctions.value(
        this,
        this.state.OkulSelectById?.length > 0 ? this.state.OkulSelectById[0]?.aktifCheckupDonemiId : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "SinifCheckupForm",
      "TopluOlcumId",
      ReactSystemFunctions.value(this, "siniftopluolcumlist_5249104_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifTopluOlcumList",
      "SinifCheckupForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "16114",
      null,
      "right",
      null,
      "90vw",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [508380, 16114] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SinifTopluOlcumListPageInit();
    }
  }
}
