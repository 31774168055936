import { ConfigProvider } from "antd";
import "antd/dist/antd";
import * as React from "react";
import PWAPrompt from "react-ios-pwa-prompt";
import ReactNotification from "react-notifications-component";
import { Provider } from "react-redux";
import "../auth/interceptors";

// MSAL imports
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { memo } from "react";
import { IAppMode } from "../bootstrapper";
import configureStore from "../redux/root-reducer";
import { IRouterType, Router } from "../routes/router";
import { ReactSystemFunctions } from "../shared/utilty/react-system-functions";

const store = configureStore();

interface IAppProps {
  pca: IPublicClientApplication;
  routerType?: IRouterType;
  appMode?: IAppMode;
}

const MsalAuthApp = (props: IAppProps) => {
  return (
    <>
      {/* <React.StrictMode> */}
      <MsalProvider instance={props.pca}>
        <React.Suspense fallback="Loading...">
          <Provider store={store}>
            <ReactNotification />
            <ConfigProvider locale={ReactSystemFunctions.getLocale()}>
              <PWAPrompt
                copyBody="This website has app functionality. Add it to your home screen to use it in fullscreen"
                copyClosePrompt="Close"
                permanentlyHideOnDismiss
              />
              <Router authType="MSAL" routerType={props.routerType} appMode={props.appMode} />
            </ConfigProvider>
          </Provider>
        </React.Suspense>
      </MsalProvider>
      {/* </React.StrictMode> */}
    </>
  );
};

export default memo(MsalAuthApp);
