import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgrenciForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgrenciForm_ScreenState {
  isSpinnerVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  GoBack: any;
  MyContext: any[];
  SinifSelectById: any[];
  OgrenciSelectById: any[];
  OkulSelectAll: any[];
  SinifbySezon: any[];
  SetFocusTo: any;
  SetValueOf: any;
  OgrenciSave: number;
  isComp63296Visible: "visible" | "hidden";
  isComp5664546Visible: "visible" | "hidden";
  isCompogrenciform_63296AuthorizationVisible: "visible" | "hidden";
}

export class OgrenciForm_ScreenBase extends React.PureComponent<IOgrenciForm_ScreenProps, any> {
  ogrenciform_849178_value_kuikaSelectBoxRef: React.RefObject<any>;
  ogrenciform_62047180_value_kuikaSelectBoxRef: React.RefObject<any>;
  ogrenciform_320356_value_kuikaSelectBoxRef: React.RefObject<any>;
  ogrenciform_90990970_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "8532a9a8-08aa-44a1-b7b8-9f9ec146603d",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Student Form", TagName: "lblHead_value" },
        { Id: 666349, PropertyName: "value", Value: "School", TagName: "Label1_0_value" },
        { Id: 849178, PropertyName: "placeholder", Value: "Choose", TagName: "selOkul_placeholder" },
        { Id: 1215607, PropertyName: "value", Value: "Class", TagName: "lblMevcutSinifId_value" },
        { Id: 62047180, PropertyName: "placeholder", Value: "Choose", TagName: "selMevcutSinifId_placeholder" },
        { Id: 293764, PropertyName: "value", Value: "Student School Number", TagName: "Label1_0_value" },
        { Id: 2677041, PropertyName: "value", Value: "Student's Full Name", TagName: "lblAdi_value" },
        { Id: 7886820, PropertyName: "value", Value: "Biological sex", TagName: "lblCinsiyet_value" },
        { Id: 320356, PropertyName: "placeholder", Value: "Choose", TagName: "selCinsiyet_placeholder" },
        { Id: 6898044, PropertyName: "value", Value: "Birth Date", TagName: "lblDogumTarihi_value" },
        { Id: 90990970, PropertyName: "placeholder", Value: "Choose", TagName: "dtDogumTarihi_placeholder" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" },
        { Id: 345605, PropertyName: "value", Value: "School team branch", TagName: "Label1_0_value" }
      ]
    },
    {
      Id: "10847788-4736-4545-a265-f97d04eba7cf",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Öğrenci Kartı" },
        { Id: 666349, PropertyName: "value", Value: "Okulu" },
        { Id: 849178, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 1215607, PropertyName: "value", Value: "Sınıfı" },
        { Id: 62047180, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 293764, PropertyName: "value", Value: "Öğrenci Okul No" },
        { Id: 2677041, PropertyName: "value", Value: "Öğrecinin Tam Adı" },
        { Id: 7886820, PropertyName: "value", Value: "Biyolojik Cinsiyeti" },
        { Id: 320356, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 6898044, PropertyName: "value", Value: "Doğum Tarihi" },
        { Id: 90990970, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 345605, PropertyName: "value", Value: "Okul takım branşı" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ogrenciform_849178_value_kuikaSelectBoxRef = React.createRef();
    this.ogrenciform_62047180_value_kuikaSelectBoxRef = React.createRef();
    this.ogrenciform_320356_value_kuikaSelectBoxRef = React.createRef();
    this.ogrenciform_90990970_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      GoBack: "",
      MyContext: [],
      SinifSelectById: [],
      OgrenciSelectById: [],
      OkulSelectAll: [],
      SinifbySezon: [],
      SetFocusTo: "",
      SetValueOf: "",
      OgrenciSave: 0,
      dataSource_320356: [
        { key: "E", text: "Erkek" },
        { key: "K", text: "Kız" }
      ],
      dataSource_920825: [
        { key: "FUT", text: "Futbol" },
        { key: "BAS", text: "Basketbol" },
        { key: "VOL", text: "Voleybol" },
        { key: "CIM", text: "Cimnastik" },
        { key: "TEN", text: "Tenis" },
        { key: "ATL", text: "Atletizm" },
        { key: "OKC", text: "Okçuluk" }
      ],
      isComp63296Visible: "hidden",
      isComp5664546Visible: "hidden",
      isCompogrenciform_63296AuthorizationVisible: "visible"
    };
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogrenciform", "ogrenciform", this.props.context);
      return;
    }

    ReactSystemFunctions.validateToken();
    await this.OgrenciFormPageInit();
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompogrenciform_63296AuthorizationVisible", [
      "a95dea93-bf37-6428-c23c-5d3d26c7407f",
      "MeMap"
    ]);

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("ogrenciform", "ogrenciform", this.props.context);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogrenciform", "ogrenciform", this.props.context);
    if (prevProps?.location?.search !== this.props?.location?.search) {
      await this.OgrenciFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ogrenciform_849178_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].okulId : undefined,
      ogrenciform_62047180_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].mevcutSinifId : undefined,
      ogrenciform_23660_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].okulNo : undefined,
      ogrenciform_40755670_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].adi : undefined,
      ogrenciform_320356_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].cinsiyet : undefined,
      ogrenciform_90990970_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].dogumTarihi : undefined,
      ogrenciform_920825_value:
        this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0].brans : undefined
    });
  }

  OgrenciFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId, "Guid"),
      sezonId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SezonId, "Guid"),
      okulId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.OkulId, "Guid"),
      Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgrenciForm/OgrenciFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SinifSelectById = result?.data.sinifSelectById;
    stateVars.OgrenciSelectById = result?.data.ogrenciSelectById;
    formVars.ogrenciform_849178_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulId : null;
    formVars.ogrenciform_849178_options = stateVars.OkulSelectAll;
    formVars.ogrenciform_62047180_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.mevcutSinifId : null;
    formVars.ogrenciform_62047180_options = stateVars.SinifbySezon;
    formVars.ogrenciform_23660_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulNo : null
    );
    formVars.ogrenciform_40755670_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.adi : null
    );
    formVars.ogrenciform_320356_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.cinsiyet : null;
    formVars.ogrenciform_90990970_value = ReactSystemFunctions.toString(
      this,
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.dogumTarihi : null
    );
    formVars.ogrenciform_920825_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.brans : null;
    stateVars.OkulSelectAll = result?.data.okulSelectAll;

    formVars.ogrenciform_849178_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.okulId : null;
    formVars.ogrenciform_849178_options = stateVars.OkulSelectAll;
    stateVars.SinifbySezon = result?.data.sinifbySezon;

    formVars.ogrenciform_62047180_value =
      stateVars.OgrenciSelectById?.length > 0 ? stateVars.OgrenciSelectById[0]?.mevcutSinifId : null;
    formVars.ogrenciform_62047180_options = stateVars.SinifbySezon;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("62047180");
    stateVars.isComp63296Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.SinifId, null) === true ? "visible" : "hidden";
    formVars.ogrenciform_849178_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.okulId : null
    );

    stateVars.dataSource_849178 = this.state.OkulSelectAll;
    stateVars.isComp5664546Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.SinifId, null) === true ? "visible" : "hidden";
    formVars.ogrenciform_62047180_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.mevcutSinifId : null
    );

    stateVars.dataSource_62047180 = this.state.SinifbySezon;
    formVars.ogrenciform_23660_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.okulNo : null
    );

    formVars.ogrenciform_40755670_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.adi : null
    );

    formVars.ogrenciform_320356_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.cinsiyet : null
    );

    formVars.ogrenciform_90990970_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.dogumTarihi : null
    );

    formVars.ogrenciform_920825_value = ReactSystemFunctions.toString(
      this,
      this.state.OgrenciSelectById?.length > 0 ? this.state.OgrenciSelectById[0]?.brans : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciFormComponent_849178_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ogrenciform_62047180_value", null, null, "Id");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciFormComponent_849178_onChange1_();
    });

    return isErrorOccurred;
  };
  OgrenciFormComponent_849178_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_849178_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "ogrenciform_849178_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sezonId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_849178_value", "value", "OkulSelectAll", "id", "aktifSezonId")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgrenciForm/OgrenciFormComponent_849178_onChange1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifbySezon = result?.data.sinifbySezon;

    formVars.ogrenciform_62047180_options = stateVars.SinifbySezon;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciFormComponent_849178_onChange2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciFormComponent_849178_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_62047180 = this.state.SinifbySezon;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgrenciFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_849178_value", "value", "OkulSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "ogrenciform_849178_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_62047180_value", "value", "SinifbySezon", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "ogrenciform_62047180_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_40755670_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogrenciform_23660_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_40755670_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogrenciform_40755670_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_320356_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "ogrenciform_320356_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_90990970_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ogrenciform_90990970_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OkulId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_849178_value", "value", "OkulSelectAll", "id", "id")
        ),
        "Guid"
      ),
      SinifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_62047180_value", "value", "SinifbySezon", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id, "Guid"),
      okulNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_23660_value", "value", "", "", "")
        ),
        "string"
      ),
      Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_40755670_value", "value", "", "", "")
        ),
        "string"
      ),
      Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_320356_value", "value", "", "key", "")
        ),
        "string"
      ),
      DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_90990970_value", "value", "", "", "")
        ),
        "Date"
      ),
      brans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogrenciform_920825_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "OgrenciForm/OgrenciFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OgrenciSave = result?.data.ogrenciSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.OgrenciFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgrenciFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
